import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getProductData } from '../../services/productService';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { OptionsDiffChecker } from '@cimpress-technology/react-product-experience-rules';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';

const VersionsWrapper = ({ version1, version2, productID, accessToken }) => {
  const [v1, setV1] = useState([]);
  const [v2, setV2] = useState([]);
  const [loader, setLoader] = useState(true);

  const version1Query = useQuery('product1', () => getProductData({ productID, version: version1, accessToken }), {
    retry: false,
  });

  const product1 = version1Query.data;

  const version2Query = useQuery('product2', () => getProductData({ productID, version: version2, accessToken }), {
    retry: false,
  });
  const product2 = version2Query.data;

  const Loading = () => (
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center">
          <Spinner />
        </div>
      </div>
    </div>
  );

  const quantityIsAdded = (product) => {
    return isEmpty(product.options.filter((option) => option.name === 'Quantity')) ? false : true;
  };

  useEffect(() => {
    if (product1 && product2) {
      !quantityIsAdded(product1) &&
        product1.options.push({
          name: 'Quantity',
          type: 'number',
          values: get(product1, 'quantity'),
        });

      !quantityIsAdded(product2) &&
        product2.options.push({
          name: 'Quantity',
          type: 'number',
          values: get(product2, 'quantity'),
        });

      setV1(get(product1, 'options'));
      setV2(get(product2, 'options'));
      setLoader(false);
    }
  }, [product1, product2]);

  return (
    <div>
      {loader && <Loading />}
      {!isEmpty(v1) && !isEmpty(v2) && !loader && <OptionsDiffChecker v1={v1} v2={v2} />}
    </div>
  );
};

export default VersionsWrapper;
