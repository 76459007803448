import { defineMessages } from 'react-intl';

export default defineMessages({
  selectValuesLabel: {
    id: 'HexValuesSelector.selectValuesLabel',
    defaultMessage: 'Select Values...',
  },
  addValuesLabel: {
    id: 'HexValuesSelector.addValuesLabel',
    defaultMessage: 'Add Values...',
  },
  duplicateValue: {
    id: 'HexValuePill.duplicateValue',
    defaultMessage: 'Value already exists',
  },
  duplicateValueWithValue: {
    id: 'HexValuePill.duplicateValueWithValue',
    defaultMessage: 'Value {value} already exists',
  },
  invalidHexValue: {
    id: 'HexValuePill.invalidHexValue',
    defaultMessage: 'Invalid hex value added. Must be in format #xxx or #xxxxxx',
  },
});
