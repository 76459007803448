import React, { Fragment, useState, useEffect } from 'react';
import { css } from 'emotion';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import NavTab from '@cimpress/react-components/lib/NavTab';
import NavTabItem from '@cimpress/react-components/lib/NavTabItem';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import DiffCard from './DiffCard';
import { getCategoryById } from '../../../services/catalogSchema';
import { NAME, DESCRIPTION, CATEGORY, VERSION } from '../constants';
import messages from '../messages';

const navTabStyle = css`
  flex: 1;
`;

const navBodyStyle = css`
  flex: 3;
  padding-left: 10px;
`;

const displayFlex = css`
  display: flex;
`;

const center = css`
  text-align: center;
`;

const fetchCategory = async (version, accessToken) => {
  try {
    const href = get(version, 'relatedResources.productCategory.0.href');
    const catId = href.split('/')[5];
    const category = await getCategoryById({ categoryId: catId, accessToken });
    return category;
  } catch (err) {
    console.warn(err);
  }
};

const ProductDataTab = ({ productV1, productV2, accessToken }) => {
  const { formatMessage } = useIntl();
  const [selectedTab, setSelectedTab] = useState(NAME);
  const [productDataV1, setProductDataV1] = useState({});
  const [productDataV2, setProductDataV2] = useState({});

  useEffect(() => {
    (async () => {
      if (!isEmpty(productV1)) {
        setProductDataV1({
          [VERSION]: productV1.version,
          [NAME]: productV1.name,
          [DESCRIPTION]: productV1.description,
          [CATEGORY]: await fetchCategory(productV1, accessToken),
        });
      }
    })();
  }, [productV1, setProductDataV1, accessToken]);

  useEffect(() => {
    (async () => {
      if (!isEmpty(productV2)) {
        setProductDataV2({
          [VERSION]: productV2.version,
          [NAME]: productV2.name,
          [DESCRIPTION]: productV2.description,
          [CATEGORY]: await fetchCategory(productV2, accessToken),
        });
      }
    })();
  }, [productV2, setProductDataV2, accessToken]);

  const onSelectName = () => {
    setSelectedTab(NAME);
  };
  const onSelectDescription = () => {
    setSelectedTab(DESCRIPTION);
  };
  const onSelectCategory = () => {
    setSelectedTab(CATEGORY);
  };

  const notEmptyInBothVersions = (key) => {
    return !(isEmpty(productDataV1[key]) && isEmpty(productDataV2[key]));
  };

  const loading = isEmpty(productDataV1) || isEmpty(productDataV2);

  return !loading ? (
    <Fragment>
      <div className={displayFlex}>
        <NavTab vertical={true} className={navTabStyle}>
          {notEmptyInBothVersions(NAME) && (
            <NavTabItem active={selectedTab === NAME}>
              <button onClick={onSelectName}>{formatMessage(messages.name)}</button>
            </NavTabItem>
          )}
          {notEmptyInBothVersions(DESCRIPTION) && (
            <NavTabItem active={selectedTab === DESCRIPTION}>
              <button onClick={onSelectDescription}>{formatMessage(messages.description)}</button>
            </NavTabItem>
          )}
          {notEmptyInBothVersions(CATEGORY) && (
            <NavTabItem active={selectedTab === CATEGORY}>
              <button onClick={onSelectCategory}>{formatMessage(messages.category)}</button>
            </NavTabItem>
          )}
        </NavTab>
        <div className={navBodyStyle}>
          <DiffCard header={selectedTab} productDataV1={productDataV1} productDataV2={productDataV2} />
        </div>
      </div>
    </Fragment>
  ) : (
    <Spinner size="large" className={center} />
  );
};

export default ProductDataTab;
