export const uomNameToAbbreviationDictionary = {
  kilograms: 'kg',
  grams: 'g',
  pounds: 'lbs',
  milliliters: 'ml',
  meters: 'm',
  centimeters: 'cm',
  millimeters: 'mm',
  microns: 'µm',
  inches: 'in',
  feet: 'ft',
  'dots per inch': 'dpi',
  pieces: 'pcs',
  'grams per square meter': 'gsm',
  years: 'year',
  months: 'month',
  days: 'd',
  hours: 'h',
  hexcode: 'hexcode',
};

export default {
  uomNameToAbbreviationDictionary,
};
