import { defineMessages } from 'react-intl';

export default defineMessages({
  hexValueEditorTitle: {
    id: 'HexValueEdior.hexValueEditorTitle',
    defaultMessage: 'This is a hex value editor',
  },
  enterHexValue: {
    id: 'HexValueEdior.enterHexValue',
    defaultMessage: 'Enter a Hex Value',
  },
});
