import React, { Fragment } from 'react';
import ProductLinkViewCard from '../../ProductLinkViewCard';

const PreviewResult = ({
  previewProductLink,
  accessToken,
  account,
  productTypes,
  standardsHaveAdoptions,
  setSelections,
}) => {
  return (
    <Fragment>
      <h4>Preview Product Link</h4>
      <ProductLinkViewCard
        previewProductLink={previewProductLink}
        accessToken={accessToken}
        account={account}
        productTypes={productTypes}
        standardsHaveAdoptions={standardsHaveAdoptions}
        setSelections={setSelections}
      />
    </Fragment>
  );
};

export default PreviewResult;
