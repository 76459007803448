import { css } from 'emotion';

export const inlineBlock = css`
  display: inline-block;
`;

export const flex = css`
  display: flex;
`;

export const topNoMargin = css`
  margin-top: 0px;
`;

export const threePxMarginTop = css`
  margin-top: 3px;
`;

export const fivePxMarginLeft = css`
  margin-left: 5px;
`;

export const fivePxMarginRight = css`
  margin-right: 5px;
`;

export const fivePxMarginTop = css`
  margin-top: 5px;
`;

export const fivePxMarginBottom = css`
  margin-bottom: 5px;
`;

export const fivePxMarginRightLeft = css`
  margin: 0px 5px;
`;

export const tenPxMarginRight = css`
  margin-right: 10px;
`;

export const tenPxMarginLeft = css`
  margin-left: 10px;
`;

export const tenPxMarginBottom = css`
  margin-bottom: 10px;
`;

export const tenPxMarginTop = css`
  margin-top: 10px;
`;

export const tenPxMargin = css`
  margin: 10px;
`;

export const twentyPxMarginRight = css`
  margin-right: 20px;
`;

export const twentyPxMarginBottom = css`
  margin-bottom: 20px;
`;

export const thirtyPxMarginTop = css`
  margin-top: 30px;
`;

export const thirtyPxMarginBottom = css`
  margin-bottom: 30px;
`;

export const thirtyPxMarginRight = css`
  margin-right: 30px;
`;
