import fetchWithAuth from '../../services/fetchWithAuth';

export const useBesClient = (baseBesUrl, accessToken) => {
  /**
   * Submit mapping to BES
   * @param {string} srcProductId
   * @param {string} targetProductId
   * @param {(BesVersionMapping|BesIrregularEvolution)} payload
   * @return {Promise<{isSuccess: boolean}>}
   */
  const putMapping = async (srcProductId, targetProductId, payload) => {
    try {
      await fetchWithAuth({
        baseUrl: baseBesUrl,
        method: 'PUT',
        route: srcProductId === targetProductId ? '/api/v1/evolutions' : '/api/v1/evolutions:split',
        body: payload,
        giveSimpleResponse: false,
        accessToken,
      });
      return { isSuccess: true };
    } catch (e) {
      console.error(e);
    }
    return { isSuccess: false };
  };

  async function postValidation(payload) {
    try {
      await fetchWithAuth({
        baseUrl: baseBesUrl,
        method: 'POST',
        route: '/api/v1/validations/relationship',
        body: payload,
        giveSimpleResponse: false,
        accessToken,
      });
      return { isSuccess: true };
    } catch (e) {
      console.error(e);
    }
    return { isSuccess: false };
  }

  async function getVersionEvolution(srcId, srcVersion, targetVersion) {
    try {
      let res = await fetchWithAuth({
        baseUrl: baseBesUrl,
        method: 'GET',
        route: `/api/v1/evolutions/${srcId}/${srcVersion}/target/${targetVersion}/`,
        giveSimpleResponse: false,
        accessToken,
      });
      return { isSuccess: res.response.status === 200, data: res.body };
    } catch (e) {
      console.error(e);
    }
    return { isSuccess: false };
  }

  return {
    putMapping,
    postValidation,
    getVersionEvolution,
  };
};
