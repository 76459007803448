import get from 'lodash/get';

import fetchWithAuth from './fetchWithAuth';

const baseUrl = 'https://ham.products.cimpress.io';

// deprecated
export const getHAMData = async ({ productId, associatedBusinessId, accessToken }) => {
  let route = `v2/hams?associatedBusinessIds=${associatedBusinessId}&productId=${productId}`;

  let hamResponse = await fetchWithAuth({
    baseUrl,
    route,
    additionalHeaders: { 'Cache-Control': 'no-cache' },
    accessToken,
  });
  return hamResponse;
};

export const getV2HamSummaries = async ({ productId, associatedBusinessId, accessToken }) => {
  let route = `v2/hams/summaries?associatedBusinessIds=${associatedBusinessId}&productId=${productId}`;

  let hamResponse = await fetchWithAuth({
    baseUrl,
    route,
    additionalHeaders: { 'Cache-Control': 'no-cache' },
    accessToken,
  });
  return hamResponse;
};

export const getV2HamById = async ({ id, accessToken }) => {
  return await fetchWithAuth({
    baseUrl,
    route: `v2/hams/${id}`,
    additionalHeaders: { 'Cache-Control': 'no-cache' },
    accessToken,
  });
};

export const getAdoption = async (link, accessToken) => {
  let hamResponse = await fetchWithAuth({ link, accessToken });
  return hamResponse;
};

export const fetchHam = async (id, accessToken) => {
  return await fetchWithAuth({
    baseUrl,
    route: `v1/hams/${id}`,
    additionalHeaders: { 'Cache-Control': 'no-cache' },
    accessToken,
  });
};

export const updateHam = async ({ id, node, accessToken }) => {
  const body = {
    name: node.name,
    description: node.description,
    associatedBusiness: node.associatedBusiness,
    attributeModelUrl: node.attributeModelUrl,
    productUrl: node.productUrl,
    parentHam: node.parentHam,
  };
  return await fetchWithAuth({ baseUrl, route: `v1/hams/${id}`, method: 'PUT', body, accessToken });
};

export const updateHamNodeAttributeModel = async ({ id, attributeModelResponse, accessToken }) => {
  await fetchHam(id, accessToken).then(async (response) => {
    response.attributeModelUrl = attributeModelResponse._links.self.href;
    response.name = attributeModelResponse.constraints.rules[0].name;

    await updateHam({ id: response.id, node: response, accessToken });
  });
};

export const updateHamNodeProductModel = async ({ id, productUrl, accessToken }) => {
  await fetchHam(id, accessToken).then(async (response) => {
    response.productUrl = productUrl;

    await updateHam({ id: response.id, node: response, accessToken });
  });
};

export const createChildHam = async ({
  parentNode,
  attributeModel,
  associatedBusinessId,
  productLinkUrl,
  accessToken,
}) => {
  const body = {
    name: get(attributeModel, `constraints.rules[0].name`),
    description: attributeModel.description,
    associatedBusiness: associatedBusinessId,
    attributeModelUrl: attributeModel._links.self.href,
    productUrl: parentNode.isProduct || parentNode.isAdoption ? productLinkUrl : null,
    parentHam: !(parentNode.isProduct || parentNode.isAdoption) ? parentNode.id : null,
  };

  return await fetchWithAuth({ baseUrl, route: `v1/hams`, method: `POST`, body, accessToken });
};

export const createAdoptionHam = async ({
  selectedNode,
  newHamName,
  associatedBusinessId,
  productLinkUrl,
  accessToken,
}) => {
  const body = {
    name: newHamName,
    description: null, // Might need another description here
    associatedBusiness: associatedBusinessId,
    attributeModelUrl: null,
    productId: selectedNode.productId,
    productUrl: productLinkUrl,
    standardId: selectedNode.standardId,
  };

  return await fetchWithAuth({ baseUrl, route: `v1/hams`, method: `POST`, body, accessToken });
};

export const createV2AdoptionHam = async ({
  selectedNode,
  newHamName,
  associatedBusinessId,
  productLinkUrl,
  accessToken,
}) => {
  const body = {
    name: newHamName,
    description: null, // Might need another description here
    associatedBusiness: associatedBusinessId,
    constraintId: null, // Assuming this is null for now, like the old v1/ham model with attributeModelUrl
    productId: selectedNode.productId,
    productUrl: productLinkUrl,
    standardId: selectedNode.standardId,
  };

  return await fetchWithAuth({ baseUrl, route: `v2/hams`, method: `POST`, body, accessToken });
};
