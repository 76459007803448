import React, { Fragment } from 'react';
import { css } from 'emotion';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import Card from '@cimpress/react-components/lib/Card';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import { info, shale } from '@cimpress/react-components/lib/colors';
import { VERSION } from '../constants';
import messages from '../messages';

const displayFlex = css`
  display: flex;
`;

const valuesText = css`
  color: ${info.darkest};
  font-size: 13px;
`;

const unchangedValuesText = css`
  color: ${shale};
  font-size: 13px;
`;

const versionBlock = css`
  margin-bottom: 20px;
  flex: 1;
`;

const center = css`
  text-align: center;
`;

const DiffCard = ({ header, productDataV1, productDataV2 }) => {
  const { formatMessage } = useIntl();

  const cardContent =
    !isEmpty(productDataV1) && !isEmpty(productDataV2) ? (
      productDataV1[header] === productDataV2[header] ? (
        <Fragment>
          <h5 className={unchangedValuesText}>{formatMessage(messages.commonInBothVersions)}</h5>
          <div>{productDataV1[header]}</div>
        </Fragment>
      ) : (
        <Fragment>
          <div className={displayFlex}>
            <div className={versionBlock}>
              <h5 className={valuesText}>{formatMessage(messages.version, { version: productDataV1[VERSION] })}</h5>
              {productDataV1[header]}
            </div>
            <div className={versionBlock}>
              <h5 className={valuesText}>{formatMessage(messages.version, { version: productDataV2[VERSION] })}</h5>
              {productDataV2[header]}
            </div>
          </div>
        </Fragment>
      )
    ) : (
      <Spinner size="medium" className={center} />
    );

  return (
    <Card key={header} header={header}>
      {cardContent}
    </Card>
  );
};

export default DiffCard;
