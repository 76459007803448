import React from 'react';
import { css } from 'emotion';
import { platinum } from '@cimpress/react-components/lib/colors';
import SingleConditionDisplay from './SingleConditionDisplay';

const ruleDisplayBox = css`
  border-radius: 2px;
  border: 1px solid ${platinum};
  padding: 10px;
  width: 90%;
`;

const MultipleConditionsDisplay = ({ conditions }) => {
  return (
    <div className={ruleDisplayBox}>
      {conditions.map((condition) => {
        return (
          <div key={JSON.stringify(condition)}>
            <SingleConditionDisplay
              attribute={condition.attribute}
              operator={condition.operator}
              values={condition.values}
            />
          </div>
        );
      })}
    </div>
  );
};

export default MultipleConditionsDisplay;
