import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { css, cx } from 'emotion';

import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import Accordion from '@cimpress/react-components/lib/Accordion';
import { warning } from '@cimpress/react-components/lib/colors';
import Button from '@cimpress/react-components/lib/Button';
import Modal from '@cimpress/react-components/lib/Modal';
import { ocean, platinum, white, coal } from '@cimpress/react-components/lib/colors';

import { VersionDropdown } from './VersionDropdown';
import { getProductInfoFromUrl, getProductPMVUrl } from '../../dataHelper';
import { PIN_TO_CURRENT, VIEW, CURRENT, selectorType } from '../../../../shared/enums/constants';
import messages from '../messages';

const warningDiv = css`
  display: flex;
  align-items: center;
  height: 100px;
  padding: 10px;
`;

const buttonsDiv = css`
  display: flex;
  justify-content: flex-end;
`;

const flexDiv = css`
  display: flex;
  align-content: center;
`;

const adoptionHamDisplay = css`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto 95px;
`;

const adoptionDiv = css`
  display: grid;
  grid-template-columns: fit-content(65%) auto auto;
  background-color: ${white};
  border: 1px solid ${platinum};
  border-radius: 3px;
  padding: 10px 16px 10px 16px;
  box-shadow: 0 1px 2px 0 ${platinum};
  color: ${coal};
  margin: 0 0 12px 0;
`;

const linkStyle = css`
  color: ${ocean.base};
  text-align: right;
  float: right !important;
  &:hover {
    cursor: pointer;
  }
`;

const versionNumber = css`
  color: ${ocean.base};
  margin-left: 8px;
`;

const center = css`
  text-align: center;
`;

export const AdoptionAccordion = ({
  ancestor,
  headerStyle,
  bodyStyle,
  style,
  index,
  getVersions,
  updateHamVersion,
  mode,
  getCurrentVersion,
  onAdoptionVersionChange,
  isAdoptionAsHam,
  type,
}) => {
  const { formatMessage } = useIntl();
  const [productLinkUrl, setProductLinkurl] = useState();
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [toValue, setToValue] = useState();
  const [newLink, setNewLink] = useState();
  const [fromValue, setFromValue] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedProductUrl, setSelectedProductUrl] = useState('');
  const [versionDetails, setVersionDetails] = useState({ pmvUrl: '', version: '' });
  const [isUpdatingVersion, setIsUpdatingVersion] = useState(false);

  useEffect(() => {
    if (isUpdatingVersion) {
      updateHamVersion(index, newLink).then(() => {
        setNewLink(undefined);
        setIsUpdatingVersion(false);
        closeModal();
      });
    }
  }, [isUpdatingVersion, index, newLink, updateHamVersion]);

  useEffect(() => {
    if (isEqual(mode, VIEW) && !isEmpty(ancestor.productUrl)) {
      if (ancestor.productUrl && ancestor.productUrl.includes(CURRENT)) {
        getCurrentVersion(ancestor.productId).then((response) => {
          let pmvUrl = getProductPMVUrl(ancestor.productId, response.version);
          setVersionDetails({ pmvUrl, version: response.version, isPinToCurrent: true });
        });
      } else {
        setVersionDetails(getProductInfoFromUrl(ancestor.productUrl, ancestor.productId));
      }
      setSelectedProductUrl(ancestor.productUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, ancestor]);

  const getProductLinkUrl = (version) => {
    return `https://api.products.cimpress.io/v1/products/${ancestor.productId}/versions/${version}`;
  };

  const getCurrentVersionLink = () => {
    return `https://api.products.cimpress.io/v1/products/${ancestor.productId}:current`;
  };

  const closeModal = () => {
    setSaveModalOpen(false);
  };

  const openModal = () => {
    setSaveModalOpen(true);
  };

  const updateVersion = () => {
    setSelectedProductUrl(newLink);
    setIsUpdatingVersion(true);
  };

  const changeValue = (e, oldSelectedValue) => {
    if (e.value === PIN_TO_CURRENT) {
      setToValue('Current');
      setNewLink(getCurrentVersionLink());
    } else {
      setToValue(`v${e.value}`);
      setNewLink(getProductLinkUrl(e.value));
    }

    if (oldSelectedValue === PIN_TO_CURRENT) {
      setFromValue('Current');
    } else {
      setFromValue(`v${oldSelectedValue}`);
    }

    openModal();
  };

  const onVersionDropdownChange = (e, oldSelectedValue, fullSelectedVersion) => {
    setSelectedProductUrl(fullSelectedVersion.url);
    onAdoptionVersionChange(e, oldSelectedValue, fullSelectedVersion);
  };

  const adoptionViewMode = (
    <div className={cx(adoptionDiv, adoptionHamDisplay)}>
      <span>{ancestor.title}</span>
      {(isEmpty(ancestor.productUrl) || isAdoptionAsHam) && !isEqual(type, selectorType.PREVIEW) ? (
        <VersionDropdown
          productId={ancestor.productId}
          selectedProductUrl={selectedProductUrl}
          onChange={onVersionDropdownChange}
          getVersions={getVersions}
          setProductLinkUrl={setProductLinkurl}
          setLoading={setLoading}
        />
      ) : (
        <span className={versionNumber}>
          ( v{versionDetails.version} {versionDetails.isPinToCurrent && '- Pinned to Current'} )
        </span>
      )}
      <a href={versionDetails.pmvUrl} className={linkStyle} target="_blank" rel="noreferrer">
        {formatMessage(messages.viewAdoption)}
      </a>
    </div>
  );

  return isEqual(mode, VIEW) ? (
    adoptionViewMode
  ) : (
    <Accordion
      key={`product-${index}`}
      title={<span>{ancestor.name}</span>}
      headerStyle={headerStyle}
      bodyStyle={bodyStyle}
      style={style}>
      <div className={flexDiv}>
        <VersionDropdown
          productId={ancestor.productId}
          selectedProductUrl={selectedProductUrl}
          onChange={changeValue}
          getVersions={getVersions}
          setProductLinkUrl={setProductLinkurl}
          setLoading={setLoading}
        />
        {loading ? null : (
          <Button href={productLinkUrl} target="_blank" type="link">
            {formatMessage(messages.viewProduct)}
          </Button>
        )}
      </div>

      <Modal
        style={{ borderTop: `4px solid ${warning.base}`, borderRadius: '5px' }}
        type="warning"
        show={saveModalOpen}
        onRequestHide={closeModal}
        title={formatMessage(messages.sureToChangeModalTitle, { fromValue: fromValue, toValue: toValue })}
        closeButton={true}
        footer={
          <div className={buttonsDiv}>
            <button className="btn btn-default" onClick={closeModal}>
              {formatMessage(messages.cancel)}
            </button>
            <button className="btn btn-primary" onClick={updateVersion}>
              {formatMessage(messages.yes)}
            </button>
          </div>
        }>
        {isUpdatingVersion ? (
          <Spinner size="medium" className={center} />
        ) : (
          <div className={warningDiv}>
            <p>{formatMessage(messages.changeModalBody)}</p>
          </div>
        )}
      </Modal>
    </Accordion>
  );
};
