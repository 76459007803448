import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { css } from 'emotion';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import Alert from '@cimpress/react-components/lib/Alert';
import NavTab from '@cimpress/react-components/lib/NavTab';
import NavTabItem from '@cimpress/react-components/lib/NavTabItem';
import Accordion from '@cimpress/react-components/lib/Accordion';

import ConstraintCard from '../../../constraints/constraintCard';
import RuleDisplay from '../../../shared/ruleDisplay';
import { getConstraintDiffs } from '../../../shared/DiffUtilities/constraints';
import {
  IDENTICAL_CONSTRAINTS,
  ADDED_CONSTRAINTS,
  REMOVED_CONSTRAINTS,
  UPDATED_CONSTRAINTS,
} from '../../../shared/DiffUtilities/constants';
import messages from '../messages';

const center = css`
  text-align: center;
`;

const displayFlex = css`
  display: flex;
`;

const navTabStyle = css`
  flex: 1;
`;

const navBodyStyle = css`
  flex: 3;
  padding-left: 10px;
`;

const ConstraintsTab = ({ productV1, productV2 }) => {
  const { formatMessage } = useIntl();
  const [constraintsDiff, setConstraintsDiffs] = useState({});
  const [selectedTab, setSelectedTab] = useState(ADDED_CONSTRAINTS);
  const [expand, setExpand] = useState(null);

  useEffect(() => {
    const constraintsV1 = get(productV1, 'constraints');
    const constraintsV2 = get(productV2, 'constraints');
    setConstraintsDiffs(getConstraintDiffs(constraintsV1, constraintsV2));
  }, [productV1, productV2, setConstraintsDiffs]);

  const hasUpdate = useMemo(() => {
    if (constraintsDiff) {
      for (let key of Object.keys(constraintsDiff)) {
        if (key !== IDENTICAL_CONSTRAINTS && !isEmpty(constraintsDiff[key])) {
          return true;
        }
      }
    }
    return false;
  }, [constraintsDiff]);

  const removedConstraints = (
    <Fragment>
      {!isEmpty(constraintsDiff) &&
        Object.keys(constraintsDiff[REMOVED_CONSTRAINTS]).map((rule) => {
          return (
            <ConstraintCard
              title={rule}
              readOnly={true}
              editing={false}
              content={
                <div className="card-block">
                  <RuleDisplay
                    conditions={constraintsDiff[REMOVED_CONSTRAINTS][rule].conditions}
                    results={constraintsDiff[REMOVED_CONSTRAINTS][rule].results}
                  />
                </div>
              }
            />
          );
        })}
    </Fragment>
  );
  const addedConstraints = (
    <Fragment>
      {!isEmpty(constraintsDiff) &&
        Object.keys(constraintsDiff[ADDED_CONSTRAINTS]).map((rule) => {
          return (
            <ConstraintCard
              title={rule}
              readOnly={true}
              editing={false}
              content={
                <div className="card-block">
                  <RuleDisplay
                    conditions={constraintsDiff[ADDED_CONSTRAINTS][rule].conditions}
                    results={constraintsDiff[ADDED_CONSTRAINTS][rule].results}
                  />
                </div>
              }
            />
          );
        })}
    </Fragment>
  );
  const updatedConstraints = (
    <Fragment>
      {!isEmpty(constraintsDiff) &&
        Object.keys(constraintsDiff[UPDATED_CONSTRAINTS]).map((rule) => {
          const oldConstraint = constraintsDiff[UPDATED_CONSTRAINTS][rule].oldConstraint;
          const newConstraint = constraintsDiff[UPDATED_CONSTRAINTS][rule].newConstraint;
          return (
            <Accordion
              title={rule}
              variant="ghost"
              bodyStyle={{ padding: '5px 15px' }}
              headerStyle={{ display: 'flex', alignItems: 'center' }}
              customOpen={expand === rule}
              key={rule}
              onHeaderClick={() => {
                setExpand(rule);
              }}>
              <h4>{formatMessage(messages.version, { version: productV1.version })}</h4>
              <ConstraintCard
                title={rule}
                readOnly={true}
                editing={false}
                content={
                  <div className="card-block">
                    <RuleDisplay conditions={oldConstraint.conditions} results={oldConstraint.results} />
                  </div>
                }
              />

              <h4>{formatMessage(messages.version, { version: productV2.version })}</h4>
              <ConstraintCard
                title={rule}
                readOnly={true}
                editing={false}
                content={
                  <div className="card-block">
                    <RuleDisplay conditions={newConstraint.conditions} results={newConstraint.results} />
                  </div>
                }
              />
            </Accordion>
          );
        })}
    </Fragment>
  );

  const tabContent =
    selectedTab === ADDED_CONSTRAINTS
      ? addedConstraints
      : selectedTab === REMOVED_CONSTRAINTS
      ? removedConstraints
      : updatedConstraints;

  const onClickAddedConstraints = () => {
    setSelectedTab(ADDED_CONSTRAINTS);
  };

  const onClickRemovedConstraints = () => {
    setSelectedTab(REMOVED_CONSTRAINTS);
  };

  const onClickUpdatedConstraints = () => {
    setSelectedTab(UPDATED_CONSTRAINTS);
  };

  const loading = isEmpty(constraintsDiff);
  return !loading ? (
    <Fragment>
      {hasUpdate ? (
        <div className={displayFlex}>
          <div className={navTabStyle}>
            <NavTab vertical={true}>
              <NavTabItem active={selectedTab === ADDED_CONSTRAINTS}>
                <button onClick={onClickAddedConstraints}>
                  {formatMessage(messages.addedConstraints) +
                    ` (${Object.keys(constraintsDiff[ADDED_CONSTRAINTS]).length})`}
                </button>
              </NavTabItem>
              <NavTabItem active={selectedTab === REMOVED_CONSTRAINTS}>
                <button onClick={onClickRemovedConstraints}>
                  {formatMessage(messages.removedConstraints) +
                    ` (${Object.keys(constraintsDiff[REMOVED_CONSTRAINTS]).length})`}
                </button>
              </NavTabItem>
              <NavTabItem active={selectedTab === UPDATED_CONSTRAINTS}>
                <button onClick={onClickUpdatedConstraints}>
                  {formatMessage(messages.UpdatedConstraints) +
                    ` (${Object.keys(constraintsDiff[UPDATED_CONSTRAINTS]).length})`}
                </button>
              </NavTabItem>
            </NavTab>
          </div>
          <div className={navBodyStyle}>{tabContent}</div>
        </div>
      ) : (
        <Alert type="info" message={formatMessage(messages.noChangesToView)} dismissible={true} />
      )}
    </Fragment>
  ) : (
    <Spinner size="large" className={center} />
  );
};

export default ConstraintsTab;
