import messages from './messages';
import isNil from 'lodash/isNil';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import { RANGE, NUMBER } from '../../shared/enums/options';

export const hasDiffValues = (v2Values, v1Values) => {
  let unchangedValues = [];
  let removedValues = cloneDeep(v1Values);

  v2Values.forEach((v2Value) => {
    let result = v2Value.range ? validateRange(v1Values, v2Value) : validateValue(v1Values, v2Value);
    if (!result) {
      return true;
    } else {
      unchangedValues.push(v2Value);
      removedValues.splice(findIndex(removedValues, { value: v2Value.value }), 1);
    }
  });
  return unchangedValues.length !== v2Values.length || removedValues.length >= 1;
};

export const getMaximumValue = ({ maximum, minimum, increment }) => {
  if (isNil(maximum)) {
    return undefined;
  }
  // eslint-disable-next-line no-mixed-operators
  return minimum + Math.floor((maximum - minimum) / increment) * increment;
};

const coerceToNumber = (numberString) => {
  if (typeof numberString === NUMBER) {
    return numberString;
  }
  return numberString ? Number(numberString) : undefined;
};

function generateRangeExample(range) {
  const numericRange = {
    minimum: coerceToNumber(range.minimum),
    maximum: coerceToNumber(range.maximum),
    increment: coerceToNumber(range.increment),
  };

  const inc = numericRange.increment || 0.00000001;
  const max = getMaximumValue(numericRange);
  const exampleValues = [numericRange.minimum + inc, numericRange.minimum + 2 * inc].filter(
    (v) => v < (max || Infinity)
  );
  return numericRange.minimum === max ? max : `${numericRange.minimum}, ${exampleValues.join(', ')} ... ${max || ''}`;
}

export const generateRangeTranslation = (formatMessage, { minimum, maximum, increment }, includeExample = false) => {
  if (isNil(minimum)) {
    return '';
  }

  if (minimum === maximum) {
    return formatMessage(messages.exactly, {
      minimum,
    });
  }

  return formatMessage(includeExample ? messages.rangeTranslationWithExample : messages.rangeTranslation, {
    minimum,
    maximum: maximum || formatMessage(messages.infinity),
    increment: increment
      ? formatMessage(messages.incrementAmount, {
          increment,
        })
      : formatMessage(messages.noIncrement),
    rangeExample: generateRangeExample({
      minimum,
      maximum,
      increment,
    }),
  });
};

const validateValue = (v1Values, v2Option) => {
  return v1Values.some((value) =>
    value.type !== RANGE ? value.value === v2Option.value && value.type === v2Option.type : null
  );
};

const validateRange = (v1Values, v2Option) => {
  return v1Values.some((value) =>
    value.type === RANGE
      ? value.range.minimum === v2Option.range.minimum && value.range.maximum === v2Option.range.maximum
      : null
  );
};

export const segregateValues = (unchanged, added, removed, v1Values, v2Values) => {
  v2Values.forEach((v2Value) => {
    let result = v2Value.range ? validateRange(v1Values, v2Value) : validateValue(v1Values, v2Value);
    if (!result) {
      added.push(v2Value);
    } else {
      unchanged.push(v2Value);
      removed.splice(findIndex(removed, { [v2Value.type]: v2Value[v2Value.type] }), 1);
    }
  });

  return { unchanged, added, removed };
};
