import React from 'react';
import { alloy, silver } from '@cimpress/react-components/lib/colors';

import HamAccordion from './AccordionTypes/HamAccordion';
import ProductAccordion from './AccordionTypes/ProductAccordion';
import { StandardAccordion } from './AccordionTypes/StandardAccordion';
import { AdoptionAccordion } from './AccordionTypes/AdoptionAccordion';

import { getStandardInfo } from '../../../services/standardDefinitionService';
import { getProductVersions, getCurrentProductVersion } from '../../../services/productService';
import { updateHamNodeProductModel, updateHamNodeAttributeModel } from '../../../services/hamService';
import { CREATE } from '../../../shared/enums/constants';

const style = { border: `1px solid ${alloy}` };
const headerStyle = { backgroundColor: 'white', borderBottom: `1px solid ${silver}` };
const mainAccordionBodyStyle = { backgroundColor: `white`, padding: '20px', height: '100%', overflow: 'auto' };
const bodyStyle = { backgroundColor: `white`, padding: '20px' };

export const AncestorAccordion = ({
  ancestors,
  accessToken,
  mode = CREATE,
  onAdoptionVersionChange,
  onOpenConditionSetManager,
  type,
}) => {
  const getAllProductVersions = async (productId) => {
    return await getProductVersions({ productId, accessToken });
  };

  const getStandardId = async (cimStandardId) => {
    return await getStandardInfo(cimStandardId, accessToken);
  };

  const updateHamProductVersion = async (indexOfProductNode, versionUrl) => {
    let ham = ancestors[indexOfProductNode - 1];
    await updateHamNodeProductModel({ id: ham.id, productUrl: versionUrl, accessToken });
  };

  const getCurrentVersion = async (productId) => {
    return await getCurrentProductVersion({ productId, accessToken });
  };

  return (
    <div style={mainAccordionBodyStyle}>
      {ancestors.map((ancestor, index) => {
        if (ancestor.constraintId) {
          // node is a ham and needs to have condition editing enabled with a popup save
          return (
            <HamAccordion
              key={`ham-${index}`}
              accessToken={accessToken}
              index={index}
              ancestor={ancestor}
              headerStyle={headerStyle}
              bodyStyle={bodyStyle}
              style={style}
              updateHamNode={updateHamNodeAttributeModel}
              mode={mode}
              onOpenConditionSetManager={onOpenConditionSetManager}
              type={type}
            />
          );
        } else if (ancestor.isProduct) {
          return (
            <ProductAccordion
              key={'product'}
              getVersions={getAllProductVersions}
              index={index}
              ancestor={ancestor}
              headerStyle={headerStyle}
              bodyStyle={bodyStyle}
              style={style}
              updateHamVersion={updateHamProductVersion}
              mode={mode}
              getCurrentVersion={getCurrentVersion}
            />
          );
        } else if (ancestor.isAdoption) {
          // node is a standard adoption. Needs to have version editing and the name should have been sent in name field instead of productId
          return (
            <AdoptionAccordion
              key={'adoption'}
              getVersions={getAllProductVersions}
              index={index}
              ancestor={ancestor}
              headerStyle={headerStyle}
              bodyStyle={bodyStyle}
              style={style}
              updateHamVersion={updateHamProductVersion}
              mode={mode}
              getCurrentVersion={getCurrentVersion}
              onAdoptionVersionChange={onAdoptionVersionChange}
              isAdoptionAsHam={ancestors.length === 2}
              type={type}
            />
          );
        } else {
          // node is a standard and we just want to show link to standard - no version editing allowed.
          // https://standard-definition.products.cimpress.io/v1/standards/CIM-LETTERHEAD
          return (
            <StandardAccordion
              key={'standard'}
              getStandardId={getStandardId}
              index={index}
              ancestor={ancestor}
              headerStyle={headerStyle}
              bodyStyle={bodyStyle}
              style={style}
              mode={mode}
            />
          );
        }
      })}
    </div>
  );
};
