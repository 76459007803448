import { defineMessages } from 'react-intl';

export default defineMessages({
  productData: {
    id: 'versionComparisonComponent.productData',
    defaultMessage: 'Product Data',
  },
  options: {
    id: 'versionComparisonComponent.options',
    defaultMessage: 'Options',
  },
  properties: {
    id: 'versionComparisonComponent.properties',
    defaultMessage: 'Properties',
  },
  constraints: {
    id: 'versionComparisonComponent.constraints',
    defaultMessage: 'Constraints',
  },
  diffProductInfo: {
    id: 'versionComparisonComponent.diffProductInfo',
    defaultMessage: 'Product Info',
  },
  quantity: {
    id: 'versionComparisonComponent.quantity',
    defaultMessage: 'Quantity',
  },
  name: {
    id: 'versionComparisonComponent.name',
    defaultMessage: 'Name',
  },
  description: {
    id: 'versionComparisonComponent.description',
    defaultMessage: 'Description',
  },
  category: {
    id: 'versionComparisonComponent.category',
    defaultMessage: 'Category',
  },
  'option-constraints': {
    id: 'versionComparisonComponent.optionConstraints',
    defaultMessage: 'Option Constraints',
  },
  comments: {
    id: 'versionComparisonComponent.comments',
    defaultMessage: 'Comments',
  },
  commonInBothVersions: {
    id: 'versionComparisonComponent.commonInBothVersions',
    defaultMessage: 'common in both versions',
  },
  version: {
    id: 'versionComparisonComponent.version',
    defaultMessage: 'version {version}',
  },
  addedProperties: {
    id: 'versionComparisonComponent.addedProperties',
    defaultMessage: 'Added Properties',
  },
  removedProperties: {
    id: 'versionComparisonComponent.removedProperties',
    defaultMessage: 'Removed Properties',
  },
  updatedProperties: {
    id: 'versionComparisonComponent.updatedProperties',
    defaultMessage: 'Updated Properties',
  },
  addedConstraints: {
    id: 'versionComparisonComponent.addedConstraints',
    defaultMessage: 'Added Constraints',
  },
  removedConstraints: {
    id: 'versionComparisonComponent.removedConstraints',
    defaultMessage: 'Removed Constraints',
  },
  UpdatedConstraints: {
    id: 'versionComparisonComponent.updatedConstraints',
    defaultMessage: 'Updated Constraints',
  },
  noChangesToView: {
    id: 'versionComparisonComponent.noChangesToView',
    defaultMessage: 'No changes to view',
  },
  staticToConditional: {
    id: 'versionComparisonComponent.staticToConditional',
    defaultMessage: 'Changed from static to conditional',
  },
  addedAssignments: {
    id: 'versionComparisonComponent.addedAssignments',
    defaultMessage: 'The following assignments were added',
  },
  removedAssignments: {
    id: 'versionComparisonComponent.removedAssignments',
    defaultMessage: 'The following assignments were removed',
  },
  oldValue: {
    id: 'versionComparisonComponent.oldValue',
    defaultMessage: 'Old Value',
  },
  newValue: {
    id: 'versionComparisonComponent.newValue',
    defaultMessage: 'New Value',
  },
  oldType: {
    id: 'versionComparisonComponent.oldType',
    defaultMessage: 'Old Type',
  },
  newType: {
    id: 'versionComparisonComponent.newType',
    defaultMessage: 'New Type',
  },
  conditionalToStatic: {
    id: 'versionComparisonComponent.conditionalToStatic',
    defaultMessage: 'Changed from conditional to static',
  },
  valueHasChanged: {
    id: 'versionComparisonComponent.valueHasChanged',
    defaultMessage: 'Value has changed',
  },
  typeHasChanged: {
    id: 'versionComparisonComponent.typeHasChanged',
    defaultMessage: 'Type has changed',
  },
  diffingError: {
    id: 'versionComparisonComponent.diffingError',
    defaultMessage: 'An Error has occurred while processing the difference.',
  },
  propertyUpdatedUnitOfMeasure: {
    id: 'versionComparisonComponent.propertyUpdatedUnitOfMeasure',
    defaultMessage:
      'Unit of measure updated from "{oldUnitOfMeasure}" to "{newUnitOfMeasure}" for property "{propertyName}".',
  },
  removedAssignment: {
    id: 'versionComparisonComponent.removedAssignment',
    defaultMessage: 'This entire assignment was removed from the property.',
  },
  addedAssignment: {
    id: 'versionComparisonComponent.addedAssignment',
    defaultMessage: 'This entire assignment was added to the property with the following conditions.',
  },
  addedConditions: {
    id: 'versionComparisonComponent.addedConditions',
    defaultMessage: 'Added Conditions',
  },
  removedConditions: {
    id: 'versionComparisonComponent.removedConditions',
    defaultMessage: 'Removed Conditions',
  },
  formula: {
    id: 'versionComparisonComponent.formula',
    defaultMessage: 'formula',
  },
  propertyValueErrorMessage: {
    id: 'versionComparisonComponent.propertyValueErrorMessage',
    defaultMessage: `The following values are causing errors. Edit for more details. [{errorValues}]`,
  },
  specificationTypeError: {
    id: 'versionComparisonComponent.specificationTypeError',
    defaultMessage: `{name} is a specification with {type} type, convert to align with definition`,
  },
  showMore: {
    id: 'versionComparisonComponent.showMore',
    defaultMessage: 'And {numOfShowMore} more...',
  },
  showLess: {
    id: 'versionComparisonComponent.showLess',
    defaultMessage: 'Show Less',
  },
  viewJSON: {
    id: 'versionComparisonComponent.viewJSON',
    defaultMessage: 'View JSON',
  },
  noVersionDifferences: {
    id: 'versionDiff.noVersionDifferences',
    defaultMessage: 'There are no differences between these versions.',
  },
});
