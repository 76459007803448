export const VERSION = 'version';

export const PRODUCT_DATA = 'productData';
export const OPTIONS = 'options';
export const PROPERTIES = 'properties';
export const CONSTRAINTS = 'constraints';

export const NAME = 'Name';
export const DESCRIPTION = 'Description';
export const CATEGORY = 'Category';
