import React, { useState, Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { css } from 'emotion';
import isEmpty from 'lodash/isEmpty';

import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import { info, slate } from '@cimpress/react-components/lib/colors';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import Alert from '@cimpress/react-components/lib/Alert';

import ConditionSetEditView from '../../../ConditionSetSelector/ConditionSets/ConditionSetEditor/ConditionSetEditView';
import messages from '../messages';

const infoIcon = css`
  margin-left: 10px;
  cursor: pointer;
`;

const infoText = css`
  border-left: 3px solid ${info.base};
  padding: 2px 10px;
  color: ${slate};
  margin-bottom: 10px;
`;

const marginConditions = css`
  margin-top: 20px;
`;

const Conditions = ({
  attributes = {},
  conditionSet = {},
  setConditionSet,
  loadingAttributes = false,
  hasConditionOldAttribute = false,
  outdatedAttributes = {},
}) => {
  const { formatMessage } = useIntl();
  const [conditionInfo, setConditionInfo] = useState(false);
  const [sortedAttributes, setSortedAttributes] = useState({});

  useEffect(() => {
    const ordered = Object.keys(attributes)
      .sort()
      .reduce((obj, key) => {
        obj[key] = attributes[key];
        return obj;
      }, {});
    setSortedAttributes(ordered);
  }, [attributes]);

  const onInfoClick = () => setConditionInfo(!conditionInfo);
  const onConditionSetChange = (oldConditionSet, newConditionSet) => {
    setConditionSet(newConditionSet);
  };

  return (
    <Fragment>
      <h3>
        {formatMessage(messages.ProductOptionConditions)}
        <IconInformationCircle onClick={onInfoClick} className={infoIcon} weight={conditionInfo ? 'fill' : 'regular'} />
      </h3>
      {conditionInfo && (
        <span className={infoText}>
          <i>{formatMessage(messages.conditionsInfo)}</i>
        </span>
      )}
      {hasConditionOldAttribute && (
        <Alert
          type="warning"
          message={formatMessage(messages.badAttributes, {
            attributeNames: JSON.stringify(Object.keys(outdatedAttributes)),
          })}
          dismissable={true}
        />
      )}
      {!isEmpty(attributes) ? (
        <div className={marginConditions}>
          <ConditionSetEditView
            conditionSet={conditionSet}
            attributes={sortedAttributes}
            onChange={onConditionSetChange}
            showNoConditionsMessage={false}
            loadingAttributes={loadingAttributes}
          />
        </div>
      ) : (
        <Spinner size="medium" />
      )}
    </Fragment>
  );
};

export default Conditions;
