import { defineMessages } from 'react-intl';

export default defineMessages({
  createChild: {
    id: 'HamSelector.createChild',
    defaultMessage: 'Create child set',
  },
  createNewCondition: {
    id: 'HamSelector.createNewCondition',
    defaultMessage: 'Create New Top Level Condition',
  },
});
