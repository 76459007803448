export const VIEW = 'view';
export const CREATE = 'create';

export const PIN_TO_CURRENT = 'pinToCurrent';
export const CURRENT = 'current';

export const hamAction = {
  CREATE_CHILD: 'createChild',
  UPDATE: 'update',
  CREATE: 'create',
};

export const selectorType = {
  PREVIEW: 'preview',
  READ: 'read',
};
