import {
  findAddedKeysToDict,
  findRemovedKeysFromDict,
  removeAddedAndRemoved,
  compareUnitOfMeasure,
} from './sharedUtils';

export const getOptionDiffs = (oldOptions, newOptions) => {
  const oldOptionsDict = {};
  oldOptions.forEach((x) => {
    oldOptionsDict[x.name] = stripIdsFromOption(x);
  });

  const newOptionsDict = {};
  newOptions.forEach((x) => {
    newOptionsDict[x.name] = stripIdsFromOption(x);
  });

  const addedOptions = findAddedKeysToDict(oldOptionsDict, newOptionsDict);
  const addedOptionsWithValues = {};
  Object.keys(addedOptions).forEach((key) => {
    addedOptionsWithValues[key] = newOptionsDict[key].values;
  });

  const removedOptions = findRemovedKeysFromDict(oldOptionsDict, newOptionsDict);
  const removedOptionsWithValues = {};
  Object.keys(removedOptions).forEach((key) => {
    removedOptionsWithValues[key] = oldOptionsDict[key].values;
  });

  const newOptionsFiltered = removeAddedAndRemoved(newOptionsDict, addedOptions);
  const oldOptionsFiltered = removeAddedAndRemoved(oldOptionsDict, removedOptions);

  const addedValuesByOption = {};
  const deletedValuesByOption = {};
  const unchangedValuesByOption = {};
  const unitOfMeasureByOption = {};

  Object.keys(oldOptionsFiltered).forEach((name) => {
    const addedValues = findAddedValues(oldOptionsFiltered[name], newOptionsFiltered[name]);
    const deletedValues = findRemovedValues(oldOptionsFiltered[name], newOptionsFiltered[name]);
    const unchangedValues = findUnchangedValues(oldOptionsFiltered[name], newOptionsFiltered[name]);
    const unitOfMeasure = compareUnitOfMeasure(oldOptionsFiltered[name], newOptionsFiltered[name]);

    addedValuesByOption[name] = addedValues;
    deletedValuesByOption[name] = deletedValues;
    unchangedValuesByOption[name] = unchangedValues;
    unitOfMeasureByOption[name] = unitOfMeasure;
  });

  return {
    addedOptions: addedOptionsWithValues,
    removedOptions: removedOptionsWithValues,
    addedValuesByOption,
    deletedValuesByOption,
    unchangedValuesByOption,
    unitOfMeasureByOption,
  };
};

export const stripIdsFromOption = (option) => {
  let noIds = {
    ...option,
    id: undefined,
  };
  if (option.name !== 'Quantity') {
    noIds.values = option.values.map((v) => {
      return {
        ...v,
        id: undefined,
      };
    });
  }

  return noIds;
};

export const findAddedValues = (oldOption, newOption) => {
  const newOptionStringifiedValues = getStringifiedValuesDict(newOption);
  const oldOptionStringifiedValues = getStringifiedValuesDict(oldOption);

  const newOptionValues = [];
  Object.keys(newOptionStringifiedValues).forEach((newOptionStringValue) => {
    if (!oldOptionStringifiedValues[newOptionStringValue]) {
      newOptionValues.push(newOptionStringifiedValues[newOptionStringValue]);
    }
  });

  return newOptionValues;
};

export const findUnchangedValues = (oldOption, newOption) => {
  const newOptionStringifiedValues = getStringifiedValuesDict(newOption);
  const oldOptionStringifiedValues = getStringifiedValuesDict(oldOption);

  const unchangedOptionValues = [];
  Object.keys(newOptionStringifiedValues).forEach((newOptionStringValue) => {
    if (oldOptionStringifiedValues[newOptionStringValue]) {
      unchangedOptionValues.push(newOptionStringifiedValues[newOptionStringValue]);
    }
  });

  return unchangedOptionValues;
};

export const findRemovedValues = (oldOption, newOption) => {
  const newOptionStringifiedValues = getStringifiedValuesDict(newOption);
  const oldOptionStringifiedValues = getStringifiedValuesDict(oldOption);

  const deletedOptionValues = [];
  Object.keys(oldOptionStringifiedValues).forEach((oldOptionStringValue) => {
    if (!newOptionStringifiedValues[oldOptionStringValue]) {
      deletedOptionValues.push(oldOptionStringifiedValues[oldOptionStringValue]);
    }
  });

  return deletedOptionValues;
};

const getStringifiedValuesDict = (option) => {
  const optionValues = {};
  if (option.values) {
    option.values.forEach((value) => {
      const stringValue = JSON.stringify(getSortedObject(value));
      optionValues[stringValue] = value;
    });
  }

  return optionValues;
};

export const getSortedObject = (unsortedObject) => {
  const sortable = [];
  Object.keys(unsortedObject).forEach((key) => {
    sortable.push([key]);
  });

  sortable.sort(function (a, b) {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  });

  const objSorted = {};
  sortable.forEach(function (item) {
    objSorted[item] = unsortedObject[item];
  });

  return objSorted;
};
