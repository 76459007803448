import isEmpty from 'lodash/isEmpty';
import each from 'lodash/each';

import { productTypeConsts } from '../constants/propInputConstants';
import { searchFulfillment } from '../../../services/v2ProductSearch';
import { searchMerchant } from '../../../services/backboneSearch';
import { getAdoptionsUnderStandard } from '../../../services/standardDefinitionService';
import { searchOverStandards } from '../../../services/possibilities';

import { checkV2Product } from './searchV2ProductByID';
import { checkV1Product } from './searchV1ProductByID';
import { checkMerchantProduct } from './searchMerchantByID';

import { searchBarTypes, errorTypes, queryOperatorTypes, productTypes } from '../constants/searchConstants';
import { queryProductLink } from '../../../services/productLinkService';

export const checkV2Prefixes = (searchString) => {
  // v2 product prefixes
  const CIM_ID = 'cim-';

  return searchString.toLowerCase().includes(CIM_ID);
};

export const checkV1Prefixes = (searchString) => {
  // v1 product prefixes
  const VIP_ID = 'vip-';
  const LED_ID = 'led-';
  const BUL_ID = 'bul-';
  const PFC_ID = 'pfc-';
  const ARP_ID = 'arp-';
  const DRK_ID = 'drk-';
  const ZJI_ID = 'zji-';

  const lowerCaseSearch = searchString.toLowerCase();
  return (
    lowerCaseSearch.includes(VIP_ID) ||
    lowerCaseSearch.includes(LED_ID) ||
    lowerCaseSearch.includes(BUL_ID) ||
    lowerCaseSearch.includes(PFC_ID) ||
    lowerCaseSearch.includes(ARP_ID) ||
    lowerCaseSearch.includes(DRK_ID) ||
    lowerCaseSearch.includes(ZJI_ID)
  );
};

export const checkMerchantPrefixes = (searchString) => {
  // merchant prefixes
  const PRD_ID = 'prd-';

  return searchString.toLowerCase().includes(PRD_ID);
};

export const plinkSearch = async ({ accessToken, accountId, searchString, excludeCatalogs }) => {
  const validateProductIdPrefixes = (productId) =>
    checkV2Prefixes(productId || checkV1Prefixes(productId) || checkMerchantPrefixes(productId));

  const productIdCase = !isEmpty(searchString) && validateProductIdPrefixes(searchString);
  const commaSeparatedList = productIdCase && searchString.includes(',');

  // Case 1: Single Product Id
  if (productIdCase && !commaSeparatedList) {
    const productId = searchString.trim();
    return queryProductLink({ accessToken, accountId, productIds: [productId], excludeCatalogs });
  }
  // Case 2: Comma separated list of Product Ids
  else if (commaSeparatedList) {
    const productIds = searchString.split(',').map((productId) => productId.trim());

    let productIdsToSearch = [];
    each(productIds, (productId) => {
      // Validate it's a valid product id
      if (validateProductIdPrefixes(productId)) {
        productIdsToSearch.push(productId);
      } // else not a valid product id because it does not have the prefix, or we don't have all valid prefixes in our lists...
    });

    return queryProductLink({ accessToken, accountId, productIds: productIdsToSearch, excludeCatalogs });
  }
  // Case 3: string (name) - query for key product name & link name, and dedup the combined result
  else {
    return queryProductLink({
      accessToken,
      accountId,
      queryOperatorType: queryOperatorTypes.OR,
      name: searchString,
      productNames: [searchString],
      excludeCatalogs,
    });
  }
};

export const SearchBarSearch = async (accessToken, accountId, searchString, productTypes, standardsHaveAdoptions) => {
  if (
    !isEmpty(searchString) &&
    (checkV2Prefixes(searchString) || checkV1Prefixes(searchString) || checkMerchantPrefixes(searchString))
  ) {
    return await productIdSearch(accessToken, accountId, searchString, productTypes, standardsHaveAdoptions);
  } else {
    return await keywordSearch(accessToken, accountId, searchString, productTypes, standardsHaveAdoptions);
  }
};

export const productIdSearch = async (accessToken, accountId, searchString, productTypes, standardsHaveAdoptions) => {
  const productsToSearch = {
    searchV1: productTypes.includes(productTypeConsts.V1_FULFILLMENT),
    searchStandards: productTypes.includes(productTypeConsts.STANDARDS),
    searchV2: productTypes.includes(productTypeConsts.V2_FULFILLMENT),
    searchMerchantProducts: productTypes.includes(productTypeConsts.MERCHANT),
  };
  const allProductsFound = {};

  const searchStrings = searchString.split(',');
  await Promise.all(
    searchStrings.map(async (product) => {
      await checkSingleProduct(
        accessToken,
        product.toUpperCase(),
        productsToSearch,
        accountId,
        allProductsFound,
        standardsHaveAdoptions
      );
    })
  );

  return { searchType: searchBarTypes.ID_SEARCH, products: Object.values(allProductsFound) };
};

const checkSingleProduct = async (
  accessToken,
  product,
  typesToSearch,
  accountId,
  allProductsFound,
  standardsHaveAdoptions
) => {
  let trimmedProduct = product.trim();
  if (checkV1Prefixes(trimmedProduct) || checkV2Prefixes(trimmedProduct)) {
    const isV2Product = await checkV2Product(accessToken, trimmedProduct);

    if (!isEmpty(isV2Product)) {
      if (!typesToSearch.searchV2) {
        allProductsFound[trimmedProduct] = {
          ...isV2Product,
          errorReason: errorTypes.NO_V2_SEARCH,
        };
      } else if (isV2Product.errorReason) {
        allProductsFound[trimmedProduct] = isV2Product;
      } else if (isV2Product.isStandard) {
        if (!typesToSearch.searchStandards) {
          allProductsFound[trimmedProduct] = {
            ...isV2Product,
            errorReason: errorTypes.NO_STANDARD_SEARCH,
          };
        } else {
          if (!standardsHaveAdoptions) {
            allProductsFound[trimmedProduct] = isV2Product;
          } else {
            const adoptions = await getAdoptionsUnderStandard(trimmedProduct, accountId, accessToken);

            if (adoptions.length > 0) {
              isV2Product.allAdoptions = adoptions;
              isV2Product.selectedAdoptions = adoptions;
              allProductsFound[trimmedProduct] = isV2Product;
            } else {
              allProductsFound[trimmedProduct] = {
                ...isV2Product,
                errorReason: errorTypes.NO_ADOPTIONS,
              };
            }
          }
        }
      } else {
        allProductsFound[trimmedProduct] = isV2Product;
      }
    } else {
      const isV1Product = await checkV1Product(accessToken, trimmedProduct);
      if (!isEmpty(isV1Product)) {
        if (isV1Product.errorReason) {
          allProductsFound[trimmedProduct] = isV1Product;
        } else if (!typesToSearch.searchV1) {
          allProductsFound[trimmedProduct] = {
            ...isV1Product,
            errorReason: errorTypes.NO_V1_SEARCH,
          };
        } else {
          allProductsFound[trimmedProduct] = isV1Product;
        }
      } else {
        allProductsFound[trimmedProduct] = { productId: trimmedProduct, errorReason: errorTypes.PRODUCT_DOESNT_EXIST };
      }
    }
  }

  if (checkMerchantPrefixes(trimmedProduct)) {
    const isMerchantProduct = await checkMerchantProduct(accessToken, trimmedProduct);
    if (!isEmpty(isMerchantProduct)) {
      if (!typesToSearch.searchMerchantProducts) {
        allProductsFound[trimmedProduct] = {
          ...isMerchantProduct,
          errorReason: errorTypes.NO_MERCHANT_SEARCH,
        };
      } else {
        allProductsFound[trimmedProduct] = {
          ...isMerchantProduct,
        };
      }
    } else {
      allProductsFound[trimmedProduct] = { productId: trimmedProduct, errorReason: errorTypes.PRODUCT_DOESNT_EXIST };
    }
  }
};

const keywordSearch = async (accessToken, accountId, searchString, productTypes, standardsHaveAdoptions) => {
  const searchV2 = productTypes.includes(productTypeConsts.V2_FULFILLMENT);
  const searchMerchantProducts = productTypes.includes(productTypeConsts.MERCHANT);
  const searchStandards = productTypes.includes(productTypeConsts.STANDARDS);

  const searchesToMake = [];
  if (searchStandards) {
    searchesToMake.push(searchOverStandards(accessToken, searchString));
  }

  if (searchV2) {
    searchesToMake.push(searchFulfillment(accessToken, accountId, searchString, null, null));
  }

  if (searchMerchantProducts) {
    searchesToMake.push(searchMerchant(accessToken, accountId, searchString));
  }

  // search for V1 products when we figure out how to search by account
  // if(productTypes.includes(productTypeConsts.V1_FULFILLMENT)) { }

  const searchResults = [];
  const addedProductIds = new Set();
  const results = await Promise.all(searchesToMake);
  results.forEach((result) => {
    result.forEach((item) => {
      if (!addedProductIds.has(item.productId)) {
        searchResults.push(item);
        addedProductIds.add(item.productId);
      }
    });
  });

  const decoratedSearchResults = await decorateStandardsWithAdoptions(
    searchResults,
    accountId,
    accessToken,
    standardsHaveAdoptions
  );
  return { searchType: searchBarTypes.KEYWORD_SEARCH, products: decoratedSearchResults };
};

const decorateStandardsWithAdoptions = async (searchResults, accountId, accessToken, standardsHaveAdoptions) => {
  if (!standardsHaveAdoptions) {
    return searchResults;
  }

  const decoratedSearchResults = [];
  for (let i = 0; i < searchResults.length; i++) {
    const item = searchResults[i];
    if (item.productType === productTypes.STANDARD) {
      const adoptions = await getAdoptionsUnderStandard(item.productId, accountId, accessToken);
      item.allAdoptions = adoptions;
      item.selectedAdoptions = adoptions;

      if (adoptions.length > 0) {
        decoratedSearchResults.push(item);
      }
    } else {
      decoratedSearchResults.push(item);
    }
  }

  return decoratedSearchResults;
};

export const doAdvancedSearch = async ({
  accessToken,
  accountId,
  keyword,
  productTypes,
  standardsHaveAdoptions,
  categoryId = null,
  catalogId = null,
}) => {
  const searchV2 = productTypes.includes(productTypeConsts.V2_FULFILLMENT);
  const searchMerchantProducts = productTypes.includes(productTypeConsts.MERCHANT);
  const searchStandards = productTypes.includes(productTypeConsts.STANDARDS);

  const searchesToMake = [];
  if (searchStandards && isEmpty(categoryId) && isEmpty(catalogId)) {
    searchesToMake.push(searchOverStandards(accessToken, keyword));
  }

  if (searchV2) {
    searchesToMake.push(searchFulfillment(accessToken, accountId, keyword, categoryId, catalogId));
  }

  if (searchMerchantProducts && !catalogId && !categoryId) {
    searchesToMake.push(searchMerchant(accessToken, accountId, keyword));
  }

  // search for V1 products when we figure out how to search by account
  // if(productTypes.includes(productTypeConsts.V1_FULFILLMENT)) { }

  const searchResults = [];
  const addedProductIds = new Set();
  const results = await Promise.all(searchesToMake);
  results.forEach((result) => {
    result.forEach((item) => {
      if (!addedProductIds.has(item.productId)) {
        searchResults.push(item);
        addedProductIds.add(item.productId);
      }
    });
  });

  const decoratedSearchResults = await decorateStandardsWithAdoptions(
    searchResults,
    accountId,
    accessToken,
    standardsHaveAdoptions
  );
  return { searchType: searchBarTypes.KEYWORD_SEARCH, products: decoratedSearchResults };
};
