import React, { Fragment, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { css } from 'emotion';
import ReactDiffViewer from 'react-diff-viewer';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';

import NavTab from '@cimpress/react-components/lib/NavTab';
import NavTabItem from '@cimpress/react-components/lib/NavTabItem';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';

import { removeBlacklistedKeys } from './removeBlacklistedKeys';
import messages from '../messages';
import './versionsDiff.css'; // override for some platform styles that don't play well with the diff view

const center = css`
  text-align: center;
`;

const fieldsToCompare = [
  { key: 'productInfo', picks: ['name', 'description', 'metadata', 'auditInfo'], label: 'diffProductInfo' },
  { key: 'quantity', label: 'quantity' },
  { key: 'options', label: 'options' },
  { key: 'properties', label: 'properties' },
  { key: 'constraints', label: 'option-constraints' },
  { key: 'relatedResources' },
];

const VersionsDiff = ({ productV1, productV2 }) => {
  const [selectedTab, setSelectedTab] = useState(fieldsToCompare[0].key);
  const [isLoading, setIsLoading] = useState(true);
  const [diffFields, setDiffFields] = useState([]);
  const { formatMessage } = useIntl();

  useEffect(() => {
    // generate diffs
    const diffFields = [];

    const version1 = removeBlacklistedKeys(productV1, ['id']);
    const version2 = removeBlacklistedKeys(productV2, ['id']);

    fieldsToCompare.forEach((field) => {
      const version = pick(version2, field.picks || [field.key]);
      let previous = pick(version1, field.picks || [field.key]);
      previous = field.picks || previous[field.key] ? previous : { [field.key]: {} };

      if (!isEqual(version, previous)) {
        if (field.key === 'relatedResources') {
          uniq(Object.keys(version.relatedResources).concat(Object.keys(previous.relatedResources || {}))).forEach(
            (key) => {
              const subFieldVersion = pick(version.relatedResources, [key]);
              let subFieldPrevious = pick(previous.relatedResources, [key]);
              subFieldPrevious = subFieldPrevious[key] ? subFieldPrevious : { [key]: [] };
              if (!isEqual(subFieldVersion, subFieldPrevious)) {
                diffFields.push({
                  key,
                  label: key,
                  version: subFieldVersion,
                  previous: subFieldPrevious,
                });
              }
            }
          );
        } else {
          diffFields.push({
            ...field,
            version,
            previous,
          });
        }
      }
    });
    setDiffFields(diffFields);
    setSelectedTab(diffFields[0].key);
    setIsLoading(false);
  }, [productV1, productV2]);

  const currentDiffField = diffFields.find((field) => field.key === selectedTab) || diffFields[0];
  return isLoading ? (
    <Spinner size="large" className={center} />
  ) : diffFields.length !== 0 ? (
    <div className="pre-css-reset">
      {diffFields ? (
        <Fragment>
          <NavTab>
            {diffFields.map((diffField) => (
              <NavTabItem key={diffField.key} active={selectedTab === diffField.key}>
                <button onClick={() => setSelectedTab(diffField.key)}>
                  {messages[diffField.label] ? formatMessage(messages[diffField.label]) : diffField.label}
                </button>
              </NavTabItem>
            ))}
          </NavTab>

          <ReactDiffViewer
            oldValue={JSON.stringify(currentDiffField.previous, null, 2)}
            newValue={JSON.stringify(currentDiffField.version, null, 2)}
            splitView={true}
            showDiffOnly={true}
          />
        </Fragment>
      ) : null}
    </div>
  ) : (
    <h5>{formatMessage(messages.noVersionDifferences)} </h5>
  );
};

export default VersionsDiff;
