import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { css } from 'emotion';

import isEmpty from 'lodash/isEmpty';

import NavTab from '@cimpress/react-components/lib/NavTab';
import NavTabItem from '@cimpress/react-components/lib/NavTabItem';
import Alert from '@cimpress/react-components/lib/Alert';
import { danger } from '@cimpress/react-components/lib/colors';

import MultipleConditionsDisplay from '../../../../shared/MultipleConditionsDisplay';
import {
  REMOVED_VALUES,
  ADDED_VALUES,
  VALUES_CONDITION_MAPPINGS,
  REMOVED_CONDITIONS,
  ADDED_CONDITIONS,
} from '../../../../shared/DiffUtilities/constants';
import { FORMULA } from '../../../../shared/enums/operators';
import messages from '../../messages';

const removedItem = css`
  text-decoration: line-through;
  color: ${danger.base} !important;
`;

const tabContentClass = css`
  padding-left: 15px;
  width: 100%;
  overflow-y: auto;
`;

const borderRight = css`
  border-right: 2px solid lightgray;
`;

const navTabClassMappings = {
  [REMOVED_VALUES]: removedItem,
};

const ChangedPropertyConditions = ({ propertyData }) => {
  const [selectedTab, setSelectedTab] = useState();
  const [tabs, setTabs] = useState([]);
  const [tabContent, setTabContent] = useState({});

  const { formatMessage } = useIntl();

  const getRemovedValuesContent = useCallback(
    (value) => {
      return (
        <div>
          <Alert message={formatMessage(messages.removedAssignment)} type="warning" dismissible={false} />
          <MultipleConditionsDisplay conditions={JSON.parse(value)} />
        </div>
      );
    },
    [formatMessage]
  );

  const getAddedValuesContent = useCallback(
    (value) => {
      return (
        <div>
          <Alert message={formatMessage(messages.addedAssignment)} type="info" dismissible={false} />
          <MultipleConditionsDisplay conditions={JSON.parse(value)} />
        </div>
      );
    },
    [formatMessage]
  );

  const getAddedRemovedConditionContent = useCallback(
    (value) => {
      const removedAttributes = value[REMOVED_CONDITIONS].map((removedConditions) => (
        <MultipleConditionsDisplay conditions={JSON.parse(removedConditions)} />
      ));

      const addedAttributes = value[ADDED_CONDITIONS].map((addedConditions) => (
        <MultipleConditionsDisplay conditions={JSON.parse(addedConditions)} />
      ));

      return (
        <div>
          {removedAttributes.length ? (
            <Fragment>
              <h3>{formatMessage(messages.removedConditions)}</h3>
              <div>{removedAttributes}</div>
            </Fragment>
          ) : null}

          {addedAttributes.length ? (
            <Fragment>
              <h3>{formatMessage(messages.addedConditions)}</h3>
              <div>{addedAttributes}</div>
            </Fragment>
          ) : null}
        </div>
      );
    },
    [formatMessage]
  );

  const getNavTabItem = useCallback(
    (key, changeType) => {
      const parsedKey = JSON.parse(key);
      return (
        <NavTabItem active={selectedTab === key}>
          <button className={navTabClassMappings[changeType]} onClick={() => setSelectedTab(key)}>
            {parsedKey.type === FORMULA
              ? `"${parsedKey.formula}" (${formatMessage(messages.formula)})`
              : parsedKey.value}
          </button>
        </NavTabItem>
      );
    },
    [selectedTab, formatMessage]
  );

  const getTabContent = useCallback(
    (type, value) => {
      switch (type) {
        case REMOVED_VALUES:
          return getRemovedValuesContent(value);
        case ADDED_VALUES:
          return getAddedValuesContent(value);
        case VALUES_CONDITION_MAPPINGS:
          return getAddedRemovedConditionContent(value);
        default:
          break;
      }
    },
    [getAddedRemovedConditionContent, getAddedValuesContent, getRemovedValuesContent]
  );

  const getTabs = useCallback(() => {
    const changeTypes = [REMOVED_VALUES, ADDED_VALUES, VALUES_CONDITION_MAPPINGS];
    const tabContentTmp = {};

    let navTabItems = [];
    let selectedTabTmp;
    changeTypes.forEach((changeType) => {
      const changes = propertyData.differenceDetails[changeType];
      const tabItems = [];
      Object.keys(changes).forEach((key) => {
        if (changeType === VALUES_CONDITION_MAPPINGS) {
          //// Check whether there are any changed conditions to display
          //// to determine whether to even show the property value in the side nav
          const hasAddedOrRemovedValues = !(
            isEmpty(changes[key][REMOVED_CONDITIONS]) && isEmpty(changes[key][ADDED_CONDITIONS])
          );
          if (hasAddedOrRemovedValues) {
            tabItems.push(getNavTabItem(key, changeType));
            if (selectedTabTmp === undefined) {
              selectedTabTmp = key;
            }
          }
        } else {
          tabItems.push(getNavTabItem(key, changeType));
          if (selectedTabTmp === undefined) {
            selectedTabTmp = key;
          }
        }
        if (changes[key].length > 1) {
          tabContentTmp[key] = (
            <Fragment>
              {changes[key].map((value) => {
                return getTabContent(changeType, value);
              })}
            </Fragment>
          );
        } else {
          tabContentTmp[key] = getTabContent(changeType, changes[key]);
        }
      });

      navTabItems = navTabItems.concat(tabItems);
    });

    setTabContent(tabContentTmp);
    if (selectedTab === undefined) {
      setSelectedTab(selectedTabTmp);
    }
    return navTabItems;
  }, [getNavTabItem, getTabContent, propertyData.differenceDetails, selectedTab]);

  useEffect(() => {
    let newTabs = getTabs();
    setTabs(newTabs);
  }, [getTabs, propertyData, selectedTab]);

  return (
    <div style={{ display: 'flex' }}>
      <div className={borderRight}>
        <NavTab vertical={true}>{tabs}</NavTab>
      </div>
      <div className={tabContentClass}>{tabContent[selectedTab]}</div>
    </div>
  );
};

export default ChangedPropertyConditions;
