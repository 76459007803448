import findIndex from 'lodash/findIndex';
import each from 'lodash/forEach';
import map from 'lodash/map';
import { v4 as uuidv4 } from 'uuid';

const attributeModelTypes = {
  NUMERIC: 'numeric',
  STRING: 'string',
};

const attributeModelValueTypes = {
  STRING_LITERAL: 'stringLiteral',
  NUMBER_LITERAL: 'numberLiteral',
  RANGE: 'range',
  FORMULA: 'formula',
};

const v2ProductAttributeTypes = {
  NUMBER: 'number',
  STRING: 'string',
};

const v2ProductAttributeValueTypes = {
  VALUE: 'value',
  RANGE: 'range',
  FORMULA: 'formula',
};

const DECIMAL_REGEX = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;

export const matchesCaseInsensitive = (a, b) => a.toUpperCase() === b.toUpperCase();

export const createConditionSet = (conditionSet = {}) => {
  const { id = uuidv4(), name = '', description = '', conditions = [] } = conditionSet;

  return {
    id,
    name,
    description,
    conditions,
  };
};

export const createCondition = (condition = {}) => {
  const { attribute = '', operator = undefined, values = null, id = uuidv4() } = condition;

  return {
    attribute,
    operator,
    values,
    id,
  };
};

export const getConditionSetIndex = (conditionSets = [], conditionSet = {}) =>
  findIndex(conditionSets, (set) => {
    if (set.id && conditionSet.id) {
      return matchesCaseInsensitive(set.id, conditionSet.id);
    }
    return matchesCaseInsensitive(set.name, conditionSet.name);
  });

// V2 product model/Condition Set to attribute model
export const mapConditionSetsModelToAttributeModel = (conditionSets, attributes) => {
  if (!conditionSets && !attributes) {
    return {};
  }

  let attributeNameToType = {};
  let attributeModelAttributes = map(attributes, (attribute) => {
    attributeNameToType[attribute.name] = attribute.type;
    return mapV2ProductModelAttributeToAttributeModelAttribute(attribute);
  });

  const rules = map(conditionSets, (conditionSet) => ({
    id: conditionSet.id || uuidv4(),
    name: conditionSet.name,
    description: conditionSet.description,
    predicates: map(conditionSet.conditions, (condition) =>
      mapConditionToAttributeModelRuleTerm(condition, attributeNameToType)
    ),
    results: [],
  }));

  const retVal = {
    attributes: attributeModelAttributes,
    constraints: {
      rules,
    },
  };

  return retVal;
};

const mapV2ProductModelAttributeToAttributeModelAttribute = (v2ProductModelAttribute) => ({
  key: v2ProductModelAttribute.name,
  description: v2ProductModelAttribute.description,
  type: matchesCaseInsensitive(v2ProductModelAttribute.type, v2ProductAttributeTypes.NUMBER)
    ? attributeModelTypes.NUMERIC
    : attributeModelTypes.STRING,
  unitOfMeasure: v2ProductModelAttribute.unitOfMeasure,
  values:
    v2ProductModelAttribute.values &&
    map(v2ProductModelAttribute.values, (attributeValue) =>
      mapV2ProductModelValuesToAttributeModelValues(attributeValue, v2ProductModelAttribute.type)
    ),
});

const mapConditionToAttributeModelRuleTerm = (condition, attributeNameToType) => ({
  id: condition.id || uuidv4(),
  attributeKey: condition.attribute,
  description: condition.description,
  operator: condition.operator,
  values:
    condition.values &&
    map(condition.values, (attributeValue) =>
      mapV2ProductModelValuesToAttributeModelValues(attributeValue, attributeNameToType[condition.attribute])
    ),
});

const mapV2ProductModelValuesToAttributeModelValues = (v2ProductModelAttributeValue, attributeType) => {
  if (!v2ProductModelAttributeValue) {
    return {};
  }

  const { id = uuidv4(), type, description } = v2ProductModelAttributeValue;
  const value = v2ProductModelAttributeValue[type];

  const attributeModelValue = {
    id,
    description,
  };

  if (attributeType === v2ProductAttributeTypes.NUMBER) {
    switch (type) {
      case v2ProductAttributeValueTypes.VALUE:
        if (value && value.match(DECIMAL_REGEX)) {
          attributeModelValue.type = attributeModelValueTypes.NUMBER_LITERAL;
          attributeModelValue.numberLiteral = value;
        } else {
          console.error('all number attributes should have number values');
        }
        break;
      case v2ProductAttributeValueTypes.RANGE:
        attributeModelValue.type = attributeModelValueTypes.RANGE;
        attributeModelValue.range = value;
        break;
      case v2ProductAttributeValueTypes.FORMULA:
        attributeModelValue.type = attributeModelValueTypes.FORMULA;
        attributeModelValue.formula = value;
        break;
      default:
        break;
    }
  } else {
    attributeModelValue.type = attributeModelValueTypes.STRING_LITERAL;
    attributeModelValue.stringLiteral = value;
  }

  return attributeModelValue;
};

// Attribute model to V2 Product Model/Condition Set model
export const mapAttributeModelToConditionSetsModel = (attributeModel, hamName) => {
  if (!attributeModel) {
    return {};
  }

  let v2ProductModelAttributes = {};

  each(attributeModel.attributes, (attribute) => {
    if (attribute.values) {
      v2ProductModelAttributes[attribute.key] = mapAttributeModelAttributeToV2ProductModelAttribute(attribute);
    }
  });

  const conditionSets =
    attributeModel.constraints && attributeModel.constraints.rules
      ? map(attributeModel.constraints.rules, (rule) => ({
          id: rule.id || uuidv4(),
          name: hamName || rule.name || '',
          description: rule.description,
          conditions: map(rule.predicates.concat(rule.results), (ruleTerm) =>
            mapAttributeModelRuleTermToCondition(ruleTerm)
          ),
        }))
      : [];

  const retVal = {
    attributes: v2ProductModelAttributes,
    conditionSets,
  };

  return retVal;
};

export const mapAttributesAndConstraintsToConditionSetsModel = (attributes, constraints, hamName) => {
  const attributeModel = {
    attributes,
    constraints,
  };

  return mapAttributeModelToConditionSetsModel(attributeModel, hamName);
};

const mapAttributeModelAttributeToV2ProductModelAttribute = (attributeModelAttribute) => ({
  name: attributeModelAttribute.key,
  description: attributeModelAttribute.description,
  type: matchesCaseInsensitive(attributeModelAttribute.type, attributeModelTypes.NUMERIC)
    ? v2ProductAttributeTypes.NUMBER
    : v2ProductAttributeTypes.STRING,
  unitOfMeasure: attributeModelAttribute.unitOfMeasure,
  values:
    attributeModelAttribute.values &&
    map(attributeModelAttribute.values, (attributeValue) =>
      mapAttributeModelValuesToV2ProductModelValues(attributeValue)
    ),
});

export const mapAttributeModelRuleTermToCondition = (ruleTerm) => ({
  id: ruleTerm.id || uuidv4(),
  attribute: ruleTerm.attributeKey,
  description: ruleTerm.description,
  operator: ruleTerm.operator,
  values:
    ruleTerm.values &&
    map(ruleTerm.values, (attributeValue) => mapAttributeModelValuesToV2ProductModelValues(attributeValue)),
});

const mapAttributeModelValuesToV2ProductModelValues = (attributeModelValue) => {
  if (!attributeModelValue) {
    return {};
  }

  const { id = uuidv4(), description, type } = attributeModelValue;
  const v2AttributeValue = {
    id,
    description,
  };

  const value = attributeModelValue[type];

  switch (type) {
    case attributeModelValueTypes.STRING_LITERAL:
      v2AttributeValue.type = v2ProductAttributeValueTypes.VALUE;
      v2AttributeValue.value = value;
      break;
    case attributeModelValueTypes.NUMBER_LITERAL:
      v2AttributeValue.type = v2ProductAttributeValueTypes.VALUE;
      v2AttributeValue.value = value;
      break;
    case attributeModelValueTypes.RANGE:
      v2AttributeValue.type = v2ProductAttributeValueTypes.RANGE;
      v2AttributeValue.range = value;
      break;
    case attributeModelValueTypes.FORMULA:
      v2AttributeValue.type = v2ProductAttributeValueTypes.FORMULA;
      v2AttributeValue.formula = value;
      break;
    default:
      break;
  }

  return v2AttributeValue;
};

export const getConditionSetFromConstraints = (constraints) => {
  const conditionSets = constraints.rules
    ? map(constraints.rules, (rule) => ({
        id: rule.id || uuidv4(),
        name: rule.name || '',
        description: rule.description,
        conditions: map(rule.predicates.concat(rule.results), (ruleTerm) =>
          mapAttributeModelRuleTermToCondition(ruleTerm)
        ),
      }))
    : [];
  return conditionSets.length > 0 ? conditionSets[0] : {};
};

export const getResultConstraintsFromConditionSet = (conditionSet, attributes, selectionName) => {
  const rules = [
    {
      id: conditionSet.id || uuidv4(),
      name: conditionSet.name || selectionName,
      description: conditionSet.description,
      predicates: [],
      results: map(conditionSet.conditions, (condition) => mapConditionToAttributeModelRuleTerm(condition, attributes)),
    },
  ];

  return rules;
};
