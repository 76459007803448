import React from 'react';
import { cx } from 'emotion';
import { LeftXTag } from '../../../../shared/LeftXTag';

import { anchorButtonInLineText } from '../../constants/css';

const CatalogTag = ({ products, catalog, removable, openDrawerWithSelectedProducts, removeSelectedCatalog }) => {
  const label = (
    <span>
      Catalog {catalog.name} -
      <button className={cx(anchorButtonInLineText)} onClick={openDrawerWithSelectedProducts}>
        {Object.keys(products).length} products
      </button>
    </span>
  );

  return (
    <LeftXTag
      data-testid="selectedProductTag"
      label={label}
      onRemove={removeSelectedCatalog}
      removable={removable}
      key="selected catalog"
    />
  );
};

export default CatalogTag;
