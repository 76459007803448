import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { css } from 'emotion';

import { platinum } from '@cimpress/react-components/lib/colors';
import Tag from '@cimpress/react-components/lib/Tag';
import Alert from '@cimpress/react-components/lib/Alert';

import {
  STATIC_TO_CONDITIONAL,
  CONDITIONAL_TO_STATIC,
  ASSIGNMENT,
  VALUE_DIFF,
  TYPE_DIFF,
} from '../../../../shared/DiffUtilities/properties';
import RuleDisplay from '../../../../shared/ruleDisplay';
import ChangedPropertyConditions from './ChangedPropertyConditions';
import messages from '../../messages';

const ruleDisplayBox = css`
  border-radius: 2px;
  border: 1px solid ${platinum};
  padding: 10px;
  width: 90%;
`;

const maxHeight = css`
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
`;

const PropertyBody = ({ propertyData }) => {
  const { formatMessage } = useIntl();

  const hasUoMUpdate =
    propertyData.oldProperty &&
    propertyData.newProperty &&
    propertyData.oldProperty.unitOfMeasure !== propertyData.newProperty.unitOfMeasure;

  const getStaticToConditionalComponent = () => {
    const newProperty = propertyData.newProperty;
    const oldProperty = propertyData.oldProperty;

    const addedRules = newProperty.assignments.map((assignment) => (
      <div className={ruleDisplayBox}>
        <RuleDisplay
          conditions={assignment.conditions}
          result={assignment.result || assignment.formula || assignment.range}
          gridView={false}
        />
      </div>
    ));
    return (
      <div className={maxHeight}>
        <h3>{formatMessage(messages.staticToConditional)}</h3>
        <h5>{formatMessage(messages.addedAssignments)}</h5>
        {addedRules}
        <h5>{formatMessage(messages.oldValue)}</h5>
        <Tag removable={false} value={oldProperty.staticAssignment.value} />
      </div>
    );
  };

  const getConditionalToStaticComponent = () => {
    const newProperty = propertyData.newProperty;
    const oldProperty = propertyData.oldProperty;

    const removedRules = oldProperty.assignments.map((assignment) => (
      <div className={ruleDisplayBox}>
        <RuleDisplay
          conditions={assignment.conditions}
          result={assignment.result || assignment.formula || assignment.range}
          gridView={false}
        />
      </div>
    ));

    return (
      <div className={maxHeight}>
        <h3>{formatMessage(messages.conditionalToStatic)}</h3>
        <h5>{formatMessage(messages.newValue)}</h5>
        <Tag removable={false} value={newProperty.staticAssignment.value} />
        <h5>{formatMessage(messages.removedAssignments)}</h5>
        {removedRules}
      </div>
    );
  };

  const getValueDiffComponent = () => {
    return (
      <div>
        <h6>{formatMessage(messages.valueHasChanged)}</h6>
        <p>
          {formatMessage(messages.oldValue)}: {propertyData.differenceDetails.oldValue}
        </p>
        <p>
          {formatMessage(messages.newValue)}: {propertyData.differenceDetails.newValue}
        </p>
      </div>
    );
  };

  const getTypeDiffComponent = () => {
    return (
      <Fragment>
        <h6>{formatMessage(messages.typeHasChanged)}</h6>
        <p>
          {formatMessage(messages.oldType)}: {propertyData.differenceDetails.oldType}
        </p>
        <p>
          {formatMessage(messages.newType)}: {propertyData.differenceDetails.newType}
        </p>
      </Fragment>
    );
  };

  let diffComponent = formatMessage(messages.diffingError);

  switch (propertyData.diffType) {
    case STATIC_TO_CONDITIONAL:
      diffComponent = getStaticToConditionalComponent();
      break;
    case CONDITIONAL_TO_STATIC:
      diffComponent = getConditionalToStaticComponent();
      break;
    case ASSIGNMENT:
      diffComponent = <ChangedPropertyConditions propertyData={propertyData} />;
      break;
    case VALUE_DIFF:
      diffComponent = getValueDiffComponent();
      break;
    case TYPE_DIFF:
      diffComponent = getTypeDiffComponent();
      break;
    default:
      break;
  }

  return (
    <div>
      {hasUoMUpdate && (
        <Alert
          type="info"
          message={formatMessage(messages.propertyUpdatedUnitOfMeasure, {
            propertyName: propertyData.newProperty.name,
            oldUnitOfMeasure: propertyData.oldProperty.unitOfMeasure,
            newUnitOfMeasure: propertyData.newProperty.unitOfMeasure,
          })}
          dismissible={false}
        />
      )}
      {diffComponent}
    </div>
  );
};

export default PropertyBody;
