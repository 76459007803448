export const filterConstraints = (rules, searchValue = '') => {
  if (searchValue) {
    return rules.filter((rule) => {
      const lowerCaseFilter = searchValue.toLowerCase();
      const name = rule.name;

      let hasConditionName = false;
      if (rule.conditions && rule.conditions.length > 0) {
        rule.conditions.forEach((condition) => {
          if (condition.attribute.toLowerCase().includes(lowerCaseFilter)) {
            hasConditionName = true;
          }
        });
      }

      let hasResultName;
      if (rule.results && rule.results.length > 0) {
        rule.results.forEach((result) => {
          if (result.attribute.toLowerCase().includes(lowerCaseFilter)) {
            hasResultName = true;
          }
        });
      }

      return name.toLowerCase().includes(lowerCaseFilter) || hasConditionName || hasResultName;
    });
  }
  return rules;
};
