import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { css } from 'emotion';

import isEqual from 'lodash/isEqual';

import Accordion from '@cimpress/react-components/lib/Accordion';
import { warning } from '@cimpress/react-components/lib/colors';
import Button from '@cimpress/react-components/lib/Button';
import Modal from '@cimpress/react-components/lib/Modal';
import { ocean, platinum, white, coal } from '@cimpress/react-components/lib/colors';

import { VersionDropdown } from './VersionDropdown';
import { getProductInfoFromUrl, getProductPMVUrl } from '../../dataHelper';
import { PIN_TO_CURRENT, VIEW, CURRENT } from '../../../../shared/enums/constants';
import messages from '../messages';

const warningDiv = css`
  display: flex;
  align-items: center;
  height: 100px;
  padding: 10px;
`;

const buttonsDiv = css`
  display: flex;
  justify-content: flex-end;
`;

const flexDiv = css`
  display: flex;
  align-content: center;
`;

const productDiv = css`
  background-color: ${white};
  border: 1px solid ${platinum};
  border-radius: 3px;
  padding: 10px 16px 10px 16px;
  box-shadow: 0 1px 2px 0 ${platinum};
  color: ${coal};
  margin: 0 0 12px 0;
`;

const linkStyle = css`
  color: ${ocean.base};
  float: right;
  &:hover {
    cursor: pointer;
  }
`;

const versionNumber = css`
  color: ${ocean.base};
  margin-left: 8px;
`;

const ProductAccordion = ({
  ancestor,
  headerStyle,
  bodyStyle,
  style,
  index,
  getVersions,
  updateHamVersion,
  mode,
  getCurrentVersion,
}) => {
  const { formatMessage } = useIntl();
  const [productLinkUrl, setProductLinkurl] = useState();
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [toValue, setToValue] = useState();
  const [newLink, setNewLink] = useState();
  const [fromValue, setFromValue] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedProductUrl, setSelectedProductUrl] = useState(ancestor.productUrl);
  const [versionDetails, setVersionDetails] = useState({ pmvUrl: '', version: '' });

  useEffect(() => {
    if (isEqual(mode, VIEW)) {
      if (ancestor.productUrl.includes(CURRENT)) {
        getCurrentVersion(ancestor.productId).then((response) => {
          let pmvUrl = getProductPMVUrl(ancestor.productId, response.version);
          setVersionDetails({ pmvUrl, version: response.version, isPinToCurrent: true });
        });
      } else {
        setVersionDetails(getProductInfoFromUrl(ancestor.productUrl, ancestor.productId));
      }
    }
  }, [mode, ancestor, getCurrentVersion]);

  const getProductLinkUrl = (version) => {
    return `https://api.products.cimpress.io/v1/products/${ancestor.productId}/versions/${version}`;
  };

  const getCurrentVersionLink = () => {
    return `https://api.products.cimpress.io/v1/products/${ancestor.productId}:current`;
  };

  const closeModal = () => {
    setSaveModalOpen(false);
  };

  const openModal = () => {
    setSaveModalOpen(true);
  };

  const updateVersion = async () => {
    setSelectedProductUrl(newLink);
    updateHamVersion(index, newLink);
    setNewLink(undefined);
    closeModal();
  };

  const changeValue = (e, oldSelectedValue) => {
    if (e.value === PIN_TO_CURRENT) {
      setToValue('Current');
      setNewLink(getCurrentVersionLink());
    } else {
      setToValue(`v${e.value}`);
      setNewLink(getProductLinkUrl(e.value));
    }

    if (oldSelectedValue === PIN_TO_CURRENT) {
      setFromValue('Current');
    } else {
      setFromValue(`v${oldSelectedValue}`);
    }

    openModal();
  };

  const productViewMode = (
    <div className={productDiv}>
      {ancestor.productId}
      <span className={versionNumber}>
        ( v{versionDetails.version} {versionDetails.isPinToCurrent && '- Pinned to Current'} )
      </span>
      <a href={versionDetails.pmvUrl} className={linkStyle} target="_blank" rel="noreferrer">
        {formatMessage(messages.viewProduct)}
      </a>
    </div>
  );

  return isEqual(mode, VIEW) ? (
    productViewMode
  ) : (
    <Accordion
      key={`product-${index}`}
      title={<span>{ancestor.productId}</span>}
      headerStyle={headerStyle}
      bodyStyle={bodyStyle}
      style={style}>
      <div className={flexDiv}>
        <VersionDropdown
          productId={ancestor.productId}
          selectedProductUrl={selectedProductUrl}
          onChange={changeValue}
          getVersions={getVersions}
          setProductLinkUrl={setProductLinkurl}
          setLoading={setLoading}
        />
        {loading ? null : (
          <Button href={productLinkUrl} target="_blank" type="link">
            {formatMessage(messages.viewProduct)}
          </Button>
        )}
      </div>

      <Modal
        style={{ borderTop: `4px solid ${warning.base}`, borderRadius: '5px' }}
        type="warning"
        show={saveModalOpen}
        onRequestHide={closeModal}
        title={formatMessage(messages.sureToChangeModalTitle, { fromValue: fromValue, toValue: toValue })}
        closeButton={true}
        footer={
          <div className={buttonsDiv}>
            <button className="btn btn-default" onClick={closeModal}>
              {formatMessage(messages.cancel)}
            </button>
            <button className="btn btn-primary" onClick={updateVersion}>
              {formatMessage(messages.yes)}
            </button>
          </div>
        }>
        <div className={warningDiv}>
          <p>{formatMessage(messages.changeModalBody)}</p>
        </div>
      </Modal>
    </Accordion>
  );
};

export default ProductAccordion;
