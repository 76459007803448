import map from 'lodash/map';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';
import includes from 'lodash/includes';

import { getTreeFromFlatData, getFlatDataFromTree, find } from 'react-sortable-tree';
import { getAdoption } from '../../services/hamService';
import { getStandardAdoptions } from '../../services/standardDefinitionService';
import { getProductData, getProduct } from '../../services/productService';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

const hamBaseUrl = 'https://ham.products.cimpress.io/v2/hams/';

export const convertFlatToTree = async (associatedBusinessId, flatDataArray, accessToken) => {
  const flatList = [];
  const rootProductNodes = new Set();
  const rootStandardNodes = new Set();
  const adoptionNameDictionary = {};

  map(flatDataArray, ({ id, name, productId, productUrl, standardId, parentHam, constraintId }) => {
    //filter adoption ham nodes from tree
    !isEmpty(constraintId) &&
      flatList.push({
        id,
        name,
        constraintId,
        productUrl,
        productId,
        parent: !isUndefined(parentHam) ? parentHam : productId,
      });

    // collecting root nodes for creating custom product and adoption nodes
    if (isUndefined(parentHam) && !isEmpty(productId)) {
      rootProductNodes.add(
        JSON.stringify({
          productId,
          name: productId,
          standardId: standardId,
        })
      );

      //for getting adoption names
      if (!isEmpty(standardId) && !isEmpty(productUrl) && isUndefined(adoptionNameDictionary[productId])) {
        adoptionNameDictionary[productId] = productUrl;
      }

      //for adding standard nodes
      if (standardId && !isEmpty(standardId)) {
        rootStandardNodes.add(standardId);
      }
    }
  });

  map(Array.from(rootStandardNodes).sort(), (standard) => {
    flatList.push({
      id: standard,
      name: standard,
      standardId: standard,
      parent: null,
      isStandard: true,
    });
  });

  try {
    await Promise.all(
      //get adoption names
      Object.entries(adoptionNameDictionary).map(async ([key, value]) => {
        //check if adoption is retired
        const productResponse = await getProduct({ productId: key, accessToken });
        const status = get(productResponse, 'status');
        if (!isEqual(status, 'retired')) {
          const response = await getAdoption(value, accessToken);
          adoptionNameDictionary[key] = response.name;
        } else {
          adoptionNameDictionary[key] = undefined;
        }
      })
    );
  } catch (e) {
    console.error(e);
  }

  map(Array.from(rootProductNodes).sort(), (product) => {
    const productNode = JSON.parse(product);
    const isAdoption = !isEmpty(productNode.standardId);
    const isRetired = !adoptionNameDictionary[productNode.productId];

    if ((isAdoption && !isRetired) || !isAdoption) {
      //filter retired adoption as hams
      flatList.push({
        id: productNode.productId,
        name: isAdoption ? adoptionNameDictionary[productNode.productId] : productNode.productId,
        parent: isAdoption ? productNode.standardId : null,
        standardId: productNode.standardId,
        productId: productNode.productId,
        isProduct: !isAdoption,
        isAdoption: isAdoption,
      });
    } else {
      return;
    }
  });

  await Promise.all(
    //get all adoptions for standard
    map(Array.from(rootStandardNodes).sort(), async (standard) => {
      const adoptionsResponse = await getStandardAdoptions(associatedBusinessId, standard, accessToken);
      map(adoptionsResponse._embedded.item, (adoption) => {
        const adoptionNode = getAdoptionNode(adoption, standard);
        !adoptionNameDictionary.hasOwnProperty(adoptionNode.productId) && flatList.push(adoptionNode);
      });
    })
  );

  const treeData = getTreeFromFlatData({
    flatData: flatList.map((node) => ({ ...node, title: node.name })),
    getKey: (node) => node.id,
    getParentKey: (node) => node.parent,
    rootKey: null,
  });

  //if tree has a single node - keep it open
  if (treeData.length === 1) {
    treeData[0].expanded = true;
  }
  return treeData;
};

export const createNoHamsTree = async ({ associatedBusinessId, accessToken, productId }) => {
  const flatNodeList = [];
  const productData = await getProductData({ productID: productId, accessToken, version: 'current' });

  if (productData.isStandard === true) {
    const adoptionsResponse = await getStandardAdoptions(associatedBusinessId, productId, accessToken);
    await Promise.all(
      map(adoptionsResponse._embedded.item, (adoption) => {
        const adoptionNode = getAdoptionNode(adoption, productId);
        flatNodeList.push(adoptionNode);
      })
    );

    const standardNode = {
      id: productId,
      name: productId,
      standardId: productId,
      parent: null,
      isStandard: true,
      expanded: true,
    };
    flatNodeList.push(standardNode);
  } else
    return [
      {
        id: productId,
        name: productId,
        parent: null,
        isProduct: true,
        isAdoption: false,
        productId: productId,
        title: productId,
        expanded: true,
        children: [],
      },
    ];

  const treeData = getTreeFromFlatData({
    flatData: flatNodeList.map((node) => ({ ...node, title: node.name })),
    getKey: (node) => node.id,
    getParentKey: (node) => node.parent,
    rootKey: null,
  });

  return treeData;
};

export const addProductInfoToAllNodes = ({ path, expandedTreeData, isAdoptionHam, adoptionHamNode }) => {
  const pathNodes = getPathNodes(path, expandedTreeData).reverse();
  const parentProductNode = pathNodes.filter(({ node }) => node.isAdoption || node.isProduct);
  const firstHamNode = pathNodes.filter(({ node }) => !isEmpty(node.productUrl) && !isEmpty(node.constraintId));
  const pathNodesClone = cloneDeep(pathNodes);
  const pathNodesWithProductInfo = pathNodesClone.map(({ node }) => {
    return node.isStandard || (node.isAdoption && isEqual(path.length, 2))
      ? { ...node, productUrl: isAdoptionHam ? adoptionHamNode.productUrl : node.productUrl }
      : {
          ...node,
          productId: isEmpty(node.productId) ? parentProductNode[0].node.id : node.productId,
          productUrl:
            isEmpty(node.productUrl) && !isEmpty(firstHamNode[0].node)
              ? firstHamNode[0].node.productUrl
              : node.productUrl,
        };
  });
  return pathNodesWithProductInfo;
};

export const generatePathTitle = (pathNodes) => {
  const pathNames = pathNodes.map((x) => x.name).reverse();
  return pathNames.join(' • ');
};

export const addEllipsisToTitle = (title, nodeName) => {
  let newTitle = title;
  return title.length > 60 ? (newTitle = title.substr(0, 50 - nodeName.length) + '...' + nodeName) : newTitle;
};

export const getProductInfoFromUrl = (productUrl, productId) => {
  let index = productUrl.indexOf('versions/');
  let version = productUrl.substr(index + 9, productUrl.length);

  return {
    pmvUrl: `https://productmanager-v2.products.cimpress.io/product-details-V2/${productId}/versions/${version}`,
    version,
  };
};

export const getProductPMVUrl = (productId, version) => {
  return `https://productmanager-v2.products.cimpress.io/product-details-V2/${productId}/versions/${version}`;
};

const searchMethod = ({ node, searchQuery }) => node.id === searchQuery;

export const findNodeInTree = (id, treeData) => {
  const result = find({ getNodeKey: ({ node }) => node.id, treeData, searchQuery: id, searchMethod });

  if (result.matches.length === 1) {
    result.matches[0].expanded = true;
    return result.matches[0];
  }
  return '';
};

export const expandPathNodes = ({ path, treeData }) => {
  const rootNode = treeData.find((node) => node.id === path[0]);
  const rootNodeClone = cloneDeep(rootNode);
  rootNodeClone.expanded = true;
  let count = path.length - 1;
  rootNodeClone.children.length > 0 && expandChild(path, rootNodeClone, count);
  return [rootNodeClone];
};

const expandChild = (path, treeNode, count) => {
  treeNode.children.length > 0 &&
    treeNode.children.forEach((childNode) => {
      if (includes(path, childNode.id)) {
        childNode.expanded = true;
        count = count - 1;
        count > 0 && expandChild(path, childNode, count);
      }
    });
};

const getPathNodes = (path, treeData) => {
  const flatData = getFlatDataFromTree({
    treeData,
    getNodeKey: ({ node }) => node.id,
    ignoreCollapsed: false,
  });

  return flatData.filter(({ node }) => includes(path, node.id) && node);
};

const getAdoptionNode = (adoption, standard) => {
  let name = '';
  forEach(adoption.versions, (version) => {
    if (version.current === true || version.version === adoption.versions.length) {
      return (name = version.name);
    }
    name = version.name;
  });

  return {
    id: adoption.productId,
    productId: adoption.productId,
    standardId: standard,
    name: name,
    parent: standard,
    isAdoption: true,
  };
};

export const addProductIdOnNode = (node, path) => {
  //adds productId and hamUrl on onNodeSelect callback node
  const nodeClone = cloneDeep(node);
  nodeClone.productId = path[0];
  return addHamUrl(nodeClone);
};

export const addProductIdOnAdoptionNode = (node) => {
  //adds productId as standardId for adoption node on response to FCA component
  const nodeClone = cloneDeep(node);
  nodeClone.productId = nodeClone.standardId && nodeClone.standardId;
  return addHamUrl(nodeClone);
};

export const addHamUrl = (node) => {
  node.hamUrl = hamBaseUrl + node.id;
  return node;
};
