import React from 'react';
import { useIntl } from 'react-intl';
import { css } from 'emotion';

import Checkbox from '@cimpress/react-components/lib/Checkbox';
import Table from '@cimpress/react-components/lib/Table';
import { anchorButton, anchorButtonNoMargin } from '../../constants/css';
import CatalogSelection from './CatalogSelection';
import SelectedProductMessage from './SelectedProductMessage';
import messages from '../messages';
import isEmpty from 'lodash/isEmpty';

const truncate = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectedProducts = ({
  tempSelectedProducts = [],
  selectedSearchResults = [],
  addSelectedProducts,
  removeSelectedProducts,
  viewAdvancedSearch,
  selectedProductsAsObject,
  selectedProductsTableProducts,
  selectAll,
  setSelectAll,
  checkBoxStates,
  selectedCatalog,
  selectCatalog,
  deselectCatalog,
  setSelectedCatalog,
  currentSearchCatalog,
  searchResults,
}) => {
  const { formatMessage } = useIntl();

  const checkboxChange = (e, product) => {
    if (checkBoxStates[product.productId]) {
      removeSelectedProducts([product]);
    } else {
      addSelectedProducts([product]);
    }
  };

  const selectAllProducts = () => {
    const productsToAdd = [];

    // only add keys that are not already selected
    selectedProductsTableProducts.forEach((product) => {
      if (!checkBoxStates[product.productId]) {
        checkBoxStates[product.productId] = true;
        productsToAdd.push(product);
      }
    });
    addSelectedProducts(productsToAdd);
  };

  const deselectAll = () => {
    const productsToRemove = [];

    // only add keys that are not already selected
    Object.keys(checkBoxStates).forEach((key) => {
      if (checkBoxStates[key]) {
        productsToRemove.push(selectedProductsAsObject[key]);
      }
    });
    removeSelectedProducts(productsToRemove);
  };

  const checkAllBoxes = () => {
    const shouldSelectAll = !selectAll; // selectAll holds the previous checkbox state, need to flip it first
    if (shouldSelectAll) {
      selectAllProducts();
    } else {
      deselectAll();
    }
    setSelectAll(shouldSelectAll);
  };

  return (
    <div>
      <button className={anchorButton} onClick={viewAdvancedSearch}>
        {`< ${formatMessage(messages.advancedSearchButtonLink)}`}
      </button>
      <h3>
        <SelectedProductMessage
          tempSelectedCatalog={selectedCatalog}
          selectedProductCount={tempSelectedProducts.length + selectedSearchResults.length}
          defaultMessage={formatMessage(messages.noSelectedProducts)}
        />
      </h3>
      {!isEmpty(selectedCatalog) && (
        <CatalogSelection
          searchResults={searchResults}
          selectedCatalog={selectedCatalog}
          selectCatalog={selectCatalog}
          deselectCatalog={deselectCatalog}
          setSelectedCatalog={setSelectedCatalog}
          currentSearchCatalog={currentSearchCatalog}
          showSelection={true}
        />
      )}
      {!isEmpty(selectedProductsTableProducts) && (
        <Table
          columns={[
            {
              Header: <Checkbox checked={selectAll} onChange={checkAllBoxes} />,
              accessor: 'checkbox',
              width: 70,
              sortable: false,
            },
            { Header: formatMessage(messages.productIdHeader), accessor: 'productId' },
            { Header: formatMessage(messages.nameHeader), accessor: 'name' },
            { Header: formatMessage(messages.descriptionHeader), accessor: 'description' },
            { Header: formatMessage(messages.categoryHeader), accessor: 'category' },
          ]}
          data={selectedProductsTableProducts.map((x) => {
            return {
              checkbox: (
                <Checkbox
                  style={{ margin: '0px' }}
                  checked={
                    (checkBoxStates.hasOwnProperty(x.productId) && checkBoxStates[x.productId]) ||
                    (!isEmpty(selectedCatalog.products) && selectedCatalog.products.hasOwnProperty(x.productId))
                  }
                  onChange={checkboxChange}
                  payload={x}
                  disabled={selectedCatalog.products && selectedCatalog.products.hasOwnProperty(x.productId)}
                />
              ),
              name: <div className={truncate}>{x.name}</div>,
              productId: (
                <a className={anchorButtonNoMargin} href={x.link} target="_blank" rel="noreferrer">
                  {x.productId}
                </a>
              ),
              description: <div className={truncate}>{x.description}</div>,
              category: x.category ? x.category.name : '',
            };
          })}
          isCondensed={false}
          isBordered={true}
          hasHover={true}
          paginationPosition="bottomLeft"
        />
      )}
    </div>
  );
};

export default SelectedProducts;
