import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { css } from 'emotion';

import SchemaTreeSelector from '@cimpress-technology/tree-selector';
import { silver } from '@cimpress/react-components/lib/colors';
import Accordion from '@cimpress/react-components/lib/Accordion';
import TextField from '@cimpress/react-components/lib/TextField';
import Button from '@cimpress/react-components/lib/Button';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';

import { accordionOverflowVisible } from '../../../constants/css';
import { doAdvancedSearch } from '../../../utilities/productSearch';
import CatalogDropdown from '../../../shared/CatalogDropdown';
import SearchSummaryText from './SearchSummaryText';
import messages from '../../messages';
import sharedMessages from '../../../shared/messages';
import AdvancedSearchResults from './AdvancedSearchResults';

const centered = css`
  text-align: center;
`;

const rightAlign = css`
  float: right;
  margin-top: 10px;
`;

const sizeOfSearches = css`
  max-width: 700px;
`;

const noResultsCss = css`
  margin-left: 20px;
  font-size: 12px;
  font-style: italic;
`;

const RETURN = 13;

const AdvancedSearch = ({
  account,
  accessToken,
  productTypes,
  standardsHaveAdoptions,
  searchResults = [],
  updateSearchResults,
  keywordPresetSearch = '',
  catalogs,
  setCatalogs,
  addSelectedProducts,
  removeSelectedProducts,
  keywordInput,
  setKeywordInput,
  catalogForSearch,
  setCatalogForSearch,
  selectedCategory,
  setSelectedCategory,
  searchResultChecks = {},
  selectedCatalog,
  setSelectedCatalog,
  onlyCatalogSearched,
  setOnlyCatalogSearched,
  currentSearchCatalog,
  setCurrentSearchCatalog,
  selectCatalog,
  deselectCatalog,
  allowCatalogSelection,
}) => {
  const { formatMessage } = useIntl();
  const [categoryError, setCategoryError] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchIsOpen, setSearchIsOpen] = useState(isEmpty(keywordPresetSearch));
  const [noResults, setNoResults] = useState(false);
  const [selectAllIsChecked, setSelectAllIsChecked] = useState(false);
  const [searchKeywordText, setSearchKeywordText] = useState(keywordInput);
  const [searchCatalogText, setSearchCatalogText] = useState(selectedCatalog && selectedCatalog.label);
  const [searchCategoryText, setSearchCategoryText] = useState(selectedCategory && selectedCategory.label);

  useEffect(() => {
    if (isEmpty(keywordPresetSearch)) {
      setSearchIsOpen(true);
    } else {
      setSearchIsOpen(false);
    }
  }, [keywordPresetSearch]);

  useEffect(() => {
    setSelectAllIsChecked(false);
    if (isEmpty(searchResults)) {
      setSearchIsOpen(true);
    }
  }, [searchResults]);

  const toggleAllSearchResultsCheck = () => {
    const newSearchResultChecks = { ...searchResultChecks };
    const checkState = !selectAllIsChecked; // must be opposite of search result checks

    Object.keys(newSearchResultChecks).forEach((productId) => {
      newSearchResultChecks[productId] = checkState;
    });

    if (checkState) {
      addSelectedProducts(searchResults);
    } else {
      removeSelectedProducts(searchResults);
    }
    setSelectAllIsChecked(!selectAllIsChecked);
  };

  const toggleSearchResultCheck = (e, product) => {
    const newSearchResultChecks = { ...searchResultChecks };
    newSearchResultChecks[product.productId] = !newSearchResultChecks[product.productId];
    if (newSearchResultChecks[product.productId]) {
      addSelectedProducts([product]);
    } else {
      removeSelectedProducts([product]);
    }
  };

  useEffect(() => {
    if (!isEmpty(keywordPresetSearch)) {
      setKeywordInput(keywordPresetSearch);
    }
  }, [setKeywordInput, keywordPresetSearch]);

  const toggleSearchAccordion = () => setSearchIsOpen(!searchIsOpen);
  const onKeyDownFilter = (e) => e.keyCode === RETURN && search();
  const updateKeyword = (e) => setKeywordInput(e.target.value);
  const changeSelectedCatalog = (catalog) => {
    setCatalogForSearch(catalog);
  };

  // (value, title, categoryPath, rest)
  const changeSelectedCategory = (value, title) => {
    setSelectedCategory({ value, label: !isEmpty(title) && title[0] });
  };
  const categoryTreeError = (error) => {
    setCategoryError(error);
  };

  const search = async () => {
    setIsSearching(true);

    setSearchKeywordText(keywordInput);

    let categoryInput = null;
    if (selectedCategory && selectedCategory.value) {
      categoryInput = selectedCategory.value;
      setSearchCategoryText(categoryInput);
    } else {
      setSearchCategoryText('');
    }

    let catalogInput = null;
    const catalogWasSelected = catalogForSearch && catalogForSearch.value && catalogForSearch.value.id;
    if (catalogWasSelected) {
      setCurrentSearchCatalog({ id: catalogForSearch.value.id, name: catalogForSearch.value.name });
      catalogInput = catalogForSearch.value.id;
      setSearchCatalogText(catalogForSearch.value.name);
    } else {
      setSearchCatalogText('');
    }

    if (isEmpty(keywordInput) && isEmpty(categoryInput) && catalogWasSelected) {
      setOnlyCatalogSearched(true);
    } else {
      setOnlyCatalogSearched(false);
    }

    const results = await doAdvancedSearch({
      accessToken,
      keywordInput,
      productTypes,
      standardsHaveAdoptions,
      catalogId: catalogInput,
      categoryId: categoryInput,
      keyword: keywordInput,
      accountId: account.id,
    });

    if (isEmpty(results) || isEmpty(results.products)) {
      setNoResults(true);
    }

    if (!isEmpty(results.products)) {
      setNoResults(false);
      setSearchIsOpen(false);
      updateSearchResults(results);
    }

    setIsSearching(false);
  };

  return (
    <div>
      <div className={accordionOverflowVisible}>
        <Accordion
          title={formatMessage(sharedMessages.searchParameters)}
          headerStyle={{ backgroundColor: 'white', border: `1px solid ${silver}` }}
          customOpen={searchIsOpen}
          onHeaderClick={toggleSearchAccordion}
          bodyStyle={{ border: 'none' }}
          style={{ border: 'none' }}>
          <div className={sizeOfSearches}>
            <TextField
              name="keyword"
              label={formatMessage(messages.keyword)}
              value={keywordInput}
              onChange={updateKeyword}
              onKeyDown={onKeyDownFilter}
            />
            {allowCatalogSelection && (
              <CatalogDropdown
                accessToken={accessToken}
                account={account}
                catalogForSearch={catalogForSearch}
                setCatalogForSearch={changeSelectedCatalog}
                catalogs={catalogs}
                setCatalogs={setCatalogs}
              />
            )}

            {!isEmpty(categoryError) ? (
              <p>{categoryError}</p>
            ) : (
              <SchemaTreeSelector
                token={accessToken}
                onChange={changeSelectedCategory}
                onError={categoryTreeError}
                value={selectedCategory && selectedCategory.value}
                spinner={true}
                allowClear={true}
              />
            )}
            <Button className={rightAlign} type="primary" onClick={search}>
              {formatMessage(sharedMessages.search)}
            </Button>
          </div>
        </Accordion>
      </div>
      {!isEmpty(searchResults) && (
        <SearchSummaryText
          searchResults={searchResults}
          searchKeywordText={searchKeywordText}
          searchCatalogText={searchCatalogText}
          searchCategoryText={searchCategoryText}
        />
      )}
      {isSearching ? (
        <div className={centered}>
          <Spinner size="large" />
          <p>{formatMessage(messages.fetchingSearch)}</p>
        </div>
      ) : !noResults ? (
        <AdvancedSearchResults
          searchResults={searchResults}
          standardsHaveAdoptions={standardsHaveAdoptions}
          searchResultCheckBoxStates={searchResultChecks}
          toggleAllSearchResultsCheck={toggleAllSearchResultsCheck}
          toggleSearchResultCheck={toggleSearchResultCheck}
          selectAllIsChecked={selectAllIsChecked}
          catalogForSearch={catalogForSearch}
          selectedCatalog={selectedCatalog}
          selectCatalog={selectCatalog}
          deselectCatalog={deselectCatalog}
          setSelectedCatalog={setSelectedCatalog}
          onlyCatalogSearched={onlyCatalogSearched}
          currentSearchCatalog={currentSearchCatalog}
        />
      ) : (
        <p className={noResultsCss}>{formatMessage(messages.noSearchResults)}</p>
      )}
    </div>
  );
};

export default AdvancedSearch;
