import get from 'lodash/get';
import isNil from 'lodash/isNil';

import { mapRangeToText, mapSingleNumericValueToText } from '../mappers/range';
import { parseRange, isRangeTextValid } from '../parsers/rangeParser';
import { VALUE, RANGE } from '../enums/options';
import { FORMULA } from '../enums/operators';

const ERROR_IDS = {
  FORMAT_ERROR: 'formatError',
  INVALID_MIN_PRECISION: 'invalidMinPrecision',
  INVALID_MAX_PRECISION: 'invalidMaxPrecision',
  INVALID_VALUE_PRECISION: 'invalidValuePrecision',
  MIN_GREATER_THAN_MAX: 'minGtMax',
  INVALID_INCREMENT_PRECISION: 'invalidIncrementPrecision',
  RANGE_IS_DUPLICATE: 'rangeIsDuplicate',
  INVALID_VALUE: 'rangeInvalidValue',
};

const WARNING_IDS = {
  MAX_NOT_ON_INCREMENT: 'maxNotOnIncrement',
};

const FLOAT_REGEX = /^([0-9]+)((\.)?([0-9.]*))?$/i;
const DECIMAL_IDX = 3;
const MANTISSA_IDX = 4;
const MAX_MANTISSA_LENGTH = 8;

const isFloat = (n) => {
  return Number(n) === n && n % 1 !== 0;
};

// get Scale of floating number e.g. scale for 123.45 is 2
const getScale = (n) => {
  if (isFloat(n)) {
    const parts = n.toString().split('.');
    return parts[1].length;
  }

  return 0;
};

const getMaxScale = (num1, num2) => {
  const scale1 = getScale(num1);
  const scale2 = getScale(num2);

  return scale1 > scale2 ? scale1 : scale2;
};

const subtract = (num1, num2) => {
  const scale = getMaxScale(num1, num2);
  const normalizeFactor = 10 ** scale;

  return (num1 * normalizeFactor - num2 * normalizeFactor) / normalizeFactor;
};

const isFactor = (dividend, divisor) => {
  const scale = getMaxScale(dividend, divisor);
  const normalizeFactor = 10 ** scale;

  const remainder = (dividend * normalizeFactor) % (divisor * normalizeFactor);
  return remainder === 0;
};

function isValidPrecision(number) {
  const matches = number.toString().match(FLOAT_REGEX);

  if (matches && matches[DECIMAL_IDX]) {
    if (matches[MANTISSA_IDX]) {
      return matches[MANTISSA_IDX].length <= MAX_MANTISSA_LENGTH;
    }
  }

  return true;
}

function getMaximumWarningMessage(range) {
  const max = range.minimum + Math.floor((range.maximum - range.minimum) / range.increment) * range.increment;

  return { id: WARNING_IDS.MAX_NOT_ON_INCREMENT, values: { max } };
}

function validateMaximum(validationResult) {
  const { numericRange } = validationResult;

  if (!isNil(numericRange.maximum)) {
    if (!isValidPrecision(numericRange.maximum)) {
      validationResult.errors.push({ id: ERROR_IDS.INVALID_MAX_PRECISION, values: { precision: MAX_MANTISSA_LENGTH } });
    } else if (!isNil(numericRange.minimum)) {
      if (numericRange.minimum > numericRange.maximum) {
        validationResult.errors.push({ id: ERROR_IDS.MIN_GREATER_THAN_MAX });
      }

      if (!isNil(numericRange.increment)) {
        if (!isFactor(subtract(numericRange.maximum, numericRange.minimum), numericRange.increment)) {
          validationResult.warnings.push(getMaximumWarningMessage(validationResult.numericRange));
        }
      }
    }
  }
}

function validateMinimum(validationResult) {
  const { range } = validationResult;

  if (!isValidPrecision(range.minimum)) {
    validationResult.errors.push({ id: ERROR_IDS.INVALID_MIN_PRECISION, values: { precision: MAX_MANTISSA_LENGTH } });
  }
}

function validateIncrement(rangeValidation) {
  const { range } = rangeValidation;

  if (!isNil(range.increment)) {
    if (!isValidPrecision(range.increment)) {
      rangeValidation.errors.push({
        id: ERROR_IDS.INVALID_INCREMENT_PRECISION,
        values: { precision: MAX_MANTISSA_LENGTH },
      });
    }
  }
}

const validateValue = (validationResult) => {
  const { value } = validationResult;

  if (!isValidPrecision(value)) {
    validationResult.errors.push({ id: ERROR_IDS.INVALID_VALUE_PRECISION, values: { precision: MAX_MANTISSA_LENGTH } });
  }
};

function validateRangeStructure(validationResult) {
  validateMinimum(validationResult);
  validateMaximum(validationResult);
  validateIncrement(validationResult);
}

const validateValueStructure = (validationResult) => {
  validateValue(validationResult);
};

const validateRangeEquality = (testRange, validRange) => {
  if (testRange.type === FORMULA || validRange.type === FORMULA) {
    return true;
  }

  const testRangeText =
    testRange.type === VALUE ? mapSingleNumericValueToText(testRange.value) : mapRangeToText(testRange.range);
  const validRangeText =
    validRange.type === VALUE ? mapSingleNumericValueToText(validRange.value) : mapRangeToText(validRange.range);

  return testRangeText === validRangeText;
};

function validateDuplicateRanges(validationResult, existingRangeValues) {
  const existingRangeTexts = existingRangeValues.map((rangeValue) =>
    rangeValue.type === VALUE ? mapSingleNumericValueToText(rangeValue.value) : mapRangeToText(rangeValue.range)
  );

  if (
    existingRangeTexts.find((existingRangeText) => existingRangeText === validationResult.rangeString) !== undefined
  ) {
    validationResult.errors.push({ id: ERROR_IDS.RANGE_IS_DUPLICATE });
  }
}

// Validates that a range is a subset of another range
// To be a subset:
// - The test range minimum needs to be within the valid range
// - The test range maximum must be within the valid range
// - If the valid range has an increment,
//    the test range minimum must be on a valid incremental value
// - If the valid range has an increment,
//    the test range increment must be a whole multiple of the valid range increment
const validateRangeIsSubsetOfRange = (testRange, validRange) => {
  const validMinimum = Number(validRange.minimum);

  if (testRange.minimum < validMinimum) {
    return false;
  }

  if (validRange.maximum) {
    if (testRange.maximum === null || testRange.maximum === undefined) {
      return false;
    }

    const validMaximum = Number(validRange.maximum);

    if (testRange.minimum > validMaximum) {
      return false;
    }

    if (!isNil(testRange.maximum) && testRange.maximum !== testRange.minimum && testRange.maximum > validMaximum) {
      return false;
    }
  }

  if (validRange.increment) {
    const validIncrement = Number(validRange.increment);

    if (!isFactor(subtract(testRange.minimum, validMinimum), validIncrement)) {
      return false;
    }

    if (!isNil(testRange.maximum) && testRange.maximum !== testRange.minimum) {
      if (!testRange.increment) {
        return false;
      }
      if (!isFactor(testRange.increment, validIncrement)) {
        return false;
      }
    }
  }

  return true;
};

const validateValidSubset = (testValueOrRange, validValueOrRange) => {
  if (testValueOrRange.type === FORMULA || validValueOrRange.type === FORMULA) {
    return true;
  }

  let testRange = getRangeFromValueOrRange(testValueOrRange);
  let validRange = getRangeFromValueOrRange(validValueOrRange);

  return validateRangeIsSubsetOfRange(testRange, validRange);
};

const validateValidRangeValue = (testRange, validValue) => {
  switch (validValue.type) {
    case VALUE:
      if (testRange.minimum !== testRange.maximum && !isNil(testRange.maximum)) {
        return false;
      }

      return testRange.minimum === Number(validValue.value);
    case RANGE:
      return validateRangeIsSubsetOfRange(testRange, validValue.range);
    case FORMULA:
      return true;
    default:
      throw new Error('Encountered unknown value type validating range value');
  }
};

const getRangeFromValueOrRange = (valueOrRange) => {
  switch (valueOrRange.type) {
    case VALUE:
      return {
        minimum: valueOrRange.value,
        maximum: valueOrRange.value,
        increment: 1,
      };
    case RANGE:
      return valueOrRange.range;
    default:
      throw new Error('Encountered unknown value type validating range value');
  }
};

const validateValidationResultAgainstValidValues = (validationResult, validValues, enforceValidValues) => {
  if (!validValues.some((validValue) => validateValidRangeValue(validationResult.numericRange, validValue))) {
    if (enforceValidValues) {
      validationResult.errors.push({ id: ERROR_IDS.INVALID_VALUE });
    } else {
      validationResult.warnings.push({ id: ERROR_IDS.INVALID_VALUE });
    }
  }
};

const convertRange = (range) => ({
  minimum: Number(range.minimum),
  maximum: range.maximum ? Number(range.maximum) : undefined,
  increment: range.increment ? Number(range.increment) : undefined,
});

export const validateRangeValues = (rangeValues, validValues, enforceValidValues) => {
  const validations = rangeValues.map((existingRange) => {
    const validationResult = {
      type: RANGE,
      errors: [],
      warnings: [],
    };
    validationResult.type = existingRange.type;
    validationResult[existingRange.type] = existingRange[existingRange.type];

    if (validationResult.type === VALUE) {
      validationResult.numericRange = convertRange({
        minimum: validationResult.value,
        maximum: validationResult.value,
      });
      validateValueStructure(validationResult);
    } else {
      validationResult.numericRange = convertRange(validationResult.range);
      validateRangeStructure(validationResult);
    }

    if (validValues) {
      validateValidationResultAgainstValidValues(validationResult, validValues, enforceValidValues);
    }

    return validationResult;
  });

  return validations.filter(
    (validation) => get(validation, 'errors.length', 0) > 0 || get(validation, 'warnings.length', 0) > 0
  );
};

export const validateRangeString = (rangeString, existingRangeValues, validValues, enforceValidValues) => {
  const validationResult = {
    type: RANGE,
    rangeString,
    errors: [],
    warnings: [],
  };

  if (rangeString.trim().length !== 0) {
    if (!isRangeTextValid(rangeString)) {
      validationResult.errors.push({ id: ERROR_IDS.FORMAT_ERROR });
    } else {
      const parsedRange = parseRange(rangeString);

      if (parsedRange) {
        validationResult.type = parsedRange.type;
        validationResult[parsedRange.type] = parsedRange[parsedRange.type];

        // If single numeric value
        if (validationResult.type === VALUE) {
          // Convert to numeric range for validation
          validationResult.numericRange = convertRange({
            minimum: validationResult.value,
            maximum: validationResult.value,
          });
          validateValueStructure(validationResult);
        } else {
          // Else is range
          validationResult.numericRange = convertRange(validationResult.range);
          validateRangeStructure(validationResult);
        }

        validateDuplicateRanges(validationResult, existingRangeValues);

        if (validValues) {
          validateValidationResultAgainstValidValues(validationResult, validValues, enforceValidValues);
        }
      }
    }
  }

  return validationResult;
};

export default {
  validateRangeString,
  validateRangeValues,
  validateValidSubset,
  validateRangeEquality,
  mapRangeToText,
  mapSingleNumericValueToText,
};
