import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { css, cx } from 'emotion';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';

import Card from '@cimpress/react-components/lib/Card';
import InlineEdit from '@cimpress/react-components/lib/InlineEdit';
import IconBin from '@cimpress-technology/react-streamline-icons/lib/IconBin';
import { teal, danger } from '@cimpress/react-components/lib/colors';

import Conditions from './Conditions';
import SelectedProducts from './SelectedProducts';

import { convertOptionsToAttributes } from '../utilities/convertOptionsToAttributes';
import { getAttributes } from '../../../services/possibilities';
import { generateListOfItems } from '../../../shared/formatters/listToStringFormatter';
import { mapRangeToText } from '../../../shared/mappers/range';
import { VALUE, RANGE } from '../../../shared/enums/options';
import { FORMULA } from '../../../shared/enums/operators';
import { LINK_MODE } from '../constants/linkModeConstants';

import messages from './messages';

const positionSelectionName = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .inline-edit-group {
    margin-bottom: 5px !important;
  }
`;

const limitWidth = css`
  max-width: 1200px;
`;

const binIcon = css`
  cursor: pointer;
`;

const hideErrorDiv = css`
  display: none;
`;
const showErrorDiv = css`
  display: block;
  color: ${danger.base};
  font-size: small;
`;

const ProductLinkCard = ({
  account,
  searchErrors,
  selectedProducts,
  selectedCatalog,
  removeSelectedCatalog,
  accessToken,
  attributeAggregationStyle,
  deleteProductLink,
  removeSelectedProducts,
  updateSelectedAdoptions,
  productTypes,
  openDrawerWithSelectedProducts,
  searchBarProps,
  isNewProductLink = true,
  conditionSet = { name: '', conditions: [] },
  setConditionSet,
  originalSelectionName = '',
  selectionName,
  setSelectionName,
  attributes = {},
  setAttributes,
  isNameUnique,
  mode,
}) => {
  const { formatMessage } = useIntl();
  const [hasManuallyEditedName, setHasManuallyEditedName] = useState(!isNewProductLink);
  const [selectedProductCount, setSelectedProductCount] = useState();
  const [hasSelectedCatalog, setHasSelectedCatalog] = useState();
  const [conditionCount, setConditionCount] = useState();
  const [loadingAttributes, setLoadingAttributes] = useState(false);
  const [hasConditionOldAttribute, setHasConditionOldAttribute] = useState(false);
  const [outdatedAttributes, setOutdatedAttributes] = useState({});

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const attributesToKeep = {};
        if (!isEmpty(attributes) && !isEmpty(conditionSet) && !isEmpty(conditionSet.conditions)) {
          conditionSet.conditions.forEach((condition) => {
            if (!isEmpty(condition.attribute)) {
              attributesToKeep[condition.attribute] = attributes[condition.attribute];
            }
          });
        }
        const newAttributes = await getAttributes(selectedProducts, selectedCatalog, accessToken);
        const convertedOptions = convertOptionsToAttributes(newAttributes.options);
        const badAttributes = {};

        if (!isEmpty(convertedOptions)) {
          !isEmpty(attributesToKeep) &&
            Object.keys(attributesToKeep).forEach((key) => {
              const hasKey = key in convertedOptions;
              if (hasKey) {
                const valueSet = new Set();
                convertedOptions[key].values.forEach((value) => valueSet.add(JSON.stringify(value)));
                attributesToKeep[key].values.forEach((value) => valueSet.add(JSON.stringify(value)));

                const deDupedValues = Array.from(valueSet).map((value) => JSON.parse(value));
                convertedOptions[key].values = deDupedValues;
              } else {
                convertedOptions[key] = attributesToKeep[key];
                badAttributes[key] = attributesToKeep[key];
              }
            });

          if (Object.keys(convertedOptions).length > newAttributes.options.length) {
            setHasConditionOldAttribute(true);
            setOutdatedAttributes(badAttributes);
          } else {
            setHasConditionOldAttribute(false);
            setOutdatedAttributes({});
          }

          setAttributes(convertedOptions);
          setLoadingAttributes(false);
          setSelectedProductCount(selectedProducts.length);
          setHasSelectedCatalog(!isEmpty(selectedCatalog));
          !isEmpty(conditionSet) && setConditionCount(conditionSet.conditions.length);
        }
      } catch (error) {
        console.error(error);
        setLoadingAttributes(false);
      }
    };

    if (
      selectedProductCount !== selectedProducts.length ||
      (!isEmpty(selectedCatalog) && !hasSelectedCatalog) ||
      (isEmpty(selectedCatalog) && hasSelectedCatalog) ||
      (!isEmpty(conditionSet) && conditionCount !== conditionSet.conditions.length)
    ) {
      setLoadingAttributes(true);
      fetchAttributes();
    }
    // Disabling this dependency for now since we set attribute in this useEffect hook, which also needs it as a dep
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessToken,
    selectedProducts,
    selectedCatalog,
    conditionSet,
    hasSelectedCatalog,
    selectedProductCount,
    conditionCount,
    setAttributes,
  ]);

  const generateNamePrefix = useCallback(
    (products, catalog) => {
      let namePrefix = '';
      if (!isUndefined(catalog)) {
        namePrefix = formatMessage(messages.allProductsInCatalog, { catalogName: catalog.name });
      } else {
        namePrefix = generateListOfItems(
          products,
          3,
          (stringToReturn, product) => {
            return stringToReturn.concat(`${product.productId}`);
          },
          formatMessage
        );
      }

      return namePrefix;
    },
    [formatMessage]
  );

  const generateNameSuffix = useCallback(
    (linkConditions) => {
      let nameSuffix = '';
      if (linkConditions.length === 0) {
        return nameSuffix;
      }

      nameSuffix = nameSuffix.concat(nameSuffix, formatMessage(messages.with));

      // If there's only one condition, the name will include some of the condition values
      if (linkConditions.length === 1) {
        const condition = linkConditions[0];
        if (condition.values) {
          nameSuffix = nameSuffix.concat(`${condition.attribute}: (`);

          nameSuffix = nameSuffix.concat(
            generateListOfItems(
              condition.values,
              3,
              (stringToReturn, conditionValue) => {
                return stringToReturn.concat(calculateValueString(conditionValue));
              },
              formatMessage
            )
          );
          nameSuffix = nameSuffix.concat(')');
        }
      }

      // If there's more than one condition, it'll only show the names of the conditions, not the values
      else {
        nameSuffix = nameSuffix.concat(formatMessage(messages.conditionsOnText));
        nameSuffix = nameSuffix.concat(
          generateListOfItems(
            linkConditions,
            3,
            (stringToReturn, condition) => {
              return stringToReturn.concat(`${condition.attribute}`);
            },
            formatMessage
          )
        );
      }

      return nameSuffix;
    },
    [formatMessage]
  );

  const generateName = useCallback(
    (products, catalog, linkConditions) => {
      let namePrefix = generateNamePrefix(products, catalog);
      let nameSuffix = generateNameSuffix(linkConditions);

      return namePrefix.concat(' ', nameSuffix);
    },
    [generateNamePrefix, generateNameSuffix]
  );

  const calculateValueString = (value) => {
    let stringToReturn = '';
    switch (value.type) {
      case RANGE:
        stringToReturn = stringToReturn.concat(`${mapRangeToText(value.range)}`);
        break;
      case VALUE:
        stringToReturn = stringToReturn.concat(`${value.value}`);
        break;
      case FORMULA:
        break;
      default:
        break;
    }
    return stringToReturn;
  };

  useEffect(() => {
    if (!hasManuallyEditedName) {
      let generatedSelectionName = '';
      if (!isEmpty(originalSelectionName)) {
        generatedSelectionName =
          mode === LINK_MODE.COPY
            ? originalSelectionName + ' (copy)'
            : mode === LINK_MODE.EDIT && originalSelectionName;
      } else {
        generatedSelectionName = generateName(
          selectedProducts,
          selectedCatalog.catalog,
          isUndefined(conditionSet) ? [] : conditionSet.conditions
        );
      }
      setSelectionName(generatedSelectionName);
    }
  }, [
    selectedProducts,
    selectedCatalog,
    generateName,
    conditionSet,
    hasManuallyEditedName,
    originalSelectionName,
    setSelectionName,
    mode,
  ]);

  const onSelectionNameChange = (value) => {
    setHasManuallyEditedName(true);
    setSelectionName(value);
  };

  const SelectionHeader = (
    <div className={cx(positionSelectionName)}>
      <div>
        <InlineEdit
          name="selectionNameField"
          label="Name"
          required={true}
          placeholder={formatMessage(messages.placeholder)}
          value={selectionName}
          onSave={({ value }) => onSelectionNameChange(value)}
          minWidth={500}
        />
        <div className={isNameUnique ? hideErrorDiv : showErrorDiv}>{formatMessage(messages.selectionNameError)}</div>
      </div>

      <IconBin size="lg" color={teal.base} className={binIcon} onClick={deleteProductLink} />
    </div>
  );

  return (
    <Fragment>
      <Card className={limitWidth} header={SelectionHeader}>
        <SelectedProducts
          searchErrors={searchErrors}
          accessToken={accessToken}
          selectedProducts={selectedProducts}
          removeSelectedProducts={removeSelectedProducts}
          updateSelectedAdoptions={updateSelectedAdoptions}
          selectedCatalog={selectedCatalog}
          removeSelectedCatalog={removeSelectedCatalog}
          productTypes={productTypes}
          openDrawerWithSelectedProducts={openDrawerWithSelectedProducts}
          searchBarProps={searchBarProps}
        />
        <Conditions
          conditionSet={conditionSet}
          setConditionSet={setConditionSet}
          attributes={attributes}
          loadingAttributes={loadingAttributes}
          hasConditionOldAttribute={hasConditionOldAttribute}
          outdatedAttributes={outdatedAttributes}
        />
      </Card>
    </Fragment>
  );
};

export default ProductLinkCard;
