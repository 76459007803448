import fetchWithAuth from './fetchWithAuth';
import { normalizeMerchantResult, getMerchantSearchRequest } from './searchUtilities';
import isEmpty from 'lodash/isEmpty';

const baseUrl = 'https://pim-backbone.products.cimpress.io';
const PAGE_SIZE = 100;

export const searchMerchant = async (accessToken, accountId, keyword) => {
  const productResults = [];
  const firstFetch = await searchForProducts(accessToken, getMerchantSearchRequest(accountId, keyword, 1));

  // add first search to productResults list
  if (firstFetch && firstFetch.productsCount && firstFetch.result) {
    firstFetch.result.forEach((result) => productResults.push(normalizeMerchantResult(result)));
  }

  // if all products were contained in page size then return here
  if (firstFetch && firstFetch.productsCount && firstFetch.productsCount <= PAGE_SIZE) {
    return productResults;
  }

  // if more pages are needed, fetch them all and add them to productResults list. then return.
  const promises = [];
  for (let i = 2; i <= Math.ceil(firstFetch.productsCount / PAGE_SIZE); i++) {
    promises.push(searchForProducts(accessToken, getMerchantSearchRequest(accountId, keyword, i)));
  }

  const responses = await Promise.all(promises);
  responses.forEach((response) => {
    response.result.forEach((result) => productResults.push(normalizeMerchantResult(result)));
  });

  return productResults;
};

export const searchSingleProductMerchant = async (accessToken, productId) => {
  const body = {
    keyword: productId,
    pageNumber: 1,
    pageSize: 100,
    searchOver: 'Merchant',
  };

  const products = await searchForProducts(accessToken, body).catch((err) => {
    console.error(err);
    return {};
  });

  if (products) {
    if (products.result && products.productCount === 1 && products.result.length === 1) {
      const product = products.result[0];
      return normalizeSingleProductResponse(product);
    } else if (products.result && products.result.length > 0) {
      let productToReturn = {};
      products.result.forEach((product) => {
        if (isEmpty(productToReturn) && product.product.productId === productId) {
          productToReturn = normalizeSingleProductResponse(product);
        }
      });
      return productToReturn;
    }
  }

  return {};
};

const normalizeSingleProductResponse = (product) => {
  const normalized = {
    productId: product.product.productId,
    name: product.name,
    description: product.product.description,
    version: product.product.version,
    hasCurrent: product.product.isCurrent,
    link: `https://pim.products.cimpress.io/merchant/products/${product.product.productId}`,
  };
  if (product.product.fulfillmentProduct) {
    normalized.fulfillmentProduct = product.product.fulfilmentProduct.productID;
    normalized.fulfillmentProductVersion = product.product.fulfilmentProduct.version;
  }
  return normalized;
};

const searchForProducts = async (accessToken, body) => {
  return fetchWithAuth({
    baseUrl,
    route: `v0/search`,
    method: `POST`,
    body,
    accessToken,
  });
};
