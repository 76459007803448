import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import Drawer from '@cimpress/react-components/lib/Drawer';
import Button from '@cimpress/react-components/lib/Button';

import { anchorButton } from '../../constants/css';
import SelectedProducts from './SelectedProducts';
import AdvancedSearch from './AdvancedSearch';
import SelectedProductMessage from './SelectedProductMessage';
import messages from '../messages';
import sharedMessages from '../../messages';

const RIGHT = 'right';
const SIZE = 0.6;

const getObjectFromArrray = (array) => {
  return array.reduce(function (acc, curr) {
    acc[curr.productId] = curr;
    return acc;
  }, {});
};

const AdvancedSearchDrawer = ({
  drawerIsOpen,
  closeDrawer,
  accessToken,
  account,
  productTypes,
  standardsHaveAdoptions,
  showSelectedProduct,
  searchResults = [],
  selectedProducts = [],
  setSelectedProducts,
  updateSearchResults,
  keywordPresetSearch,
  viewSelectedProducts,
  viewAdvancedSearch,
  selectedCatalog = {},
  setSelectedCatalog,
  allowCatalogSelection,
}) => {
  const { formatMessage } = useIntl();
  const advancedSearchHeader = formatMessage(messages.advancedSearchHeader);
  const selectedProductsHeader = formatMessage(messages.selectedProductsHeader);
  const [selectAll, setSelectAll] = useState(false);
  const [allProductObjects, setAllProductObjects] = useState({});
  const [selectedProductTableList, setSelectedProductTableList] = useState([]); // all products to show in the products table
  const [tempSelectedProducts, setTempSelectedProducts] = useState(JSON.parse(JSON.stringify(selectedProducts))); // copy of selected products from link card
  const [selectedSearchResults, setSelectedSearchResults] = useState([]); // products selected from search results table
  const [aggregatedSelectedProducts, setAggregatedSelectedProducts] = useState([]); // all products selected on the products table

  const [onlyCatalogSearched, setOnlyCatalogSearched] = useState(false);
  const [currentSearchCatalog, setCurrentSearchCatalog] = useState({});
  const [tempSelectedCatalog, setTempSelectedCatalog] = useState(selectedCatalog);

  // check mark states for both tables
  const [selectedProductTableChecks, setSelectedProductTableChecks] = useState({});
  const [searchResultChecks, setSearchResultChecks] = useState({});

  const [catalogs, setCatalogs] = useState();
  const [keywordInput, setKeywordInput] = useState(keywordPresetSearch);
  const [catalogForSearch, setCatalogForSearch] = useState(undefined);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const selectedProductCount = tempSelectedProducts.length + selectedSearchResults.length;

  const clickViewSelectedProduct = () => {
    updateAggregateOfSelectedProducts();
    viewSelectedProducts();
  };

  const clickViewAdvancedSearch = () => {
    updateAggregateOfSelectedProducts();
    viewAdvancedSearch();
  };

  const updateAggregateOfSelectedProducts = () => {
    const productObject = {};
    selectedProducts.forEach((product) => (productObject[product.productId] = product));
    selectedSearchResults.forEach((product) => (productObject[product.productId] = product));

    if (!isEmpty(tempSelectedCatalog)) {
      Object.values(tempSelectedCatalog.products).forEach((product) => (productObject[product.productId] = product));
    }

    setSelectedProductTableList(Object.values(productObject));
  };

  useEffect(() => {
    // only runs once at initialization
    const states = {};
    selectedProducts.forEach((x) => (states[x.productId] = true));

    setSelectAll(false);
    setSelectedProductTableChecks(states);
    setTempSelectedProducts(JSON.parse(JSON.stringify(selectedProducts)));

    const copySelectedProducts = JSON.parse(JSON.stringify(selectedProducts));
    if (!isEmpty(selectedCatalog)) {
      setSelectedProductTableList(copySelectedProducts.concat(Object.values(selectedCatalog.products)));
    } else {
      setSelectedProductTableList(copySelectedProducts);
    }
  }, [selectedProducts, selectedCatalog]);

  useEffect(() => {
    setAllProductObjects(getObjectFromArrray(selectedProductTableList));
  }, [selectedProductTableList]);

  useEffect(() => {
    setTempSelectedCatalog(selectedCatalog);
  }, [selectedCatalog]);

  useEffect(() => {
    // only runs when new search results are found
    const states = {};
    const allProducts = {};

    // add default state of false for all search results
    searchResults.forEach((result) => {
      states[result.productId] = false;
      allProducts[result.productId] = result;
    });

    // default select all selected products in the search table as well.
    selectedProducts.forEach((product) => {
      if (states.hasOwnProperty(product.productId)) {
        states[product.productId] = true;
      }
      allProducts[product.productId] = product;
    });

    setSearchResultChecks(states);
    setAllProductObjects(allProducts);
  }, [searchResults, selectedProducts]);

  const resetSearchFields = () => {
    setKeywordInput('');
    setCatalogForSearch(null);
    setCurrentSearchCatalog({});
    setOnlyCatalogSearched(false);
    setSelectedCategory(null);
    setSelectAll(false);
    setSelectedProductTableChecks({});
    setSelectedSearchResults([]);
  };

  const closeAndSaveProducts = () => {
    setSelectedProducts(tempSelectedProducts.concat(selectedSearchResults));
    setSelectedCatalog(tempSelectedCatalog);
    if (!isEmpty(tempSelectedCatalog)) {
      setSelectedProductTableList(
        tempSelectedProducts.concat(selectedSearchResults).concat(Object.values(tempSelectedCatalog.products))
      );
    } else {
      setSelectedProductTableList(tempSelectedProducts.concat(selectedSearchResults));
    }
    setTempSelectedProducts(tempSelectedProducts.concat(selectedSearchResults));

    resetSearchFields();
    closeDrawer();
  };

  const cancelClick = () => {
    setTempSelectedCatalog(selectedCatalog);
    setTempSelectedProducts(selectedProducts);
    if (!isEmpty(selectedCatalog)) {
      setSelectedProductTableList(selectedProducts.concat(Object.values(selectedCatalog.products)));
    } else {
      setSelectedProductTableList(selectedProducts);
    }

    resetSearchFields();
    closeDrawer();
  };

  useEffect(() => {
    const searchStates = {};
    const selectedProductStates = {};

    // add default state of false for all search results
    searchResults.forEach((result) => {
      searchStates[result.productId] = false;
    });

    // add default state of false for all search results
    selectedProducts.forEach((result) => {
      selectedProductStates[result.productId] = false;
    });

    tempSelectedProducts.forEach((result) => {
      searchStates[result.productId] = true;
      selectedProductStates[result.productId] = true;
    });

    selectedSearchResults.forEach((result) => {
      searchStates[result.productId] = true;
      selectedProductStates[result.productId] = true;
    });

    setSearchResultChecks(searchStates);
    setSelectedProductTableChecks(selectedProductStates);
  }, [tempSelectedProducts, selectedSearchResults, searchResults, selectedProducts]);

  const addSearchResultSelection = (productsToAdd) => {
    const oldTempProducts = JSON.parse(JSON.stringify(tempSelectedProducts));
    const oldsearchResultsSelections = JSON.parse(JSON.stringify(selectedSearchResults));
    const oldSearchResultIds = new Set();
    oldsearchResultsSelections.forEach((product) => oldSearchResultIds.add(product.productId));

    const selectedProductsObject = getObjectFromArrray(selectedProducts);
    const searchResultProductObject = getObjectFromArrray(selectedSearchResults);
    const tempSelectedProductsOject = getObjectFromArrray(tempSelectedProducts);
    const selectedAndSearchAggregateObject = getObjectFromArrray(aggregatedSelectedProducts);

    let newSearchResultProducts = [];
    newSearchResultProducts = newSearchResultProducts.concat(oldsearchResultsSelections);

    let newSelectedSearchAggregate = JSON.parse(JSON.stringify(selectedProductTableList));
    let newSelectedProducts = [];
    newSelectedProducts = newSelectedProducts.concat(oldTempProducts);
    productsToAdd.forEach((product) => {
      if (
        isEmpty(tempSelectedCatalog) ||
        (!isEmpty(tempSelectedCatalog) && !tempSelectedCatalog.products.hasOwnProperty(product.productId))
      ) {
        if (selectedProductsObject[product.productId]) {
          if (!tempSelectedProductsOject[product.productId]) {
            newSelectedProducts.push(product);
          }
        } else {
          if (!searchResultProductObject[product.productId]) {
            newSearchResultProducts.push(product);
          }
        }

        if (!selectedAndSearchAggregateObject[product.productId]) {
          newSelectedSearchAggregate.push(product);
        }
      }
    });

    setSelectedSearchResults(newSearchResultProducts);
    setTempSelectedProducts(newSelectedProducts);

    setAggregatedSelectedProducts(newSearchResultProducts.concat(newSelectedProducts));
  };

  const removeSearchResultSelection = (productsToRemove) => {
    const oldTempProducts = JSON.parse(JSON.stringify(tempSelectedProducts));
    const oldSelectedProducts = JSON.parse(JSON.stringify(selectedSearchResults));

    const toRemoveSet = new Set();
    productsToRemove.forEach((x) => toRemoveSet.add(x.productId));
    const newTempProducts = oldTempProducts.filter((x) => !toRemoveSet.has(x.productId));
    const newSelectedProducts = oldSelectedProducts.filter((x) => !toRemoveSet.has(x.productId));

    setSelectedSearchResults(newSelectedProducts);
    setTempSelectedProducts(newTempProducts);

    setAggregatedSelectedProducts(newTempProducts.concat(newSelectedProducts));
  };

  const selectCatalog = () => {
    const productsToRemoveFromSearchAndTempSelect = getObjectFromArrray(searchResults); // all search results are in this catalog.

    // remove products that are in search results from tempSelected and selectedSearchResults
    const newSelectedSearchResults = selectedSearchResults.filter(
      (x) => !productsToRemoveFromSearchAndTempSelect.hasOwnProperty(x.productId)
    );
    const newTempSelected = tempSelectedProducts.filter(
      (x) => !productsToRemoveFromSearchAndTempSelect.hasOwnProperty(x.productId)
    );

    setSelectedSearchResults(newSelectedSearchResults);
    setTempSelectedProducts(newTempSelected);
  };

  const deselectCatalog = () => {};

  return (
    <Drawer
      position={RIGHT}
      size={SIZE}
      show={drawerIsOpen}
      onRequestHide={closeDrawer}
      header={showSelectedProduct ? selectedProductsHeader : advancedSearchHeader}
      closeOnClickOutside={false}
      footer={
        <div>
          <button className={anchorButton} onClick={clickViewSelectedProduct}>
            <SelectedProductMessage
              tempSelectedCatalog={tempSelectedCatalog}
              selectedProductCount={selectedProductCount}
              defaultMessage={null}
            />
          </button>
          <div>
            <Button type="default" onClick={cancelClick}>
              {formatMessage(sharedMessages.cancel)}
            </Button>
            <Button
              type="primary"
              onClick={closeAndSaveProducts}
              disabled={
                selectedProducts.length === 0 &&
                selectedSearchResults.length === 0 &&
                isEmpty(selectedCatalog) &&
                isEmpty(tempSelectedCatalog)
              }>
              {selectedProductCount === 0 &&
              selectedProducts.length > 0 &&
              ((isEmpty(selectedCatalog) && isEmpty(tempSelectedCatalog)) ||
                (isEmpty(tempSelectedCatalog) && !isEmpty(selectedCatalog)))
                ? formatMessage(messages.removeAllSelectedProducts)
                : formatMessage(messages.useSelectedProducts)}
            </Button>
          </div>
        </div>
      }>
      {showSelectedProduct ? (
        <SelectedProducts
          tempSelectedProducts={tempSelectedProducts}
          viewAdvancedSearch={clickViewAdvancedSearch}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          selectedProductsTableProducts={selectedProductTableList}
          selectedSearchResults={selectedSearchResults}
          checkBoxStates={selectedProductTableChecks}
          selectedProductsAsObject={allProductObjects}
          addSelectedProducts={addSearchResultSelection}
          removeSelectedProducts={removeSearchResultSelection}
          selectedCatalog={tempSelectedCatalog}
          selectCatalog={selectCatalog}
          deselectCatalog={deselectCatalog}
          setSelectedCatalog={setTempSelectedCatalog}
          currentSearchCatalog={currentSearchCatalog}
          searchResults={searchResults}
        />
      ) : (
        <AdvancedSearch
          accessToken={accessToken}
          account={account}
          productTypes={productTypes}
          standardsHaveAdoptions={standardsHaveAdoptions}
          keywordPresetSearch={keywordPresetSearch}
          searchResults={searchResults}
          updateSearchResults={updateSearchResults}
          addSelectedProducts={addSearchResultSelection}
          removeSelectedProducts={removeSearchResultSelection}
          catalogs={catalogs}
          setCatalogs={setCatalogs}
          keywordInput={keywordInput}
          setKeywordInput={setKeywordInput}
          catalogForSearch={catalogForSearch}
          setCatalogForSearch={setCatalogForSearch}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          searchResultChecks={searchResultChecks}
          selectCatalog={selectCatalog}
          deselectCatalog={deselectCatalog}
          selectedCatalog={tempSelectedCatalog}
          setSelectedCatalog={setTempSelectedCatalog}
          onlyCatalogSearched={onlyCatalogSearched}
          setOnlyCatalogSearched={setOnlyCatalogSearched}
          currentSearchCatalog={currentSearchCatalog}
          setCurrentSearchCatalog={setCurrentSearchCatalog}
          allowCatalogSelection={allowCatalogSelection}
        />
      )}
    </Drawer>
  );
};

export default AdvancedSearchDrawer;
