import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { css, cx } from 'emotion';

import Checkbox from '@cimpress/react-components/lib/Checkbox';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import { alloy } from '@cimpress/react-components/lib/colors';

import messages from '../messages';
import isEmpty from 'lodash/isEmpty';
import { flexRow, infoIcon, infoText } from '../../constants/css';

const noMargin = css`
  margin: 0px !important;
`;

const disabled = css`
  color: ${alloy};
`;

const marginTopAndBottom = css`
  margin-bottom: 15px;
  margin-top: 15px;
`;

const marginLeft = css`
  margin-left: 10px;
`;

const CatalogSelection = ({
  searchResults = [],
  selectedCatalog,
  selectCatalog,
  deselectCatalog,
  setSelectedCatalog,
  currentSearchCatalog,
  showSelection = false,
}) => {
  const { formatMessage } = useIntl();
  const [showCatalogInfo, setShowCatalogInfo] = useState(false);
  const categoryCheckboxDisabled =
    !isEmpty(selectedCatalog) && selectedCatalog.catalog.id !== currentSearchCatalog.id && !showSelection;
  const isChecked = !isEmpty(selectedCatalog)
    ? showSelection
      ? true
      : selectedCatalog.catalog.id === currentSearchCatalog.id
    : false;

  const changeSelectedCatalog = () => {
    if (isEmpty(selectedCatalog)) {
      selectCatalog();
      setSelectedCatalog({
        catalog: {
          id: currentSearchCatalog.id,
          name: currentSearchCatalog.name,
        },
        products: searchResults.reduce(function (acc, curr) {
          acc[curr.productId] = curr;
          return acc;
        }, {}),
      });
    } else {
      deselectCatalog();
      setSelectedCatalog({});
    }
  };

  return (
    <div>
      <div className={cx(marginTopAndBottom, flexRow)}>
        <Checkbox
          className={noMargin}
          checked={isChecked}
          onChange={changeSelectedCatalog}
          disabled={categoryCheckboxDisabled}
        />
        {showSelection ? (
          <span>
            {formatMessage(messages.linkToEntireCatalog)}: <strong>{selectedCatalog.catalog.name}</strong>
          </span>
        ) : (
          <span className={categoryCheckboxDisabled ? disabled : null}>
            {formatMessage(messages.linkToEntireCatalog)}: <strong>{currentSearchCatalog.name}</strong>
          </span>
        )}
        <IconInformationCircle
          onClick={() => setShowCatalogInfo(!showCatalogInfo)}
          className={infoIcon}
          weight={showCatalogInfo ? 'fill' : 'regular'}
        />
      </div>
      {showCatalogInfo && (
        <p className={cx(marginTopAndBottom, infoText, marginLeft)}>
          <i>{formatMessage(messages.catalogHelpMessage)}</i>
        </p>
      )}

      <p className={marginTopAndBottom}>{formatMessage(messages.orSelectProduct)} </p>
    </div>
  );
};

export default CatalogSelection;
