import React from 'react';
import { useIntl } from 'react-intl';
import { css } from 'emotion';
import Checkbox from '@cimpress/react-components/lib/Checkbox';
import Table from '@cimpress/react-components/lib/Table';
import CatalogSelection from '../CatalogSelection';

import { anchorButtonNoMargin } from '../../../constants/css';
import messages from '../../messages';
import isEmpty from 'lodash/isEmpty';

const noMargin = css`
  margin: 0px !important;
`;

const truncate = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AdvancedSearchResults = ({
  searchResults = [],
  searchResultCheckBoxStates,
  toggleAllSearchResultsCheck,
  toggleSearchResultCheck,
  selectAllIsChecked = false,
  onlyCatalogSearched = false,
  selectedCatalog,
  selectCatalog,
  deselectCatalog,
  setSelectedCatalog,
  currentSearchCatalog,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      {onlyCatalogSearched && !isEmpty(currentSearchCatalog) && (
        <CatalogSelection
          searchResults={searchResults}
          selectedCatalog={selectedCatalog}
          selectCatalog={selectCatalog}
          deselectCatalog={deselectCatalog}
          setSelectedCatalog={setSelectedCatalog}
          currentSearchCatalog={currentSearchCatalog}
          showSelection={false}
        />
      )}
      {!isEmpty(searchResultCheckBoxStates) && !isEmpty(searchResults) && (
        <Table
          columns={[
            {
              Header: (
                <Checkbox className={noMargin} checked={selectAllIsChecked} onChange={toggleAllSearchResultsCheck} />
              ),
              accessor: 'checkbox',
              width: 70,
              sortable: false,
            },
            { Header: formatMessage(messages.productIdHeader), accessor: 'productId' },
            { Header: formatMessage(messages.nameHeader), accessor: 'name' },
            { Header: formatMessage(messages.descriptionHeader), accessor: 'description', maxWidth: 250 },
            { Header: formatMessage(messages.categoryHeader), accessor: 'category', maxWidth: 225 },
          ]}
          data={searchResults.map((x) => {
            return {
              checkbox: (
                <Checkbox
                  className={noMargin}
                  checked={
                    searchResultCheckBoxStates[x.productId] ||
                    (!isEmpty(selectedCatalog) && selectedCatalog.products.hasOwnProperty(x.productId))
                  }
                  onChange={toggleSearchResultCheck}
                  payload={x}
                  disabled={!isEmpty(selectedCatalog) && selectedCatalog.products.hasOwnProperty(x.productId)}
                />
              ),
              name: <div className={truncate}>{x.name}</div>,
              productId: (
                <a className={anchorButtonNoMargin} href={x.link} target="_blank" rel="noreferrer">
                  {x.productId}
                </a>
              ),
              description: <div className={truncate}>{x.description}</div>,
              category: x.category ? <div className={truncate}>{x.category.name}</div> : '',
            };
          })}
          isCondensed={false}
          isBordered={true}
          hasHover={true}
          paginationPosition="bottomLeft"
        />
      )}
    </div>
  );
};

export default AdvancedSearchResults;
