import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { css, cx } from 'emotion';

import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import { infoIcon, infoText } from '../../../constants/css';
import messages from '../../messages';

import {
  twentyPxMarginBottom,
  tenPxMarginLeft,
  fivePxMarginBottom,
  tenPxMarginBottom,
} from '../../../../../shared/commonStyles';

const searchResultDetails = css`
  font-size: 12px;
  font-weight: 600;
`;

const SearchSummaryText = ({
  searchResults,
  showSearchParams = false,
  searchKeywordText,
  searchCatalogText,
  searchCategoryText,
}) => {
  const { formatMessage } = useIntl();
  const [showHelperText, setShowHelperText] = useState(false);

  const toggleHelperText = () => {
    setShowHelperText(!showHelperText);
  };

  return (
    <div className={cx(tenPxMarginBottom)}>
      <p>
        {formatMessage(messages.advancedSearchResultsDetailsIntro, {
          numOfResults: searchResults && searchResults.length,
        })}
        <IconInformationCircle
          onClick={toggleHelperText}
          className={infoIcon}
          weight={showHelperText ? 'fill' : 'regular'}
        />
      </p>
      {showSearchParams && (
        <div className={cx(twentyPxMarginBottom, tenPxMarginLeft)}>
          {!!searchKeywordText && (
            <p className={cx(searchResultDetails, fivePxMarginBottom)}>
              {formatMessage(messages.advancedSearchResultsDetailsKeywords, { keywords: searchKeywordText })}
            </p>
          )}
          {!!searchCatalogText && (
            <p className={cx(searchResultDetails, fivePxMarginBottom)}>
              {formatMessage(messages.advancedSearchResultsDetailsCatalog, { catalog: searchCatalogText })}
            </p>
          )}
          {!!searchCategoryText && (
            <p className={cx(searchResultDetails, fivePxMarginBottom)}>
              {formatMessage(messages.advancedSearchResultsDetailsCategory, { category: searchCategoryText })}
            </p>
          )}
        </div>
      )}
      {showHelperText && (
        <p className={infoText}>
          <i>{formatMessage(messages.advancedSearchResultsHelperText)}</i>
        </p>
      )}
    </div>
  );
};

export default SearchSummaryText;
