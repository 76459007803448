import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { css, cx } from 'emotion';
import { constraintConditionShape } from './propTypes';
import messages from './messages';
import SingleConditionDisplay from './SingleConditionDisplay';

const gridDisplay = css`
  display: grid;
  grid-gap: 5px;
`;

const layouts = {
  resultOnly: css`
    grid-template-columns: auto;
  `,
  singleResult: css`
    grid-template-columns: min-content auto;
  `,
  ruleConstraint: css`
    grid-template-columns: 50% 50%;
  `,
  ruleConstraintContents: css`
    grid-template-columns: min-content auto;
  `,
  ruleConstraintContentsFlex: css`
    grid-template-columns: min-content auto;
    display: flex;
  `,
};

const margin = css`
  margin: 5px;
`;

const RuleDisplay = ({ conditions, resultAttribute, result, results, gridView = true, unitOfMeasures = {} }) => {
  const { formatMessage } = useIntl();
  return (
    <div
      className={
        gridView
          ? cx(gridDisplay, results ? layouts.ruleConstraint : conditions ? layouts.singleResult : layouts.resultOnly)
          : cx(layouts.ruleConstraintContentsFlex)
      }>
      {result ? (
        <div>
          <SingleConditionDisplay
            attribute={resultAttribute}
            operator={'in'}
            values={[result]}
            unitOfMeasures={unitOfMeasures}
          />
        </div>
      ) : null}
      {conditions ? (
        <div
          className={
            gridView ? cx(gridDisplay, layouts.ruleConstraintContents) : cx(layouts.ruleConstraintContentsFlex)
          }>
          <div className={margin}>{result ? formatMessage(messages.when) : formatMessage(messages.if)}</div>
          <div>
            {conditions.map((condition) => (
              <div key={JSON.stringify(condition)}>
                <SingleConditionDisplay
                  attribute={condition.attribute}
                  operator={condition.operator}
                  values={condition.values}
                  unitOfMeasures={unitOfMeasures}
                />
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {results ? (
        <div
          className={
            gridView ? cx(gridDisplay, layouts.ruleConstraintContents) : cx(layouts.ruleConstraintContentsFlex)
          }>
          <div className={margin}>{formatMessage(messages.then)}</div>
          <div>
            {results.map((result) => (
              <div key={JSON.stringify(result)}>
                <SingleConditionDisplay
                  attribute={result.attribute}
                  operator={result.operator}
                  values={result.values}
                  unitOfMeasures={unitOfMeasures}
                />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

RuleDisplay.propTypes = {
  conditions: PropTypes.arrayOf(PropTypes.shape(constraintConditionShape)),
  result: PropTypes.shape(constraintConditionShape),
  results: PropTypes.arrayOf(PropTypes.shape(constraintConditionShape)),
  resultAttribute: PropTypes.string,
  gridView: PropTypes.bool,
};

export default RuleDisplay;
