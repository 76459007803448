export const DIFFERENT = 'different';
export const IDENTICAL = 'identical';

export const IDENTICAL_CONSTRAINTS = 'identicalConstraints';
export const ADDED_CONSTRAINTS = 'addedConstraints';
export const REMOVED_CONSTRAINTS = 'removedConstraints';
export const UPDATED_CONSTRAINTS = 'updatedConstraints';

export const ADDED_OPTIONS = 'addedOptions';
export const REMOVED_OPTIONS = 'removedOptions';
export const DELETED_VALUES_BY_OPTION = 'deletedValuesByOption';
export const ADDED_VALUES_BY_OPTION = 'addedValuesByOption';
export const UNCHANGED_VALUES_BY_OPTION = 'unchangedValuesByOption';
export const UNIT_OF_MEASURE_BY_OPTION = 'unitOfMeasureByOption';

export const REMOVED_VALUES = 'removedValues';
export const ADDED_VALUES = 'addedValues';
export const VALUES_CONDITION_MAPPINGS = 'valuesConditionMappings';
export const REMOVED_CONDITIONS = 'removedConditions';
export const ADDED_CONDITIONS = 'addedConditions';
