import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { css } from 'emotion';

import ConditionSetDisplay from '../../../ConditionSetSelector/ConditionSets/ConditionSetEditor/ConditionSetDisplay';
import { getConditionSetFromConstraints } from '../../../ConditionSetSelector/ConditionSets/conditionSetHelpers';
import messages from '../../NewLinkTab/messages';

const positionConditions = css`
  margin: 10px 0;
`;

const marginTop = css`
  margin-top: 10px;
`;

const Conditions = ({ constraints }) => {
  const { formatMessage } = useIntl();

  const [conditionSet, setConditionSet] = useState([]);

  useEffect(() => {
    const conditionSet = getConditionSetFromConstraints(constraints);
    setConditionSet(conditionSet);
  }, [constraints]);

  return (
    <div className={positionConditions}>
      {formatMessage(messages.ProductOptionConditions)}
      <div className={marginTop}>
        <ConditionSetDisplay conditions={conditionSet.conditions} />
      </div>
    </div>
  );
};

export default Conditions;
