import isEmpty from 'lodash/isEmpty';
import { productTypeConsts } from '../components/ProductLinker/constants/propInputConstants';
import { productTypes } from '../components/ProductLinker/constants/searchConstants';

export const normalizeV2FulfillmentResult = (result) => {
  if (result.matching_versions && !isEmpty(result.matching_versions)) {
    let link = '';
    if (result.productType === productTypeConsts.STANDARDS) {
      link = `https://productmanager-v2.products.cimpress.io/standards-overview/${result.standardId}`;
    } else {
      link = `https://productmanager-v2.products.cimpress.io/product-details-v2/${result.productId}`;
    }

    return {
      productType:
        result.productType === productTypes.STANDARD
          ? productTypes.STANDARD
          : result.productType === productTypes.ADOPTION
          ? productTypes.ADOPTION
          : productTypeConsts.V2_FULFILLMENT,
      productId: result.productId,
      catalogs: result.catalogs.reduce(function (acc, curr) {
        acc[curr.id] = curr;
        return acc;
      }, {}),
      name: result.matching_versions[0].productName,
      description: result.matching_versions[0].description,
      category: result.matching_versions[0].category,
      version: result.matching_versions[0].version,
      hasCurrent: result.matching_versions[0].isCurrent,
      standardId: result.productType === productTypes.STANDARD ? result.standardId : null,
      link,
    };
  }

  return null;
};

export const getV2SearchRequestBody = (accountId, keyword, categoryId = null, catalogId = 'null', pageNumber = 1) => {
  const ACTIVE = 'active';
  const PAGE_SIZE = 100;
  const AND = 'and';
  const STATUS = 'status';
  const TERM = 'term';
  const CATALOG_ID = 'catalogId';
  const ACCOUNT_ID = 'accountId';
  const COMPOUND = 'compound';
  const QUERY_SEARCH = 'querySearch';
  const FIELD_NAMES = {
    CATEGORY: 'category.id',
    PRODUCT_NAME: 'productName',
    DESCRIPTION: 'description',
  };
  const sortOrder = [
    {
      direction: 'desc',
      fieldName: 'isCurrent',
    },
    {
      direction: 'asc',
      fieldName: 'status',
    },
    {
      direction: 'desc',
      fieldName: 'modifiedAt',
    },
  ];

  const body = {
    operator: AND,
    page: pageNumber,
    pageSize: PAGE_SIZE,
    scroll: false,
    shouldAggregate: false,
    productQuery: {
      query: {
        operator: AND,
        type: COMPOUND,
        compoundQuery: [
          {
            type: TERM,
            fieldName: STATUS,
            value: ACTIVE,
          },
          {
            type: TERM,
            fieldName: ACCOUNT_ID,
            value: accountId,
          },
        ],
      },
    },
  };

  if (catalogId) {
    body.productQuery.query.compoundQuery.push({
      type: TERM,
      fieldName: CATALOG_ID,
      value: catalogId,
    });
  }

  if (categoryId || keyword) {
    body.versionQuery = {
      sortOrder,
      query: {
        operator: AND,
        type: COMPOUND,
        compoundQuery: [],
      },
    };

    if (categoryId) {
      body.versionQuery.query.compoundQuery.push({
        type: QUERY_SEARCH,
        value: categoryId,
        fieldNames: [FIELD_NAMES.CATEGORY],
      });
    }

    if (keyword) {
      body.versionQuery.query.compoundQuery.push({
        type: QUERY_SEARCH,
        value: keyword,
        fieldNames: [FIELD_NAMES.PRODUCT_NAME, FIELD_NAMES.DESCRIPTION],
      });
    }
  }

  return body;
};

export const normalizeMerchantResult = (result) => {
  return {
    productType: productTypeConsts.MERCHANT,
    productId: result.product.productId,
    name: result.name,
    description: result.product.description,
    version: result.product.version,
    hasCurrent: result.product.isCurrent,
    catalogs: {},
    category: null,
    link: `https://pim.products.cimpress.io/merchant/products/${result.product.productId}`,
  };
};

export const getMerchantSearchRequest = (accountId, keyword, pageNumber = 1) => {
  const MERCHANT = 'Merchant';
  const ACTIVE = 'ACTIVE';
  const PAGE_SIZE = 100;
  const OR = 'OR';
  const STATUS = 'status';

  const body = {
    keyword,
    pageNumber: pageNumber,
    pageSize: PAGE_SIZE,
    searchOver: MERCHANT,
    facets: [
      {
        name: STATUS,
        items: [ACTIVE],
        operator: OR,
      },
    ],
    accountId,
  };

  return body;
};
