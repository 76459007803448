import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import without from 'lodash/without';

import Button from '@cimpress/react-components/lib/Button';
import { silver, platinum } from '@cimpress/react-components/lib/colors';

import { validateRuleName } from '../shared/validators/keyValidators';

import { intlShape, constraintRuleShape } from '../shared/propTypes';

import {
  addCondition,
  updateCondition,
  removeCondition,
  addResult,
  updateResult,
  removeResult,
} from './constraintActions';

import messages from './messages';

import ConditionEditor from '../shared/conditionEditor';
import PredicateEditor from '../shared/predicateEditor';
import { tenPxMarginTop } from '../shared/commonStyles';
import { css, cx } from 'emotion';

const ruleResultBackgroundColor = css`
   {
    background-color: ${silver};
  }
`;

const darkerHr = css`
   {
    border-top-color: ${platinum};
  }
`;

class ConstraintEditor extends Component {
  static propTypes = {
    rule: PropTypes.shape(constraintRuleShape),
    constraintRules: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    options: PropTypes.shape({}).isRequired,
    intl: PropTypes.shape(intlShape).isRequired,
  };

  static defaultProps = { rule: undefined };

  onAddCondition = () => this.props.onChange(this.props.rule.id, addCondition(this.props.rule));
  onUpdateCondition = (originalCondition, condition) =>
    this.props.onChange(this.props.rule.id, updateCondition(this.props.rule, originalCondition, condition));
  onRemoveCondition = (originalCondition, condition) =>
    this.props.onChange(this.props.rule.id, removeCondition(this.props.rule, originalCondition, condition));

  onAddResult = () => this.props.onChange(this.props.rule.id, addResult(this.props.rule));
  onUpdateResult = (originalResult, result) =>
    this.props.onChange(this.props.rule.id, updateResult(this.props.rule, originalResult, result));
  onRemoveResult = (originalResult, result) => {
    this.props.onChange(this.props.rule.id, removeResult(this.props.rule, originalResult, result));
  };

  validateName = (newName) => {
    const {
      intl: { formatMessage },
    } = this.props;

    if (newName === this.props.rule.name) {
      return '';
    }
    if (!newName) {
      return formatMessage(messages.mustProvideRuleName);
    }
    return validateRuleName(newName, this.props.constraintRules, 'Rule');
  };

  render() {
    const {
      rule,
      options,
      intl: { formatMessage },
    } = this.props;

    if (!rule) {
      return null;
    }

    const usedOptions = [...rule.conditions, ...rule.results].map((condition) => condition.attribute).filter((c) => c);
    const remainingOptions = without(Object.keys(options), ...usedOptions);

    return (
      <Fragment>
        <div className="card-block">
          <p>
            <b>{formatMessage(messages.ruleConditionMessage)}</b>
          </p>
          <PredicateEditor
            conditions={rule.conditions}
            results={rule.results}
            attributes={options}
            addCondition={this.onAddCondition}
            updateCondition={this.onUpdateCondition}
            removeCondition={this.onRemoveCondition}
          />

          <br />
        </div>
        <div className={cx('card-block', ruleResultBackgroundColor)}>
          <p>
            <b>{formatMessage(messages.ruleResultMessage)}</b>
          </p>
          {rule.results.map((result, i) => (
            <Fragment key={result.attribute || i}>
              <ConditionEditor
                style={tenPxMarginTop}
                condition={result}
                conditions={rule.conditions}
                results={rule.results}
                attributes={options}
                updateCondition={this.onUpdateResult}
                removeCondition={this.onRemoveResult}
              />
              {i < rule.results.length - 1 && <hr className={darkerHr} />}
            </Fragment>
          ))}
          {!rule.results.length && (
            <div>
              <div className="text-muted h5">{formatMessage(messages.noResultsForRule)}</div>
            </div>
          )}
          <Button
            className={tenPxMarginTop}
            type="default"
            onClick={this.onAddResult}
            disabled={!remainingOptions.length}>
            {formatMessage(messages.addResult)}
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(ConstraintEditor);
