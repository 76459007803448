import React, { useState, useEffect, Fragment } from 'react';
import { css } from 'emotion';
import isEmpty from 'lodash/isEmpty';

import Spinner from '@cimpress/react-components/lib/shapes/Spinner';

import ResultsList from './ResultsList';
import PreviewResult from './PreviewResult';
import ActionButtons from './ActionButtons';

const resultsPosition = css`
  margin-top: 20px;
  max-height: 700px;
  display: flex;
  flex-wrap: wrap;
`;

const linkViewCardPosition = css`
  min-width: 350px;
  flex: 50%;
  margin-left: 10px;
`;

const centerSpinner = css`
  text-align: center;
  margin-top: 30px;
`;

const LinkSearchResults = ({
  searchResults,
  onEditProductLink,
  onSelectProductLink,
  accessToken,
  selectedProductLink,
  loading,
  showSearchResults,
  account,
  productTypes,
  standardsHaveAdoptions,
  selectionsChanged,
}) => {
  const [previewProductLink, setPreviewProductLink] = useState(selectedProductLink);
  const [showLinkPreview, setShowLinkPreview] = useState(false);
  const [selections, setSelections] = useState({});

  const onResultClick = (link) => {
    setPreviewProductLink(link);
    setShowLinkPreview(true);
  };

  useEffect(() => {
    if (!isEmpty(searchResults)) {
      setPreviewProductLink({});
      setShowLinkPreview(false);
    }
  }, [searchResults, selectedProductLink]);

  useEffect(() => {
    if (!isEmpty(selectedProductLink)) {
      //opens already selected product link
      onResultClick(selectedProductLink);
    }
  }, [selectedProductLink]);

  useEffect(() => {
    setSelections({});
  }, [previewProductLink]);

  const onSelectLink = () => {
    selectionsChanged(selections);
    onSelectProductLink(previewProductLink);
  };

  const onEditLink = () => {
    onEditProductLink(previewProductLink);
  };

  return (
    <Fragment>
      {loading ? (
        <div className={centerSpinner}>
          <Spinner />
          <p>fetching links...</p>
        </div>
      ) : (
        showSearchResults && (
          <div className={resultsPosition}>
            <ResultsList
              searchResults={searchResults}
              onResultClick={onResultClick}
              previewProductLink={previewProductLink}
              selectedProductLink={selectedProductLink}
            />
            {showLinkPreview && (
              <div className={linkViewCardPosition}>
                <PreviewResult
                  previewProductLink={previewProductLink}
                  accessToken={accessToken}
                  account={account}
                  productTypes={productTypes}
                  standardsHaveAdoptions={standardsHaveAdoptions}
                  setSelections={setSelections}
                />
                <ActionButtons
                  onSelectLink={onSelectLink}
                  onEditLink={onEditLink}
                  useButtonDisabled={isEmpty(selections)}
                />
              </div>
            )}
          </div>
        )
      )}
    </Fragment>
  );
};

export default LinkSearchResults;
