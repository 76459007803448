import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { css, cx } from 'emotion';
import isEmpty from 'lodash/isEmpty';

import TextField from '@cimpress/react-components/lib/TextField';
import Button from '@cimpress/react-components/lib/Button';
import { teal, info } from '@cimpress/react-components/lib/colors';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import IconSearch from '@cimpress-technology/react-streamline-icons/lib/IconSearch';

import { searchBarTypes } from '../../constants/searchConstants';
import { anchorButton } from '../../constants/css';
import { SearchBarSearch } from '../../utilities/productSearch';
import Dropdown from './Dropdown';
import OutsideAlerter from './Dropdown/OutsideClicker';
import messages from '../messages';
import SearchErrors from './SearchErrors';

const advancedSearchButton = css`
  float: right;
`;

const centerSpinner = css`
  text-align: center;
`;

const searchBoxStyle = css`
  width: 100%;
  display: flex;

  form {
    width: 100%;
  }
`;

const searchDiv = css`
  max-width: 700px;
`;

const noResultsStyle = css`
  font-size: 12px;
  font-style: italic;
  margin-left: 10px;
  margin-top: -10px;
`;

const positionSearchErrors = css`
  padding-left: 5px;
`;

const ProductSearchBar = ({
  account,
  productTypes,
  standardsHaveAdoptions,
  accessToken,
  searchResults,
  searchErrors,
  selectedProducts,
  searchType,
  updateSearchResults,
  openAdvancedSearchDrawer,
  openDrawerFromDropdown,
  advancedSearchIsOpen,
  addSelectedProducts,
  removeSelectedProducts,
  dropdownClosed,
  dropdownOpen,
  closeDropdownAndClearSearch,
}) => {
  const { formatMessage } = useIntl();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  // const [searchResultMessage, setSearchResultMessage] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [noResults, setNoResults] = useState(false);

  const searchResultMessage = ''; // Remove this when we hook up the search result message

  useEffect(() => {
    if (searchType === searchBarTypes.KEYWORD_SEARCH && searchResults.length > 0 && !advancedSearchIsOpen) {
      setShowDropdown(true);
      dropdownOpen();
    }
  }, [searchResults, searchType, dropdownOpen, advancedSearchIsOpen]);

  const onChange = (e) => {
    setSearch(e.target.value);
    setNoResults(false);
    setShowDropdown(false);
  };

  const hardCloseDropdown = () => {
    setShowDropdown(false);
    closeDropdownAndClearSearch();
  };

  const closeDropdown = () => {
    setShowDropdown(false);
    dropdownClosed();
  };

  const onSearch = async (e) => {
    if (loading) {
      return;
    }

    e.preventDefault();
    setLoading(true);

    const results = await SearchBarSearch(accessToken, account.id, search, productTypes, standardsHaveAdoptions);
    if (isEmpty(results) || isEmpty(results.products)) {
      setNoResults(true);
    }

    updateSearchResults(results, search);
    setLoading(false);
  };

  return (
    <div className={searchDiv}>
      <button className={cx(anchorButton, advancedSearchButton)} onClick={openAdvancedSearchDrawer}>
        {formatMessage(messages.advancedSearchButtonLink)}
      </button>
      <div className={searchBoxStyle}>
        <form onSubmit={onSearch}>
          <TextField
            label="Search"
            value={search}
            onChange={onChange}
            rightAddon={
              <Button
                style={{ paddingTop: '12px', paddingBottom: '12px', border: `solid ${info.base} 1px` }}
                type="link"
                onClick={onSearch}>
                <IconSearch size="lg" color={teal.base} />
              </Button>
            }
          />
        </form>
      </div>
      {loading ? (
        <div className={centerSpinner}>
          <Spinner />
          <p>{formatMessage(messages.fetchingSearch)}</p>
        </div>
      ) : !isEmpty(searchErrors) ? (
        <div className={positionSearchErrors}>
          {!isEmpty(searchErrors) &&
            searchErrors.map((searchResult) => {
              return searchResult.errorReason ? (
                <SearchErrors key={searchResult.productId} searchResponse={searchResult} />
              ) : null;
            })}
          <p>{searchResultMessage}</p>
        </div>
      ) : noResults ? (
        <p className={noResultsStyle}>{formatMessage(messages.noSearchResults)}</p>
      ) : null}

      {!showDropdown ? null : (
        <OutsideAlerter onClick={hardCloseDropdown}>
          <Dropdown
            searchResults={searchResults}
            selectedProducts={selectedProducts}
            addSelectedProducts={addSelectedProducts}
            removeSelectedProducts={removeSelectedProducts}
            openDrawerFromDropdown={openDrawerFromDropdown}
            closeDropdown={closeDropdown}
          />
        </OutsideAlerter>
      )}
    </div>
  );
};

export default ProductSearchBar;
