import fetchWithAuth from './fetchWithAuth';
import { productTypes } from '../components/ProductLinker/constants/searchConstants';

const possibilitiesProductTypes = {
  v1: 'productV1',
  v2: 'productV2',
  merchant: 'merchant',
};
const baseUrl = 'https://possibility.products.cimpress.io';

export const searchOverStandards = async (accessToken, searchString) => {
  let route = `v1/standards/search?fuzzySearch=false${!!searchString ? `&search=${searchString}` : ''}`;

  try {
    const standards = await fetchWithAuth({ baseUrl, route, accessToken });
    if (standards && standards.count > 0) {
      const standardsList = standards.items.map((x) => {
        return {
          productType: productTypes.STANDARD,
          productId: x.productId,
          catalogs: {},
          name: x.name,
          description: x.description,
          category: {
            id: x.category.categoryId,
            name: x.category.name,
          },
          version: x.productVersion,
          standardId: x.standardId,
          link: `https://productmanager-v2.products.cimpress.io/standards-overview/${x.standardId}`,
        };
      });
      return standardsList;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getAttributes = (productsList, selectedCatalog, accessToken) => {
  let combinedProductsList = JSON.parse(JSON.stringify(productsList));
  if (selectedCatalog && selectedCatalog.products) {
    combinedProductsList = combinedProductsList.concat(Object.values(selectedCatalog.products));
  }

  const body = { products: [] };
  combinedProductsList.forEach((product) => {
    const productToAdd = { productId: product.productId };
    if (
      product.productType === productTypes.V2_FULFILLMENT ||
      product.productType === productTypes.STANDARD ||
      product.productType === productTypes.ADOPTION
    ) {
      productToAdd.productType = possibilitiesProductTypes.v2;
      productToAdd.version = product.version;
    } else if (product.productType === productTypes.V1_FULFILLMENT) {
      productToAdd.productType = possibilitiesProductTypes.v1;
    } else if (product.productType === productTypes.MERCHANT) {
      productToAdd.productType = possibilitiesProductTypes.merchant;
      productToAdd.version = product.version;
    }

    if (body.products.length < 100) {
      body.products.push(productToAdd);
    }
  });

  return fetchWithAuth({
    baseUrl,
    route: `v1/product/options:combine`,
    method: `POST`,
    body,
    accessToken,
  });
};
