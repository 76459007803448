import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StylePropType from 'react-style-proptype';
import debounce from 'lodash.debounce';
import { cx } from 'emotion';

import { Unchecked, Checked, Indeterminate } from './shapes/checkbox';
import { alloy, teal, white } from '@cimpress/react-components/lib/colors';

function generateUniqueId(label) {
  const uniqueId = `${label}-${Math.floor(Math.random() * 0xffff)}`;
  return uniqueId.replace(/[^A-Za-z0-9-]/gi, '');
}

const Checkbox = (props) => {
  const debouncedChange = debounce(
    (e) => {
      props.onChange && props.onChange(e, props.payload);
    },
    200,
    {
      leading: true,
      trailing: false,
    }
  );

  const handleChange = (e) => {
    e.persist();
    debouncedChange(e);
  };

  const { checked, indeterminate, label, disabled, className, style, whiteBackground, id, inline } = props;
  const [hover, setHover] = useState(false);

  // build a unique ID for this field in case one was not provided
  // logic from https://github.com/callemall/material-ui/blob/master/src/Checkbox/Checkbox.js
  const [inputId, setInputId] = useState(id);
  useEffect(() => {
    if (!inputId) {
      setInputId(generateUniqueId(label));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const backgroundColor = whiteBackground ? white.base : undefined;

  let image;
  if (checked) {
    image = disabled ? (
      <Checked color={alloy.base} backgroundColor={backgroundColor} />
    ) : (
      <Checked backgroundColor={backgroundColor} />
    );
  } else if (indeterminate) {
    image = disabled ? (
      <Indeterminate color={alloy.base} backgroundColor={backgroundColor} />
    ) : (
      <Indeterminate backgroundColor={backgroundColor} />
    );
  } else {
    image = disabled ? (
      <Unchecked color={alloy.base} backgroundColor={backgroundColor} />
    ) : hover ? (
      <Unchecked color={teal.base} backgroundColor={backgroundColor} />
    ) : (
      <Unchecked backgroundColor={backgroundColor} />
    );
  }

  const disabledStyle = disabled ? { cursor: 'not-allowed', pointerEvents: 'none' } : {};

  return (
    <div
      className={cx('checkbox', className)}
      style={{ ...style, ...disabledStyle, display: inline ? 'inline' : 'block' }}>
      <label
        className={cx('control-label', { 'text-muted': disabled })}
        htmlFor={inputId}
        onClick={disabled ? undefined : handleChange}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        style={{ paddingLeft: 0, ...disabledStyle }}>
        <span style={{ position: 'relative', top: '3px', paddingRight: '10px' }}>{image}</span>
        <input
          type="checkbox"
          disabled={disabled}
          checked={checked}
          onBlur={(e) => props.onBlur && props.onBlur(e)}
          onFocus={(e) => props.onFocus && props.onFocus(e)}
          onChange={handleChange}
          style={{ visibility: 'hidden' }}
        />
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  label: PropTypes.node,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  whiteBackground: PropTypes.bool,
  onChange: PropTypes.func,
  payload: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  style: StylePropType,
  inline: PropTypes.bool,
};

Checkbox.defaultProps = {
  disabled: false,
  className: '',
  whiteBackground: false,
};

export default Checkbox;
