import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { cx } from 'emotion';

import { tenPxMarginBottom } from '../commonStyles';

import messages from './messages';

export const ValidationResultMessage = ({ result }) => {
  return (
    <li>
      <FormattedMessage
        {...messages[result.id]}
        values={{
          ...result.values,
          intro: (...msg) => <span>{msg}</span>,
          list: (...msg) => <ul>{msg}</ul>,
          listitem: (...msg) => <li key={msg}>{msg}</li>,
        }}
      />
    </li>
  );
};

export const ValidationResultDisplay = ({ errors, warnings }) => {
  return (
    <Fragment>
      {errors && errors.length > 0 ? (
        <div className={cx(tenPxMarginBottom, 'text-danger')}>
          <FormattedMessage {...messages.errorValidatingInput} />
          <ul>
            {errors.map((error) => (
              <ValidationResultMessage key={JSON.stringify(error)} result={error} />
            ))}
          </ul>
        </div>
      ) : null}
      {warnings && warnings.length > 0 ? (
        <div className={cx(tenPxMarginBottom, 'text-warning')}>
          <FormattedMessage {...messages.warningValidatingInput} />
          <ul>
            {warnings.map((warning) => (
              <ValidationResultMessage key={JSON.stringify(warning)} result={warning} />
            ))}
          </ul>
        </div>
      ) : null}
    </Fragment>
  );
};

const resultsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.object,
  })
);

ValidationResultDisplay.propTypes = {
  errors: resultsPropType,
  warnings: resultsPropType,
};
