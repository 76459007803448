import PropTypes from 'prop-types';
import { FORMULA, OPERATOR_TYPES } from './enums/operators';
import { RANGE, VALUE } from './enums/options';

export const intlShape = {
  formatMessage: PropTypes.func.isRequired,
};

export const unitOfMeasureShape = {
  unitOfMeasureType: PropTypes.string,
  unitOfMeasure: PropTypes.string,
};

export const rangeShape = {
  minimum: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maximum: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  increment: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  integer: PropTypes.bool,
};

export const valueShape = {
  type: PropTypes.oneOf([VALUE, RANGE, FORMULA]),
  description: PropTypes.string,
  value: PropTypes.string,
  range: PropTypes.shape(rangeShape),
  formula: PropTypes.string,
};

const valuesPropType = PropTypes.arrayOf(PropTypes.shape(valueShape));

export const atttributeShape = {
  name: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  values: valuesPropType,
};

export const optionShape = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  type: PropTypes.string,
  unitOfMeasure: PropTypes.string,
  values: valuesPropType,
};

export const constraintConditionShape = {
  id: PropTypes.string,
  attribute: PropTypes.string,
  values: valuesPropType,
  operator: PropTypes.oneOf(Object.values(OPERATOR_TYPES)),
};

export const constraintRuleShape = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  conditions: PropTypes.arrayOf(PropTypes.shape(constraintConditionShape)),
  results: PropTypes.arrayOf(PropTypes.shape(constraintConditionShape)),
};

export const assignmentShape = {
  type: PropTypes.string,
  value: PropTypes.string,
  formula: PropTypes.string,
};

export const propertyShape = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  type: PropTypes.string,
  unitOfMeasure: PropTypes.string,
  staticAssignment: PropTypes.shape(assignmentShape),
  assignments: PropTypes.arrayOf(
    PropTypes.shape({
      conditions: PropTypes.arrayOf(PropTypes.shape(constraintConditionShape)),
      result: PropTypes.shape(assignmentShape),
    })
  ),
};

export const dataSheetsShape = {
  name: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      terms: PropTypes.arrayOf({
        key: PropTypes.string.isRequired,
        operator: PropTypes.oneOf(Object.values(OPERATOR_TYPES)).isRequired,
        values: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.oneOf([RANGE, VALUE]).isRequired,
            value: PropTypes.string,
            range: PropTypes.shape({ rangeShape }),
          })
        ),
      }),
    })
  ),
};

export const constraintsShape = {
  dataSheets: PropTypes.arrayOf(PropTypes.shape(dataSheetsShape)),
  rules: PropTypes.arrayOf(PropTypes.shape(constraintRuleShape)),
};

export const propertyConditionAttributeShape = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  type: PropTypes.string,
  unitOfMeasure: PropTypes.string,
  values: valuesPropType,
  staticAssignment: PropTypes.shape(assignmentShape),
  assignments: PropTypes.arrayOf(
    PropTypes.shape({
      conditions: PropTypes.arrayOf(PropTypes.shape(constraintConditionShape)),
      result: PropTypes.shape(assignmentShape),
    })
  ),
};

export const conditionSetShape = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  conditions: PropTypes.arrayOf(PropTypes.shape(constraintConditionShape)),
};
