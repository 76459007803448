import React, { useState, Fragment, useEffect } from 'react';

// import { useIntl } from 'react-intl';
import { css } from 'emotion';
// import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
// import cloneDeep from 'lodash/cloneDeep';

import Accordion from '@cimpress/react-components/lib/Accordion';
import { silver } from '@cimpress/react-components/lib/colors';
import InlineEdit from '@cimpress/react-components/lib/InlineEdit';

// import messages from './messages';

const positionSelectionName = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .inline-edit-group {
    margin-bottom: 5px !important;
  }
  margin-top: -30px;
  margin-left: 30px;
`;

const DataAssignmentEditor = ({
  isEditing = true,
  data,
  allDataAssignments,
  productOptions,
  isTableView,
  disabledTableView,
  disabledTableViewSettings,
  canStopEditing,
  onUpdateAssignment,
  onUpdateAssignmentName,
  goToCardView,
  goToTableView,
}) => {
  // const { formatMessage } = useIntl();

  const [openEditor, setOpenEditor] = useState(false);
  const [name, setName] = useState('');
  const [selectedData, setSelectedData] = useState({});
  const toggleAccordion = () => setOpenEditor(!openEditor);

  useEffect(() => {
    setSelectedData(data);
    !isEmpty(data) && setName(data.name);
  }, [data]);

  const onUpdateName = (value) => {
    setName(value);
    onUpdateAssignmentName(value);
  };

  const assignmentName = isEditing ? (
    <div className={positionSelectionName}>
      <InlineEdit
        value={name}
        onSave={({ value }) => onUpdateName}
        placeholder={'Enter Name'}
        size="h6"
        minWidth={500}
        maxHeight={200}
      />
    </div>
  ) : (
    <span>{name}</span>
  );
  return (
    <Fragment>
      <Accordion
        title={assignmentName}
        headerStyle={{ backgroundColor: 'white', border: `1px solid ${silver}` }}
        onHeaderClick={toggleAccordion}
        bodyStyle={{ border: 'none' }}>
        WIP
        {selectedData.name && selectedData.name}
      </Accordion>
    </Fragment>
  );
};

export default DataAssignmentEditor;
