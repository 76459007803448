import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { cx, css } from 'emotion';

import InlineEdit from '@cimpress/react-components/lib/InlineEdit';

import { valueShape } from '../../shared/propTypes';
import { LeftXTag } from '../../shared/LeftXTag';
import messages from './messages';

const inlineEditStyle = css`
  display: inline-block;
  font-size: 14px;

  .inline-edit-group {
    min-width: 50px;
    margin-bottom: 5px !important;

    input {
      padding: inherit;
    }

    .resting-text {
      margin-top: 5px;
    }

    .error-message {
      top: 100%;
    }
  }
`;

const inlineEditStyleWhenEditing = css`
  .inline-edit-group {
    margin-bottom: 15px !important;

    .error-message {
      top: 110%;
    }
  }
`;

export const StringValuePill = ({ value, values, canEdit, onValueRemoved, onValueChanged }) => {
  const { formatMessage } = useIntl();
  const [existingValues, updateExistingValues] = useState([]);
  const [isEditing, updateEditState] = useState(false);

  useEffect(() => {
    if (values) {
      const validationValues = values
        .filter((otherValue) => otherValue.value.toLowerCase() !== value.value.toLowerCase())
        .map((otherValue) => otherValue.value.toLowerCase());

      updateExistingValues(validationValues);
    }
  }, [value, values]);

  const onRemoveClicked = () => onValueRemoved(value);

  const validateText = (inputText) => {
    if (existingValues.includes(inputText.toLowerCase())) {
      return formatMessage(messages.invalidInput);
    }

    return null;
  };

  const updateValue = ({ value: inputValue }) => {
    const updatedValue = { ...value, value: inputValue };

    onValueChanged(value, updatedValue);
  };

  const text = value ? value.value : '';

  const label = canEdit ? (
    <div className={isEditing ? cx(inlineEditStyle, inlineEditStyleWhenEditing) : cx(inlineEditStyle)}>
      <InlineEdit
        value={text}
        validateInput={validateText}
        onSave={updateValue}
        minWidth={50}
        required
        onEditStateChange={updateEditState}
        data-testid="InlineEdit"
      />
    </div>
  ) : (
    text
  );

  return <LeftXTag data-testid="Tag" label={label} onRemove={onRemoveClicked} removable={!isEditing} />;
};

StringValuePill.propTypes = {
  value: PropTypes.shape(valueShape).isRequired,
  values: PropTypes.arrayOf(PropTypes.shape(valueShape)).isRequired,
  canEdit: PropTypes.bool,
  onValueRemoved: PropTypes.func.isRequired,
  onValueChanged: PropTypes.func,
};

StringValuePill.defaultProps = {
  value: {},
  canEdit: false,
};
