import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { LeftXTag } from '../../shared/LeftXTag';
import { valueShape } from '../../shared/propTypes';
import { getRangeMessage } from '../../shared/formatters/rangeTranslator';

import messages from '../../shared/formatters/messages';

export const RangeValuesDisplay = ({ values, onValueRemoved }) => {
  const { formatMessage } = useIntl();

  return (
    <Fragment>
      {values.map((rangeValue) =>
        rangeValue[rangeValue.type] ? (
          <LeftXTag
            key={rangeValue.range ? JSON.stringify(rangeValue.range) : JSON.stringify(rangeValue.value)}
            label={
              rangeValue.value
                ? formatMessage(messages.rangeExactly, { minimum: rangeValue.value })
                : formatMessage(getRangeMessage(rangeValue.range), rangeValue.range)
            }
            onRemove={() => onValueRemoved(rangeValue)}
            removable={true}
          />
        ) : null
      )}
    </Fragment>
  );
};

RangeValuesDisplay.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape(valueShape)).isRequired,
  onValueRemoved: PropTypes.func.isRequired,
};

RangeValuesDisplay.defaultProps = {
  values: [],
};
