import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import NavTab from '@cimpress/react-components/lib/NavTab';
import NavTabItem from '@cimpress/react-components/lib/NavTabItem';

import filter from 'lodash/filter';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import find from 'lodash/find';

import { intlShape, optionShape, constraintConditionShape, propertyShape } from '../../shared/propTypes';

import { FORMULA, OPERATOR_TYPES } from '../../shared/enums/operators';
import { RANGE, VALUE, NUMBER } from '../../shared/enums/options';

import { NumberValueEditor } from '../NumberValueEditor/NumberValueEditor';
import { RangeValuesEditor } from '../RangeValuesEditor/RangeValuesEditor';
import { FormulaValueEditor } from '../FormulaValueEditor/FormulaValueEditor';

import messages from '../../shared/messages';

const productOptionPropType = PropTypes.shape(optionShape);
const productPropertyPropType = PropTypes.shape(propertyShape);

export const NumberValuesEditor = ({
  condition,
  attributes,
  selectedAttribute,
  selectedOperator,
  allowFormulas,
  onUpdateConditionValues,
  intl: { formatMessage },
}) => {
  const shouldShowRangeEditor = (selectedOperator) =>
    !selectedOperator || selectedOperator === OPERATOR_TYPES.IN || selectedOperator === OPERATOR_TYPES.NOT_IN;
  const defaultSelectionType = shouldShowRangeEditor(selectedOperator) ? RANGE : VALUE;
  const getInitialSelectionType = () => {
    if (condition.values && !!find(condition.values, (value) => value.type === FORMULA)) {
      return FORMULA;
    }

    if (condition.values && !!find(condition.values, (value) => value.type === RANGE)) {
      return RANGE;
    }

    return defaultSelectionType;
  };

  const [selectionType, setSelectionType] = useState(getInitialSelectionType());
  const [oldSelectedOperator, setOldSelectedOperator] = useState(selectedOperator);

  useEffect(() => {
    if (selectionType !== FORMULA && oldSelectedOperator !== selectedOperator) {
      if (defaultSelectionType !== selectionType) {
        if (defaultSelectionType === RANGE) {
          onRangeTabSelect();
        } else {
          onNumberTabSelect();
        }
      }
      setOldSelectedOperator(selectedOperator);
    }
  }, [defaultSelectionType, selectionType, oldSelectedOperator, selectedOperator]); // eslint-disable-line react-hooks/exhaustive-deps

  const onRangeTabSelect = () => {
    onUpdateConditionValues([]);
    setSelectionType(RANGE);
  };

  const onNumberTabSelect = () => {
    onUpdateConditionValues([]);
    setSelectionType(VALUE);
  };

  const onFormulaTabSelect = () => {
    onUpdateConditionValues([]);
    setSelectionType(FORMULA);
  };

  const renderEditor = (selectionType) => {
    if (selectionType === RANGE) {
      return (
        <RangeValuesEditor
          values={condition.values}
          attribute={selectedAttribute}
          onValuesChanged={onUpdateConditionValues}
        />
      );
    } else if (selectionType === FORMULA) {
      const value = condition.values.length > 0 ? condition.values[0] : { type: FORMULA };
      const variableNames = filter(
        keys(pickBy(attributes, (attribute) => attribute.type === NUMBER)),
        (variableKey) => variableKey !== condition.attribute
      );
      return (
        <FormulaValueEditor
          value={value}
          variableNames={variableNames}
          onValueChanged={(updatedValue) => onUpdateConditionValues([updatedValue])}
        />
      );
    } else if (selectionType === VALUE) {
      const value = condition.values.length > 0 ? condition.values[0] : { type: VALUE };

      return (
        <NumberValueEditor
          value={value}
          attribute={selectedAttribute}
          onValueChanged={(updatedValue) => onUpdateConditionValues([updatedValue])}
        />
      );
    }

    return null;
  };

  const showRangeEditor = shouldShowRangeEditor(selectedOperator);

  return (
    <React.Fragment>
      <NavTab>
        <NavTabItem active={selectionType === RANGE} style={{ display: showRangeEditor ? 'inherit' : 'none' }}>
          <button onClick={onRangeTabSelect}>{formatMessage(messages.ranges)}</button>
        </NavTabItem>
        <NavTabItem active={selectionType === VALUE} style={{ display: showRangeEditor ? 'none' : 'inherit' }}>
          <button onClick={onNumberTabSelect}>{formatMessage(messages.number)}</button>
        </NavTabItem>
        {allowFormulas ? (
          <NavTabItem active={selectionType === FORMULA}>
            <button onClick={onFormulaTabSelect}>{formatMessage(messages.formula)}</button>
          </NavTabItem>
        ) : null}
      </NavTab>
      {renderEditor(selectionType)}
    </React.Fragment>
  );
};

NumberValuesEditor.propTypes = {
  selectedAttribute: PropTypes.oneOfType([productOptionPropType, productPropertyPropType]).isRequired,
  selectedOperator: PropTypes.string,
  condition: PropTypes.shape(constraintConditionShape).isRequired,

  attributes: PropTypes.objectOf(PropTypes.oneOfType([productOptionPropType, productPropertyPropType])).isRequired,

  allowFormulas: PropTypes.bool,

  onUpdateConditionValues: PropTypes.func.isRequired,

  intl: PropTypes.shape(intlShape).isRequired,
};

NumberValuesEditor.defaultProps = {
  selectionType: undefined,
  selectedOperator: undefined,
  allowFormulas: true,
};

export default injectIntl(NumberValuesEditor);
