import { Accordion, Alert, Tag } from '@cimpress/react-components';
import React from 'react';
import { css } from 'emotion';
import { alloy } from '@cimpress/react-components/lib/colors';
import { useIntl } from 'react-intl';
import msg from './messages';
import { formatProductName, ProductNameDisplayMode } from './helper';

const UnmappedOptionAlert = ({ srcProduct, targetProduct, srcUnmapped, targetUnmapped, optionDefaults }) => {
  const { formatMessage } = useIntl();
  let nameDisplayMode = ProductNameDisplayMode.NAME_WITH_ID;
  if (srcProduct.productId === targetProduct.productId) {
    nameDisplayMode = ProductNameDisplayMode.VERSION_ONLY;
  }
  return (
    <Alert
      type="warning"
      dismissible={false}
      message={
        <Accordion
          className={css`
            margin-bottom: 0 !important;
          `}
          title={formatMessage(msg.unmappedOptions)}>
          <table className={tableCss}>
            <tbody>
              <tr>
                <td>{formatProductName(srcProduct, nameDisplayMode, formatMessage(msg.noVersion))}</td>
                <td>{formatProductName(targetProduct, nameDisplayMode, formatMessage(msg.noVersion))}</td>
              </tr>
              <tr>
                <td className={paddingCss}>
                  {srcUnmapped.map((elt) => (
                    <Tag key={elt.name} value={elt.name} />
                  ))}
                </td>
                <td className={paddingCss}>
                  {targetUnmapped
                    .filter((elt) => optionDefaults[elt.name] === undefined)
                    .map((elt) => (
                      <Tag key={elt.name} value={elt.name} />
                    ))}
                </td>
              </tr>
            </tbody>
          </table>
        </Accordion>
      }
    />
  );
};

const paddingCss = css`
  span {
    margin-right: 5px;
    margin-top: 5px;
  }
`;

const tableCss = css`
  width: 100%;
  margin: 0 !important;

  tr:only-child {
    td {
      border: 0 none;
    }
  }
  td:first-child {
    border-right: 1px solid ${alloy};
    padding-right: 25px;
  }
  td:last-child {
    border-left: 1px solid ${alloy};
    padding-left: 25px;
  }

  tr {
    vertical-align: top;
  }

  td {
    width: 50%;
  }
`;

export default UnmappedOptionAlert;
