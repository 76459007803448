import React, { Fragment, useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { VALUE } from '../../shared/enums/options';
import { atttributeShape } from '../../shared/propTypes';
import AddForm from '../../shared/addForm';

import { HexValuePill } from './HexValuePill';
import { HexValuesSelector } from './HexValuesSelector';
import { convertDecimalToHex, convertHexToDecimal } from '../../shared/HexConversion/hexConverter';

export const HexValuesEditor = ({ values, attribute, onValuesChanged, multiSelect = true }) => {
  if (values.length > 1 && !multiSelect) {
    throw new Error('Props Error: `values` cannot contain more than one value if `multiSelect` is set to false');
  }
  const [convertedAttribute, setConvertedAttribute] = useState({});
  const [convertedValues, setConvertedValues] = useState([]);

  useEffect(() => {
    if (attribute) {
      const filteredValues = attribute.values.filter((x) => x.type !== x.value);
      const newValues = filteredValues.map((x) => {
        return { ...x, value: convertDecimalToHex(x.value) };
      });
      const newAttribute = {
        ...attribute,
        values: newValues,
      };
      setConvertedAttribute(newAttribute);
    }
  }, [attribute]);

  useEffect(() => {
    const newValues = values.map((x) => {
      return { ...x, value: convertDecimalToHex(x.value) };
    });
    setConvertedValues(newValues);
  }, [values]);

  const onValueRemoved = (value) => {
    const decimalValue = convertHexToDecimal(value.value);
    const updatedValues = values.filter((selectedValue) => selectedValue.value !== decimalValue);

    onValuesChanged(updatedValues);
  };

  // TODO Update this to work with decimals
  const onValueChanged = (originalValue, updatedValue) => {
    const originalDecimalValue = convertHexToDecimal(originalValue.value);
    const updatedDecimalValue = convertHexToDecimal(updatedValue.value);

    const updatedValues = [...values];
    const originalIndex = updatedValues.findIndex((searchValue) => searchValue.value === originalDecimalValue);

    if (originalIndex >= 0) {
      updatedValues.splice(originalIndex, 1, { type: VALUE, value: updatedDecimalValue });

      onValuesChanged(updatedValues);
    }
  };

  const onAdd = (newValue) => {
    const valuesClone = cloneDeep(values);
    const decimal = convertHexToDecimal(newValue);
    valuesClone.push({ type: VALUE, value: decimal });

    onValuesChanged(valuesClone);
  };

  // TODO: make work with decimals and numbers
  const onMultiAdd = (newValues) => {
    const uniqueNewValues = validateNewValues(newValues);
    let valuesClone = cloneDeep(values);
    valuesClone = valuesClone.concat(
      uniqueNewValues.map((newValue) => {
        if (!validateValue(newValue)) {
          return { type: VALUE, value: convertHexToDecimal(newValue) };
        } else {
          return undefined;
        }
      })
    );
    onValuesChanged(valuesClone.filter((x) => x !== undefined));
  };

  const validateNewValues = (newValues) => {
    return newValues.reduce((uniqueNewValues, newValue) => {
      if (uniqueNewValues.every((value) => value.toLowerCase() !== newValue.toLowerCase()))
        uniqueNewValues.push(newValue);
      return uniqueNewValues;
    }, []);
  };

  const validateValue = (newValue) => {
    if (newValue === '') {
      return false;
    }
    let isInvalid = false;
    values.forEach((value) => {
      if (value.value === convertHexToDecimal(newValue)) {
        isInvalid = true;
      }
    });
    if (convertHexToDecimal(newValue) === null) {
      isInvalid = true;
    }
    return isInvalid;
  };

  return (
    <Fragment>
      {attribute ? (
        <HexValuesSelector
          selectedValues={convertedValues}
          availableValues={convertedAttribute ? convertedAttribute.values : null}
          onSelectedValuesChanged={onValuesChanged}
          data-testid="HexValuesSelector"
          multiSelect={multiSelect}
        />
      ) : (
        <AddForm onAdd={onAdd} validateOption={validateValue} data-testid="AddForm" onMultiAdd={onMultiAdd} />
      )}

      {multiSelect ? (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {convertedValues.map((value) => (
            <HexValuePill
              key={value.value}
              hexValue={value}
              canEdit={!Boolean(attribute)}
              onValueRemoved={onValueRemoved}
              onValueChanged={onValueChanged}
              data-testid="HexValuePill"
            />
          ))}
        </div>
      ) : null}
    </Fragment>
  );
};

HexValuesEditor.propTypes = {
  attribute: PropTypes.shape(atttributeShape),
  onValuesChanged: PropTypes.func.isRequired,
  values: PropTypes.arrayOf({}),
};

HexValuesEditor.defaultProps = {
  values: [],
};
