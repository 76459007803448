import { defineMessages } from 'react-intl';

export default defineMessages({
  selectConditionSetToLink: {
    id: 'conditionSetSelector.selectConditionSetToLink',
    defaultMessage: 'Select Condition Set to Link',
  },
  createOrEditCondition: {
    id: 'conditionSetSelector.createOrEditCondition',
    defaultMessage: 'Create / Edit Conditions',
  },
  close: {
    id: 'conditionSetSelector.close',
    defaultMessage: 'Close',
  },
  seeDetails: {
    id: 'conditionSetSelector.seeDetails',
    defaultMessage: 'See Details',
  },
  refreshHamTree: {
    id: 'conditionSetSelector.refreshHamTree',
    defaultMessage: 'Refresh Tree',
  },
});
