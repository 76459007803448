import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { css } from 'emotion';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import find from 'lodash/find';
import each from 'lodash/each';

import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import Modal from '@cimpress/react-components/lib/Modal';
import Button from '@cimpress/react-components/lib/Button';
import Snackbar from '@cimpress/react-components/lib/Snackbar';

import { PIN_TO_CURRENT, CREATE, CURRENT } from '../../../shared/enums/constants';
import ConditionSetEditor from './ConditionSetEditor/ConditionSetEditor';
import {
  getAttributeModelByCurrentProductVersion,
  getAttributeModelByProductVersion,
  getCurrentProductVersion,
  getProductVersion,
} from '../../../services/productService';
import { getAttributeModelByHref } from '../../../services/attributeModelService';
import { createConstraint, getConstraint } from '../../../services/constraintService';

import {
  getConditionSetIndex,
  mapAttributesAndConstraintsToConditionSetsModel,
  mapAttributeModelToConditionSetsModel,
  mapConditionSetsModelToAttributeModel,
  createConditionSet,
} from './conditionSetHelpers';

import { tenPxMarginRight } from '../../../shared/commonStyles';
import messages from './messages';

const buttonMargin = css`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const center = css`
  text-align: center;
`;

const snackbarDelay = 10000;

export const ConditionSetWrapper = ({
  accessToken,
  productUrl,
  attributeModelReferenceHref,
  constraintId,
  hamName,
  selectedVersion,
  productId,
  nodeId,
  onSave,
  onCancel,
  creatingChildHam,
  mode = CREATE,
  onOpenConditionSetManager,
  type,
}) => {
  const { formatMessage } = useIntl();
  const [attributes, setAttributes] = useState({});
  const [conditionSets, setConditionSets] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [saving, setSaving] = useState(false);

  const conditionSet = conditionSets.length > 0 ? conditionSets[0] : {};

  const [editing, setEditing] = useState(false);

  useEffect(() => {
    const fetchConstraints = async () => {
      setLoading(true);
      setErrorMessage('');

      if (constraintId) {
        try {
          let constraintResponse = await getConstraint({ constraintId, accessToken });

          const version = productUrl && productUrl.includes(':current') ? CURRENT : productUrl.split('/')[7];

          // fetch product url and get attribute model response
          const attributeModelUrl =
            version === CURRENT
              ? await getAttributeModelByCurrentProductVersion({ productId, accessToken })
              : await getAttributeModelByProductVersion({ productId, version, accessToken });

          const attributeModelResponse = await getAttributeModelByHref({
            attributeModelReferenceHref: attributeModelUrl._links.attributeModel.href,
            accessToken,
          });

          const mappedResponse = mapAttributesAndConstraintsToConditionSetsModel(
            attributeModelResponse.attributes,
            constraintResponse,
            hamName
          );

          if (creatingChildHam) {
            if (selectedVersion) {
              const productVersion =
                selectedVersion === PIN_TO_CURRENT
                  ? await getCurrentProductVersion({ productId, accessToken })
                  : await getProductVersion({ productId, version: selectedVersion, accessToken });

              let newAttributes = {};
              const newAttributesArray = filter(mappedResponse.attributes, (attributeModelAttribute) =>
                find(
                  productVersion.options.concat(productVersion.properties, [{ name: 'Quantity' }]),
                  (productAttribute) => attributeModelAttribute.name === productAttribute.name
                )
              );

              each(newAttributesArray, (newAttribute) => {
                newAttributes[newAttribute.name] = newAttribute;
              });

              setAttributes(newAttributes);
            } else {
              setAttributes(mappedResponse.attributes);
            }

            setConditionSets([createConditionSet()]);
            setEditing(true);
          } else {
            setAttributes(mappedResponse.attributes);
            setConditionSets(mappedResponse.conditionSets);
            setEditing(false);
          }
          setLoading(false);
        } catch (error) {
          setErrorMessage(error.message.concat(` - ${JSON.stringify(error)}`));
        }
      }
      // If attributeModelReferenceHref and no constraintId, should be create child under adoption flow
      else if (!constraintId && attributeModelReferenceHref && creatingChildHam) {
        try {
          let response = await getAttributeModelByHref({ attributeModelReferenceHref, accessToken });
          const mappedResponse = mapAttributeModelToConditionSetsModel(response, hamName);

          if (selectedVersion) {
            const productVersion =
              selectedVersion === PIN_TO_CURRENT
                ? await getCurrentProductVersion({ productId, accessToken })
                : await getProductVersion({ productId, version: selectedVersion, accessToken });

            let newAttributes = {};
            const newAttributesArray = filter(mappedResponse.attributes, (attributeModelAttribute) =>
              find(
                productVersion.options.concat(productVersion.properties, [{ name: 'Quantity' }]),
                (productAttribute) => attributeModelAttribute.name === productAttribute.name
              )
            );

            each(newAttributesArray, (newAttribute) => {
              newAttributes[newAttribute.name] = newAttribute;
            });

            setAttributes(newAttributes);
          } else {
            setAttributes(mappedResponse.attributes);
          }

          setConditionSets([createConditionSet()]);
          setEditing(true);
          setLoading(false);
        } catch (error) {
          setErrorMessage(error.message.concat(` - ${JSON.stringify(error)}`));
        }
      }
    };

    fetchConstraints();
  }, [
    accessToken,
    constraintId,
    creatingChildHam,
    hamName,
    productId,
    productUrl,
    selectedVersion,
    attributeModelReferenceHref,
  ]);

  const onChange = (oldConditionSet, newConditionSet) => {
    const conditionSetsClone = cloneDeep(conditionSets);
    const currentConditionSetIndex = getConditionSetIndex(conditionSetsClone, oldConditionSet);

    if (currentConditionSetIndex > -1) {
      conditionSetsClone.splice(currentConditionSetIndex, 1, newConditionSet);
    }

    setConditionSets(conditionSetsClone);
  };

  const onDeleteClick = (conditionSet) => {
    const conditionSetsClone = cloneDeep(conditionSets);
    const currentConditionSetIndex = getConditionSetIndex(conditionSetsClone, conditionSet);

    if (currentConditionSetIndex > -1) {
      conditionSetsClone.splice(currentConditionSetIndex, 1);
    }

    setConditionSets(conditionSetsClone);
  };

  const onCancelClick = () => {
    setErrorMessage('');
    setEditing(false);
    onCancel();
    onHideModal();
  };

  const onSaveClick = async () => {
    setSaving(true);
    setErrorMessage('');

    const attributeModel = mapConditionSetsModelToAttributeModel(conditionSets, attributes);

    try {
      const constraintResponse = await createConstraint({
        constraint: attributeModel.constraints,
        accessToken,
      });

      await onSave(constraintResponse, attributeModel.constraints);
    } catch (error) {
      setErrorMessage(error.message.concat(` - ${JSON.stringify(error)}`));
    }

    setSaving(false);
    onHideModal();
  };

  const onShowModal = () => {
    setShowModal(true);
  };

  const onHideModal = () => {
    setShowModal(false);
  };

  const onEditClick = () => {
    setEditing((prevEditing) => !prevEditing);
  };

  const onHideSnackbar = () => {
    setErrorMessage('');
  };

  return (
    <Fragment>
      <Snackbar show={!!errorMessage} bsStyle="danger" delay={snackbarDelay} onHideSnackbar={onHideSnackbar}>
        {errorMessage}
      </Snackbar>
      {loading ? (
        <Spinner size="medium" className={center} />
      ) : (
        <Fragment>
          <Modal
            bsStyle="warning"
            show={showModal}
            onRequestHide={onHideModal}
            title={formatMessage(messages.modalTitle, { conditionSetName: conditionSet.name })}
            closeButton={true}
            footer={
              <Fragment>
                <Button type="default" className={tenPxMarginRight} onClick={onHideModal}>
                  {formatMessage(messages.cancel)}
                </Button>
                <Button type="primary" className={tenPxMarginRight} onClick={onSaveClick}>
                  {formatMessage(messages.yes)}
                </Button>
              </Fragment>
            }>
            {saving ? <Spinner size="medium" className={center} /> : formatMessage(messages.modalBody)}
          </Modal>
          <ConditionSetEditor
            nodeId={nodeId}
            attributes={attributes}
            conditionSet={conditionSet}
            conditionSets={conditionSets}
            onChange={onChange}
            onDelete={onDeleteClick}
            onEditClick={onEditClick}
            editing={editing}
            mode={mode}
            onOpenConditionSetManager={onOpenConditionSetManager}
            type={type}
          />
          {editing ? (
            <div className={buttonMargin}>
              <Button type="default" className={tenPxMarginRight} onClick={onCancelClick}>
                {formatMessage(messages.cancel)}
              </Button>
              <Button
                type="primary"
                className={tenPxMarginRight}
                onClick={creatingChildHam ? onSaveClick : onShowModal}
                disabled={!conditionSet.name}>
                {formatMessage(messages.save)}
              </Button>
              {saving && <Spinner size="medium" />}
            </div>
          ) : null}
        </Fragment>
      )}
    </Fragment>
  );
};

ConditionSetWrapper.propTypes = {
  accessToken: PropTypes.string.isRequired,
  attributeModelReferenceHref: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedVersion: PropTypes.string,
  productId: PropTypes.string.isRequired,
};

ConditionSetWrapper.defaultProps = {};

export default ConditionSetWrapper;
