const HEX_REGEX = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{8})$/i;

const ERROR_IDS = {
  INVALID_COLOR: 'colorInvalid',
};

const isValidColor = (str) => {
  return str ? str.match(HEX_REGEX) !== null : false;
};

export const validateHexString = (hexString) => {
  const validationResult = {
    hexString,
    errors: [],
    warnings: [],
  };

  if (hexString && !isValidColor(hexString)) {
    validationResult.errors.push({ id: ERROR_IDS.INVALID_COLOR });
  }
  return validationResult;
};
