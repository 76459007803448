import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import TextField from '@cimpress/react-components/lib/TextField';

import { ValidValuesHeader } from '../../shared/ValidValuesHeader';
import { ValidationResultDisplay } from '../../shared/ValidationResultDisplay';

import { VALUE } from '../../shared/enums/options';

import { atttributeShape, valueShape } from '../../shared/propTypes';

import messages from './messages';

import { validateNumber } from '../../shared/validators/numberValidators';

const validateValue = (value) => {
  if (!value) {
    throw new Error('NumberValueEditor did not receive a value');
  } else if (value.type && value.type !== VALUE) {
    throw new Error('NumberValueEditor received a value with a incorrect value type');
  }
};

export const NumberValueEditor = ({ value, attribute, onValueChanged, enforceValidValues }) => {
  const { formatMessage } = useIntl();

  const [numberState, updateNumberState] = useState(() => (value && value.value ? value.value : ''));
  const [validationState, updateValidationState] = useState({ errors: [], warnings: [] });

  useEffect(() => {
    validateValue(value);
    updateNumberState(value.value);
  }, [value]);

  useEffect(() => {
    const validValues = attribute ? attribute.values : null;
    const validationResult = validateNumber(numberState, validValues, enforceValidValues);

    updateValidationState(validationResult);
  }, [numberState, attribute, enforceValidValues]);

  const onTextChanged = ({ target: { value } }) => {
    updateNumberState(value);
  };

  const onBlur = () => {
    const updatedValue = { type: VALUE, value: validationState.numberString };

    onValueChanged(updatedValue);
  };

  return value ? (
    <React.Fragment>
      {attribute ? <ValidValuesHeader attribute={attribute} /> : null}

      <TextField
        label={formatMessage(messages.enterNumber)}
        value={validationState.numberString}
        onChange={onTextChanged}
        onBlur={onBlur}
        data-testid={'NumberInput'}
      />

      <ValidationResultDisplay errors={validationState.errors} warnings={validationState.warnings} />
    </React.Fragment>
  ) : null;
};

NumberValueEditor.propTypes = {
  value: PropTypes.shape(valueShape),
  attribute: PropTypes.shape(atttributeShape),
  onValueChanged: PropTypes.func.isRequired,
  enforceValidValues: PropTypes.bool,
};

NumberValueEditor.defaultProps = {
  value: { type: VALUE },
  enforceValidValues: false,
};
