import { css } from 'emotion';

import React, { Fragment } from 'react';
import omit from 'lodash/omit';
import keys from 'lodash/keys';
import toPairs from 'lodash/toPairs';
import filter from 'lodash/filter';
import { Accordion, Select, Tag, Button, Tooltip } from '@cimpress/react-components';
import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import IconCheckCircleAlt from '@cimpress-technology/react-streamline-icons/lib/IconCheckCircleAlt';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import IconUndo from '@cimpress-technology/react-streamline-icons/lib/IconUndo';
import { success, warning, info, alloy } from '@cimpress/react-components/lib/colors';
import { optionHasRange, toOption } from './helper';
import { FormattedMessage, useIntl } from 'react-intl';
import msg from './messages';
const maxSelectMenuHeight = 125; // Limit menu height to prevent accordion from hiding options. Portaling results in zIndex conflict with select "x" element
const OptionMapper = ({
  mappingData,
  srcUnmapped,
  targetUnmapped,
  targetValues,
  onValueMappingChanged,
  onSourceOptionChanged,
  onTargetOptionChanged,
}) => {
  const { formatMessage } = useIntl();

  function isValueMappingEmpty(elt) {
    return elt.src.length !== 0 || elt.target.length !== 0;
  }

  function onSourceValueChange(index, newSrcValue) {
    const valueMapping = [...mappingData.values];
    let oldSrc = {};
    if (index === valueMapping.length) {
      valueMapping[index] = { src: newSrcValue, target: [] };
    } else {
      const oldMapping = valueMapping[index];
      oldMapping.src.forEach((elt) => (oldSrc[elt] = false));
      valueMapping[index] = { src: newSrcValue, target: oldMapping.target };
    }

    onValueMappingChanged(
      Object.assign({}, mappingData, {
        values: filter(valueMapping, isValueMappingEmpty),
        removedValues: {
          src: omit(Object.assign(oldSrc, mappingData.removedValues.src), newSrcValue),
        },
      })
    );
  }

  function onTargetValueChange(index, newTargetValue) {
    const valueMapping = [...mappingData.values];
    const oldMapping = valueMapping[index];
    valueMapping[index] = { src: oldMapping.src, target: newTargetValue };

    onValueMappingChanged(
      Object.assign({}, mappingData, {
        values: filter(valueMapping, isValueMappingEmpty),
        removedValues: {
          src: Object.assign({}, mappingData.removedValues.src),
        },
      })
    );
  }

  function onConfirmedUnmapped(valuesToRemove) {
    const removedValues = Object.assign({}, mappingData.removedValues.src);
    valuesToRemove.forEach((elt) => (removedValues[elt] = true));
    onValueMappingChanged(
      Object.assign({}, mappingData, {
        removedValues: {
          src: removedValues,
        },
      })
    );
  }

  function onUndoUnmapped(valuesToUndo) {
    const removedValues = Object.assign({}, mappingData.removedValues.src);
    valuesToUndo.forEach((elt) => (removedValues[elt] = false));
    onValueMappingChanged(
      Object.assign({}, mappingData, {
        removedValues: {
          src: removedValues,
        },
      })
    );
  }

  const unmappedValues = toPairs(mappingData.removedValues.src);
  const unconfirmedUnmapped = unmappedValues.filter(([key, alreadyMarkedAsRemoved]) => !alreadyMarkedAsRemoved);
  const confirmedUnmapped = unmappedValues.filter(([key, alreadyMarkedAsRemoved]) => alreadyMarkedAsRemoved);
  return (
    <Fragment>
      <Accordion
        type="minimal"
        title={
          <span>
            {mappingData.isCompleted ? (
              <IconCheckCircleAlt color={success.base} weight="fill" className={rightMargin} />
            ) : (
              <IconAlertTriangle color={warning.base} weight="fill" className={rightMargin} />
            )}
            <span>
              {mappingData.target.length !== 0 ? (
                <FormattedMessage
                  {...msg.mapSourceToTarget}
                  values={{
                    ...formatter,
                    sourceAttribute: mappingData.src.join(' + '),
                    targetAttribute: mappingData.target,
                  }}
                />
              ) : mappingData.isCompleted ? (
                <FormattedMessage
                  {...msg.removingOption}
                  values={{
                    ...formatter,
                    attributeName: mappingData.src.join(' + '),
                  }}
                />
              ) : (
                <FormattedMessage
                  {...msg.selectOptionToMapTo}
                  values={{
                    ...formatter,
                    attributeName: mappingData.src.join(' + '),
                  }}
                />
              )}
            </span>
          </span>
        }>
        <div>
          <table className={tableCss}>
            <tbody>
              <tr>
                <td>{formatMessage(msg.option)}</td>
                <td>{formatMessage(msg.mappedTo)}</td>
              </tr>
              <tr>
                {/* TODO: Allow attribute split */}
                <td>
                  <Select
                    placeholder={formatMessage(msg.selectOptionToMapFrom)}
                    isMulti={true}
                    value={mappingData.src.map(toOption)}
                    options={srcUnmapped
                      //Preventing option with ranges from being combined
                      .filter((opt) => mappingData.src.length === 0 || !optionHasRange(opt.values))
                      .map((opt) => toOption(opt.name))}
                    onChange={(selected) => onSourceOptionChanged(selected ? selected.map((elt) => elt.value) : [])}
                    maxMenuHeight={maxSelectMenuHeight}
                  />
                </td>
                <td>
                  <Select
                    placeholder={formatMessage(msg.selectOptionToMapToPlaceHolder)}
                    isMulti={false}
                    isClearable={true}
                    value={mappingData.target.map(toOption)}
                    options={targetUnmapped.map(toOption)}
                    onChange={(selected) => onTargetOptionChanged([(selected || {}).value])}
                    maxMenuHeight={maxSelectMenuHeight}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          {mappingData.mappingMode !== 'numeric' ? (
            <table className={tableCss}>
              <tbody>
                {mappingData.values.filter((elt) => elt.range === undefined).length > 0 ? (
                  <tr>
                    <td>{formatMessage(msg.values)}</td>
                    <td>{formatMessage(msg.mappedTo)}</td>
                  </tr>
                ) : (
                  <Fragment />
                )}
                {mappingData.values
                  .filter((elt) => elt.range === undefined)
                  .map((val, index) => (
                    <tr key={index}>
                      <td>
                        <Select
                          placeholder={formatMessage(msg.selectValuesToMapFrom)}
                          isMulti={true}
                          value={val.src.map(toOption)}
                          options={keys(mappingData.removedValues.src).map(toOption)}
                          onChange={(selected) =>
                            onSourceValueChange(index, selected !== null ? selected.map((elt) => elt.value) : [])
                          }
                          closeMenuOnSelect={false}
                          maxMenuHeight={maxSelectMenuHeight}
                        />
                      </td>
                      <td>
                        <Select
                          placeholder={formatMessage(msg.selectValueToMapTo)}
                          isMulti={false}
                          isClearable={true}
                          value={val.target.map(toOption)}
                          options={(targetValues || {}).sort().map(toOption)}
                          onChange={(selected) => onTargetValueChange(index, selected !== null ? [selected.value] : [])}
                          // Portal all selects cause problem with menu zIndex being lower than the select clearable
                          // menu and menuPortal css didn't work for portal

                          // Problematic for target since we are display all values. This isn't great but it gets us most of the way there
                          // If source has a lot of values it will also have a lot of values to confirm unmapped
                          menuPortalTarget={mappingData.values.length - 1 === index ? document.body : undefined}
                          maxMenuHeight={maxSelectMenuHeight}
                        />
                      </td>
                    </tr>
                  ))}
                {keys(mappingData.removedValues.src).length !== 0 && (
                  <tr>
                    <td>
                      <Select
                        isMulti={true}
                        value={null}
                        options={keys(mappingData.removedValues.src).map(toOption)}
                        onChange={(selected) =>
                          onSourceValueChange(
                            mappingData.values.length,
                            selected != null ? selected.map((elt) => elt.value) : []
                          )
                        }
                        closeMenuOnSelect={false}
                        maxMenuHeight={maxSelectMenuHeight}
                      />
                    </td>
                    <td />
                  </tr>
                )}
              </tbody>
            </table>
          ) : toPairs(mappingData.removedValues.src).length > 0 ? (
            <div>{formatMessage(msg.numericInstruction, { optionName: mappingData.target.join(', ') })}</div>
          ) : (
            <Fragment />
          )}
          {unmappedValues.length !== 0 && (
            <Fragment>
              {unconfirmedUnmapped.length > 0 && (
                <div>
                  <h4>
                    <IconAlertTriangle color={warning.base} className={rightMargin} />
                    {formatMessage(msg.confirmValuesUnmapped)}
                    <Tooltip contents={formatMessage(msg.unmappedValueTooltip)}>
                      <IconInformationCircle />
                    </Tooltip>{' '}
                    :
                    <Button type="link" onClick={() => onConfirmedUnmapped(unconfirmedUnmapped.map(([key]) => key))}>
                      {formatMessage(msg.confirmAll)}
                    </Button>
                  </h4>
                  {unconfirmedUnmapped.map(([key]) => (
                    <span key={key} className={rounderTag}>
                      <Tag
                        value={
                          <Fragment>
                            {key}{' '}
                            <Button style={iconBtn} size="sm" type="link" onClick={() => onConfirmedUnmapped([key])}>
                              <IconCheckCircleAlt />
                            </Button>
                          </Fragment>
                        }
                      />
                    </span>
                  ))}
                </div>
              )}
              {confirmedUnmapped.length > 0 && (
                <div>
                  <h4>
                    <IconInformationCircle color={info.base} weight="fill" className={rightMargin} />
                    {formatMessage(msg.valuesConfirmedUnmapped)}:
                    <Button type="link" onClick={() => onUndoUnmapped(confirmedUnmapped.map(([key]) => key))}>
                      {formatMessage(msg.undoAll)}
                    </Button>
                  </h4>
                  {confirmedUnmapped.map(([key]) => (
                    <span key={key} className={rounderTag}>
                      <Tag
                        key={key}
                        value={
                          <Fragment>
                            {key}{' '}
                            <Button style={iconBtn} size="sm" type="link" onClick={() => onUndoUnmapped([key])}>
                              <IconUndo />
                            </Button>
                          </Fragment>
                        }
                      />
                    </span>
                  ))}
                </div>
              )}
            </Fragment>
          )}
        </div>
      </Accordion>
    </Fragment>
  );
};

const rightMargin = css`
  margin-right: 10px;
`;
const tableCss = css`
  width: 100%;
  margin: 0 !important;

  tr:only-child {
    td {
      border: 0 none;
    }
  }

  td:first-child {
    border-right: 1px solid ${alloy};
    padding-right: 25px;
  }

  td:last-child {
    border-left: 1px solid ${alloy};
    padding-left: 25px;
  }

  td {
    width: 50%;
  }
`;

const iconBtn = {
  padding: 0,
  verticalAlign: 'sub',
};
// This can't be used directly on Tag component. It will override existing className
const rounderTag = css`
  .tag {
    border-radius: 50px;
    margin: 5px 5px 0 0;
  }
`;
const formatter = {
  span: (msg) => <span>{msg}</span>,
  b: (msg) => <b>{msg}</b>,
};
export default OptionMapper;
