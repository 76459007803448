import React, { useState, useEffect, Fragment } from 'react';
import { useIntl } from 'react-intl';

import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import includes from 'lodash/includes';
import { css } from 'emotion';

import { navigate } from '@cimpress-technology/unified-cimpress-navigator';

import Loading from '../../shared/Loading';
import Accordion from '@cimpress/react-components/lib/Accordion';
import Button from '@cimpress/react-components/lib/Button';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import IconSynchronizeArrowsAlt from '@cimpress-technology/react-streamline-icons/lib/IconSynchronizeArrowsAlt';
import { silver, ocean } from '@cimpress/react-components/lib/colors';

import { AncestorAccordion } from './AncestorAccordion/AncestorAccordion';
import HamSelector from './HamSelector/HamSelector';
import { getV2HamSummaries, createV2AdoptionHam, getV2HamById } from '../../services/hamService';
import { VIEW, PIN_TO_CURRENT, hamAction, selectorType } from '../../shared/enums/constants';
import messages from './messages';

import {
  convertFlatToTree,
  createNoHamsTree,
  addProductInfoToAllNodes,
  generatePathTitle,
  findNodeInTree,
  expandPathNodes,
  addProductIdOnNode,
  addProductIdOnAdoptionNode,
  addHamUrl,
} from './dataHelper';

const flexBox = css`
  display: flex;
`;

const hamSelectorDiv = css`
  width: 400px;
`;

const hamViewDiv = css`
  width: 400px;
  flex: 55%;
  margin-left: 15px;
`;

const refreshButton = css`
  flex: 2%;
  padding-top: 20px;
`;

const createNewButton = css`
  width: 100%;
  border: 1px solid ${silver} !important;
`;

const seeDetailsStyle = css`
  float: right;
  color: ${ocean.base} !important;
  &:hover {
    cursor: pointer;
    font-weight: 600;
  }
`;

export const ConditionSetSelector = ({
  queryProductId,
  hamUrl,
  associatedBusinessId,
  accessToken,
  onNodeSelect,
  openSelector = false,
  type,
}) => {
  const { formatMessage } = useIntl();
  const conditionSetManagerUrl = 'https://conditionsetmanager.products.cimpress.io';

  const [hamId, setHamId] = useState(hamUrl ? hamUrl.split('/hams/')[1] : '');
  const [productId, setProductId] = useState(queryProductId);
  const [hamTreeData, setHamTreeData] = useState([]);
  const [nodePath, setNodePath] = useState([]);
  const [selectedNode, setSelectedNode] = useState({});
  const [openHamSelector, setOpenHamSelector] = useState(openSelector);
  const [selectorTitle, setSelectorTitle] = useState(formatMessage(messages.selectConditionSetToLink));
  const [showAncestorAccordion, setShowAncestorAccordion] = useState(false);
  const [refreshTree, setRefreshTree] = useState(false);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (!isEmpty(hamUrl)) {
      const queryHamId = hamUrl.split('/hams/')[1];
      if (isEmpty(selectedNode) || selectedNode.id !== queryHamId || !selectedNode.isAdoption) {
        setHamId(queryHamId);
      }
    }
    if (!isEmpty(queryProductId)) {
      if (isEmpty(selectedNode) || selectedNode.productId !== queryProductId) {
        setProductId(queryProductId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hamUrl, queryProductId]);

  useEffect(() => {
    const contructTreeData = async () => {
      try {
        const hamResponse = await getV2HamSummaries({ productId, associatedBusinessId, accessToken });
        const treeData = !isEmpty(hamResponse._embedded.item)
          ? await convertFlatToTree(associatedBusinessId, hamResponse._embedded.item, accessToken)
          : !isEmpty(productId) && (await createNoHamsTree({ associatedBusinessId, accessToken, productId }));

        if (!isEmpty(hamId)) {
          getHamNode(treeData);
        } else {
          setHamTreeData(treeData);
          setLoader(false);
          setShowAncestorAccordion(false);
        }
        setRefreshTree(false);
      } catch (error) {
        // figure out what to do if query product id doesn't exist
        setLoader(false);
      }
    };

    const getHamNode = async (treeData) => {
      let hamNode = findNodeInTree(hamId, treeData);
      let isAdoptionHam = false;
      let adoptionHamNode = {};

      if (isEmpty(hamNode)) {
        isAdoptionHam = true;
        adoptionHamNode = await getV2HamById({ id: hamId, accessToken });
        hamNode = findNodeInTree(adoptionHamNode.productId, treeData);
      }
      const expandedTreeData = !isEmpty(hamNode) && expandPathNodes({ path: hamNode.path, treeData });

      const flatPathNodes = addProductInfoToAllNodes({
        path: hamNode.path,
        expandedTreeData,
        isAdoptionHam,
        adoptionHamNode,
      });

      const title = generatePathTitle(flatPathNodes);
      const clonedPath = cloneDeep(flatPathNodes);

      if (isAdoptionHam) {
        const node = cloneDeep(adoptionHamNode);
        node.isAdoptionHam = true;
        setSelectedNode(node);
      } else {
        let selectedTreeNode = flatPathNodes.filter((pathNode) => hamNode.node.id === pathNode.id);
        setSelectedNode(!isEmpty(selectedTreeNode) && addProductIdOnNode(selectedTreeNode[0], hamNode.path));
      }

      setHamTreeData(expandedTreeData);
      setNodePath(clonedPath.reverse());
      setSelectorTitle(title);
      setShowAncestorAccordion(true);
      setLoader(false);
    };

    if (!isEmpty(accessToken) && !isEmpty(associatedBusinessId)) {
      contructTreeData();
    }
  }, [productId, associatedBusinessId, accessToken, hamId, refreshTree]);

  const onSelect = (node, path) => {
    const flatPathNodes = addProductInfoToAllNodes({ path, expandedTreeData: hamTreeData, isAdoptionHam: false });
    const title = generatePathTitle(flatPathNodes);
    const selectedTreeNode = flatPathNodes.filter((pathNode) => node.id === pathNode.id);
    const clonedPath = cloneDeep(flatPathNodes);
    setNodePath(clonedPath.reverse());
    setSelectorTitle(title);

    if (!isEmpty(selectedTreeNode)) {
      setSelectedNode(
        selectedTreeNode[0].isAdoption ? selectedTreeNode[0] : addProductIdOnNode(selectedTreeNode[0], path)
      );
    }
    setShowAncestorAccordion(true);

    //only fire on a ham node select
    if (!node.isAdoption) {
      onNodeSelect(addProductIdOnNode(node, path));
    }
  };

  const getCurrentVersionLink = () => {
    const productId = includes(selectedNode.id, 'CIM') ? selectedNode.id : selectedNode.productId;
    return `https://api.products.cimpress.io/v1/products/${productId}:current`;
  };

  const onAdoptionVersionChange = async (e, oldSelectedValue, fullSelectedVersion) => {
    let currentHam = null;
    let productLinkUrl = null;

    const existingHams = await getV2HamSummaries({
      productId: selectedNode.productId,
      associatedBusinessId,
      accessToken,
    });

    if (e.value === PIN_TO_CURRENT) {
      currentHam = existingHams
        ? find(
            existingHams._embedded.item,
            (ham) => isEmpty(ham.constraintId) && ham.productUrl === getCurrentVersionLink()
          )
        : null;
      productLinkUrl = getCurrentVersionLink();
    } else {
      const existingHams = await getV2HamSummaries({
        productId: selectedNode.productId,
        associatedBusinessId,
        accessToken,
      });

      currentHam = existingHams
        ? find(
            existingHams._embedded.item,
            (ham) => isEmpty(ham.constraintId) && ham.productUrl === fullSelectedVersion.url
          )
        : null;
      productLinkUrl = fullSelectedVersion.url;
    }

    if (!isEmpty(currentHam)) {
      const node = addHamUrl(currentHam);
      const nodeWithId = addProductIdOnAdoptionNode(node);
      onNodeSelect(nodeWithId);
    } else {
      const newHamName = `${selectedNode.name} Version-${e.value}`;
      const createHamV2Response = await createV2AdoptionHam({
        selectedNode,
        newHamName,
        associatedBusinessId,
        productLinkUrl,
        accessToken,
      });
      const node = addHamUrl(createHamV2Response);
      const nodeWithId = addProductIdOnAdoptionNode(node);

      onNodeSelect(nodeWithId);
    }
  };

  const onAccordionHeaderClick = (event, isNowOpen) => {
    setOpenHamSelector(isNowOpen);
  };

  const openConditionSetManager = async (url) => {
    const userData = {}; // Populate if we ever want to pass data via ucn.
    const ucnNavigateResponse = await navigate({
      userData,
      origin: window.location.href,
      destination: url,
      accessToken,
    });

    if (!isEmpty(ucnNavigateResponse.error)) {
      console.error(ucnNavigateResponse.error.message);
    } else {
      // Replace with this when FCA page supports preserving state when navigating away
      // window.location.assign(ucnNavigateResponse.destination);
      window.open(ucnNavigateResponse.destination, '_blank').focus();
    }
  };

  const generateConditionManagerUrl = (action, nodeId) => {
    const { productId } = selectedNode;
    let url = new URL(conditionSetManagerUrl + '/condition-set');

    associatedBusinessId && url.searchParams.append('businessId', associatedBusinessId);
    nodeId && url.searchParams.append('hamId', nodeId);
    productId && url.searchParams.append('productId', productId);
    url.searchParams.append('hamAction', action);

    openConditionSetManager(url);
  };

  const onEditConditions = () => {
    let url = new URL(conditionSetManagerUrl + '/condition-set');

    associatedBusinessId && url.searchParams.append('businessId', associatedBusinessId);
    queryProductId && url.searchParams.append('productId', queryProductId);
    url.searchParams.append('hamAction', hamAction.CREATE);

    openConditionSetManager(url);
  };

  const onCreateNewChildCondition = (node) => {
    const { productId } = node;
    let url = new URL(conditionSetManagerUrl + '/condition-set');

    associatedBusinessId && url.searchParams.append('businessId', associatedBusinessId);
    productId && url.searchParams.append('productId', productId);

    //hack for condition set manager to open the expected view for adoption & product
    productId && url.searchParams.append('hamId', productId);

    url.searchParams.append('hamAction', hamAction.CREATE_CHILD);

    openConditionSetManager(url);
  };

  const onRefresh = () => {
    setHamId(selectedNode.id);
    setRefreshTree(true);
  };

  return (
    <div>
      {loader ? (
        <Loading />
      ) : (
        <Accordion
          onHeaderClick={onAccordionHeaderClick}
          customOpen={openHamSelector}
          title={
            <Fragment>
              <span>{selectorTitle}</span>
              <span className={seeDetailsStyle}>
                {openHamSelector ? formatMessage(messages.close) : formatMessage(messages.seeDetails)}
              </span>
            </Fragment>
          }
          headerStyle={{ background: 'white' }}
          bodyStyle={{ padding: '0 0 30px 0' }}>
          {type !== selectorType.PREVIEW && (
            <Button variant="anchor" onClick={onEditConditions} className={createNewButton}>
              {formatMessage(messages.createOrEditCondition)}
            </Button>
          )}
          <div className={flexBox}>
            {type !== selectorType.PREVIEW && (
              <Fragment>
                <div className={hamSelectorDiv}>
                  <HamSelector
                    mode={VIEW}
                    treeData={hamTreeData}
                    selectedNode={selectedNode}
                    onSelect={onSelect}
                    hamId={hamId}
                    onCreateNewChildCondition={onCreateNewChildCondition}
                    type={type}
                  />
                </div>
                <div className={refreshButton}>
                  <Tooltip contents={formatMessage(messages.refreshHamTree)}>
                    <IconSynchronizeArrowsAlt onClick={onRefresh} size="2x" style={{ cursor: 'pointer' }} />
                  </Tooltip>
                </div>
              </Fragment>
            )}
            {showAncestorAccordion && (
              <div className={hamViewDiv}>
                <AncestorAccordion
                  accessToken={accessToken}
                  ancestors={nodePath}
                  setRefreshHamTree={false}
                  mode={VIEW}
                  onAdoptionVersionChange={onAdoptionVersionChange}
                  onOpenConditionSetManager={generateConditionManagerUrl}
                  selectorTitle={selectorTitle}
                  type={type}
                />
              </div>
            )}
          </div>
        </Accordion>
      )}
    </div>
  );
};
