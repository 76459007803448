import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { cx, css } from 'emotion';

import TextField from '@cimpress/react-components/lib/TextField';
import Button from '@cimpress/react-components/lib/Button';

import { ValidationResultDisplay } from '../../shared/ValidationResultDisplay';
import InvalidInputResults from '../../shared/InvalidInputResults';

import { valueShape } from '../../shared/propTypes';

import { validateRangeString, validateRangeValues } from '../../shared/validators/rangeValidators';
import { mapRangeToText } from '../../shared/mappers/range';

import { twentyPxMarginRight } from '../../shared/commonStyles';
import { flexParent } from '../../shared/flexStyles';

import messages from './messages';

const textFieldHeight = css`
  height: 48px;
`;
const flexOne = css`
  flex: 1;
  min-width: 100px;
`;
const flexTwo = css`
  flex: 2;
  min-width: 100px;
`;
const maxRangeFormWidth = css`
  max-width: 600px;
`;

const UPDATE_MINMAX = 'UPDATE_MINMAX';
const UPDATE_INCREMENT = 'UPDATE_INCREMENT';
const RESET_RANGE_STATE = 'RESET_RANGE_STATE';

const MIN_MAX_REGEX = /^([^-]+)((-)?(.*)|[+])?$/i;
const MIN_IDX = 1;
const HYPHEN_IDX = 3;
const MAX_IDX = 4;

const computeRangeString = ({ minMax, increment }) => {
  const matches = minMax.trim().match(MIN_MAX_REGEX);

  if (matches) {
    return mapRangeToText({
      minimum: matches[MIN_IDX],
      maximum: matches[HYPHEN_IDX] ? matches[MAX_IDX] : matches[MIN_IDX],
      increment,
    });
  }

  return '';
};

const computeRangeState = (state, action) => {
  switch (action.type) {
    case UPDATE_MINMAX:
      return {
        ...state,
        minMax: action.payload,
      };
    case UPDATE_INCREMENT:
      return {
        ...state,
        increment: action.payload,
      };
    case RESET_RANGE_STATE:
      return { minMax: '', increment: '' };
    default:
      throw new Error('Unknown action type received');
  }
};

export const RangeValueEditor = ({ existingValues, validValues, onValueAdded, enforceValidValues }) => {
  const { formatMessage } = useIntl();

  const [rangeState, updateRangeState] = useReducer(computeRangeState, { minMax: '', increment: '' });
  const [validationState, updateValidationState] = useState({});

  useEffect(() => {
    const rangeString = computeRangeString(rangeState);

    // Compute range string and update based on result
    const updatedValidationState = validateRangeString(rangeString, existingValues, validValues, enforceValidValues);

    updateValidationState(updatedValidationState);
  }, [rangeState, existingValues, validValues, enforceValidValues]);

  const onFormSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onValueAdded({ type: validationState.type, [validationState.type]: validationState[validationState.type] });
    updateRangeState({ type: RESET_RANGE_STATE });
  };

  const invalidInputs = enforceValidValues ? validateRangeValues(existingValues, validValues, enforceValidValues) : [];

  return (
    <div>
      <form className={cx(flexParent, maxRangeFormWidth)} onSubmit={onFormSubmit}>
        <TextField
          className={cx(flexTwo, twentyPxMarginRight)}
          label={formatMessage(messages.minMax)}
          value={rangeState.minMax}
          onChange={(e) => updateRangeState({ type: UPDATE_MINMAX, payload: e.target.value })}
          data-testid="MinMaxField"
        />

        <TextField
          className={cx(flexOne, twentyPxMarginRight)}
          label={formatMessage(messages.incrementWithoutUnit)}
          value={rangeState.increment}
          onChange={(e) => updateRangeState({ type: UPDATE_INCREMENT, payload: e.target.value })}
          data-testid="IncrementField"
        />

        <Button
          type="default"
          className={textFieldHeight}
          disabled={
            (validationState.range === undefined && validationState.value === undefined) ||
            validationState.errors.length !== 0 ||
            (invalidInputs && invalidInputs.length !== 0)
          }
          data-testid="AddRangeButton">
          {formatMessage(messages.addNew)}
        </Button>
      </form>

      <ValidationResultDisplay errors={validationState.errors} warnings={validationState.warnings} />
      <InvalidInputResults invalidInputs={invalidInputs} />
    </div>
  );
};

RangeValueEditor.propTypes = {
  existingValues: PropTypes.arrayOf(PropTypes.shape(valueShape)),
  validValues: PropTypes.arrayOf(PropTypes.shape(valueShape)),
  onValueAdded: PropTypes.func.isRequired,
  enforceValidValues: PropTypes.bool,
};

RangeValueEditor.defaultProps = {
  existingValues: [],
  enforceValidValues: false,
};
