import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';

export const sanitizeArray = (itemsToSanitize, blackListedKeys) => {
  let sanitizedItems = [];
  forEach(itemsToSanitize, (item) => {
    if (item && typeof item === 'object') {
      sanitizedItems.push(removeBlacklistedKeys(item, blackListedKeys));
    } else {
      sanitizedItems.push(item);
    }
  });
  return sanitizedItems;
};

export const removeBlacklistedKeys = (objectToSanitize, blackListedKeys) => {
  let objectClone = {};
  forEach(Object.keys(objectToSanitize), (key) => {
    if (Array.isArray(objectToSanitize[key])) {
      objectClone[key] = sanitizeArray(objectToSanitize[key], blackListedKeys);
    } else if (!blackListedKeys.includes(key)) {
      switch (typeof objectToSanitize[key]) {
        case 'string':
          objectClone[key] = objectToSanitize[key];
          break;
        case 'object':
          if (objectToSanitize[key] !== null) {
            objectClone[key] = removeBlacklistedKeys(objectToSanitize[key], blackListedKeys);
          }
          break;
        case 'undefined':
          break;
        default:
          objectClone[key] = objectToSanitize[key];
      }
    }
  });
  return objectClone;
};

export const removeTemplateKeysAndConditionIdsInRelatedResources = (relatedResources) => {
  var clone = cloneDeep(relatedResources);
  forEach(Object.keys(clone), (key) => {
    forEach(clone[key], (objectToCheck) => {
      forEach(objectToCheck.conditions, (condition) => {
        condition.id = null;
        forEach(condition.values, (value) => {
          value.id = null;
        });
      });
      if (objectToCheck.keys !== undefined) {
        objectToCheck.keys.forEach((relatedKey) => {
          relatedKey.id = undefined;
        });
      }
    });
  });

  return clone;
};
