import isEmpty from 'lodash/isEmpty';

import { productTypes, errorTypes } from '../constants/searchConstants';
import { getSku, getFamily, getFamilyBySku } from '../../../services/introduction';
import { getCategoryByFamily } from '../../../services/catalogSchema';

export const checkV1Product = async (accessToken, productId) => {
  const isV1Product = await getSku({ productId, accessToken }).catch((err) => {
    return null;
  });
  if (isV1Product) {
    if (!isV1Product.status === 'NonOrderable') {
      return {
        productType: productTypes.V1_FULFILLMENT,
        productId,
        errorReason: errorTypes.PRODUCT_IS_RETIRED,
      };
    } else {
      const productInfo = {
        productType: productTypes.V1_FULFILLMENT,
        productId,
        name: isV1Product.name,
        description: isV1Product.description,
        fulfiller: isV1Product.externalFulfillerId,
        link: `https://productmanager.products.cimpress.io/rules/product/edit?mcpSku=${productId}`,
      };

      const familyId = await getFamilyBySku({ productId, accessToken }).catch((err) => {
        console.error(err);
        return productInfo;
      });

      if (familyId && familyId.length > 0) {
        const category = await getCategoryByFamily({ familyId: familyId[0].familyReferenceId, accessToken }).catch(
          (err) => {
            console.error(err);
            return productInfo;
          }
        );

        if (category && category.results && category.results.length > 0) {
          productInfo.category = {
            name: category.results[0].name,
            id: category.results[0].categoryId,
          };
        }

        return productInfo;
      }
    }
  } else {
    const isV1Family = await getFamily({ productId, accessToken }).catch((err) => {
      console.error(err);
      return {};
    });
    if (!isEmpty(isV1Family)) {
      return {
        productType: productTypes.V1_FULFILLMENT,
        productId,
        errorReason: errorTypes.PRODUCT_IS_FAMILY,
      };
    } else {
      return {
        productType: productTypes.V1_FULFILLMENT,
        productId,
        errorReason: errorTypes.PRODUCT_DOESNT_EXIST,
      };
    }
  }

  return {};
};
