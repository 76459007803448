import React, { Fragment, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { css, cx } from 'emotion';

import { LeftXTag } from '../../../../shared/LeftXTag';
import CatalogTag from './CatalogTag';
import StandardTag from './StandardTag';
import { topNoMargin } from '../../../../shared/commonStyles';
import { anchorButton, anchorButtonInLineText } from '../../constants/css';
import messages from '../messages';
import ProductSearchBar from '../../NewLinkTab/ProductSearchBar';

const positionHeader = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const positionSearch = css`
  margin-top: -25px;
`;

const MAX_TAGS_DISPLAYED = 5;

const SelectedProducts = ({
  searchErrors,
  selectedProducts,
  selectedCatalog,
  accessToken,
  removeSelectedProducts,
  updateSelectedAdoptions,
  removeSelectedCatalog,
  isEditing = true,
  productTypes,
  openDrawerWithSelectedProducts,
  searchBarProps,
}) => {
  const { formatMessage } = useIntl();
  const [showSearch, setShowSearch] = useState(false);

  const selectedProductTags = (!isEmpty(selectedProducts) || !isEmpty(selectedCatalog)) && (
    <div>
      {!isEmpty(selectedCatalog) && (
        <CatalogTag
          products={selectedCatalog.products}
          catalog={selectedCatalog.catalog}
          removable={isEditing}
          openDrawerWithSelectedProducts={openDrawerWithSelectedProducts}
          removeSelectedCatalog={removeSelectedCatalog}
        />
      )}
      {!isEmpty(selectedProducts) &&
        selectedProducts.map((product, index) =>
          index < MAX_TAGS_DISPLAYED && !product.selectedAdoptions && !product.allAdoptions ? (
            <LeftXTag
              data-testid="selectedProductTag"
              label={product.productId + ' - ' + product.name}
              onRemove={() => removeSelectedProducts([product])}
              removable={isEditing}
              key={product.productId + '-' + product.name}
            />
          ) : (
            product.selectedAdoptions &&
            product.allAdoptions && (
              <StandardTag
                key={product.productId + '-' + product.name}
                product={product}
                selectedAdoptions={product.selectedAdoptions}
                allAdoptions={product.allAdoptions}
                removeSelectedStandard={() => removeSelectedProducts([product])}
                updateSelectedAdoptions={updateSelectedAdoptions}
                removable={isEditing}
              />
            )
          )
        )}
      <button className={cx(anchorButtonInLineText)} onClick={openDrawerWithSelectedProducts}>
        {selectedProducts.length > MAX_TAGS_DISPLAYED
          ? formatMessage(messages.seeMoreSelectedProducts, { count: selectedProducts.length - MAX_TAGS_DISPLAYED })
          : formatMessage(messages.seeDetails)}
      </button>
    </div>
  );

  const addMoreProductsLink = (
    <button className={cx(anchorButton)} onClick={() => setShowSearch(true)}>
      {formatMessage(messages.addMoreProducts)}
    </button>
  );

  const productSearch = (
    <div className={cx(positionSearch)}>
      <ProductSearchBar {...searchBarProps} />
    </div>
  );

  useEffect(() => {
    !isEmpty(searchErrors) && setShowSearch(true);
  }, [searchErrors]);

  return (
    <Fragment>
      <div className={cx(positionHeader)}>
        <h3 className={topNoMargin}>{formatMessage(messages.SelectedProducts)}</h3>
        {!showSearch && addMoreProductsLink}
      </div>
      {showSearch && productSearch}
      {selectedProductTags}
    </Fragment>
  );
};

export default SelectedProducts;
