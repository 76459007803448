import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { css } from 'emotion';

import isEqual from 'lodash/isEqual';

import Accordion from '@cimpress/react-components/lib/Accordion';
import Button from '@cimpress/react-components/lib/Button';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { ocean, platinum, white, coal } from '@cimpress/react-components/lib/colors';

import { VIEW } from '../../../../shared/enums/constants';
import messages from '../messages';

const standardDiv = css`
  background-color: ${white};
  border: 1px solid ${platinum};
  border-radius: 3px;
  padding: 10px 16px 10px 16px;
  box-shadow: 0 1px 2px 0 ${platinum};
  color: ${coal};
  margin: 12px 0 12px 0;
`;

const linkStyle = css`
  color: ${ocean.base};
  float: right;
  &:hover {
    cursor: pointer;
  }
`;

export const StandardAccordion = ({ ancestor, headerStyle, bodyStyle, style, index, getStandardId, mode }) => {
  const { formatMessage } = useIntl();
  const [url, setUrl] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getStandardId(ancestor.standardId).then((info) => {
      setUrl(`https://productmanager-v2.products.cimpress.io/standards-overview/${info.standardId}`);
      setLoading(false);
    });
  }, [ancestor.standardId, getStandardId]);

  const standardViewMode = (
    <div className={standardDiv}>
      {ancestor.name}
      <a href={url} className={linkStyle} target="_blank" rel="noreferrer">
        {formatMessage(messages.viewStandard)}
      </a>
    </div>
  );

  // node is a standard and we just want to show link to standard - no version editing allowed.
  // https://standard-definition.products.cimpress.io/v1/standards/CIM-LETTERHEAD
  return isEqual(mode, VIEW) ? (
    standardViewMode
  ) : (
    <Accordion
      key={`standard-${index}`}
      title={<span>{ancestor.name}</span>}
      headerStyle={headerStyle}
      bodyStyle={bodyStyle}
      style={style}>
      {loading ? (
        <Spinner size="medium" />
      ) : (
        <Button href={url} target="_blank" type="link">
          {formatMessage(messages.viewStandard)}
        </Button>
      )}
    </Accordion>
  );
};
