import { defineMessages } from 'react-intl';

export default defineMessages({
  mustProvideRuleName: {
    id: 'optionConstraintEditContainer.mustProvideRuleName',
    defaultMessage: 'Must provide a rule name',
  },
  optionConstraintName: {
    id: 'optionConstraintEditContainer.optionConstraintName',
    defaultMessage: 'Constraint Name',
  },
  ruleConditionMessage: {
    id: 'optionConstraintEditContainer.ruleConditionMessage',
    defaultMessage: 'When a user makes a selection that matches all of these conditions',
  },
  ruleResultMessage: {
    id: 'optionConstraintEditContainer.ruleResultMessage',
    defaultMessage: 'Do all of the following',
  },
  noResultsForRule: {
    id: 'optionConstraintEditContainer.noResultsForRule',
    defaultMessage: 'No results for this rule. Add one to get started.',
  },
  addResult: {
    id: 'optionConstraintEditContainer.addResult',
    defaultMessage: 'Add result',
  },
  conditionalRelationship: {
    id: 'optionConstraintSummary.conditionalRelationship',
    defaultMessage: 'Conditional relationship',
  },
  optionsConstraints: {
    id: 'optionConstraintsPage.optionsConstraints',
    defaultMessage: 'Options Constraints',
  },
  rules: {
    id: 'optionConstraintsOverviewComponent.rules',
    defaultMessage: 'Rules',
  },
  noRulesConfigured: {
    id: 'optionConstraintsOverviewComponent.noRulesConfigured',
    defaultMessage: 'There are no rules configured.',
  },
  noDataSheetsConfigured: {
    id: 'optionConstraintsOverviewComponent.noDataSheetsConfigured',
    defaultMessage: 'There are no data sheets configured.',
  },
  dataSheets: {
    id: 'optionConstraintsOverviewComponent.dataSheets',
    defaultMessage: 'Data Sheets',
  },
  filterConstraints: {
    id: 'optionConstraintsOverviewComponent.filterConstraints',
    defaultMessage: 'Filter by Option or Property Names',
  },
  dataSheet: {
    id: 'ruleOrDataSheetPrompt.dataSheet',
    defaultMessage: 'Data Sheet',
  },
  rule: {
    id: 'ruleOrDataSheetPrompt.rule',
    defaultMessage: 'Rule',
  },
  whatWantToCreate: {
    id: 'ruleOrDataSheetPrompt.whatWantToCreate',
    defaultMessage: 'What would you like to create?',
  },
  backToDataSheetsSummary: {
    id: 'constraintsOverviewComponent.backToDataSheetsSummary',
    defaultMessage: 'Back to Data Sheets Summary',
  },
});
