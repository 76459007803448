import React from 'react';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';

const Loading = () => (
  <div className="container">
    <div className="row">
      <div className="col-md-12 text-center">
        <Spinner />
      </div>
    </div>
  </div>
);

export default Loading;
