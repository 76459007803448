import React, { useState, Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { css } from 'emotion';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { NavTab, NavTabItem } from '@cimpress/react-components';
import Button from '@cimpress/react-components/lib/Button';
import Alert from '@cimpress/react-components/lib/Alert';

import NewLinkTab from './NewLinkTab';
import ExistinglinkTab from './ExistingLinkTab';
import ProductLinkViewCard from './ExistingLinkTab/ProductLinkViewCard';
import { productTypeConsts, aggregationTypes, existingSelectedPlink } from './constants/propInputConstants';
import { queryProductLink, getProductLinkById, followLink } from '../../services/productLinkService';
import { LINK_MODE } from './constants/linkModeConstants';
import { getIdFromHref } from './utilities/helper';
import messages from './messages';

const CREATE_NEW = 'CREATE_NEW';
const USE_EXISTING = 'USE_EXISTING';

const positionButton = css`
  margin-top: 15px;
  float: right;
`;

const ProductLinker = ({
  account,
  productTypes = [productTypeConsts.MERCHANT, productTypeConsts.V2_FULFILLMENT],
  standardsHaveAdoptions = true,
  attributeAggregationStyle = aggregationTypes.SUPERSET,
  accessToken,
  onUseProductLink,
  authProfile,
  readOnly,
  existingProductLink,
  allowCatalogSelection = true,
  getProductsOnLink = () => {},
  onChangeSelectionClick,
}) => {
  const { formatMessage } = useIntl();

  const [selectedTab, setSelectedTab] = useState(CREATE_NEW);
  const [selectedProductLink, setSelectedProductLink] = useState({});
  const [mode, setMode] = useState(LINK_MODE.NONE);
  const [failedToFetchLink, setFailedToFetchLink] = useState(false);

  useEffect(() => {
    const existingLink =
      existingProductLink && Object.keys(existingProductLink).filter((key) => existingProductLink[key] !== '');
    if (existingLink) {
      let fetchKey = existingLink[0];

      if (fetchKey === existingSelectedPlink.LINKID || fetchKey === existingSelectedPlink.HREF) {
        let productLinkId = existingProductLink.linkId
          ? existingProductLink.linkId
          : getIdFromHref(existingProductLink.href);

        getProductLinkById({ productLinkId, accessToken })
          .then((res) => {
            let existingPlink = {
              productLinkName: res.name,
              productLinkHref: get(res, '_links.self.href'),
            };
            if (!isEmpty(existingPlink)) {
              setSelectedProductLink(existingPlink);
              setMode(LINK_MODE.VIEW);
              setSelectedTab(USE_EXISTING);
            } else {
              setFailedToFetchLink(true);
            }
          })
          .catch((e) => {
            setFailedToFetchLink(true);
            console.error(e);
          });
      } else if (fetchKey === existingSelectedPlink.RELATEDRESOURCE) {
        queryProductLink({
          accountId: account && account.id,
          accessToken,
          relatedResourceHref: existingProductLink && existingProductLink[fetchKey],
        })
          .then((res) => {
            let foundPlink = get(res, '_embedded.item[0]');
            if (!isEmpty(foundPlink)) {
              setSelectedProductLink(foundPlink);
              setMode(LINK_MODE.VIEW);
              setSelectedTab(USE_EXISTING);
            } else {
              setFailedToFetchLink(true);
            }
          })
          .catch((e) => {
            setFailedToFetchLink(true);
            console.error(e);
          });
      }
    }
  }, [existingProductLink, accessToken, account]);

  const showCreateNewTab = () => {
    setSelectedTab(CREATE_NEW);
  };
  const showUseExistingTab = () => {
    setMode(LINK_MODE.NONE);
    setSelectedTab(USE_EXISTING);
  };

  const onChangeSelection = () => {
    setMode(LINK_MODE.NONE);
    setSelectedTab(USE_EXISTING);
    onChangeSelectionClick();
  };

  const onEditProductLink = (link) => {
    followLink({ href: link.productLinkHref, accessToken })
      .then((response) => {
        !isEmpty(response.relatedResources) ? setMode(LINK_MODE.COPY) : setMode(LINK_MODE.EDIT);
        setSelectedProductLink(link);
        showCreateNewTab();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const onSelectProductLink = (link) => {
    setSelectedProductLink(link);
    setMode(LINK_MODE.VIEW);
    onUseProductLink(link);
  };

  const newLinkContent = (
    <NewLinkTab
      account={account}
      productTypes={productTypes}
      standardsHaveAdoptions={standardsHaveAdoptions}
      attributeAggregationStyle={attributeAggregationStyle}
      mode={mode}
      setMode={setMode}
      selectedProductLink={selectedProductLink}
      accessToken={accessToken}
      onSelectProductLink={onSelectProductLink}
      allowCatalogSelection={allowCatalogSelection}
      selectionsChanged={getProductsOnLink}
    />
  );

  const existingLinkContent = (
    <ExistinglinkTab
      account={account}
      productTypes={productTypes}
      standardsHaveAdoptions={standardsHaveAdoptions}
      attributeAggregationStyle={attributeAggregationStyle}
      accessToken={accessToken}
      setSelectedProductLink={setSelectedProductLink}
      onEditProductLink={onEditProductLink}
      onSelectProductLink={onSelectProductLink}
      selectedProductLink={selectedProductLink}
      authProfile={authProfile}
      allowCatalogSelection={allowCatalogSelection}
      selectionsChanged={getProductsOnLink}
    />
  );

  const viewSelectedProductLink = (
    <div>
      <ProductLinkViewCard
        previewProductLink={selectedProductLink}
        accessToken={accessToken}
        account={account}
        productTypes={productTypes}
        standardsHaveAdoptions={standardsHaveAdoptions}
      />
      {!readOnly && (
        <Button type="default" onClick={onChangeSelection} className={positionButton}>
          {formatMessage(messages.changeSelection)}
        </Button>
      )}
    </div>
  );

  const viewFailedToFetchLink = (
    <div>
      <Alert message={formatMessage(messages.failedToFetch)} type="danger" dismissable={false} />
    </div>
  );

  return (
    <Fragment>
      {mode === LINK_MODE.VIEW ? (
        failedToFetchLink ? (
          viewFailedToFetchLink
        ) : (
          viewSelectedProductLink
        )
      ) : (
        <Fragment>
          <NavTab>
            <NavTabItem active={selectedTab === USE_EXISTING}>
              <button onClick={showUseExistingTab}>{formatMessage(messages.useExistingProductLinkTab)}</button>
            </NavTabItem>
            <NavTabItem active={selectedTab === CREATE_NEW}>
              <button onClick={showCreateNewTab}>{formatMessage(messages.createNewLinkTab)}</button>
            </NavTabItem>
          </NavTab>
          {selectedTab === CREATE_NEW ? newLinkContent : existingLinkContent}
        </Fragment>
      )}
    </Fragment>
  );
};

export default ProductLinker;
