import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { atttributeShape, valueShape } from '../../shared/propTypes';
import { VALUE } from '../../shared/enums/options';

import { ValidValuesHeader } from '../../shared/ValidValuesHeader';
import { RangeValueEditor } from './RangeValueEditor';
import { RangeValuesDisplay } from './RangeValuesDisplay';

export const RangeValuesEditor = ({ attribute, values, onValuesChanged, enforceValidValues }) => {
  const onValueAdded = (newValue) => {
    const updatedRanges = [...values, newValue];

    onValuesChanged(updatedRanges);
  };

  const onValueRemoved = (removedRangeValue) => {
    const updatedRanges = values.filter((rangeValue) => {
      if (removedRangeValue.type === VALUE) {
        return rangeValue.type === VALUE
          ? rangeValue.value !== removedRangeValue.value
          : rangeValue.minimum !== removedRangeValue.value && rangeValue.maximum !== removedRangeValue.value;
      } else {
        // else removedRangeValue.type === RANGE
        return rangeValue.type === VALUE
          ? rangeValue.value !== removedRangeValue.range.minimum && rangeValue.value !== removedRangeValue.range.maximum
          : rangeValue.range.minimum !== removedRangeValue.range.minimum ||
              rangeValue.range.maximum !== removedRangeValue.range.maximum ||
              rangeValue.range.increment !== removedRangeValue.range.increment;
      }
    });

    onValuesChanged(updatedRanges);
  };

  return (
    <Fragment>
      {attribute ? <ValidValuesHeader attribute={attribute} /> : null}

      <RangeValueEditor
        existingValues={values}
        validValues={attribute ? attribute.values : null}
        onValueAdded={onValueAdded}
        enforceValidValues={enforceValidValues}
      />

      <RangeValuesDisplay values={values} onValueRemoved={onValueRemoved} />
    </Fragment>
  );
};

RangeValuesEditor.propTypes = {
  attribute: PropTypes.shape(atttributeShape),
  values: PropTypes.arrayOf(PropTypes.shape(valueShape)),
  onValuesChanged: PropTypes.func.isRequired,
  enforceValidValues: PropTypes.bool,
};

RangeValuesEditor.defaultProps = {
  values: [],
  enforceValidValues: false,
};
