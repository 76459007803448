import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { cx } from 'emotion';

import isEmpty from 'lodash/isEmpty';
import { LeftXTag } from '../../../../shared/LeftXTag';
import { anchorButtonInLineText } from '../../constants/css';

import messages from './messages';

let MAX_TAGS_DISPLAYED = 5;

const SelectedProducts = ({ selectedCatalog, selectedProducts, openDrawer, setIsCatalogView }) => {
  const { formatMessage } = useIntl();
  const { catalog, products } = selectedCatalog;

  const [maxTagsDisplayed, setMaxTagsDisplayed] = useState(MAX_TAGS_DISPLAYED);

  const onSeeMoreToggle = () => {
    if (maxTagsDisplayed < selectedProducts.length) {
      openDrawer();
    } else {
      setMaxTagsDisplayed(MAX_TAGS_DISPLAYED);
    }
  };

  const viewCatalog = () => {
    setIsCatalogView(true);
    openDrawer();
  };

  const selectedProductTags = (
    <div>
      {!isEmpty(catalog) && !isEmpty(products) && (
        <LeftXTag
          data-testid="selectedCatalogTag"
          label={
            <span>
              {formatMessage(messages.catalogTag, { name: catalog.name })}
              <button className={cx(anchorButtonInLineText)} onClick={viewCatalog}>
                {Object.keys(products).length} products
              </button>
            </span>
          }
          key={catalog.id + '-' + catalog.name}
          removable={false}
        />
      )}

      {!isEmpty(selectedProducts) && (
        <Fragment>
          {selectedProducts.map(
            (product, index) =>
              index < maxTagsDisplayed && (
                <LeftXTag
                  data-testid="selectedProductTag"
                  label={product.productId + '-' + product.name}
                  key={product.productId + '-' + product.name}
                  removable={false}
                />
              )
          )}
          {selectedProducts.length > MAX_TAGS_DISPLAYED && (
            <button className={anchorButtonInLineText} onClick={onSeeMoreToggle}>
              {selectedProducts.length > maxTagsDisplayed
                ? formatMessage(messages.seeXMore, { count: selectedProducts.length - maxTagsDisplayed })
                : formatMessage(messages.seeLess)}
            </button>
          )}
        </Fragment>
      )}
    </div>
  );

  return <Fragment>{selectedProductTags}</Fragment>;
};

export default SelectedProducts;
