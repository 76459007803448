import isEmpty from 'lodash/isEmpty';

import { findAddedKeysToDict, findRemovedKeysFromDict, removeAddedAndRemoved } from './sharedUtils';

const VALUE_SA_TYPE = 'value';
const FORMULA_SA_TYPE = 'formula';

export const ADDED_PROPERTY = 'added property';
export const REMOVED_PROPERTY = 'removed property';
export const UPDATED_PROPERTY = 'updated property';
export const IDENTICAL = 'identical';
export const TYPE_DIFF = 'type diff';
export const VALUE_DIFF = 'value diff';
export const STATIC_TO_CONDITIONAL = 'static to conditional';
export const CONDITIONAL_TO_STATIC = 'conditional to static';
export const ASSIGNMENT = 'assignment';

const JSON_DIFF_BUT_IDENTICAL = 'jsonDiff but identical';
const SA_UNKNOWN = 'static assignment unknown';

export const getPropertyDiffs = (oldProperties, newProperties) => {
  let oldPropertiesDict = {};
  oldProperties.forEach((x) => {
    oldPropertiesDict[x.name] = stripIdsFromProperty(x);
  });

  let newPropertiesDict = {};
  newProperties.forEach((x) => {
    newPropertiesDict[x.name] = stripIdsFromProperty(x);
  });

  const propertyDiffs = {};
  const addedPropertyNames = {};

  Object.keys(findAddedKeysToDict(oldPropertiesDict, newPropertiesDict)).forEach((key) => {
    propertyDiffs[key] = {
      diffType: ADDED_PROPERTY,
      oldProperty: null,
      newProperty: newPropertiesDict[key],
    };
    addedPropertyNames[key] = true;
  });

  const removedPropertyNames = {};
  Object.keys(findRemovedKeysFromDict(oldPropertiesDict, newPropertiesDict)).forEach((key) => {
    propertyDiffs[key] = {
      diffType: REMOVED_PROPERTY,
      oldProperty: oldPropertiesDict[key],
      newProperty: null,
    };
    removedPropertyNames[key] = true;
  });

  // find all old and new properties that are were not added or removed completely from the property set
  newPropertiesDict = removeAddedAndRemoved(newPropertiesDict, addedPropertyNames);
  oldPropertiesDict = removeAddedAndRemoved(oldPropertiesDict, removedPropertyNames);

  // find all properties in old and new sets that have static assignments
  const newPropertiesStaticAssignments = filterProperties(newPropertiesDict, (v) => v.staticAssignment !== undefined);
  const oldPropertiesStaticAssignments = filterProperties(oldPropertiesDict, (v) => v.staticAssignment !== undefined);

  // find all properties in old and new sets that have conditional assignments
  const newPropertiesAssignments = filterProperties(newPropertiesDict, (v) => v.staticAssignment === undefined);
  const oldPropertiesAssignments = filterProperties(oldPropertiesDict, (v) => v.staticAssignment === undefined);

  // find all properties in old and new sets that used to have static assignments and now are conditional or vice versa
  // were conditional, now are static
  const addedStaticAssignments = findAddedKeysToDict(oldPropertiesStaticAssignments, newPropertiesStaticAssignments);
  Object.keys(addedStaticAssignments).forEach((key) => {
    const valueConditionMapping = getValuesConditionMappings(oldPropertiesAssignments[key], 'removedConditions');

    propertyDiffs[key] = {
      diffType: CONDITIONAL_TO_STATIC,
      oldProperty: oldPropertiesAssignments[key],
      newProperty: newPropertiesStaticAssignments[key],
      differenceDetails: {
        valuesConditionMappings: valueConditionMapping,
      },
    };
  });

  // were static, now are conditional
  const removedStaticAssignments = findRemovedKeysFromDict(
    oldPropertiesStaticAssignments,
    newPropertiesStaticAssignments
  );
  Object.keys(removedStaticAssignments).forEach((key) => {
    const valueConditionMapping = getValuesConditionMappings(newPropertiesAssignments[key], 'addedConditions');
    propertyDiffs[key] = {
      diffType: STATIC_TO_CONDITIONAL,
      oldProperty: oldPropertiesStaticAssignments[key],
      newProperty: newPropertiesAssignments[key],
      differenceDetails: {
        valuesConditionMappings: valueConditionMapping,
      },
    };
  });

  const changedStaticAssignments = {};
  Object.keys(newPropertiesStaticAssignments).forEach((key) => {
    if (!removedStaticAssignments[key] && !addedStaticAssignments[key]) {
      if (JSON.stringify(oldPropertiesStaticAssignments[key]) === JSON.stringify(newPropertiesStaticAssignments[key])) {
        propertyDiffs[key] = {
          diffType: IDENTICAL,
          oldProperty: oldPropertiesStaticAssignments[key],
          newProperty: oldPropertiesStaticAssignments[key],
        };
      } else {
        changedStaticAssignments[key] = {
          oldProperty: oldPropertiesStaticAssignments[key],
          newProperty: newPropertiesStaticAssignments[key],
        };
      }
    }
  });

  const changedAssignments = {};
  Object.keys(newPropertiesAssignments).forEach((key) => {
    if (!removedStaticAssignments[key] && !addedStaticAssignments[key]) {
      if (JSON.stringify(oldPropertiesAssignments[key]) === JSON.stringify(newPropertiesAssignments[key])) {
        propertyDiffs[key] = {
          diffType: IDENTICAL,
          oldProperty: oldPropertiesAssignments[key],
          newProperty: oldPropertiesAssignments[key],
        };
      } else {
        changedAssignments[key] = {
          oldProperty: oldPropertiesAssignments[key],
          newProperty: newPropertiesAssignments[key],
        };
      }
    }
  });

  getStaticAssignmentDiffs(changedStaticAssignments, propertyDiffs);
  getAssignmentDiffs(changedAssignments, propertyDiffs);

  return propertyDiffs;
};

export const getAssignmentDiffs = (changedAssignments, propertyDiffs) => {
  Object.keys(changedAssignments).forEach((name) => {
    const oldP = {};
    const newP = {};
    changedAssignments[name].oldProperty.assignments.forEach((assignment) => {
      const valueKey = JSON.stringify(assignment.result);
      const conditionsHash = JSON.stringify(assignment.conditions);
      if (!oldP[valueKey]) {
        oldP[valueKey] = [];
      }
      oldP[valueKey].push(conditionsHash);
    });

    changedAssignments[name].newProperty.assignments.forEach((assignment) => {
      const valueKey = JSON.stringify(assignment.result);
      const conditionsHash = JSON.stringify(assignment.conditions);
      if (!newP[valueKey]) {
        newP[valueKey] = [];
      }
      newP[valueKey].push(conditionsHash);
    });

    if (JSON.stringify(changedAssignments[name].oldProperty) === JSON.stringify(changedAssignments[name].newProperty)) {
      propertyDiffs[name] = {
        diffType: IDENTICAL,
        oldProperty: changedAssignments[name].oldProperty,
        newProperty: changedAssignments[name].newProperty,
      };
    } else {
      const diffs = checkAssignmentsForDiffs(oldP, newP);
      let isIdentical = isEmpty(diffs.addedValues) && isEmpty(diffs.removedValues);
      Object.values(diffs.valuesConditionMappings).forEach((valueConditionMapping) => {
        isIdentical =
          isIdentical &&
          isEmpty(valueConditionMapping.addedConditions) &&
          isEmpty(valueConditionMapping.removedConditions);
      });

      propertyDiffs[name] = {
        diffType: isIdentical ? IDENTICAL : ASSIGNMENT,
        oldProperty: changedAssignments[name].oldProperty,
        newProperty: changedAssignments[name].newProperty,
        differenceDetails: diffs,
      };
    }
  });
};

export const checkAssignmentsForDiffs = (oldP, newP) => {
  // values can add or remove
  // conditions on values can be added or removed
  const addedValues = {};
  const removedValues = {};
  const valuesConditionMappings = {};
  Object.keys(oldP).forEach((valueHash) => {
    if (!newP[valueHash]) {
      // if removed value
      removedValues[valueHash] = oldP[valueHash];
    } else {
      // find removed conditions and identical conditions for shared values
      oldP[valueHash].forEach((conditionHash) => {
        if (!valuesConditionMappings[valueHash]) {
          valuesConditionMappings[valueHash] = {
            removedConditions: [],
            addedConditions: [],
            identicalConditions: [],
          };
        }
        if (newP[valueHash] && !newP[valueHash].includes(conditionHash)) {
          valuesConditionMappings[valueHash].removedConditions.push(conditionHash);
        } else {
          valuesConditionMappings[valueHash].identicalConditions.push(conditionHash);
        }
      });
    }
  });

  Object.keys(newP).forEach((valueHash) => {
    if (!oldP[valueHash]) {
      // if added value
      addedValues[valueHash] = newP[valueHash];
    } else {
      // find added conditions for shared values
      newP[valueHash].forEach((conditionHash) => {
        if (oldP[valueHash] && !oldP[valueHash].includes(conditionHash)) {
          valuesConditionMappings[valueHash].addedConditions.push(conditionHash);
        }
      });
    }
  });

  return {
    addedValues,
    removedValues,
    valuesConditionMappings,
  };
};

export const getStaticAssignmentDiffs = (changedStaticAssignments, propertyDiffs) => {
  Object.keys(changedStaticAssignments).forEach((name) => {
    const oldP = changedStaticAssignments[name].oldProperty;
    const newP = changedStaticAssignments[name].newProperty;

    propertyDiffs[name] = checkStaticAssignmentForDiffs(oldP, newP);
  });
};

export const checkStaticAssignmentForDiffs = (oldP, newP) => {
  const oldSA = oldP.staticAssignment;
  const newSA = newP.staticAssignment;

  const noDiff = {
    diffType: JSON_DIFF_BUT_IDENTICAL,
    oldProperty: oldP,
    newProperty: newP,
  };

  if (oldP.type !== newP.type) {
    noDiff.diffType = TYPE_DIFF;
  }

  if (oldSA.type === newSA.type) {
    if (oldSA.type === VALUE_SA_TYPE) {
      if (oldSA.value === newSA.value) {
        return noDiff;
      } else {
        return {
          diffType: VALUE_DIFF,
          oldProperty: oldP,
          newProperty: newP,
          differenceDetails: {
            oldValue: oldSA.value,
            newValue: newSA.value,
          },
        };
      }
    } else if (oldSA.type === FORMULA_SA_TYPE) {
      if (oldSA.formula === newSA.formula) {
        return noDiff;
      } else {
        return {
          diffType: VALUE_DIFF,
          oldProperty: oldP,
          newProperty: newP,
          differenceDetails: {
            oldValue: oldSA.value,
            newValue: newSA.value,
          },
        };
      }
    } else {
      // edge case here if new type added that we haven't handled yet
      return {
        oldProperty: oldP,
        newProperty: newP,
        diffType: SA_UNKNOWN,
      };
    }
  } else {
    return {
      diffType: TYPE_DIFF,
      oldProperty: oldP,
      newProperty: newP,
      differenceDetails: {
        oldType: oldSA.type,
        newType: newSA.type,
        oldvalue: oldSA.value,
        newValue: newSA.value,
      },
    };
  }
};

export const filterProperties = (properties, compareFunc) => {
  const asArray = Object.entries(properties);
  const filtered = asArray.filter(([key, value]) => compareFunc(value));
  const justStaticAssignments = {};

  filtered.forEach((array) => {
    justStaticAssignments[array[0]] = array[1];
  });

  return justStaticAssignments;
};

export const stripIdsFromProperty = (property) => {
  const noIds = {
    ...property,
    id: undefined,
    staticAssignment: property.staticAssignment ? { ...property.staticAssignment, id: undefined } : undefined,
    assignments: property.assignments
      ? property.assignments.map((a) => {
          return {
            conditions: a.conditions.map((c) => {
              return {
                ...c,
                values: c.values.map((v) => {
                  return {
                    ...v,
                    id: undefined,
                  };
                }),
                id: undefined,
              };
            }),
            result: {
              ...a.result,
              id: undefined,
            },
          };
        })
      : undefined,
  };

  return noIds;
};

const getValuesConditionMappings = (oldAssignments, key) => {
  let valueConditionMapping = {};
  oldAssignments.assignments.forEach((assignment) => {
    const assignmentHash = JSON.stringify(assignment.result);

    //Make sure both are initialized
    valueConditionMapping[assignmentHash] = valueConditionMapping[assignmentHash] || {};
    valueConditionMapping[assignmentHash][key] = valueConditionMapping[assignmentHash][key] || [];

    const conditions = assignment.conditions.map((condition) => {
      return JSON.stringify(condition);
    });
    valueConditionMapping[assignmentHash][key].push(conditions);
  });
  return valueConditionMapping;
};
