import React, { Fragment } from 'react';

import SearchProductLink from './SearchproductLink';

const ExistinglinkTab = ({
  account,
  productTypes,
  standardsHaveAdoptions,
  accessToken,
  onEditProductLink,
  onSelectProductLink,
  selectedProductLink,
  authProfile,
  allowCatalogSelection,
  selectionsChanged,
}) => {
  return (
    <Fragment>
      <SearchProductLink
        onEditProductLink={onEditProductLink}
        onSelectProductLink={onSelectProductLink}
        account={account}
        accessToken={accessToken}
        selectedProductLink={selectedProductLink}
        productTypes={productTypes}
        standardsHaveAdoptions={standardsHaveAdoptions}
        authProfile={authProfile}
        allowCatalogSelection={allowCatalogSelection}
        selectionsChanged={selectionsChanged}
      />
    </Fragment>
  );
};

export default ExistinglinkTab;
