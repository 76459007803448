import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { css } from 'emotion';

import Select from '@cimpress/react-components/lib/Select';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';

import { getAllRelatedResourceTypes } from '../../../../services/productLinkService';
import messages from '../messages';

const center = css`
  text-align: center;
`;

const RelatedResourcesDropdown = ({ selectedRelatedResourceType, setSelectedRelatedResourceType, accessToken }) => {
  const { formatMessage } = useIntl();
  const [relatedResourceTypes, setRelatedResourceTypes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const types = await getAllRelatedResourceTypes({ accessToken });
      setLoading(false);
      setRelatedResourceTypes(types);
    })();
  }, [accessToken]);

  return loading ? (
    <Spinner className={center} size="medium" />
  ) : (
    <Select
      isClearable
      label={formatMessage(messages.relatedResource)}
      value={selectedRelatedResourceType}
      options={relatedResourceTypes.map((relatedResourceType) => {
        return { value: relatedResourceType.relatedResourceType, label: relatedResourceType.name };
      })}
      onChange={setSelectedRelatedResourceType}
    />
  );
};

export default RelatedResourcesDropdown;
