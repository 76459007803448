import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { cx } from 'emotion';
import cloneDeep from 'lodash/cloneDeep';
import without from 'lodash/without';

import Button from '@cimpress/react-components/lib/Button';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';

import ConditionEditor from './ConditionSetConditionEditor';

import { optionShape, propertyConditionAttributeShape } from '../../../../shared/propTypes';
import { tenPxMarginTop, tenPxMargin, fivePxMarginLeft, threePxMarginTop, flex } from '../../../../shared/commonStyles';
import { conditionSetShape } from '../../../../shared/propTypes';
import { createCondition } from '../conditionSetHelpers';

import messages from '../messages';

const ConditionSetEditView = ({
  conditionSet,
  attributes,
  onChange,
  showNoConditionsMessage = true,
  loadingAttributes = false,
}) => {
  const { formatMessage } = useIntl();

  const currentConditions = conditionSet.conditions ? cloneDeep(conditionSet.conditions) : [];

  const usedAttributes = currentConditions.map((condition) => condition.attribute).filter((c) => c);
  const remainingAttributes = without(Object.keys(attributes), ...usedAttributes);

  const onAddCondition = () => {
    const newConditionSet = cloneDeep(conditionSet);

    if (!newConditionSet.conditions) {
      newConditionSet.conditions = [createCondition()];
    } else {
      newConditionSet.conditions.push(createCondition());
    }

    onChange(conditionSet, newConditionSet);
  };

  const onUpdateCondition = (originalCondition, condition) => {
    const newConditionSet = cloneDeep(conditionSet);

    newConditionSet.conditions = newConditionSet.conditions.map((value) =>
      value.id === originalCondition.id ? condition : value
    );

    onChange(conditionSet, newConditionSet);
  };

  const onRemoveCondition = (condition) => {
    const newConditionSet = cloneDeep(conditionSet);

    newConditionSet.conditions = newConditionSet.conditions.filter((value) => value.id !== condition.id);

    onChange(conditionSet, newConditionSet);
  };

  if (!conditionSet) {
    return null;
  }

  return (
    <div>
      {conditionSet.conditions &&
        conditionSet.conditions.map((condition, i) => (
          <Fragment key={condition.attribute || i}>
            <ConditionEditor
              style={tenPxMarginTop}
              condition={condition}
              conditions={conditionSet.conditions}
              attributes={attributes}
              updateCondition={onUpdateCondition}
              removeCondition={onRemoveCondition}
            />
            {i < conditionSet.conditions.length - 1 && <hr />}
          </Fragment>
        ))}
      {showNoConditionsMessage && !(conditionSet.conditions && conditionSet.conditions.length) && (
        <div>
          <div className="text-muted h5">{formatMessage(messages.noConditionsAddToStart)}</div>
        </div>
      )}
      <Button
        type={conditionSet.conditions && conditionSet.conditions.length > 0 ? 'link' : 'primary'}
        className={tenPxMargin}
        onClick={onAddCondition}
        disabled={!remainingAttributes.length || loadingAttributes}>
        <div className={flex}>
          {formatMessage(messages.addCondition)}{' '}
          {loadingAttributes ? <Spinner className={cx(fivePxMarginLeft, threePxMarginTop)} size="small" /> : null}
        </div>
      </Button>
    </div>
  );
};

ConditionSetEditView.propTypes = {
  conditionSet: PropTypes.shape(conditionSetShape).isRequired,
  attributes: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.shape(optionShape), PropTypes.shape(propertyConditionAttributeShape)])
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ConditionSetEditView;
