export const getConstraintDiffs = (oldConstraints, newConstraints) => {
  const oldConstraintsByName = {};
  const newConstraintsByName = {};
  oldConstraints = stripIdsFromConstraint(oldConstraints);
  newConstraints = stripIdsFromConstraint(newConstraints);

  oldConstraints.rules.forEach((constraint) => {
    oldConstraintsByName[constraint.name] = constraint;
  });
  newConstraints.rules.forEach((constraint) => {
    newConstraintsByName[constraint.name] = constraint;
  });

  const removedConstraints = getRemovedConstraints(oldConstraintsByName, newConstraintsByName);
  const addedConstraints = getAddedConstraints(oldConstraintsByName, newConstraintsByName);
  const { identicalConstraints, updatedConstraints } = getIdenticalAndUpdatedConstraints(
    oldConstraintsByName,
    newConstraintsByName
  );

  return {
    addedConstraints,
    removedConstraints,
    updatedConstraints,
    identicalConstraints,
  };
};

export const getAddedConstraints = (oldConstraintsByName, newConstraintsByName) => {
  const addedConstraints = {};
  Object.keys(newConstraintsByName).forEach((name) => {
    if (!oldConstraintsByName[name]) {
      addedConstraints[name] = newConstraintsByName[name];
    }
  });
  return addedConstraints;
};

export const getRemovedConstraints = (oldConstraintsByName, newConstraintsByName) => {
  const removedConstraints = {};
  Object.keys(oldConstraintsByName).forEach((name) => {
    if (!newConstraintsByName[name]) {
      removedConstraints[name] = oldConstraintsByName[name];
    }
  });
  return removedConstraints;
};

export const getIdenticalAndUpdatedConstraints = (oldConstraintsByName, newConstraintsByName) => {
  const identicalConstraints = {};
  const updatedConstraints = {};
  Object.keys(oldConstraintsByName).forEach((name) => {
    const constraint = oldConstraintsByName[name];
    if (newConstraintsByName[name]) {
      if (JSON.stringify(newConstraintsByName[name]) === JSON.stringify(constraint)) {
        identicalConstraints[name] = constraint;
      } else {
        updatedConstraints[name] = {
          oldConstraint: constraint,
          newConstraint: newConstraintsByName[name],
        };
      }
    }
  });

  return { identicalConstraints, updatedConstraints };
};

export const stripIdsFromConstraint = (constraint) => {
  const noIds = {
    ...constraint,
    id: undefined,
    rules: constraint.rules.map((r) => {
      return {
        ...r,
        id: undefined,
        conditions: r.conditions.map((condition) => {
          return {
            ...condition,
            values: condition.values.map((v) => {
              return {
                ...v,
                id: undefined,
              };
            }),
            id: undefined,
          };
        }),
        results: r.results.map((result) => {
          return {
            ...result,
            values: result.values.map((v) => {
              return {
                ...v,
                id: undefined,
              };
            }),
            id: undefined,
          };
        }),
      };
    }),
  };

  return noIds;
};
