import React from 'react';
import { useIntl } from 'react-intl';
import { css, cx } from 'emotion';

import { errorTypes } from '../../constants/searchConstants';
import { persimmon } from '@cimpress/react-components/lib/colors';
import IconNavigationNext from '@cimpress-technology/react-streamline-icons/lib/IconNavigationNext';

import { anchorButtonNoUnderline } from '../../constants/css';
import messages from '../messages';

const errorDiv = css`
  display: flex;
  align-items: center;
  color: ${persimmon.darker};
  margin-bottom: 8px;
  font-size: 13px;
`;

const spanMessage = css`
  margin-left: 8px;
  margin-right: 8px;
`;

const linkMargin = css`
  margin-top: 0px;
  margin-bottom: -3px;
`;

const SearchErrors = ({ searchResponse }) => {
  const { formatMessage } = useIntl();

  const getErrorMessage = (searchResponse) => {
    let message = '';
    let link = null;
    switch (searchResponse.errorReason) {
      case errorTypes.PRODUCT_DOESNT_EXIST:
        message = formatMessage(messages.productIdInvalid, { productId: searchResponse.productId });
        break;
      case errorTypes.NO_MERCHANT_SEARCH:
        message = formatMessage(messages.noMerchantSearch, { productId: searchResponse.productId });
        break;
      case errorTypes.NO_V2_SEARCH:
        message = formatMessage(messages.noV2Search, { productId: searchResponse.productId });
        break;
      case errorTypes.NO_V1_SEARCH:
        message = formatMessage(messages.noV1Search, { productId: searchResponse.productId });
        break;
      case errorTypes.NO_STANDARD_SEARCH:
        message = formatMessage(messages.noStandardSearch, { productId: searchResponse.productId });
        break;
      case errorTypes.PRODUCT_IS_ADOPTION:
        message = formatMessage(messages.noAdoptions, { productId: searchResponse.productId });
        break;
      case errorTypes.NO_ADOPTIONS:
        message = formatMessage(messages.noAdoptionsInAccount, { productId: searchResponse.productId });
        break;
      case errorTypes.PRODUCT_IS_DRAFT:
        message = formatMessage(messages.noDraftProducts, { productId: searchResponse.productId });
        link = `https://productmanager-v2.products.cimpress.io/product-details-v2/${searchResponse.productId}`;
        break;
      case errorTypes.PRODUCT_IS_FAMILY:
        message = formatMessage(messages.noFamilies, { productId: searchResponse.productId });
        link = `https://catalog.products.cimpress.io/family/${searchResponse.productId}`;
        break;
      case errorTypes.PRODUCT_IS_RETIRED:
        link = `https://productmanager-v2.products.cimpress.io/product-details-v2/${searchResponse.productId}`;
        message = formatMessage(messages.noRetiredProducts, { productId: searchResponse.productId });
        break;
      case errorTypes.API_ERROR:
        message = formatMessage(messages.defaultProductError, { productId: searchResponse.productId });
        break;
      default:
        console.error('unknown error reason: ', searchResponse.errorReason);
        message = formatMessage(messages.defaultProductError, { productId: searchResponse.productId });
    }

    return { message, link };
  };

  const errorMessage = getErrorMessage(searchResponse);

  if (errorMessage) {
    return (
      <div className={errorDiv}>
        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span className={spanMessage}>{errorMessage.message}</span>
        {errorMessage.link ? (
          <a
            className={cx(anchorButtonNoUnderline, linkMargin)}
            href={errorMessage.link}
            target="_blank"
            rel="noopener noreferrer">
            <IconNavigationNext />
          </a>
        ) : null}
      </div>
    );
  }
  return null;
};

export default SearchErrors;
