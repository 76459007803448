import isEmpty from 'lodash/isEmpty';

import RangeValidators from '../validators/rangeValidators';
import { getOptionDiffs } from './options';
import { getPropertyDiffs } from './properties';
import { getConstraintDiffs } from './constraints';
import { standardMetaDataDiffs } from './metadata';
import { mapRangeToText } from '../mappers/range';
import { RANGE, VALUE, NUMBER } from '../enums/options';

export const diffStandardVersions = (oldVersion, newVersion) => {
  const oldOptions = [{ values: oldVersion.quantity, name: 'Quantity' }].concat(oldVersion.options);
  const newOptions = [{ values: newVersion.quantity, name: 'Quantity' }].concat(newVersion.options);
  const optionDiffs = getOptionDiffs(oldOptions, newOptions);
  const propertyDiffs = getPropertyDiffs(oldVersion.properties, newVersion.properties);
  const constraintDiffs = getConstraintDiffs(oldVersion.constraints, newVersion.constraints);
  const metaDiffs = standardMetaDataDiffs(oldVersion, newVersion);

  return {
    optionDiffs,
    propertyDiffs,
    constraintDiffs,
    metaDiffs,
  };
};

export const convertValue = (value) => {
  if (value.type === 'value') {
    return value.value;
  } else {
    return mapRangeToText(value.range);
  }
};

export const convertToStandardOptionTypeDict = (options) => {
  return Object.assign({}, ...options.map((option) => ({ [option.name]: { type: option.type } })));
};

export const getOptionDiffDetails = (optionName, optionDiffs, adoptionOptions) => {
  const valuesToCheckAgainst = [];
  const returnObject = {
    adoptedValues: {},
    valuesToAdopt: {},
    unitOfMeasure: {},
  };

  // if entire option is new
  if (optionDiffs.addedOptions[optionName]) {
    optionDiffs.addedOptions[optionName].forEach((value) => {
      const label = convertValue(value);
      valuesToCheckAgainst.push(value);
      returnObject.valuesToAdopt[label] = { value: { ...value, label }, isNew: false, isDeleted: false }; // change here if you want green circles around all pills for new options
    });
  } else {
    adoptionOptions.forEach((option) => {
      if (option.name === optionName) {
        option.values.forEach((value) => {
          const label = convertValue(value);
          returnObject.adoptedValues[label] = { value: { ...value, label } };
        });
      }
    });

    // if entire option is being deleted then find values to deleted and mark as such
    if (optionDiffs.removedOptions[optionName]) {
      optionDiffs.removedOptions[optionName].forEach((value) => {
        const label = convertValue(value);
        if (returnObject.adoptedValues[label]) {
          returnObject.adoptedValues[label].isDeleted = true;
          returnObject.adoptedValues[label].isNew = false;
        } else {
          returnObject.valuesToAdopt[label] = { value: { ...value, label }, isNew: false, isDeleted: true };
        }
      });
    }

    // all unchanged values
    if (optionDiffs.unchangedValuesByOption[optionName]) {
      optionDiffs.unchangedValuesByOption[optionName].forEach((unchangedValue) => {
        const label = convertValue(unchangedValue);
        valuesToCheckAgainst.push(unchangedValue);
        if (returnObject.adoptedValues[label]) {
          returnObject.adoptedValues[label].isNew = false;
          returnObject.adoptedValues[label].isNew = false;
        } else {
          returnObject.valuesToAdopt[label] = { value: { ...unchangedValue, label }, isNew: false, isDeleted: false };
        }
      });
    }

    // all added values to option default to living in valuesToAdopt
    if (optionDiffs.addedValuesByOption[optionName]) {
      optionDiffs.addedValuesByOption[optionName].forEach((addedValue) => {
        const label = convertValue(addedValue);
        valuesToCheckAgainst.push(addedValue);
        returnObject.valuesToAdopt[label] = { value: { ...addedValue, label }, isNew: true, isDeleted: false };
      });
    }

    // find all values that were deleted and tag them correctly in both adopted and valuesToAdopt
    if (optionDiffs.deletedValuesByOption[optionName]) {
      optionDiffs.deletedValuesByOption[optionName].forEach((removedValue) => {
        const label = convertValue(removedValue);

        if (returnObject.adoptedValues[label]) {
          returnObject.adoptedValues[label].isNew = false;
          returnObject.adoptedValues[label].isDeleted = true;
        } else {
          returnObject.valuesToAdopt[label] = { value: { ...removedValue, label }, isNew: false, isDeleted: true };
        }
      });
    }

    if (!isEmpty(optionDiffs.unitOfMeasureByOption[optionName])) {
      returnObject.unitOfMeasure = optionDiffs.unitOfMeasureByOption[optionName];
    }
  }

  Object.keys(returnObject.adoptedValues).forEach((adoptedValueKey) => {
    const adoptedValue = returnObject.adoptedValues[adoptedValueKey];
    if (adoptedValue.value.type === RANGE || adoptedValue.value.type === VALUE || adoptedValue.value.type === NUMBER) {
      if (
        (adoptedValue.isNew === null || adoptedValue.isNew === undefined) &&
        (adoptedValue.isDeleted === null || adoptedValue.isDeleted === undefined)
      ) {
        let isSubset = false;
        valuesToCheckAgainst.forEach((valueToCheckAgainst) => {
          if (!isSubset) {
            isSubset = RangeValidators.validateValidSubset(adoptedValue.value, valueToCheckAgainst);
            returnObject.adoptedValues[adoptedValueKey].isDeleted = !isSubset;
          }
        });
      }
    }
  });
  return returnObject;
  /* example output
  return {
    adoptedValues: {
      'small': {
        value: {
          value: 'small',
          type: 'value',
          label: 'small'
        },
        isNew: false,
        isDeleted: false,
      },
      'medium': {
        value: {
          value: 'medium',
          type: 'value',
          label: 'medium'
        },
        isNew: false,
        isDeleted: false,
      },
    },
    valuesToAdopt: {
      '1-10#.01': {
        value: {
          value: {
            minimum: 1,
            maximum: 10,
            increment: .01
          },
          type: 'range',
          label: '1-10#.01'
        },
        isNew: false,
        isDeleted: false,
      },
      'large': {
        value: {
          value: 'large',
          type: 'value',
          label: 'large'
        },
        isNew: false,
        isDeleted: false,
      },
      'x-large': {
        value: {
          value: 'x-large',
          type: 'value',
          label: 'x-large'
        },
        isNew: true,
        isDeleted: false,
      },
      'x-small': {
        value: {
          value: 'x-small',
          type: 'value',
          label: 'x-small'
        },
        isNew: false,
        isDeleted: true,
      }
    }
  }*/
};

export default {
  diffStandardVersions,
  convertToStandardOptionTypeDict,
  getOptionDiffDetails,
};
