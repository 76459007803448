import { defineMessages } from 'react-intl';

export default defineMessages({
  advancedSearch: {
    id: 'productLinker.advancedSearch',
    defaultMessage: 'Advanced Search',
  },
  searchBarLabel: {
    id: 'productLinker.searchBarLabel',
    defaultMessage: 'Search by product link name, product Id, product name',
  },
  advancedPlinkSearch: {
    id: 'productLinker.advancedPlinkSearch',
    defaultMessage: 'Advanced Product Link Search',
  },
  resetSearch: {
    id: 'productLinker.resetSearch',
    defaultMessage: 'Reset Search',
  },

  plinkName: {
    id: 'productLinker.plinkName',
    defaultMessage: 'Product Link Name',
  },
  productIdsOrName: {
    id: 'productLinker.productIdsOrName',
    defaultMessage: 'Product Ids/Product Name',
  },
  relatedResource: {
    id: 'productLinker.relatedResource',
    defaultMessage: 'Related Resource',
  },
  createdByMe: {
    id: 'productLinker.createdByMe',
    defaultMessage: 'Created By Me ({name})',
  },
  resultsHelperInfo: {
    id: 'resultsList.resultsHelperInfo',
    defaultMessage:
      'You can select previously created product links from the list below. If the product link needs changes, you can edit the product link and save it as a copy.',
  },
  productLinks: {
    id: 'resultsList.productLinks',
    defaultMessage: 'Product Links',
  },
  editLink: {
    id: 'actionButtons.editLink',
    defaultMessage: 'Edit',
  },
  useLink: {
    id: 'actionButtons.useLink',
    defaultMessage: 'Use',
  },
});
