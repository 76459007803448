import React, { useState, useEffect } from 'react';
import { css } from 'emotion';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import { getPropertyDiffs } from '../../../shared/DiffUtilities/properties';
import PropertiesDiffChecker from './propertyDiff/PropertiesDiffChecker';

const center = css`
  text-align: center;
`;

const PropertiesTab = ({ productV1, productV2 }) => {
  const [propertyDiffs, setPropertyDiffs] = useState();

  useEffect(() => {
    const propertiesV1 = get(productV1, 'properties');
    const propertiesV2 = get(productV2, 'properties');
    setPropertyDiffs(getPropertyDiffs(propertiesV1, propertiesV2));
  }, [productV1, productV2, setPropertyDiffs]);

  const loading = isEmpty(propertyDiffs);
  return !loading ? (
    <PropertiesDiffChecker propertyDiffs={propertyDiffs} />
  ) : (
    <Spinner size="large" className={center} />
  );
};

export default PropertiesTab;
