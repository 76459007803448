import { defineMessages } from 'react-intl';

export default defineMessages({
  ProductOptionConditions: {
    id: 'productLinkCard.ProductOptionConditions',
    defaultMessage: 'Product Option Conditions',
  },
  MoreInfo: {
    id: 'productLinkCard.MoreInfo',
    defaultMessage: 'More Info',
  },
  conditionsInfo: {
    id: 'productLinkCard.conditionsInfo',
    defaultMessage:
      'Conditions add specificity to a product selection. When added, product will only be linked if the condition is true.',
  },
  SelectedProducts: {
    id: 'productLinkCard.SelectedProducts',
    defaultMessage: 'Selected Products',
  },
  seeDetails: {
    id: 'selectedProducts.seeDetails',
    defaultMessage: 'See Details',
  },
  seeMoreSelectedProducts: {
    id: 'selectedProducts.seeMoreSelectedProducts',
    defaultMessage: 'And {count} More...',
  },
  addMoreProducts: {
    id: 'selectedProducts.addMoreProducts',
    defaultMessage: 'Add More Products',
  },
  placeholder: {
    id: 'productLinkCard.placeholderText',
    defaultMessage: 'Please enter a name',
  },
  conditionsOnText: {
    id: 'productLinkCard.conditionsOnText',
    defaultMessage: 'conditions on ',
  },
  with: {
    id: 'productLinkCard.with',
    defaultMessage: ' with ',
  },
  allProductsInCatalog: {
    id: 'productLinkCard.allProductsInCatalog',
    defaultMessage: 'All products in {catalogName} catalog',
  },
  adoptionSelectionDetails: {
    id: 'productLinkCard.adoptionSelectionDetails',
    defaultMessage: 'Only variants in selected adoptions will be linked. At least one must be selected.',
  },
  adoptionModalTitle: {
    id: 'productLinkCard.adoptionModalTitle',
    defaultMessage: 'Selected Adoptions of {productId}',
  },
  adoptionTagPlural: {
    id: 'productLinkCard.adoptionTagPlural',
    defaultMessage: '{adoptionCount} adoptions',
  },
  adoptionTagSingle: {
    id: 'productLinkCard.adoptionTagSingle',
    defaultMessage: '1 adoption',
  },
  badAttributes: {
    id: 'productLinkCard.badAttributes',
    defaultMessage: 'Conditions contain attributes that do not exist on any selected products: {attributeNames} ',
  },
  selectionNameError: {
    id: 'productLinkCard.selectionNameError',
    defaultMessage: 'Selection name should be unique',
  },
});
