import isEmpty from 'lodash/isEmpty';
import { VALUE } from '../enums/options';
import { uomNameToAbbreviationDictionary } from '../enums/unitOfMeasure';

export const validateHexCode = (hexCode) => {
  const reg = /^#([0-9a-f]{3}){1,2}$/i;
  if (!reg.test(hexCode)) {
    return false;
  }
  return true;
};

export const convertHexToDecimal = (hexCode) => {
  if (!hexCode || !validateHexCode(hexCode)) {
    return null;
  }

  let color = hexCode.substring(1);
  if (color.length === 3) {
    color = color
      .split('')
      .map((hex) => hex + hex)
      .join('');
  }
  try {
    return parseInt(color, 16).toString();
  } catch (e) {
    return null;
  }
};

export const convertDecimalToHex = (value) => {
  try {
    if (value <= 16777215) {
      return '#' + parseInt(value, 10).toString(16).padStart(6, 0).toUpperCase();
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};

export const convertResultToHex = (result) => {
  return {
    type: VALUE,
    value: !isEmpty(result[result.type]) ? convertDecimalToHex(result[result.type]).toUpperCase() : '',
  };
};

export const isHexCode = (unitOfMeasure) =>
  !!unitOfMeasure && unitOfMeasure.toLowerCase() === uomNameToAbbreviationDictionary.hexcode.toLowerCase();

export default {
  validateHexCode,
  convertHexToDecimal,
  convertDecimalToHex,
  convertResultToHex,
  isHexCode,
};
