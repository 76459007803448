import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { css, cx } from 'emotion';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import Trash from '@cimpress-technology/react-streamline-icons/lib/IconBin';
import { teal, danger } from '@cimpress/react-components/lib/colors';
import Select from '@cimpress/react-components/lib/Select';
import Tooltip from '@cimpress/react-components/lib/Tooltip';

import { OperatorSelector } from '../../../OperatorSelector/OperatorSelector';
import ValuesEditor from '../../../ValuesEditor/ValuesEditor';
import { RoundButton } from '../../../../shared/styledComponents';

import { optionShape, propertyConditionAttributeShape, constraintConditionShape } from '../../../../shared/propTypes';

import messages from '../messages';

const errorAlertDiv = css`
  width: 30px;
  height: 55px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const ConditionEditor = ({ condition, conditions, attributes, updateCondition, removeCondition }) => {
  const { formatMessage } = useIntl();

  const [selectedAttribute, setSelectedAttribute] = useState({
    value: condition.attribute,
    label: condition.attribute,
  });
  const [conditionErrorMessage, setConditionErrorMessage] = useState('');

  useEffect(() => {
    const listOfErrors = [];

    if (!condition.attribute) {
      listOfErrors.push(formatMessage(messages.missingAttribute));
    }
    if (!condition.operator) {
      listOfErrors.push(formatMessage(messages.missingOperator));
    }
    if (isEmpty(condition.values)) {
      listOfErrors.push(formatMessage(messages.missingValues));
    }

    if (isEmpty(listOfErrors)) {
      setConditionErrorMessage('');
    } else {
      let errorMessage = formatMessage(messages.conditionErrorStartMessage) + ' ';

      if (listOfErrors.length === 1) {
        errorMessage = errorMessage + listOfErrors[0] + '. ';
      } else {
        for (let i = 0; i < listOfErrors.length; i++) {
          if (i === listOfErrors.length - 1) {
            errorMessage = errorMessage + formatMessage(messages.and) + ' ' + listOfErrors[i] + '. ';
          } else if (i === listOfErrors.length - 2) {
            errorMessage = errorMessage + listOfErrors[i] + ' ';
          } else {
            errorMessage = errorMessage + listOfErrors[i] + ', ';
          }
        }
      }

      errorMessage = errorMessage + formatMessage(messages.conditionErrorEndMessage);
      setConditionErrorMessage(errorMessage);
    }
  }, [condition, formatMessage]);

  const onRemoveCondition = () => removeCondition(condition);

  const updateConditionAttribute = (newAttribute) => {
    const newCondition = cloneDeep(condition);

    newCondition.attribute = newAttribute ? newAttribute.value : null;
    newCondition.values = [];

    setSelectedAttribute(newAttribute);
    updateCondition(condition, newCondition);
  };

  const updateConditionOperator = (selectedOperator) => {
    const newCondition = cloneDeep(condition);

    newCondition.operator = selectedOperator;

    updateCondition(condition, newCondition);
  };

  const updateConditionValues = (values) => {
    const newCondition = cloneDeep(condition);
    newCondition.values = values;

    updateCondition(condition, newCondition);
  };

  const usedAttributes = conditions.reduce(
    (accumulator, existingCondition) =>
      existingCondition.attribute ? { ...accumulator, [existingCondition.attribute]: 1 } : accumulator,
    {}
  );

  // allow the user to make changes to the current selected attribute
  delete usedAttributes[condition.attribute];

  const selectableAttributes = Object.values(attributes).filter((attribute) => !usedAttributes[attribute.name]);
  const attributeNames = selectableAttributes.map((attribute) => ({
    value: attribute.name,
    label: attribute.name,
    id: attribute.id,
  }));

  const currentAttribute = attributes[condition.attribute];

  return (
    <div className="row">
      <div className="col-sm-3">
        <Select
          clearable={false}
          label={formatMessage(messages.attributeLabel)}
          value={selectedAttribute}
          options={attributeNames}
          onChange={updateConditionAttribute}
          // If we have already added values, the selected attribute needs to be disabled
          disabled={condition.values && condition.values.length > 0}
        />
      </div>
      <div className="col-sm-2">
        <OperatorSelector
          attributeType={currentAttribute ? currentAttribute.type : null}
          selectedOperator={condition.operator}
          onSelectionChanged={updateConditionOperator}
        />
      </div>
      <div className="col-sm-5">
        <ValuesEditor
          condition={condition}
          attributes={attributes}
          allowFormulas={true}
          updateConditionValues={updateConditionValues}
        />
      </div>
      <div className="col-sm-1">
        {removeCondition ? (
          <RoundButton type="link" onClick={onRemoveCondition}>
            <Trash size="lg" color={teal.base} weight="fill" />
          </RoundButton>
        ) : null}
      </div>
      <div className={cx(errorAlertDiv, 'col-sm-1')}>
        {!isEmpty(conditionErrorMessage) && (
          <Tooltip contents={conditionErrorMessage} variant="popover">
            <IconAlertTriangle color={danger.base} size="lg" weight="fill" />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

ConditionEditor.propTypes = {
  condition: PropTypes.shape(constraintConditionShape).isRequired,

  conditions: PropTypes.arrayOf(PropTypes.shape(constraintConditionShape)),
  attributes: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.shape(optionShape), PropTypes.shape(propertyConditionAttributeShape)])
  ).isRequired,

  updateCondition: PropTypes.func.isRequired,
  removeCondition: PropTypes.func,
};

ConditionEditor.defaultProps = {
  conditions: [],
};

export default ConditionEditor;
