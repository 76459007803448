import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import Label from '@cimpress/react-components/lib/Label';
import { silver } from '@cimpress/react-components/lib/colors';

import HexWrapTag from './HexWrapTag';
import TextWrapTag from './textWrapTag';
import FormulaString from './formulaString';
import { isHexCode } from '../shared/HexConversion/hexConverter';

import operatorMessages from './messages/operatorMessages';
import { getRangeMessage } from './formatters/rangeTranslator';

const SingleConditionDisplay = ({ attribute, operator, values, unitOfMeasures = {} }) => {
  const { formatMessage } = useIntl();

  return (
    <span>
      {attribute && (
        <Fragment>
          <Label type="info" text={attribute} /> {operator ? formatMessage(operatorMessages[operator]) : null}{' '}
        </Fragment>
      )}
      {values &&
        values.map((value) => {
          if (!isEmpty(attribute) && isHexCode(unitOfMeasures[attribute])) {
            let decimalValue;
            if (!isEmpty(value.value)) {
              decimalValue = value.value;
            } else if (!isEmpty(value.range) && value.range.minimum === value.range.maximum) {
              decimalValue = value.range.minimum;
            }

            return !isEmpty(decimalValue) ? (
              <HexWrapTag key={decimalValue} decimalValue={decimalValue} />
            ) : (
              <TextWrapTag
                style={{ margin: '3px', backgroundColor: silver }}
                key={value.value}
                value={<FormulaString>{value.formula}</FormulaString>}
              />
            );
          }

          return value.formula ? (
            <TextWrapTag
              style={{ margin: '3px', backgroundColor: silver }}
              key={value.formula}
              value={<FormulaString>{value.formula}</FormulaString>}
            />
          ) : (
            <TextWrapTag
              style={{ margin: '3px' }}
              key={value.range ? JSON.stringify(value.range) : value.value}
              value={value.range ? formatMessage(getRangeMessage(value.range), value.range) : value.value}
            />
          );
        })}
    </span>
  );
};

SingleConditionDisplay.propTypes = {
  attribute: PropTypes.string,
  operator: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SingleConditionDisplay;
