import {
  addRelatedResourcesToProductLink as addRelatedResourcesToLink,
  removeRelatedResourceFromProductLink as removeRelatedResourceFromLink,
} from '../../services/productLinkService';

export const addRelatedResources = async ({ productLinkId, relatedResources, accessToken }) => {
  try {
    const updateResourceResponse = await addRelatedResourcesToLink({
      relatedResources,
      productLinkId,
      accessToken,
    });
    return updateResourceResponse;
  } catch (e) {
    return console.error(e);
  }
};

export const removeRelatedResources = async ({ productLinkId, relatedResources, accessToken }) => {
  try {
    const updateResourceResponse = await removeRelatedResourceFromLink({
      relatedResources,
      productLinkId,
      accessToken,
    });
    return updateResourceResponse;
  } catch (e) {
    return console.error(e);
  }
};

export default {
  removeRelatedResources,
  addRelatedResources,
};
