import React, { useEffect, useState, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { css, cx } from 'emotion';
import isEmpty from 'lodash/isEmpty';

import TextField from '@cimpress/react-components/lib/TextField';
import Button from '@cimpress/react-components/lib/Button';
import { teal, ocean } from '@cimpress/react-components/lib/colors';
import IconSearch from '@cimpress-technology/react-streamline-icons/lib/IconSearch';

import AdvancedSearchDrawer from './AdvancedSearchDrawer';
import LinkSearchResults from './LinkSearchResults';

import { queryProductLink } from '../../../../services/productLinkService';

import { anchorButton } from '../../constants/css';

import { checkV1Prefixes, checkV2Prefixes, checkMerchantPrefixes } from '../../utilities/productSearch';
import { plinkSearch } from '../../utilities/productSearch';

import messages from '../messages';

const advancedSearchButton = css`
  float: right;
`;

const searchBoxStyle = css`
  width: 100%;
  display: flex;

  form {
    width: 100%;
  }
`;

const searchDiv = css`
  max-width: 700px;
`;

const SearchProductLink = ({
  account,
  accessToken,
  onEditProductLink,
  onSelectProductLink,
  selectedProductLink,
  productTypes,
  standardsHaveAdoptions,
  authProfile,
  allowCatalogSelection,
  selectionsChanged,
}) => {
  const { formatMessage } = useIntl();
  const [search, setSearch] = useState('');
  const [advancedSearchDrawerIsOpen, setAdvancedSearchDrawerIsOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    if (!isEmpty(selectedProductLink)) {
      setShowResults(true);
    } else {
      setShowResults(false);
    }
  }, [selectedProductLink]);
  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const onSearch = async (e) => {
    if (loading) {
      return;
    }

    e.preventDefault();
    setLoading(true);

    const results = await plinkSearch({
      accessToken,
      accountId: account.id,
      searchString: search,
      excludeCatalogs: !allowCatalogSelection,
    });
    setSearchResults(results);
    setLoading(false);
    setShowResults(true);
  };

  const onAdvancedSearch = async (plinkName, catalog, products, relatedResourceType, createdBy) => {
    let productIds;
    let productNames;

    if (!isEmpty(products)) {
      const productsList = products.split(',');
      const isProductId =
        checkV2Prefixes(productsList[0]) || checkV1Prefixes(productsList[0]) || checkMerchantPrefixes(productsList[0]);
      if (isProductId) {
        productIds = productsList;
      } else {
        productNames = productsList;
      }
    }

    const searchObject = { accessToken };
    if (account && account.id) {
      searchObject.accountId = account.id;
    }

    if (!isEmpty(productIds)) {
      searchObject.productIds = productIds;
    }

    if (!isEmpty(productNames)) {
      searchObject.productNames = productNames;
    }

    if (!isEmpty(plinkName)) {
      searchObject.name = plinkName;
    }

    if (!isEmpty(catalog)) {
      searchObject.catalogIds = [catalog.id];
    }

    if (!isEmpty(createdBy)) {
      searchObject.createdBy = createdBy;
    }

    if (!isEmpty(relatedResourceType)) {
      searchObject.relatedResourceType = relatedResourceType;
    }

    searchObject.excludeCatalogs = !allowCatalogSelection;

    setLoading(true);
    const results = await queryProductLink(searchObject);

    setSearchResults(results);
    setLoading(false);
    setShowResults(true);
  };

  const closeDrawer = () => setAdvancedSearchDrawerIsOpen(false);
  const openDrawer = () => setAdvancedSearchDrawerIsOpen(true);

  return (
    <Fragment>
      <div className={searchDiv}>
        <button className={cx(anchorButton, advancedSearchButton)} onClick={openDrawer}>
          {formatMessage(messages.advancedSearch)}
        </button>
        <div className={searchBoxStyle}>
          <form onSubmit={onSearch}>
            <TextField
              label={formatMessage(messages.searchBarLabel)}
              value={search}
              onChange={onChange}
              rightAddon={
                <Button
                  style={{
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    borderColor: ocean.base,
                  }}
                  type="link"
                  onClick={onSearch}>
                  <IconSearch size="lg" color={teal.base} />
                </Button>
              }
            />
          </form>
        </div>
      </div>
      <AdvancedSearchDrawer
        account={account}
        drawerIsOpen={advancedSearchDrawerIsOpen}
        standardsHaveAdoptions={standardsHaveAdoptions}
        closeDrawer={closeDrawer}
        onAdvancedSearch={onAdvancedSearch}
        accessToken={accessToken}
        authProfile={authProfile}
        allowCatalogSelection={allowCatalogSelection}
      />
      <LinkSearchResults
        searchResults={searchResults}
        loading={loading}
        onEditProductLink={onEditProductLink}
        onSelectProductLink={onSelectProductLink}
        accessToken={accessToken}
        selectedProductLink={selectedProductLink}
        showSearchResults={showResults}
        account={account}
        productTypes={productTypes}
        standardsHaveAdoptions={standardsHaveAdoptions}
        selectionsChanged={selectionsChanged}
      />
    </Fragment>
  );
};

export default SearchProductLink;
