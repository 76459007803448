import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { css } from 'emotion';

import TextField from '@cimpress/react-components/lib/TextField';
import { ValidationResultDisplay } from '../../shared/ValidationResultDisplay';

import { VALUE } from '../../shared/enums/options';
import { validateHexString } from '../../shared/validators/hexcodeValidator';

import { valueShape } from '../../shared/propTypes';
import messages from './messages';

const validateValue = (value) => {
  if (!value) {
    throw new Error('HexValueEditor did not receive a value');
  } else if (value.type && value.type !== VALUE) {
    throw new Error('HexValueEditor received a value with a incorrect value type');
  }
};

export const HexValueEditor = ({ value, onValueChanged }) => {
  const { formatMessage } = useIntl();
  const [hexState, setHexState] = useState(() => (value && value.value ? value.value : ''));
  const [validationState, updateValidationState] = useState({ errors: [], warnings: [] });

  useEffect(() => {
    validateValue(value);
    setHexState(value.value);
  }, [value]);

  useEffect(() => {
    const validationResult = validateHexString(hexState);
    updateValidationState(validationResult);
  }, [hexState]);

  const onTextChanged = ({ target: { value } }) => {
    setHexState(value);
  };

  const onBlur = () => {
    const updatedValue = { type: VALUE, value: validationState.hexString };
    onValueChanged(updatedValue);
  };

  const displayHexColor = css`
     {
      float: left;
      height: 20px;
      width: 20px;
      border-radius: 3px;
      border: 1px solid ${hexState};
      clear: both;
      background-color: ${hexState};
      margin-top: 10px;
    }
  `;

  const hexcodeInputArea = css`
     {
      display: grid;
      grid-template-columns: 35px auto;
    }
  `;

  return value ? (
    <Fragment>
      <div className={hexcodeInputArea}>
        <div className={displayHexColor}></div>
        <TextField
          label={formatMessage(messages.enterHexValue)}
          value={validationState.hexString}
          onChange={onTextChanged}
          onBlur={onBlur}
          data-testid={'HexInput'}
        />
      </div>
      <ValidationResultDisplay errors={validationState.errors} warnings={validationState.warnings} />
    </Fragment>
  ) : null;
};

HexValueEditor.propTypes = {
  value: PropTypes.shape(valueShape),
  onValueChanged: PropTypes.func.isRequired,
};

HexValueEditor.defaultProps = {
  value: { type: VALUE },
};
