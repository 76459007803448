import React, { useState, useEffect } from 'react';
import { css } from 'emotion';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import { OptionsDiffChecker } from '../../OptionsDiffChecker/OptionsDiffChecker';

const center = css`
  text-align: center;
`;

const OptionsTab = ({ productV1, productV2 }) => {
  const [v1, setV1] = useState();
  const [v2, setV2] = useState();

  useEffect(() => {
    const options = cloneDeep(get(productV1, 'options'));
    const quantityV1 = get(productV1, 'quantity');
    options.unshift({ name: 'Quantity', values: quantityV1 });
    setV1(options);
  }, [productV1]);

  useEffect(() => {
    const options = cloneDeep(get(productV2, 'options'));
    const quantityV2 = get(productV2, 'quantity');
    options.unshift({ name: 'Quantity', values: quantityV2 });
    setV2(options);
  }, [productV2]);

  const loading = isEmpty(v1) || isEmpty(v2);

  return !loading ? <OptionsDiffChecker v1={v1} v2={v2} /> : <Spinner size="large" className={center} />;
};

export default OptionsTab;
