import React from 'react';
import ConditionSetWrapper from '../../ConditionSets/ConditionSetWrapper';

const HamAccordion = ({ ancestor, accessToken, updateHamNode, mode, onOpenConditionSetManager, type }) => {
  // node is a ham and needs to have condition editing enabled with a popup save

  return (
    <div>
      <ConditionSetWrapper
        nodeId={ancestor.id}
        accessToken={accessToken}
        attributeModelReferenceHref={ancestor.attributeModelUrl}
        constraintId={ancestor.constraintId}
        onSave={updateHamNode}
        onCancel={() => {}}
        readOnlyEdit={true}
        hamName={ancestor.name}
        conditionId={ancestor.id}
        productId={ancestor.productId}
        mode={mode}
        onOpenConditionSetManager={onOpenConditionSetManager}
        productUrl={ancestor.productUrl}
        type={type}
      />
    </div>
  );
};

export default HamAccordion;
