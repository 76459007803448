import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { cx } from 'emotion';

import { ValidationResultMessage } from '../ValidationResultDisplay/ValidationResultDisplay';
import { tenPxMarginBottom } from '../commonStyles';
import { getRangeMessage } from '../formatters/rangeTranslator';
import messages from '../ValidationResultDisplay/messages';
import formattingMessage from '../formatters/messages';

const InvalidInputResults = ({ invalidInputs }) => {
  const { formatMessage } = useIntl();
  const invalidInputResults = invalidInputs.map((invalidInput, index) => {
    const { errors, warnings } = invalidInput;
    return (
      <Fragment key={index}>
        {errors && errors.length > 0 ? (
          <div className={cx(tenPxMarginBottom, 'text-danger')}>
            <FormattedMessage {...messages.errorValidatingInput} />
            {invalidInput.value
              ? formatMessage(formattingMessage.rangeExactly, { minimum: invalidInput.value })
              : formatMessage(getRangeMessage(invalidInput.range), invalidInput.range)}
            <ul>
              {errors.map((error) => (
                <ValidationResultMessage key={JSON.stringify(error)} result={error} />
              ))}
            </ul>
          </div>
        ) : null}
        {warnings && warnings.length > 0 ? (
          <div className={cx(tenPxMarginBottom, 'text-warning')}>
            <FormattedMessage {...messages.warningValidatingInput} />
            {invalidInput.value
              ? formatMessage(formattingMessage.rangeExactly, { minimum: invalidInput.value })
              : formatMessage(getRangeMessage(invalidInput.range), invalidInput.range)}
            <ul>
              {warnings.map((warning) => (
                <ValidationResultMessage key={JSON.stringify(warning)} result={warning} />
              ))}
            </ul>
          </div>
        ) : null}
      </Fragment>
    );
  });
  return invalidInputResults;
};

const resultsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.object,
  })
);

InvalidInputResults.propTypes = {
  invalidInputs: PropTypes.arrayOf(
    PropTypes.shape({
      errors: resultsPropType,
      warnings: resultsPropType,
    })
  ),
};

export default InvalidInputResults;
