import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { css, cx } from 'emotion';
import Select, { components } from 'react-select';
import CreateableSelect from 'react-select/creatable';

import { alloy } from '@cimpress/react-components/lib/colors';
import SelectWrapper from '@cimpress/react-components/lib/SelectWrapper';
import selectStyles from '@cimpress/react-components/lib/Select/styles';

import { convertHexToDecimal } from '../../shared/HexConversion/hexConverter';
import Checkbox from '../StringValuesEditor/Checkbox';
import { VALUE } from '../../shared/enums/options';
import { valueShape } from '../../shared/propTypes';

import messages from './messages';
import { flex } from '../../shared/commonStyles';

const SET_VALUE_ACTION = 'set-value';

const stringValuesSelectorStyle = css`
  .form-group {
    input {
      transition: inherit;
    }
  }
`;

const boxCss = css`
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border: 1px solid ${alloy};
  display: inline-block;
  align-items: center;
  line-height: 14px;
  margin-bottom: -2px;
`;

const minWidth = css`
  min-width: 10em;
`;

export const HexValuesSelector = ({ selectedValues, availableValues, onSelectedValuesChanged, multiSelect }) => {
  const { formatMessage } = useIntl();
  const [options, updateOptions] = useState({});
  const [selectedOptions, updateSelectedOptions] = useState();
  const [searchText, updateSearchText] = useState('');

  useEffect(() => {
    const optionsHash = availableValues ? generateOptionsHash(availableValues) : generateOptionsHash(selectedValues);

    updateOptions(optionsHash);
  }, [availableValues, selectedValues]);

  useEffect(() => {
    const selected = selectedValues.map((value) => options[value.value]).filter((value) => value);

    if (selected.length === 1 && !multiSelect) {
      selected[0].label = getHexBoxFormatting(selected[0].label, selected[0].value.value);
    }
    updateSelectedOptions(selected);
  }, [multiSelect, options, selectedValues]);

  const onSelectionsChanged = (updatedSelections) => {
    if (updatedSelections) {
      if (multiSelect) {
        onSelectedValuesChanged(
          updatedSelections.map((selection) => {
            return { ...selection.value, value: convertHexToDecimal(selection.value.value) };
          })
        );
      } else {
        onSelectedValuesChanged([
          { ...updatedSelections.value, value: convertHexToDecimal(updatedSelections.value.value) },
        ]);
      }
    } else {
      onSelectedValuesChanged([]);
    }
  };

  const onSearchTextChanged = (updatedSearchText, { action }) => {
    if (action !== SET_VALUE_ACTION) {
      updateSearchText(updatedSearchText);
    }
  };

  const props = {
    id: 'HexValuesSelector',
    placeholder: availableValues ? formatMessage(messages.selectValuesLabel) : formatMessage(messages.addValuesLabel),
    value: selectedOptions,
    options: Object.values(options),
    components: {
      Option: StringValueCheckbox,
    },
    isClearable: !multiSelect,
    hideSelectedOptions: !multiSelect,
    controlShouldRenderValue: !multiSelect,
    closeMenuOnSelect: !multiSelect,
    backspaceRemovesValue: false,
    tabSelectsValue: false,
    isMulti: multiSelect,
    onChange: onSelectionsChanged,
    filterOption: filterFunc,
    getNewOptionData: getNewValue,
    createOptionPosition: 'first',
    styles: selectStyles,
    inputValue: searchText,
    onInputChange: onSearchTextChanged,
    'data-testid': 'HexValuesSelector',
  };

  return (
    <div className={stringValuesSelectorStyle}>
      <SelectWrapper selectedSelect={availableValues ? Select : CreateableSelect} {...props} />
    </div>
  );
};

HexValuesSelector.propTypes = {
  selectedValues: PropTypes.arrayOf(PropTypes.shape(valueShape)),
  availableValues: PropTypes.arrayOf(PropTypes.shape(valueShape)),
  onSelectedValuesChanged: PropTypes.func.isRequired,
  multiSelect: PropTypes.bool,
};

HexValuesSelector.defaultProps = {
  selectedValues: [],
};

const generateOptionsHash = (values) => {
  return values.reduce(
    (hash, value) => ({
      ...hash,
      [value.value]: { label: value.value, value },
    }),
    {}
  );
};

const filterFunc = (option, searchText) => {
  const optionLabel = option.label.toLowerCase();
  const probe = searchText.toLowerCase();
  const probeExists = optionLabel.indexOf(probe) > -1;

  return probeExists;
};

const getHexBoxFormatting = (label, colorValue) => {
  return (
    <span>
      <span
        className={cx(
          boxCss,
          css`
            background-color: ${colorValue};
          `
        )}></span>
      {label}
    </span>
  );
};

const StringValueCheckbox = (props) => {
  const checkBoxValue = getHexBoxFormatting(props.label, props.value.value);

  return (
    <components.Option {...props} isSelected={false}>
      {props.isMulti ? (
        <div className={flex}>
          <Checkbox label={checkBoxValue} checked={props.isSelected} className={minWidth} />
        </div>
      ) : (
        checkBoxValue
      )}
    </components.Option>
  );
};

const getNewValue = (inputText, optionLabel) => {
  return {
    label: optionLabel,
    value: {
      type: VALUE,
      value: inputText,
    },
  };
};
