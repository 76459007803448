import { productTypes } from '../components/ProductLinker/constants/searchConstants';
import fetchWithAuth from './fetchWithAuth';

import { normalizeV2FulfillmentResult, getV2SearchRequestBody } from './searchUtilities';
const baseUrl = 'https://search.products.cimpress.io';

const PAGE_SIZE = 100;

export const searchFulfillment = async (accessToken, accountId, keyword, categoryId = null, catalogId = null) => {
  const productResults = [];
  const firstFetch = await searchForProducts(
    accessToken,
    getV2SearchRequestBody(accountId, keyword, categoryId, catalogId, 1)
  );

  // add first search to productResults list
  if (firstFetch && firstFetch.productsCount && firstFetch.result) {
    firstFetch.result.forEach((result) => productResults.push(normalizeV2FulfillmentResult(result)));
  }

  // if all products were contained in page size then return here
  if (!(firstFetch && firstFetch.productsCount && firstFetch.productsCount <= PAGE_SIZE)) {
    // if more pages are needed, fetch them all and add them to productResults list. then return.
    const promises = [];
    for (let i = 2; i <= Math.ceil(firstFetch.productsCount / PAGE_SIZE); i++) {
      promises.push(
        searchForProducts(accessToken, getV2SearchRequestBody(accountId, keyword, categoryId, catalogId, i))
      );
    }

    const responses = await Promise.all(promises);
    responses.forEach((response) => {
      response.result.forEach((result) => {
        const normalizedResult = normalizeV2FulfillmentResult(result);
        if (normalizedResult) {
          productResults.push(normalizedResult);
        }
      });
    });
  }

  const adoptionFilteredResults = productResults.filter((x) => x.productType !== productTypes.ADOPTION);
  const standardFilteredResults = adoptionFilteredResults.filter((x) => x.productType !== productTypes.STANDARD);

  standardFilteredResults.sort((a, b) => {
    return a.productId <= b.productId ? -1 : 1;
  });

  return standardFilteredResults;
};

const searchForProducts = async (accessToken, body) => {
  return fetchWithAuth({
    baseUrl,
    route: `v2/products/versions:search`,
    method: `POST`,
    body,
    accessToken,
  });
};
