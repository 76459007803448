import React from 'react';
import { useIntl } from 'react-intl';
import { css } from 'emotion';

import Button from '@cimpress/react-components/lib/Button';
import messages from '../../messages';
import { tenPxMarginLeft } from '../../../../../shared/commonStyles';

const positionActionButtons = css`
  margin-top: 15px;
  float: right;
`;

const ActionButtons = ({ onSelectLink, onEditLink, editButtonDisabled = false, useButtonDisabled = false }) => {
  const { formatMessage } = useIntl();

  return (
    <div className={positionActionButtons}>
      <Button type="default" onClick={onEditLink} disabled={editButtonDisabled}>
        {formatMessage(messages.editLink)}
      </Button>
      <Button type="primary" onClick={onSelectLink} className={tenPxMarginLeft} disabled={useButtonDisabled}>
        {formatMessage(messages.useLink)}
      </Button>
    </div>
  );
};

export default ActionButtons;
