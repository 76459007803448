import fetchWithAuth from './fetchWithAuth';

const attributeModelBaseUrl = 'https://attributemodels.products.cimpress.io';

export const getAttributeModelByHref = ({ attributeModelReferenceHref, accessToken }) =>
  fetchWithAuth({
    link: attributeModelReferenceHref,
    giveSimpleResponse: true,
    accessToken,
  });

export const createAttributeModel = ({ attributeModel, accessToken }) =>
  fetchWithAuth({
    baseUrl: attributeModelBaseUrl,
    method: 'POST',
    route: 'v0/attributeModels',
    body: attributeModel,
    giveSimpleResponse: true,
    accessToken,
  });

export const updateAttributeModel = ({ id, attributeModel, accessToken }) =>
  fetchWithAuth({
    baseUrl: attributeModelBaseUrl,
    method: 'PUT',
    route: `v0/attributeModels/${id}`,
    body: attributeModel,
    giveSimpleResponse: true,
    accessToken,
  });
