import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { css, cx } from 'emotion';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import Card from '@cimpress/react-components/lib/Card';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import SelectedProducts from './SelectedProducts';
import Conditions from './Conditions';
import ViewAllProductsDrawer from './ViewAllProductsDrawer';

import { getConstraint } from '../../../../services/constraintService';
import { getProductGroup } from '../../../../services/productGroupService';
import { followLink as followProductLink } from '../../../../services/productLinkService';
import { followLink as followGenericLink } from '../../../../services/halHelper';
import { getCatalogById } from '../.././../../services/productService';
import { productIdSearch, doAdvancedSearch } from '../../utilities/productSearch';
import messages from './messages';

const positionSelectionName = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .inline-edit-group {
    margin-bottom: 5px !important;
    margin-top: -10px;
  }
`;

const limitWidth = css`
  max-width: 1200px;
`;

const ProductLinkViewCard = ({
  previewProductLink,
  accessToken,
  account,
  productTypes,
  standardsHaveAdoptions,
  setSelections,
}) => {
  const { formatMessage } = useIntl();

  const [constraints, setConstraints] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCatalog, setSelectedCatalog] = useState({});
  const [loader, setLoader] = useState(true);
  const [viewAllProductsDrawerOpen, setViewAllProductsDrawerOpen] = useState(false);
  const [isCatalogView, setIsCatalogView] = useState(false);

  const SelectionHeader = <div className={cx(positionSelectionName)}>{previewProductLink.productLinkName}</div>;

  const closeDrawer = () => {
    setIsCatalogView(false);
    setViewAllProductsDrawerOpen(false);
  };
  const openDrawer = () => setViewAllProductsDrawerOpen(true);

  useEffect(() => {
    const fetchCatalog = async (productGroup) => {
      try {
        let catalogId = productGroup.productCatalogs[0].id;
        if (!isEmpty(catalogId)) {
          const catalogResponse = await getCatalogById({ catalogId, accessToken });
          const decoratedProducts = await doAdvancedSearch({
            accessToken,
            accountId: account.id,
            standardsHaveAdoptions,
            productTypes,
            catalogId,
          });
          const selectedCatalogTemp = {};
          selectedCatalogTemp.catalog = { name: catalogResponse.name, id: catalogResponse.id };
          selectedCatalogTemp.products = decoratedProducts.products.reduce(function (acc, curr) {
            acc[curr.productId] = curr;
            return acc;
          }, {});
          setSelectedCatalog(selectedCatalogTemp);
        }
      } catch (e) {
        console.error(e);
        setLoader(false);
      }
    };

    const fetchProducts = async (productsList) => {
      try {
        const allProducts = productsList.map((product) => product.id);
        const decoratedProducts = await productIdSearch(accessToken, account.id, allProducts.join(','), productTypes);
        decoratedProducts.products &&
          !isEmpty(decoratedProducts.products) &&
          setSelectedProducts(decoratedProducts.products);
      } catch (e) {
        console.error(e);
        setLoader(false);
      }
    };

    const processCatalogData = (productGroup) => {
      const catalogPromises = [];
      const catalogProductIdsPromises = [];
      productGroup.productCatalogs.forEach(async (catalog) => {
        catalogPromises.push(followGenericLink({ href: catalog.href, accessToken }));
        catalogProductIdsPromises.push(followGenericLink({ href: `${catalog.href}/products`, accessToken }));
      });

      Promise.all(catalogPromises).then((catalogResults) => {
        Promise.all(catalogProductIdsPromises).then((catalogProductResults) => {
          const catalogs = catalogResults.map((x) => {
            return {
              name: x.name,
              catalogId: x.id,
            };
          });

          const products = [];
          catalogProductResults.forEach((productResults) => {
            productResults.productIds.forEach((productId) => {
              products.push({
                productId,
                isStandard: false,
              });
            });
          });

          setSelections({ catalogs, products });
        });
      });
    };

    const fetchProductLink = async () => {
      // reset all card info
      setConstraints({});
      setSelectedProducts([]);
      setSelectedCatalog({});
      setLoader(true);

      try {
        let plink = await followProductLink({ href: previewProductLink.productLinkHref, accessToken });
        let mergedConstraint = {};
        const pushRuleToMergedConstraint = (rule) => {
          mergedConstraint.rules.push(rule);
        };

        if (plink.constraints && !isEmpty(plink.constraints)) {
          for (let i = 0; i < plink.constraints.length; i++) {
            const constraint = plink.constraints[i];
            let temp = await getConstraint({ constraintId: constraint.id, accessToken });

            if (isEmpty(mergedConstraint)) {
              mergedConstraint = temp;
            } else {
              temp.rules.forEach((rule) => pushRuleToMergedConstraint(rule));
            }
          }

          setConstraints(mergedConstraint);
        }

        if (plink.productGroups && !isEmpty(plink.productGroups)) {
          // TODO handle case with multiple product groups. Not doing now because each group could have a catalog which isn't allowed in a single one on save.
          let productGroup = await getProductGroup({
            productGroupId: plink.productGroups[0].id,
            accessToken,
          });

          if (!isEmpty(productGroup)) {
            !isEmpty(productGroup.productCatalogs) && (await fetchCatalog(productGroup));
            const productsList = get(productGroup, 'staticIdentifiers.products');
            !isEmpty(productsList) && (await fetchProducts(productsList));
            setLoader(false);
          }

          if (setSelections) {
            if (!isEmpty(productGroup.productCatalogs)) {
              processCatalogData(productGroup);
            } else if (!isEmpty(productGroup.staticIdentifiers)) {
              const products = [];
              productGroup.staticIdentifiers.products.forEach((x) => {
                products.push({
                  productId: x.id,
                  isStandard: false,
                });
              });
              productGroup.staticIdentifiers.standards.forEach((x) => {
                products.push({
                  productId: x.id,
                  isStandard: true,
                });
              });
              setSelections({ catalogs: [], products });
            }
          }
        }
      } catch (e) {
        console.error(e);
        setLoader(false);
      }
    };

    !isEmpty(previewProductLink) && fetchProductLink();
  }, [previewProductLink, accessToken, account.id, productTypes, standardsHaveAdoptions, setSelections]);

  return (
    <Fragment>
      <Card className={limitWidth} header={SelectionHeader}>
        {loader ? (
          <div style={{ textAlign: 'center' }}>
            <Spinner size="medium" />
            <p>{formatMessage(messages.fetchingDetails)}</p>
          </div>
        ) : (
          <Fragment>
            <SelectedProducts
              selectedProducts={selectedProducts}
              selectedCatalog={selectedCatalog}
              openDrawer={openDrawer}
              setIsCatalogView={setIsCatalogView}
            />
            {!isEmpty(constraints) && <Conditions constraints={constraints} />}
          </Fragment>
        )}
      </Card>
      <ViewAllProductsDrawer
        drawerIsOpen={viewAllProductsDrawerOpen}
        closeDrawer={closeDrawer}
        accessToken={accessToken}
        selectedCatalog={selectedCatalog}
        selectedProducts={selectedProducts}
        isCatalogView={isCatalogView}
      />
    </Fragment>
  );
};

export default ProductLinkViewCard;
