import fetchWithAuth from './fetchWithAuth';
import queryString from 'query-string';

const baseUrl = 'https://groups.products.cimpress.io';

export const getProductGroup = async ({ productGroupId, accessToken }) => {
  return await fetchWithAuth({
    baseUrl,
    route: `v1/groups/${productGroupId}`,
    accessToken,
  });
};

export const createGroup = async ({ accessToken, name, products = [], standards = [], productCatalogs, accountId }) => {
  const body = {
    name,
    owner: {
      accountId,
    },
    staticIdentifiers: {
      products,
      standards,
    },
    productCatalogs,
  };

  return await fetchWithAuth({
    baseUrl,
    route: `v1/groups`,
    method: `POST`,
    body,
    accessToken,
  });
};

export const updateGroup = async ({
  productGroupId,
  accessToken,
  name,
  products = [],
  standards = [],
  productCatalogs,
  accountId,
}) => {
  const body = {
    name,
    owner: {
      accountId,
    },
    staticIdentifiers: {
      products,
      standards,
    },
    productCatalogs,
  };

  return await fetchWithAuth({
    baseUrl,
    route: `v1/groups/${productGroupId}`,
    method: `PUT`,
    body,
    accessToken,
  });
};

export const queryGroups = async ({ groupName, accessToken }) => {
  const query = queryString.stringify({ groupName });
  return await fetchWithAuth({
    baseUrl,
    route: `v1/groups?${query}`,
    accessToken,
  });
};
