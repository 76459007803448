import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { cx, css } from 'emotion';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import { teal, warning } from '@cimpress/react-components/lib/colors';
import Pencil from '@cimpress-technology/react-streamline-icons/lib/IconPencilAlt';
import Trash from '@cimpress-technology/react-streamline-icons/lib/IconBin';
import IconNavigationNext from '@cimpress-technology/react-streamline-icons/lib/IconNavigationNext';
import IconAdd from '@cimpress-technology/react-streamline-icons/lib/IconAdd';
import Copy from '@cimpress-technology/react-streamline-icons/lib/IconCopy';
import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';

import { tenPxMarginBottom, tenPxMarginLeft } from '../shared/commonStyles';
import { flexParentCenterSpace } from '../shared/flexStyles';
import isEqual from 'lodash/isEqual';
import { VIEW, hamAction, selectorType } from '../shared/enums/constants';

const IconButton = ({ className, disabled, ...rest }) => (
  <button
    className={cx(
      css`
        padding: 0px;
        border: none;
        background: none;
      `,
      'clickable',
      className
    )}
    {...rest}
  />
);

const errorBorder = css`
   {
    border: solid ${warning.base} 1px !important;
  }
`;

const ConstraintCard = ({
  title,
  nodeId,
  content,
  editing,
  onDeleteClick,
  onEditClick,
  onOpenConditionSetManager,
  onCopyClick,
  errorMessage,
  mode,
  type,
}) => (
  <div className={errorMessage !== '' ? cx('card', tenPxMarginBottom, errorBorder) : cx('card', tenPxMarginBottom)}>
    <div className={cx('card-header', flexParentCenterSpace)}>
      {title}
      <div>
        {errorMessage !== '' ? (
          <Tooltip direction="top" contents={errorMessage}>
            <IconAlertTriangle weight={'fill'} size="lg" color={warning.base} />
          </Tooltip>
        ) : null}
        {!isEqual(mode, VIEW) && onEditClick ? (
          <IconButton className={tenPxMarginLeft} type="link" onClick={onEditClick}>
            <Pencil weight={editing ? 'fill' : 'regular'} size="lg" color={teal.base} />
          </IconButton>
        ) : null}
        {onCopyClick ? (
          <IconButton className={tenPxMarginLeft} type="link" onClick={onCopyClick}>
            <Copy size="lg" color={teal.base} />
          </IconButton>
        ) : null}
        {!isEqual(mode, VIEW) && onDeleteClick ? (
          <IconButton className={tenPxMarginLeft} type="link" onClick={onDeleteClick}>
            <Trash size="lg" color={teal.base} />
          </IconButton>
        ) : null}
        {isEqual(mode, VIEW) && !isEqual(type, selectorType.PREVIEW) ? (
          <Fragment>
            <Tooltip direction="top" contents={'Add child condition'}>
              <IconButton
                className={tenPxMarginLeft}
                type="link"
                onClick={() => onOpenConditionSetManager(hamAction.CREATE_CHILD, nodeId)}>
                <IconAdd size="sm" color={teal.base} />
              </IconButton>
            </Tooltip>
            <Tooltip direction="top" contents={'Edit condition'}>
              <IconButton
                className={tenPxMarginLeft}
                type="link"
                onClick={() => onOpenConditionSetManager(hamAction.UPDATE, nodeId)}>
                <IconNavigationNext size="sm" color={teal.base} />
              </IconButton>
            </Tooltip>
          </Fragment>
        ) : null}
      </div>
    </div>
    {content}
  </div>
);

ConstraintCard.propTypes = {
  title: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  editing: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func.isRequired,
  onCopyClick: PropTypes.func,
  errorMessage: PropTypes.string,
};

ConstraintCard.defaultProps = {
  errorMessage: '',
};

export default ConstraintCard;
