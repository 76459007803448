import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { css, cx } from 'emotion';

const button = css`
  background-color: transparent;
  padding: 5px;
  border: none;
  color: #0088a9;
  text-decoration: none;
`;

const underline = css`
  text-decoration: underline;
  outline: 0;
`;

const underlineHoverOrFocus = css({
  ':hover,:focus': underline,
});

const ShowMoreLess = ({
  initialItemsToShow,
  totalItemsToShow,
  showMoreMessage,
  showLessMessage,
  children,
  buttonClassName,
  bodyClassName,
}) => {
  const [itemsToShow, setItemsToShow] = useState(initialItemsToShow);
  const [expanded, setExpanded] = useState(false);

  const showMoreOrLess = () => {
    if (itemsToShow === initialItemsToShow) {
      setItemsToShow(totalItemsToShow);
      setExpanded(true);
    } else {
      setItemsToShow(initialItemsToShow);
      setExpanded(false);
    }
  };

  return (
    <Fragment>
      <div className={bodyClassName}>{React.Children.toArray(children).slice(0, itemsToShow)}</div>
      {totalItemsToShow > initialItemsToShow ? (
        <button
          className={cx(button, underlineHoverOrFocus, buttonClassName)}
          onClick={showMoreOrLess}
          data-testid={'showMoreLessButton'}>
          {expanded ? showLessMessage : showMoreMessage}
        </button>
      ) : null}
    </Fragment>
  );
};

ShowMoreLess.propTypes = {
  initialItemsToShow: PropTypes.number.isRequired,
  totalItemsToShow: PropTypes.number.isRequired,
  showMoreMessage: PropTypes.string.isRequired,
  showLessMessage: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  buttonClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
};

ShowMoreLess.defaultProps = {
  buttonClassName: '',
  bodyClassName: '',
};

export default ShowMoreLess;
