import { defineMessages } from 'react-intl';

export default defineMessages({
  advancedSearchHeader: {
    id: 'productLinker.advancedSearchHeader',
    defaultMessage: 'Advanced Search',
  },
  selectedProductsHeader: {
    id: 'productLinker.selectedProductsHeader',
    defaultMessage: 'Selected Products',
  },
  useSelectedProducts: {
    id: 'productLinker.useSelectedProducts',
    defaultMessage: 'Use Selected Products',
  },
  removeAllSelectedProducts: {
    id: 'productLinker.removeAllSelectedProducts',
    defaultMessage: 'Remove All Selected Products',
  },
  selectedProductCountSingular: {
    id: 'productLinker.selectedProductCountSingular',
    defaultMessage: '{count} Selected Product',
  },
  selectedProductCountPlural: {
    id: 'productLinker.selectedProductCountPlural',
    defaultMessage: '{count} Selected Products',
  },
  advancedSearchButtonLink: {
    id: 'productLinker.advancedSearchButtonLink',
    defaultMessage: '< Advanced Search',
  },
  keyword: {
    id: 'productLinker.keyword',
    defaultMessage: 'Keyword',
  },
  selectedProductCountLink: {
    id: 'productLinker.selectedProductCountLink',
    defaultMessage: '{count} Products Selected',
  },
  fetchingSearch: {
    id: 'productLinker.fetchingSearch',
    defaultMessage: 'Fetching Search Results...',
  },
  productIdInvalid: {
    id: 'productLinker.productIdInvalid',
    defaultMessage: '{productId} is not a valid product id.',
  },
  noMerchantSearch: {
    id: 'productLinker.noMerchantSearch',
    defaultMessage: '{productId} is a merchant product. The tool you are using does not support them.',
  },
  noV2Search: {
    id: 'productLinker.noV2Search',
    defaultMessage: '{productId} is a V2 fulfillment product. The tool you are using does not support them.',
  },
  noV1Search: {
    id: 'productLinker.noV1Search',
    defaultMessage: '{productId} is a V1 fulfillment product. The tool you are using does not support them.',
  },
  noStandardSearch: {
    id: 'productLinker.noStandardSearch',
    defaultMessage: '{productId} is a standard product. The tool you are using does not support them.',
  },
  noAdoptions: {
    id: 'productLinker.noAdoptions',
    defaultMessage: '{productId} is a standard adoption. Please link to the standard or a custom product instead',
  },
  noAdoptionsInAccount: {
    id: 'productLinker.noAdoptionsInAccount',
    defaultMessage: 'No adoptions found for {productId} in current account.',
  },
  noDraftProducts: {
    id: 'productLinker.noDraftProducts',
    defaultMessage: '{productId} has no versions. To link it, make an active version.',
  },
  noFamilies: {
    id: 'productLinker.noFamilies',
    defaultMessage: '{productId} is a family identifier. Instead, link to a sku inside of it.',
  },
  noRetiredProducts: {
    id: 'productLinker.noRetiredProducts',
    defaultMessage: '{productId} is an archived product. To link it, make an active version.',
  },
  defaultProductError: {
    id: 'productLinker.defaultProductError',
    defaultMessage: 'There was an error fetching {productId}. Try again or contact support.',
  },
  productIdHeader: {
    id: 'productLinker.productIdHeader',
    defaultMessage: 'Product ID',
  },
  nameHeader: {
    id: 'productLinker.nameHeader',
    defaultMessage: 'Name',
  },
  descriptionHeader: {
    id: 'productLinker.descriptionHeader',
    defaultMessage: 'Description',
  },
  categoryHeader: {
    id: 'productLinker.categoryHeader',
    defaultMessage: 'Category',
  },
  noSearchResults: {
    id: 'productLinker.noSearchResults',
    defaultMessage: 'No search results...',
  },
  advancedSearchResultsDetailsIntro: {
    id: 'productLinker.advancedSearchResultsDetailsIntro',
    defaultMessage: 'Showing {numOfResults} products from search results. ',
  },
  advancedSearchResultsDetailsKeywords: {
    id: 'productLinker.advancedSearchResultsDetailsKeywords',
    defaultMessage: 'Keywords: {keywords} ',
  },
  advancedSearchResultsDetailsCatalog: {
    id: 'productLinker.advancedSearchResultsDetailsCatalog',
    defaultMessage: 'Catalog: {catalog}',
  },
  advancedSearchResultsDetailsCategory: {
    id: 'productLinker.advancedSearchResultsDetailsCategory',
    defaultMessage: 'Category: {category}',
  },
  advancedSearchResultsHelperText: {
    id: 'productLinker.advancedSearchResultsHelperText',
    defaultMessage:
      'Select products below to add them to your product link, To select all, use the checkbox at the top of the table. Products will not be added until you click the "use selected products" button at the bottom of the screen. If you click cancel, all selections will be ignored.',
  },
  selectedCatalog: {
    id: 'productLinker.selectedCatalog',
    defaultMessage: '1 Catalog Selected',
  },
  selectedCatalogPlusSingleProduct: {
    id: 'productLinker.selectedCatalogPlusSingleProduct',
    defaultMessage: '1 Catalog Selected, 1 Product Selected',
  },
  selectedCatalogPlusProducts: {
    id: 'productLinker.selectedCatalogPlusProducts',
    defaultMessage: '1 Catalog Selected, {count} Products Selected',
  },
  noSelectedProducts: {
    id: 'productLinker.noSelectedProducts',
    defaultMessage: 'No Selected Products',
  },
  catalogHelpMessage: {
    id: 'productLinker.catalogHelpMessage',
    defaultMessage:
      'By linking to an entire catalog you do not need to select individual products. Any product added to the catalog will be automatically included in the product link. Selecting a catalog disallows you from deselecting any products within that catalog.',
  },
  linkToEntireCatalog: {
    id: 'productLinker.linkToEntireCatalog',
    defaultMessage: 'Link to entire catalog',
  },
  orSelectProduct: {
    id: 'productLinker.orSelectProduct',
    defaultMessage: 'or select individual products from below',
  },
  ProductOptionConditions: {
    id: 'productLinker.ProductOptionConditions',
    defaultMessage: 'Product Option Conditions',
  },
  fetchingProductLink: {
    id: 'productLinker.fetchingProductLink',
    defaultMessage: 'Fetching Product Link',
  },
  savePlink: {
    id: 'productLinker.savePlink',
    defaultMessage: 'Save',
  },
  savingProductLink: {
    id: 'productLinker.savingProductLink',
    defaultMessage: 'Saving Product Link',
  },
  creationFailed: {
    id: 'productLinker.creationFailed',
    defaultMessage:
      'Failed to create {error}. Please contact CT Product Catalog Support <CTProductCatalogSupport@cimpress.com>',
  },
});
