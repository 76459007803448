import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Close from '@cimpress-technology/react-streamline-icons/lib/IconClose';
import { teal } from '@cimpress/react-components/lib/colors';
import Select from '@cimpress/react-components/lib/Select';
import cloneDeep from 'lodash/cloneDeep';

import { OperatorSelector } from '../components/OperatorSelector';
import { HexValuesEditor } from '../components/HexValuesEditor/HexValuesEditor';
import { uomNameToAbbreviationDictionary } from './enums/unitOfMeasure';

import ValuesEditor from '../components/ValuesEditor';
import { intlShape, optionShape, propertyConditionAttributeShape, constraintConditionShape } from './propTypes';

import messages from './messages';

import { RoundButton } from './styledComponents';

const optionType = PropTypes.shape(optionShape);
const propertyType = PropTypes.shape(propertyConditionAttributeShape);

export const conditionEditorPropTypes = {
  condition: PropTypes.shape(constraintConditionShape).isRequired,

  conditions: PropTypes.arrayOf(PropTypes.shape(constraintConditionShape)),
  results: PropTypes.arrayOf(PropTypes.shape(constraintConditionShape)),
  attributes: PropTypes.objectOf(PropTypes.oneOfType([optionType, propertyType])).isRequired,

  updateCondition: PropTypes.func.isRequired,
  removeCondition: PropTypes.func,

  allowFormulas: PropTypes.bool,

  style: PropTypes.string,
};

class ConditionEditorInternal extends Component {
  static propTypes = {
    ...conditionEditorPropTypes,

    intl: PropTypes.shape(intlShape).isRequired,
  };

  static defaultProps = {
    conditions: [],
    results: [],
    style: '',
    allowFormulas: true,
    optionLabel: null,
  };

  constructor(props) {
    super(props);

    const { condition } = props;

    this.state = {
      selectedOption: { value: condition.attribute, label: condition.attribute },
    };
  }

  removeCondition = () => this.props.removeCondition(this.props.condition);

  updateConditionOption = (selectedOption) => {
    const newCondition = {
      ...this.props.condition,
      attribute: selectedOption ? selectedOption.value : null,
      values: [],
    };

    this.props.updateCondition(this.props.condition, newCondition);
    this.setState({ selectedOption });
  };

  updateConditionOperator = (selectedOperator) => {
    const newCondition = {
      ...this.props.condition,
      operator: selectedOperator,
    };

    this.props.updateCondition(this.props.condition, newCondition);
  };

  updateConditionValues = (values) => {
    const updatedCondition = cloneDeep(this.props.condition);
    updatedCondition.values = values;

    this.props.updateCondition(this.props.condition, updatedCondition);
  };

  render() {
    const {
      condition,
      conditions,
      results,
      attributes,
      removeCondition,
      allowFormulas,
      optionLabel,
      intl: { formatMessage },
    } = this.props;

    const { selectedOption } = this.state;

    const usedAttributes = [...conditions, ...results].reduce(
      (accumulator, existingCondition) =>
        existingCondition.attribute ? { ...accumulator, [existingCondition.attribute]: 1 } : accumulator,
      {}
    );

    // allow the user to make changes to the current selected attribute
    delete usedAttributes[condition.attribute];

    const selectableAttributes = Object.values(attributes).filter((attribute) => !usedAttributes[attribute.name]);
    const optionNames = selectableAttributes.map((attribute) => ({
      value: attribute.name,
      label: attribute.name,
      id: attribute.id,
    }));

    const selectedAttribute = attributes[condition.attribute];

    return (
      <div className="row">
        <div className="col-sm-3">
          <Select
            clearable={false}
            label={optionLabel || formatMessage(messages.optionLabel)}
            value={selectedOption}
            options={optionNames}
            onChange={this.updateConditionOption}
            // If we have already added values, the selected attribute needs to be disabled
            disabled={condition.values && condition.values.length > 0}
          />
        </div>
        <div className="col-sm-2">
          <OperatorSelector
            attributeType={selectedAttribute ? selectedAttribute.type : null}
            selectedOperator={condition.operator}
            onSelectionChanged={this.updateConditionOperator}
            uom={selectedAttribute && selectedAttribute.unitOfMeasure}
          />
        </div>
        <div className="col-sm-6">
          {selectedAttribute &&
          selectedAttribute.unitOfMeasure &&
          selectedAttribute.unitOfMeasure.toLowerCase() === uomNameToAbbreviationDictionary.hexcode ? (
            <HexValuesEditor
              values={condition.values}
              attribute={selectedAttribute}
              onValuesChanged={this.updateConditionValues}
            />
          ) : (
            <ValuesEditor
              condition={condition}
              attributes={attributes}
              allowFormulas={allowFormulas}
              updateConditionValues={this.updateConditionValues}
            />
          )}
        </div>
        <div className="col-sm-1">
          {removeCondition ? (
            <RoundButton type="link" onClick={this.removeCondition}>
              <Close size="lg" color={teal.base} />
            </RoundButton>
          ) : null}
        </div>
      </div>
    );
  }
}

export const ConditionEditor = injectIntl(ConditionEditorInternal);
export default ConditionEditor;
