import React, { Fragment } from 'react';
import { css, cx } from 'emotion';

import { anchorButton } from '../../../constants/css';
import { noMargin } from './css';

const allResults = css`
  display: flex;
  align-items: center;
  padding: 10px 10px 5px 10px;
`;

const noMarginButton = css`
  margin: 0px;
`;

const SeeAll = ({ openAdvancedSearchDrawer, searchResults, closeDropdown, MAX_SIZE }) => {
  const clickSeeAll = () => {
    closeDropdown();
    openAdvancedSearchDrawer();
  };
  return (
    <div className={allResults} key={`dropdown-selectAll`}>
      {searchResults.length > MAX_SIZE ? (
        <Fragment>
          <p className={noMargin}>
            <i>And {searchResults.length - MAX_SIZE} More...</i>
          </p>
          <button className={cx(anchorButton, noMarginButton)} onClick={clickSeeAll}>
            <i>Click here to see all</i>
          </button>
        </Fragment>
      ) : (
        <button className={cx(anchorButton, noMarginButton)} onClick={clickSeeAll}>
          <i>Click here to see search more details of {searchResults.length} results</i>
        </button>
      )}
    </div>
  );
};

export default SeeAll;
