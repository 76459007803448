export const findRemovedKeysFromDict = (oldDict, newDict) => {
  const removedKeys = {};
  Object.keys(oldDict).forEach((key) => {
    if (!newDict[key]) {
      removedKeys[key] = oldDict[key];
    }
  });
  return removedKeys;
};

export const findAddedKeysToDict = (oldDict, newDict) => {
  const addedKeys = {};
  Object.keys(newDict).forEach((key) => {
    if (!oldDict[key]) {
      addedKeys[key] = newDict[key];
    }
  });
  return addedKeys;
};

export const removeAddedAndRemoved = (keyList, listToRemove) => {
  const filtered = {};
  Object.keys(keyList).forEach((key) => {
    if (!listToRemove[key]) {
      filtered[key] = keyList[key];
    }
  });

  return filtered;
};

export const compareUnitOfMeasure = (oldAttribute, newAttribute) => {
  if (oldAttribute.unitOfMeasure !== newAttribute.unitOfMeasure) {
    return {
      oldUnitOfMeasure: oldAttribute.unitOfMeasure,
      newUnitOfMeasure: newAttribute.unitOfMeasure,
    };
  }

  return null;
};
