import { defineMessages } from 'react-intl';

export default defineMessages({
  createChild: {
    id: 'HamSelector.createChild',
    defaultMessage: 'Create child set',
  },
  createNewCondition: {
    id: 'HamSelector.createNewCondition',
    defaultMessage: 'Create New Top Level Condition',
  },
  selectOrCreateNewCondition: {
    id: 'HamSelector.selectOrCreateNewCondition',
    defaultMessage: 'Select or Create a new Condition Set',
  },
  selectConditionSetToLink: {
    id: 'HamSelector.selectConditionSetToLink',
    defaultMessage: 'Select Condition Set to Link',
  },
  createOrEditCondition: {
    id: 'HamSelector.createOrEditCondition',
    defaultMessage: 'Create/Edit Conditions',
  },
});
