import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { cx, css } from 'emotion';

import { ocean, alloy, shale } from '@cimpress/react-components/lib/colors';
import InlineEdit from '@cimpress/react-components/lib/InlineEdit';

import { valueShape } from '../../shared/propTypes';
import { convertHexToDecimal, validateHexCode } from '../../shared/HexConversion/hexConverter';
import messages from './messages';

const tagCss = css`
  border: 1px solid ${alloy};
  display: flex;
  align-items: center;
  background-color: white;
  color: ${shale};
  border-radius: 12px;
  font-size: 10px;
  line-height: 14px;
  padding: 5px 9px;
  margin: 3px;
  flex-shrink: 1;
`;

const xButton = css`
  border: none;
  background-color: transparent;
  line-height: 14px;
  font-weight: 100;
  font-size: 10px;
  color: ${ocean.base};
  padding: 0px;

  &:hover {
    color: ${alloy};
  }
`;

const inlineEditStyle = css`
  display: inline-block;
  font-size: 14px;

  .inline-edit-group {
    min-width: 50px;
    margin-bottom: 5px !important;

    input {
      padding: inherit;
    }

    .resting-text {
      margin-top: 5px;
    }

    .error-message {
      top: 100%;
    }
  }
`;

const inlineEditStyleWhenEditing = css`
  .inline-edit-group {
    margin-bottom: 15px !important;

    .error-message {
      top: 110%;
    }
  }
`;

export const HexValuePill = ({ hexValue, canEdit, onValueRemoved, onValueChanged }) => {
  const { formatMessage } = useIntl();
  const [existingValues] = useState([]);
  const [isEditing, updateEditState] = useState(false);

  const onRemoveClicked = () => {
    onValueRemoved(hexValue);
  };

  const validateText = (inputText) => {
    if (inputText) {
      if (existingValues.includes(inputText.toLowerCase())) {
        return formatMessage(messages.duplicateValue);
      }

      if (!validateHexCode(inputText) || !convertHexToDecimal(inputText)) {
        return formatMessage(messages.invalidHexValue);
      }
    }

    return null;
  };

  const updateValue = (inputValue) => {
    const updatedValue = { ...hexValue, value: inputValue.value };
    onValueChanged(hexValue, updatedValue);
  };

  const minWidthEdit = 50;
  const label = canEdit ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          width: '12px',
          height: '12px',
          backgroundColor: hexValue.value,
          marginRight: '5px',
          marginLeft: '5px',
          border: `1px solid ${alloy}`,
        }}></div>
      <div className={isEditing ? cx(inlineEditStyle, inlineEditStyleWhenEditing) : inlineEditStyle}>
        <InlineEdit
          value={hexValue.value}
          validateInput={validateText}
          onSave={updateValue}
          minWidth={minWidthEdit}
          required
          onEditStateChange={updateEditState}
          data-testid="InlineEdit"
        />
      </div>
    </div>
  ) : (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <span
        style={{
          width: '12px',
          height: '12px',
          backgroundColor: hexValue.value,
          marginRight: '5px',
          marginLeft: '5px',
          border: `1px solid ${alloy}`,
        }}></span>
      {hexValue.value}
    </span>
  );

  return (
    <span className={tagCss}>
      {!isEditing ? (
        <button className={xButton} onClick={onRemoveClicked}>
          x
        </button>
      ) : null}
      {label}
    </span>
  );
};

HexValuePill.propTypes = {
  decimalValue: PropTypes.shape(valueShape).isRequired,
  canEdit: PropTypes.bool,
  onValueRemoved: PropTypes.func.isRequired,
  onValueChanged: PropTypes.func,
};

HexValuePill.defaultProps = {
  decimalValue: {},
  canEdit: false,
};
