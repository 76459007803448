import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { css } from 'emotion';
import isEmpty from 'lodash/isEmpty';

import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import Select from '@cimpress/react-components/lib/Select';

import { getCatalogsByAccount } from '../../../services/productService';
import messages from './messages';

const flex = css`
  display: flex;
  align-items: center;
`;

const pMargin = css`
  margin: 0 10px 4px;
`;

const CatalogDropdown = ({ account, accessToken, catalogForSearch, setCatalogForSearch, catalogs, setCatalogs }) => {
  const { formatMessage } = useIntl();
  const [loadingCatalogs, setLoadingCatalogs] = useState(isEmpty(catalogs));

  useEffect(() => {
    const fetchCatalogs = async () => {
      try {
        const allCatalogs = await getCatalogsByAccount(account.id, accessToken);

        if (allCatalogs && allCatalogs._embedded && allCatalogs._embedded.item) {
          if (isEmpty(allCatalogs._embedded.item)) {
            setCatalogs({});
          } else {
            const catalogObj = {};
            allCatalogs._embedded.item.forEach((x) => (catalogObj[x.name] = x));
            setCatalogs(catalogObj);
          }
        }

        setLoadingCatalogs(false);
      } catch (error) {
        setCatalogs({});
        setLoadingCatalogs(false);
      }
    };

    if (!isEmpty(accessToken) && !isEmpty(account) && account.id) {
      fetchCatalogs();
    }
  }, [accessToken, setCatalogs, setLoadingCatalogs, account]);

  const changeSelectedCatalog = (catalog) => {
    setCatalogForSearch(catalog);
  };

  return (
    <div>
      {loadingCatalogs ? (
        <div className={flex}>
          <Spinner size="medium" /> <p className={pMargin}>{formatMessage(messages.fetchingCatalogs)}</p>
        </div>
      ) : isEmpty(catalogs) ? (
        <h5>{formatMessage(messages.noCatalogsFound)}</h5>
      ) : (
        <Select
          isClearable
          label={formatMessage(messages.catalog)}
          value={catalogForSearch}
          options={Object.keys(catalogs).map((x) => {
            return { value: catalogs[x], label: x };
          })}
          onChange={changeSelectedCatalog}
        />
      )}
    </div>
  );
};

export default CatalogDropdown;
