import isEmpty from 'lodash/isEmpty';

import { productTypes, errorTypes } from '../constants/searchConstants';
import { followCategoryLink } from '../../../services/noApi';
import { getProduct, getProductVersion, getCatalogsByProduct } from '../../../services/productService';
import { searchOverStandards } from '../../../services/possibilities';

export const checkV2Product = async (accessToken, productId) => {
  const v2Response = await getProduct({ accessToken, productId }).catch((err) => {
    return null;
  });
  if (v2Response) {
    if (v2Response.status === 'draft') {
      return {
        productType: productTypes.V2_FULFILLMENT,
        productId: v2Response.productId,
        errorReason: errorTypes.PRODUCT_IS_DRAFT,
      };
    }

    if (v2Response.status === 'retired') {
      return {
        productType: productTypes.V2_FULFILLMENT,
        productId: v2Response.productId,
        errorReason: errorTypes.PRODUCT_IS_RETIRED,
      };
    }
    return await getProductVersion({ productId, version: v2Response.current || v2Response.latest, accessToken })
      .then(async (response) => {
        const productToReturn = {
          productType: response.isStandard ? productTypes.STANDARD : productTypes.V2_FULFILLMENT,
          productId: productId,
          version: response.version,
          name: response.name,
          isStandard: response.isStandard,
          hasCurrent: !!v2Response.current,
          standardId: response.isStandard ? response.standardId : null,
        };

        if (response.standardReference) {
          return {
            ...productToReturn,
            productType: productTypes.ADOPTION,
            productId,
            errorReason: errorTypes.PRODUCT_IS_ADOPTION,
          };
        }

        if (response.isStandard) {
          const standard = await searchOverStandards(accessToken, productId);
          if (!isEmpty(standard)) {
            standard.forEach((item) => {
              if (item.productId === productId) {
                productToReturn.link = `https://productmanager-v2.products.cimpress.io/standards-overview/${item.standardId}`;
              }
            });
          }
        } else {
          productToReturn.link = `https://productmanager-v2.products.cimpress.io/product-details-v2/${productId}`;
        }

        try {
          if (
            response &&
            response.relatedResources &&
            response.relatedResources.productCategory &&
            response.relatedResources.productCategory.length > 0
          ) {
            const category = await followCategoryLink(response.relatedResources.productCategory[0].href, accessToken);
            productToReturn.category = {
              name: category.name,
              id: category.categoryId,
            };
          }
        } catch (e) {
          console.error(e);
        }

        const catalogs = await getCatalogsByProduct({ productId, accessToken }).catch((err) => {
          console.error(err);
          return productToReturn;
        });

        if (catalogs && catalogs._embedded && catalogs._embedded.item && catalogs._embedded.item.length > 0) {
          productToReturn.catalogs = catalogs._embedded.item.reduce(function (acc, curr) {
            acc[curr.id] = {
              id: curr.id,
              name: curr.name,
            };
            return acc;
          }, {});
        }

        return productToReturn;
      })
      .catch((err) => {
        console.error(err);
        return {
          productType: productTypes.V2_FULFILLMENT,
          productId,
          errorReason: errorTypes.API_ERROR,
          errorBody: err,
        };
      });
  }

  return {};
};
