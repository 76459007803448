import { defineMessages } from 'react-intl';

export default defineMessages({
  current: {
    id: 'AncestorAccordions.current',
    defaultMessage: 'Current',
  },
  yes: {
    id: 'AncestorAccordions.yes',
    defaultMessage: 'Yes',
  },
  cancel: {
    id: 'AncestorAccordions.cancel',
    defaultMessage: 'Cancel',
  },
  retired: {
    id: 'AncestorAccordions.retired',
    defaultMessage: 'Retired',
  },
  sureToChangeModalTitle: {
    id: 'AncestorAccordions.sureToChangeModalTitle',
    defaultMessage: 'Are you sure you want to change the version from {fromValue} to {toValue}?',
  },
  changeModalBody: {
    id: 'AncestorAccordions.changeModalBody',
    defaultMessage:
      'There could be some orders that get affected if you modify the version or this change of version can affect other FCs that are associated with it.',
  },
  pinToCurrent: {
    id: 'AncestorAccordions.pinToCurrent',
    defaultMessage: 'Pin to Current',
  },
  viewProduct: {
    id: 'AncestorAccordions.viewProduct',
    defaultMessage: 'View Product',
  },
  viewAdoption: {
    id: 'AncestorAccordions.viewAdoption',
    defaultMessage: 'View Adoption',
  },
  viewStandard: {
    id: 'AncestorAccordions.viewStandard',
    defaultMessage: 'View Standard',
  },
  accordionTitle: {
    id: 'AncestorAccordions.accordionTitle',
    defaultMessage: 'See Condition details',
  },
});
