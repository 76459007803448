import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import fetchWithAuth from './fetchWithAuth';
import { followLink as followGenericLink } from './halHelper';
import { queryOperatorTypes } from '../components/ProductLinker/constants/searchConstants';

const baseUrl = 'https://links.products.cimpress.io';
const groupBaseUrl = 'https://groups.products.cimpress.io';
const constraintBaseUrl = 'https://constraints.products.cimpress.io';

export const queryProductLink = async ({
  accessToken,
  queryOperatorType = queryOperatorTypes.AND,
  name,
  accountId,
  createdBy,
  relatedResourceType,
  productIds,
  productNames,
  catalogIds,
  excludeCatalogs = false,
  limit = 1000,
}) => {
  const query = queryString.stringify(
    {
      name,
      accountId,
      queryOperatorType,
      createdBy,
      relatedResourceType,
      productIds,
      productNames,
      catalogIds,
      excludeCatalogs,
      limit,
    },
    { skipNull: true }
  );
  const route = `v1/productLinks${!isEmpty(query) ? `?${query}` : ''}`;

  let productLinkResponse = await fetchWithAuth({
    baseUrl,
    route,
    additionalHeaders: { 'Cache-Control': 'no-cache' },
    accessToken,
  });
  return productLinkResponse;
};

export const followLink = async ({ href, accessToken, shouldCache = false }) => {
  if (href.includes('https://links.products.cimpress.io/v1/productLinks/')) {
    return await followGenericLink({ href, accessToken, shouldCache });
  }
  return Promise.reject('Unrecognized link');
};

export const createProductLink = async ({ accessToken, name, accountId, groupId, constraintId }) => {
  const body = {
    name,
    owner: {
      accountId,
    },
    productGroups: [{ id: groupId, href: groupBaseUrl + `/v1/groups/${groupId}` }],
  };

  if (constraintId !== '') {
    body.constraints = [{ id: constraintId, href: constraintBaseUrl + `/v1/constraints/${constraintId}` }];
  }

  return await fetchWithAuth({
    baseUrl,
    route: 'v1/productLinks',
    method: `POST`,
    body,
    accessToken,
  });
};

export const updateProductLink = async ({ accessToken, name, accountId, groupId, constraintId, productLinkId }) => {
  const body = {
    name,
    owner: {
      accountId,
    },
    productGroups: [{ id: groupId, href: groupBaseUrl + `/v1/groups/${groupId}` }],
  };
  if (!isEmpty(constraintId) && constraintId !== '') {
    body.constraints = [{ id: constraintId, href: constraintBaseUrl + `/v1/constraints/${constraintId}` }];
  }

  return await fetchWithAuth({
    baseUrl,
    route: `v1/productLinks/${productLinkId}`,
    method: `PUT`,
    body,
    accessToken,
  });
};

export const getProductLinkById = async ({ productLinkId, accessToken }) => {
  return await fetchWithAuth({
    baseUrl,
    route: `v1/productLinks/${productLinkId}`,
    accessToken,
  });
};

export const putProductLinkById = async ({ accessToken, productLinkId, productLink }) => {
  return await fetchWithAuth({
    baseUrl,
    route: `v1/productLinks/${productLinkId}`,
    method: `PUT`,
    body: productLink,
    accessToken,
  });
};

export const addRelatedResourcesToProductLink = async ({ accessToken, relatedResources, productLinkId }) => {
  const productLinkBody = await getProductLinkById({ accessToken, productLinkId });

  let newResources = [];

  relatedResources.forEach((newResource) => {
    let shouldAdd = true;
    productLinkBody.relatedResources.forEach((oldResource) => {
      if (isEqual(oldResource, newResource) && shouldAdd) {
        shouldAdd = false;
      }
    });
    if (shouldAdd) {
      newResources.push(newResource);
    }
  });

  productLinkBody.relatedResources = newResources.concat(productLinkBody.relatedResources);

  return await putProductLinkById({ accessToken, productLinkId, productLink: productLinkBody });
};

export const removeRelatedResourceFromProductLink = async ({ accessToken, relatedResources, productLinkId }) => {
  const productLinkBody = await getProductLinkById({ accessToken, productLinkId });

  const resourcesAfterRemoval = [];

  relatedResources.forEach((resourceToremove) => {
    productLinkBody.relatedResources.forEach((resourceToCheck) => {
      if (!isEqual(resourceToCheck, resourceToremove)) {
        resourcesAfterRemoval.push(resourceToCheck);
      }
    });
  });

  productLinkBody.relatedResources = resourcesAfterRemoval;
  return await putProductLinkById({ accessToken, productLinkId, productLink: productLinkBody });
};

export const getRelatedResourceTypes = async ({ accessToken, limit, token, teamName }) => {
  const query = queryString.stringify({ limit, token, teamName }, { skipNull: true });

  return await fetchWithAuth({
    baseUrl,
    route: `v1/relatedResourceTypes${!isEmpty(query) ? `?${query}` : ''}`,
    method: `GET`,
    accessToken,
  });
};

export const getAllRelatedResourceTypes = async ({ accessToken }) => {
  let relatedResourceTypes = [];
  let nextToken = null;

  do {
    try {
      const response = await getRelatedResourceTypes({ accessToken, limit: 500, token: nextToken });
      nextToken = response.nextToken;
      relatedResourceTypes = relatedResourceTypes.concat(response._embedded.item);
    } catch (err) {
      console.warn(err);
    }
  } while (!isEmpty(nextToken));

  return relatedResourceTypes;
};
