import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { css, cx } from 'emotion';
import isNil from 'lodash/isNil';
import IconListBullets from '@cimpress-technology/react-streamline-icons/lib/IconListBullets';
import IconNumeric from '@cimpress-technology/react-streamline-icons/lib/IconNumeric';
import { shale } from '@cimpress/react-components/lib/colors';
import RuleDisplay from '../../../../shared/ruleDisplay';
import ShowMoreLess from './ShowMoreLess';
import { STRING } from '../../../../shared/enums/options';
import { flexParentCenter } from '../../../../shared/flexStyles';
import { tenPxMarginBottom, tenPxMarginLeft, tenPxMarginRight } from '../../../../shared/commonStyles';

import messages from '../../messages';

const INITIAL_ITEMS_SHOWN = 5;

const hrStyle = css`
  width: 100%;
  margin: 0;
`;

const selfAlignStart = css`
  align-self: flex-start;
  margin-left: 15px;
  margin-bottom: 10px;
`;

const flexParentCenterSpace = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AttributeTypeIcon = ({ attributeType, size = '2x' }) => {
  if (isNil(attributeType)) {
    return '';
  }

  const AttributeIcon = attributeType === STRING ? IconListBullets : IconNumeric;
  return (
    <div className={tenPxMarginRight}>
      <AttributeIcon size={size} color={shale} />
    </div>
  );
};

const PropertyOverviewCard = ({ property, additionalCss }) => {
  const { formatMessage } = useIntl();

  const renderPropertyAssignments = () => {
    const assignments = property.assignments;
    return assignments.map((assignment, index) => (
      <Fragment key={assignment.id}>
        {index > 0 && <hr className={hrStyle} />}
        <div className="card-block">
          <RuleDisplay
            conditions={assignment.conditions}
            result={assignment.result || assignment.formula || assignment.range}
            gridView={false}
          />
        </div>
      </Fragment>
    ));
  };

  return (
    <div className={cx('card', tenPxMarginBottom)}>
      <div className={cx('card-header', flexParentCenterSpace)}>
        <div className={flexParentCenter}>
          <Fragment>
            <span className={additionalCss}>
              {property.name}
              {property.unitOfMeasure ? ` (${property.unitOfMeasure})` : ''}
            </span>
            <div className={tenPxMarginLeft} />
            <AttributeTypeIcon attributeType={property.type} size="lg" />
          </Fragment>
        </div>
      </div>
      {property.staticAssignment ? (
        <div className="card-block">
          <RuleDisplay result={property.staticAssignment} gridView={false} additionalCss={additionalCss} />
        </div>
      ) : (
        <ShowMoreLess
          totalItemsToShow={property.assignments.length}
          initialItemsToShow={INITIAL_ITEMS_SHOWN}
          showMoreMessage={formatMessage(messages.showMore, {
            numOfShowMore:
              property.assignments && property.assignments.length > INITIAL_ITEMS_SHOWN
                ? property.assignments.length - INITIAL_ITEMS_SHOWN
                : 0,
          })}
          showLessMessage={formatMessage(messages.showLess)}
          buttonClassName={selfAlignStart}>
          {renderPropertyAssignments()}
        </ShowMoreLess>
      )}
    </div>
  );
};

export default PropertyOverviewCard;
