import { defineMessages } from 'react-intl';

export default defineMessages({
  seeXMore: {
    id: 'productLinkViewCard.seeXMore',
    defaultMessage: 'See {count} more',
  },
  seeLess: {
    id: 'productLinkViewCard.seeLess',
    defaultMessage: 'See less',
  },
  selectedProducts: {
    id: 'productLinkViewCard.selectedProducts',
    defaultMessage: 'Selected Products',
  },
  fetchingDetails: {
    id: 'productLinkViewCard.fetchingDetails',
    defaultMessage: 'Fetching Link Details',
  },
  productSelections: {
    id: 'viewAllProductsDrawer.productSelections',
    defaultMessage: 'Product Selections',
  },
  productIdHeader: {
    id: 'viewAllProductsDrawer.productIdHeader',
    defaultMessage: 'Product ID',
  },
  nameHeader: {
    id: 'viewAllProductsDrawer.nameHeader',
    defaultMessage: 'Name',
  },
  descriptionHeader: {
    id: 'viewAllProductsDrawer.descriptionHeader',
    defaultMessage: 'Description',
  },
  categoryHeader: {
    id: 'viewAllProductsDrawer.categoryHeader',
    defaultMessage: 'Category',
  },
  selectedProductsCount: {
    id: 'viewAllProductsDrawer.selectedProductsCount',
    defaultMessage: '{count} Selected Products',
  },
  closeDrawer: {
    id: 'viewAllProductsDrawer.closeDrawer',
    defaultMessage: 'Close',
  },
  catalogSelections: {
    id: 'viewAllProductsDrawer.catalogSelections',
    defaultMessage: 'Products in Selected Catalog',
  },
  catalog: {
    id: 'viewAllProductsDrawer.catalog',
    defaultMessage: 'Catalog:',
  },
  catalogTag: {
    id: 'viewAllProductsDrawer.catalogTag',
    defaultMessage: 'Catalog {name} -',
  },
});
