import React, { Fragment, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { css } from 'emotion';
import isEmpty from 'lodash/isEmpty';

import Alert from '@cimpress/react-components/lib/Alert';
import NavTab from '@cimpress/react-components/lib/NavTab';
import NavTabItem from '@cimpress/react-components/lib/NavTabItem';
import Accordion from '@cimpress/react-components/lib/Accordion';

import messages from '../../messages';
import {
  IDENTICAL,
  ADDED_PROPERTY,
  REMOVED_PROPERTY,
  UPDATED_PROPERTY,
} from '../../../../shared/DiffUtilities/properties';
import PropertyOverviewCard from './PropertyOverviewCard';
import PropertyBody from './PropertyBody';

const displayFlex = css`
  display: flex;
`;

const navTabStyle = css`
  flex: 1;
`;

const navBodyStyle = css`
  flex: 3;
  padding-left: 10px;
`;

const scrollBox = css`
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const lineThrough = css`
  text-decoration: line-through;
`;

const PropertiesDiffChecker = ({ propertyDiffs }) => {
  const { formatMessage } = useIntl();
  const [hasUpdate, setHasUpdate] = useState(false);
  const [addedProperties, setAddedProperties] = useState({});
  const [removedProperties, setRemovedProperties] = useState({});
  const [updatedProperties, setUpdatedProperties] = useState({});
  const [selectedTab, setSelectedTab] = useState(ADDED_PROPERTY);

  useEffect(() => {
    let addedProperties = {};
    let removedProperties = {};
    let updatedProperties = {};

    if (propertyDiffs) {
      Object.keys(propertyDiffs).forEach((key) => {
        switch (propertyDiffs[key].diffType) {
          case ADDED_PROPERTY:
            addedProperties[key] = propertyDiffs[key].newProperty;
            break;
          case REMOVED_PROPERTY:
            removedProperties[key] = propertyDiffs[key].oldProperty;
            break;
          case IDENTICAL:
            break;
          default:
            updatedProperties[key] = propertyDiffs[key];
        }
      });
    }

    if (Object.keys(addedProperties).length > 0) {
      setSelectedTab(ADDED_PROPERTY);
    } else if (Object.keys(removedProperties).length > 0) {
      setSelectedTab(REMOVED_PROPERTY);
    } else if (Object.keys(updatedProperties).length > 0) {
      setSelectedTab(UPDATED_PROPERTY);
    }

    setAddedProperties(addedProperties);
    setRemovedProperties(removedProperties);
    setUpdatedProperties(updatedProperties);
    setHasUpdate(!isEmpty(addedProperties) || !isEmpty(removedProperties) || !isEmpty(updatedProperties));
  }, [propertyDiffs]);

  const onClickAddedProperties = () => {
    setSelectedTab(ADDED_PROPERTY);
  };

  const onClickRemovedProperties = () => {
    setSelectedTab(REMOVED_PROPERTY);
  };

  const onClickUpdatedProperties = () => {
    setSelectedTab(UPDATED_PROPERTY);
  };

  const contentAdded = (
    <Fragment>
      {Object.keys(addedProperties).map((property, index) => {
        return <PropertyOverviewCard property={addedProperties[property]} key={`${property}_${index}`} />;
      })}
    </Fragment>
  );

  const contentRemoved = (
    <Fragment>
      {Object.keys(removedProperties).map((property, index) => {
        return (
          <PropertyOverviewCard
            property={removedProperties[property]}
            key={`${property}_${index}`}
            additionalCss={lineThrough}
          />
        );
      })}
    </Fragment>
  );

  const contentUpdated = () => {
    let isFirstAccordion = true;
    return (
      <Fragment>
        {Object.keys(updatedProperties).map((property, index) => {
          const accordion = (
            <Accordion
              customOpen={isFirstAccordion}
              title={property}
              key={`${property}_${index}`}
              bodyStyle={{
                padding: '10px 20px',
              }}>
              <div className={scrollBox}>
                <PropertyBody propertyData={updatedProperties[property]} />
              </div>
            </Accordion>
          );
          isFirstAccordion = false;
          return accordion;
        })}
      </Fragment>
    );
  };

  let tabContent;

  switch (selectedTab) {
    case ADDED_PROPERTY:
      tabContent = contentAdded;
      break;
    case REMOVED_PROPERTY:
      tabContent = contentRemoved;
      break;
    case IDENTICAL:
      break;
    default:
      tabContent = contentUpdated();
  }

  return (
    <Fragment>
      {hasUpdate ? (
        <div className={displayFlex}>
          <div className={navTabStyle}>
            <NavTab vertical={true}>
              <NavTabItem active={selectedTab === ADDED_PROPERTY}>
                <button onClick={onClickAddedProperties}>
                  {formatMessage(messages.addedProperties) + ` (${Object.keys(addedProperties).length})`}
                </button>
              </NavTabItem>
              <NavTabItem active={selectedTab === REMOVED_PROPERTY}>
                <button onClick={onClickRemovedProperties}>
                  {formatMessage(messages.removedProperties) + ` (${Object.keys(removedProperties).length})`}
                </button>
              </NavTabItem>
              <NavTabItem active={selectedTab === UPDATED_PROPERTY}>
                <button onClick={onClickUpdatedProperties}>
                  {formatMessage(messages.updatedProperties) + ` (${Object.keys(updatedProperties).length})`}
                </button>
              </NavTabItem>
            </NavTab>
          </div>
          <div className={navBodyStyle}>{tabContent}</div>
        </div>
      ) : (
        <Alert type="info" message={formatMessage(messages.noChangesToView)} dismissible={true} />
      )}
    </Fragment>
  );
};

export default PropertiesDiffChecker;
