import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { css } from 'emotion';

import Select from '@cimpress/react-components/lib/Select';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { success, warning } from '@cimpress/react-components/lib/colors';
import { PIN_TO_CURRENT } from '../../../../shared/enums/constants';
import messages from '../messages';

const dropDown = css`
  width: 130px;
`;

const optionValuesStyle = css`
  align-items: center;

  svg {
    margin-top: 0px;
  }
`;

const centerDiv = css`
  align-content: center;
  padding: 5px 5px 0 5px;
`;

const colorSuccess = css`
  color: ${success.base};
`;

const colorWarning = css`
  color: ${warning.base};
`;

export const VersionDropdown = ({
  productId,
  selectedProductUrl,
  getVersions,
  onChange,
  setProductLinkUrl,
  setLoading = () => {},
}) => {
  const { formatMessage } = useIntl();
  const [allVersionsDict, setAllVersionDict] = useState({});
  const [selectedValue, setSelectedValue] = useState();
  const [versionLoading, setVersionLoading] = useState(true);
  const versionValuesDict = {};

  const getProductLinkUrl = (productId, version) => {
    return `https://productmanager-v2.products.cimpress.io/product-details-V2/${productId}/versions/${version}`;
  };

  const getCurrentVersionLink = (productId) => {
    return `https://api.products.cimpress.io/v1/products/${productId}:current`;
  };

  const fetchVersions = useCallback(async () => {
    const versionResponse = await getVersions(productId);

    let currentVersion;
    let versions = [];
    if (versionResponse._embedded.item.length > 0) {
      versionResponse._embedded.item.forEach((version) => {
        if (version.current) {
          versions.unshift({ url: getCurrentVersionLink(productId) });
          currentVersion = version.version;
        }
        versions.push({
          version: version.version,
          current: version.current,
          status: version.status,
          url: version._links.self.href,
        });
      });
    }

    const versionsDict = {};
    let selectedVersion = false;

    versions.forEach((version) => {
      if (version.version) {
        if (selectedProductUrl === version.url) {
          setSelectedValue(version.version);
          setProductLinkUrl(getProductLinkUrl(productId, version.version));
          selectedVersion = true;
        }
        versionsDict[version.version] = version;
      } else {
        versionsDict[PIN_TO_CURRENT] = version;
      }
    });

    !selectedVersion && setSelectedValue();
    setAllVersionDict(versionsDict);

    if (!selectedVersion && selectedProductUrl && selectedProductUrl.includes('current')) {
      setSelectedValue(PIN_TO_CURRENT);
      if (currentVersion) {
        setProductLinkUrl(getProductLinkUrl(productId, currentVersion));
      }
    }

    setLoading(false);
    setVersionLoading(false);
  }, [productId, selectedProductUrl, getVersions, setLoading, setProductLinkUrl]);

  useEffect(() => {
    setVersionLoading(true);
  }, [productId, selectedProductUrl]);

  useEffect(() => {
    if (versionLoading) {
      fetchVersions();
    }
  }, [versionLoading, fetchVersions]);

  const onSelectChange = (e) => {
    const oldSelectedValue = selectedValue;

    onChange(e, oldSelectedValue, allVersionsDict[e.value]);
  };

  const ShowProductDetails = ({ version }) => {
    if (!version.version) {
      return formatMessage(messages.pinToCurrent);
    } else if (version.current) {
      return (
        <Fragment>
          <span className={colorSuccess}>{String(version.version + ` (${formatMessage(messages.current)})`)}</span>
        </Fragment>
      );
    } else if (version.status === 'retired') {
      return (
        <Fragment>
          <span className={colorWarning}>{String(version.version + ` (${formatMessage(messages.retired)})`)}</span>
        </Fragment>
      );
    } else {
      return String(version.version);
    }
  };

  const versionValues = Object.keys(allVersionsDict)
    .map((versionKey) => {
      versionValuesDict[versionKey] = {
        value: versionKey,
        label: (
          <div className={optionValuesStyle}>
            <ShowProductDetails version={allVersionsDict[versionKey]} />{' '}
          </div>
        ),
      };
      return versionValuesDict[versionKey];
    })
    .reverse();

  return versionLoading ? (
    <Spinner size="medium" />
  ) : (
    <div className={centerDiv}>
      <Select
        className={dropDown}
        label="Versions"
        options={versionValues}
        value={versionValuesDict[selectedValue]}
        maxMenuHeight={150}
        onChange={onSelectChange}
      />
    </div>
  );
};

export default VersionDropdown;
