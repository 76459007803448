import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { css, cx } from 'emotion';
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import Accordion from '@cimpress/react-components/lib/Accordion';
import IconAdd from '@cimpress-technology/react-streamline-icons/lib/IconAdd';
import { ocean, silver } from '@cimpress/react-components/lib/colors';
import Button from '@cimpress/react-components/lib/Button';

import './treeStyles.css';
import { VIEW, CREATE } from '../../shared/enums/constants';
import messages from './messages';

FileExplorerTheme.scaffoldBlockPxWidth = 20;
FileExplorerTheme.rowHeight = 30;
FileExplorerTheme.rowDirection = 'ltr';

const addIconPosition = css`
   {
    margin-right: 5px !important;
    height: 10px !important;
  }
`;
const addChildFont = css`
   {
    font-size: 12px;
  }
`;
const treeContainer = css`
   {
    height: 300px;
    overflow: auto;
  }
`;
// const createNewButton = css`
//   margin-bottom: 25px !important;
//   width: 100%;
//   border: 1px solid ${silver} !important;
// `;
const createNewButton = css`
  width: 100%;
  border: 1px solid ${silver} !important;
`;

const productNodeStyle = css`
  &:hover {
    cursor: default;
  }
`;

const adoptionNodeStyle = css`
  &:hover {
    cursor: pointer;
  }
`;

const selectedNodeSpan = css`
  font-weight: bold;
  color: ${ocean.base};
`;

export const HamSelector = ({
  mode = CREATE,
  treeData,
  onCreateNew,
  accordionOpen = true,
  onCreateChild,
  selectedNode,
  onSelect,
  selectorTitle,
  onAccordionHeaderClick,
  onEditClick,
}) => {
  const { formatMessage } = useIntl();

  const [tree, setTree] = useState([]);
  const [selectedTreeNode, setSelectedTreeNode] = useState({});
  const [title, setTitle] = useState(
    isEqual(mode, CREATE)
      ? formatMessage(messages.selectOrCreateNewCondition)
      : formatMessage(messages.selectConditionSetToLink)
  );

  useEffect(() => {
    setTree(treeData);
  }, [treeData]);

  useEffect(() => {
    setSelectedTreeNode(selectedNode);
  }, [selectedNode]);

  useEffect(() => {
    isEqual(mode, CREATE) && setTitle(selectorTitle);
  }, [selectorTitle, mode]);

  // const onCreateNewCondition = () => {
  //   setTitle(formatMessage(messages.createNewCondition));
  //   onCreateNew();
  // };

  const onEditConditions = () => {
    onEditClick();
  };

  return (
    <Fragment>
      <Accordion
        onHeaderClick={onAccordionHeaderClick}
        customOpen={accordionOpen}
        title={<span>{!isEmpty(title) ? title : formatMessage(messages.selectOrCreateNewCondition)}</span>}
        variant="ghost"
        bodyStyle={{ padding: '0 0 30px 0' }}>
        {/* <Button variant="anchor" onClick={() => onCreateNewCondition()} className={createNewButton}>
          {formatMessage(messages.createNewCondition)}
        </Button> */}
        {isEqual(mode, VIEW) && (
          <Button variant="anchor" onClick={() => onEditConditions} className={createNewButton}>
            {formatMessage(messages.createOrEditCondition)}
          </Button>
        )}
        <div style={{ margin: '25px 0 0 25px' }}>
          <SortableTree
            treeData={tree}
            getNodeKey={({ node }) => node.id}
            onChange={(tree) => setTree(tree)}
            theme={FileExplorerTheme}
            isVirtualized={false}
            canDrag={false}
            generateNodeProps={({ node, path }) => ({
              buttons: [
                isEqual(mode, CREATE) && (
                  <Fragment>
                    {!node.isStandard && (
                      <span onClick={() => onCreateChild(node, path)} className={addChildFont}>
                        <IconAdd weight="fill" color={ocean.base} size="sm" className={addIconPosition} />
                        {formatMessage(messages.createChild)}
                      </span>
                    )}
                  </Fragment>
                ),
              ],
              title: (
                <span
                  onClick={!node.isStandard && !node.isProduct ? () => onSelect(node, path) : null}
                  className={cx(
                    node.isProduct || node.isStandard ? productNodeStyle : adoptionNodeStyle,
                    !isEmpty(selectedTreeNode) && selectedTreeNode.title === node.title ? selectedNodeSpan : null
                  )}>
                  {node.title}
                </span>
              ),
            })}
            className={treeContainer}
          />
        </div>
      </Accordion>
    </Fragment>
  );
};

HamSelector.propTypes = {
  flatData: PropTypes.arrayOf(PropTypes.object),
};

HamSelector.defaultProps = {
  flatData: [],
};

export default HamSelector;
