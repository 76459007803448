import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { css } from 'emotion';
import get from 'lodash/get';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import Button from '@cimpress/react-components/lib/Button';
import Alert from '@cimpress/react-components/lib/Alert';

import AdvancedSearchDrawer from './AdvancedSearchDrawer';
import ProductSearchBar from './ProductSearchBar';
import ProductLinkCard from '../ProductLinkCard';
import { searchBarTypes, linkCreationErrorTypes } from '../constants/searchConstants';
import { LINK_MODE } from '../constants/linkModeConstants';
import { getIdFromHref } from '../utilities/helper';
import messages from './messages';

import { getConstraint } from '../../../services/constraintService';
import { getProductGroup, queryGroups } from '../../../services/productGroupService';
import { getCatalogById } from '../../../services/productService';
import { getConditionSetFromConstraints } from '../../ConditionSetSelector/ConditionSets/conditionSetHelpers';
import { productIdSearch, doAdvancedSearch } from '../utilities/productSearch';
import { createGroup, updateGroup } from '../../../services/productGroupService';
import {
  createProductLink,
  updateProductLink,
  queryProductLink,
  followLink,
} from '../../../services/productLinkService';
import { createConstraint } from '../../../services/constraintService';
import { getResultConstraintsFromConditionSet } from '../../ConditionSetSelector/ConditionSets/conditionSetHelpers';

const positionSaveButton = css`
  margin-top: 15px;
  float: right;
`;

const loaderStyle = css`
  text-align: center;
  margin-top: 10%;
`;

const catalogHref = 'https://api.products.cimpress.io/v1/catalogs/';
const productLinkHref = 'https://links.products.cimpress.io/v1/productLinks/';

const NewLinkTab = ({
  account,
  productTypes,
  standardsHaveAdoptions,
  attributeAggregationStyle, // needed for product link card for constraint area
  accessToken,
  mode,
  setMode,
  selectedProductLink,
  onSelectProductLink,
  allowCatalogSelection,
  selectionsChanged,
}) => {
  const { formatMessage } = useIntl();
  const [advancedSearchDrawerIsOpen, setAdvancedSearchDrawerIsOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchType, setSearchType] = useState();
  const [searchErrors, setSearchErrors] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchDropdownOpen, setSearchDropdownOpen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [keywordPreset, setKeywordPreset] = useState('');
  const [showSelectedProducts, setShowSelectedProducts] = useState(false);
  const [showProductLinkCard, setShowProductLinkCard] = useState(false);
  const [selectedCatalog, setSelectedCatalog] = useState({});
  const [conditionSet, setConditionSet] = useState({ name: '', conditions: [] });
  const [loader, setLoader] = useState(true);
  const [selectionName, setSelectionName] = useState('');
  const [attributes, setAttributes] = useState({});
  const [isNameUnique, setIsNameUnique] = useState(true);
  const [canSavePlink, setCanSavePlink] = useState(true);
  const [creationErrorType, setCreationErrorType] = useState('');
  const [groupId, setGroupId] = useState('');

  const closeDrawer = () => {
    setAdvancedSearchDrawerIsOpen(false);
    setKeywordPreset('');
    setSearchResults([]);
    setSearchErrors([]);
    setSearchType(null);
    setSearchDropdownOpen(false);
    setSearchString('');
  };
  const openDrawer = () => setAdvancedSearchDrawerIsOpen(true);

  const viewSelectedProducts = () => setShowSelectedProducts(true);
  const viewAdvancedSearch = () => setShowSelectedProducts(false);

  useEffect(() => {
    const fetchCatalog = async (productGroup) => {
      try {
        let catalogId = productGroup.productCatalogs[0].id;
        if (!isEmpty(catalogId)) {
          const catalogResponse = await getCatalogById({ catalogId, accessToken });
          const decoratedProducts = await doAdvancedSearch({
            accessToken,
            accountId: account.id,
            standardsHaveAdoptions,
            productTypes,
            catalogId,
          });
          const selectedCatalogTemp = {};
          selectedCatalogTemp.catalog = { name: catalogResponse.name, id: catalogResponse.id };
          selectedCatalogTemp.products = decoratedProducts.products.reduce(function (acc, curr) {
            acc[curr.productId] = curr;
            return acc;
          }, {});
          return selectedCatalogTemp;
        }
      } catch (e) {
        console.error(e);
        setLoader(false);
      }
    };

    const getProducts = async (productsList) => {
      try {
        const allProducts = productsList.map((product) => product.id);
        const decoratedProducts = await productIdSearch(accessToken, account.id, allProducts.join(','), productTypes);
        return decoratedProducts.products;
      } catch (e) {
        console.error(e);
        setLoader(false);
      }
    };

    const fetchProductLink = async () => {
      try {
        let plink = await followLink({ href: selectedProductLink.productLinkHref, accessToken });

        let productGroup = await getProductGroup({
          productGroupId: plink.productGroups[0].id,
          accessToken,
        });
        !isEmpty(productGroup) && setGroupId(plink.productGroups[0].id);
        let catalog, products;
        if (!isEmpty(productGroup)) {
          if (!isEmpty(productGroup.productCatalogs)) {
            catalog = await fetchCatalog(productGroup);
          }
          const productsList = get(productGroup, 'staticIdentifiers.products');
          if (!isEmpty(productsList)) {
            products = await getProducts(productsList);
          }
          setShowProductLinkCard(true);
        }

        if (!isEmpty(plink.constraints)) {
          let constraints = await getConstraint({ constraintId: plink.constraints[0].id, accessToken });
          const conditionSet = getConditionSetFromConstraints(constraints);
          setConditionSet({ name: conditionSet.name ? conditionSet.name : '', conditions: conditionSet.conditions });
        }
        !isEmpty(products) && setSelectedProducts(products);
        !isEmpty(catalog) && setSelectedCatalog(catalog);
        setLoader(false);
      } catch (e) {
        console.error(e);
        setLoader(false);
      }
    };

    if ((mode === LINK_MODE.COPY || mode === LINK_MODE.EDIT) && !isEmpty(selectedProductLink)) {
      fetchProductLink();
    } else {
      setLoader(false);
    }
  }, [mode, selectedProductLink, accessToken, account, productTypes, standardsHaveAdoptions]);

  useEffect(() => {
    /**
     * Want to show link card when the first product is selected, but not if the dropdown is open.
     * After the link card is open, we do not want to close it until all products/conditions have been removed.
     */
    if (!showProductLinkCard) {
      if ((!isEmpty(selectedProducts) || !isEmpty(selectedCatalog)) && !searchDropdownOpen) {
        setShowProductLinkCard(true);
      }
    } else {
      if (
        isEmpty(selectedProducts) &&
        isEmpty(selectedCatalog) &&
        isEmpty(conditionSet.conditions) &&
        !searchDropdownOpen
      ) {
        setShowProductLinkCard(false);
      }
    }

    // let showWarning = false; // add this back in when we do something with showWarning
    selectedProducts.forEach((product) => {
      if (product.productType === 'STANDARD') {
        // showWarning = true; // add this back in when we do something with showWarning
      }
    });
    // Disabling for now because we dont want this to trigger when conditions are updated
  }, [searchDropdownOpen, searchResults, selectedProducts]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEmpty(selectedCatalog) || !isEmpty(selectedProducts)) {
      setCanSavePlink(true);
    }
  }, [selectedCatalog, selectedProducts]);

  const updateSelections = () => {
    let returnValue = { products: [], catalogs: [] };
    if (!isEmpty(selectedCatalog)) {
      returnValue.products = [];
      let productList = [];
      if (selectedCatalog.products) {
        Object.keys(selectedCatalog.products).forEach((key) => {
          productList.push({
            productId: selectedCatalog.products[key].productId,
            isStandard: selectedCatalog.products[key].standardId ? true : false,
          });
        });
      }
      returnValue.products = productList;
      returnValue.catalogs = [{ catalogId: selectedCatalog.catalog.id, catalogName: selectedCatalog.catalog.name }];
    } else if (selectedProducts && selectedProducts.length >= 0) {
      returnValue.products = selectedProducts.map((x) => {
        return { productId: x.productId, isStandard: x.standardId ? true : false };
      });
    }
    selectionsChanged(returnValue);
  };

  useEffect(() => {
    const validateGroupName = async () => {
      const groupResults = await queryGroups({ accessToken, groupName: selectionName });
      const matchingGroups = get(groupResults, '_embedded.item');
      const groupMatch = filter(matchingGroups, (group) => isEqual(group.name, selectionName));
      if (isEmpty(groupMatch)) {
        setIsNameUnique(true);
        (!isEmpty(selectedProducts) || !isEmpty(selectedCatalog)) && setCanSavePlink(true);
      } else {
        setIsNameUnique(false);
        setCanSavePlink(false);
      }
    };

    const validateSelectionName = async () => {
      const searchResults = await queryProductLink({ accountId: account.id, accessToken, selectionName });
      const matchingSelections = get(searchResults, '_embedded.item');
      const matches = filter(matchingSelections, (selection) => isEqual(selection.productLinkName, selectionName));
      if (isEmpty(matches)) {
        validateGroupName();
      } else {
        setIsNameUnique(false);
        setCanSavePlink(false);
      }
    };

    if (!isEmpty(selectionName) && mode !== LINK_MODE.EDIT) {
      validateSelectionName();
    }
  }, [selectionName, accessToken, account, selectedCatalog, selectedProducts, mode]);

  const dropdownClosed = () => {
    setSearchDropdownOpen(false);
  };

  const closeDropdownAndClearSearch = () => {
    setSearchDropdownOpen(false);
    clearSearchState();
  };

  const clearSearchState = () => {
    setSearchString('');
    setSearchResults([]);
    setSearchErrors([]);
  };

  const dropdownOpen = useCallback(() => {
    setSearchDropdownOpen(true);
  }, [setSearchDropdownOpen]);

  const openDrawerWithSelectedProducts = () => {
    viewSelectedProducts();
    openDrawer();
  };

  const openDrawerWithAdvancedSearch = () => {
    viewAdvancedSearch();
    openDrawer();
  };

  const openDrawerFromDropdown = () => {
    viewAdvancedSearch();
    setKeywordPreset(searchString);
    openDrawer();
  };

  const addSelectedProducts = (productsToAdd) => {
    const oldSelectedProducts = JSON.parse(JSON.stringify(selectedProducts));

    // dont add a selected product if it is already in the list
    productsToAdd.forEach((product) => {
      let found = false;
      for (let i = 0; i < oldSelectedProducts.length; i++) {
        if (oldSelectedProducts[i].productId === product.productId) {
          found = true;
        }
      }
      if (!found) {
        oldSelectedProducts.push(product);
      }
    });

    setSelectedProducts(oldSelectedProducts);
  };

  const removeSelectedCatalog = () => {
    setSelectedCatalog({});
  };

  const updateSelectedAdoptions = (standardProduct, selectedAdoptions) => {
    const copySelectedProducts = JSON.parse(JSON.stringify(selectedProducts));
    copySelectedProducts.forEach((product) => {
      if (product.productId === standardProduct) {
        product.selectedAdoptions = selectedAdoptions;
      }
    });

    setSelectedProducts(copySelectedProducts);
  };

  const removeSelectedProducts = (productsToRemove) => {
    const selectedProductsMinusRemoved = [];
    const productsToRemoveObject = productsToRemove.reduce(function (acc, curr) {
      acc[curr.productId] = curr;
      return acc;
    }, {});

    selectedProducts.forEach((product) => {
      if (!productsToRemoveObject[product.productId]) {
        selectedProductsMinusRemoved.push(product);
      }
    });

    setSelectedProducts(selectedProductsMinusRemoved);
  };

  const updateSearchResults = (newSearchResults, search) => {
    setKeywordPreset('');
    const searchResultsNoErrors = newSearchResults.products.filter((x) => !x.errorReason);
    const searchResultsWithErrors = newSearchResults.products.filter((x) => x.errorReason);

    if (newSearchResults.searchType === searchBarTypes.ID_SEARCH) {
      addSelectedProducts(searchResultsNoErrors);
      setSearchString('');
      setSearchType(newSearchResults.searchType);
      setSearchErrors(searchResultsWithErrors);
    }
    if (newSearchResults.searchType === searchBarTypes.KEYWORD_SEARCH) {
      setSearchString(search);
      setSearchType(newSearchResults.searchType);
      setSearchErrors(searchResultsWithErrors);
      setSearchResults(searchResultsNoErrors);
    }
  };

  const deleteProductLink = () => {
    setSelectedProducts([]);
    setSelectedCatalog({});
    setConditionSet({ name: '', conditions: [] });
    setSelectionName('');
    setShowProductLinkCard(false);
    setMode(LINK_MODE.NONE);
  };

  const onCreationFailed = (errorType, e) => {
    setCreationErrorType(errorType);
    setLoader(false);
    setCanSavePlink(false);
    console.error(e);
  };

  const createLink = () => {
    setLoader(true);
    const standards = [];
    const products = [];

    !isEmpty(selectedProducts) &&
      forEach(selectedProducts, (selectedProduct) => {
        if (selectedProduct && selectedProduct.isStandard) {
          const adoptions = !isEmpty(selectedProduct.selectedAdoptions)
            ? map(selectedProduct.selectedAdoptions, ({ productId }) => ({ id: productId }))
            : [];

          standards.push({
            id: selectedProduct.productId,
            adoptions,
          });
        } else {
          selectedProduct && products.push({ id: selectedProduct.productId });
        }
      });

    const catalog = !isEmpty(selectedCatalog)
      ? [
          {
            id: selectedCatalog.catalog && selectedCatalog.catalog.id,
            href: catalogHref + selectedCatalog.catalog.id,
          },
        ]
      : [];

    createGroup({
      accessToken,
      name: selectionName,
      products,
      standards,
      productCatalogs: catalog,
      accountId: account.id,
    })
      .then((group) => {
        let constraintId = '';
        if (!isEmpty(conditionSet.conditions)) {
          const rules = getResultConstraintsFromConditionSet(conditionSet, attributes, selectionName);
          createConstraint({
            constraint: { rules },
            accessToken,
          })
            .then((constraintResponse) => {
              constraintId = constraintResponse.id;
              createProductLink({
                accessToken,
                name: selectionName,
                accountId: account.id,
                groupId: group.id,
                constraintId,
              })
                .then((productLinkResponse) => {
                  const formatedProductLink = {
                    relatedResourceTypes: [],
                    productLinkHref: productLinkHref + productLinkResponse.id,
                    productLinkName: productLinkResponse.name,
                  };
                  setLoader(false);
                  onSelectProductLink(formatedProductLink);
                  updateSelections();
                })
                .catch((e) => {
                  onCreationFailed(linkCreationErrorTypes.PLINK_CREATION, e);
                });
            })
            .catch((e) => {
              onCreationFailed(linkCreationErrorTypes.CONDITION_CREATION, e);
            });
        } else {
          createProductLink({
            accessToken,
            name: selectionName,
            accountId: account.id,
            groupId: group.id,
            constraintId: '',
          })
            .then((productLinkResponse) => {
              const formatedProductLink = {
                relatedResourceTypes: [],
                productLinkHref: productLinkHref + productLinkResponse.id,
                productLinkName: productLinkResponse.name,
              };
              setLoader(false);
              onSelectProductLink(formatedProductLink);
              updateSelections();
            })
            .catch((e) => {
              onCreationFailed(linkCreationErrorTypes.PLINK_CREATION, e);
            });
        }
      })
      .catch((e) => {
        onCreationFailed(linkCreationErrorTypes.GROUP_CREATION, e);
      });
  };

  const updateLink = () => {
    setLoader(true);
    const products = !isEmpty(selectedProducts) ? selectedProducts.map(({ productId }) => ({ id: productId })) : [];
    const catalog = !isEmpty(selectedCatalog)
      ? [
          {
            id: selectedCatalog.catalog && selectedCatalog.catalog.id,
            href: catalogHref + selectedCatalog.catalog.id,
          },
        ]
      : [];

    updateGroup({
      productGroupId: groupId,
      accessToken,
      name: selectionName,
      products,
      productCatalogs: catalog,
      accountId: account.id,
    })
      .then((groupResponse) => {
        let constraintId = '';
        if (!isEmpty(conditionSet.conditions)) {
          const rules = getResultConstraintsFromConditionSet(conditionSet, attributes, selectionName);
          createConstraint({
            constraint: { rules },
            accessToken,
          })
            .then((constraintResponse) => {
              constraintId = constraintResponse.id;
              updateProductLink({
                accessToken,
                name: selectionName,
                accountId: account.id,
                groupId: groupResponse.id,
                constraintId,
                productLinkId: getIdFromHref(selectedProductLink.productLinkHref),
              })
                .then((productLinkResponse) => {
                  const formatedProductLink = {
                    relatedResourceTypes: [],
                    productLinkHref: productLinkHref + productLinkResponse.id,
                    productLinkName: productLinkResponse.name,
                  };
                  setLoader(false);
                  onSelectProductLink(formatedProductLink);
                  updateSelections();
                })
                .catch((e) => {
                  onCreationFailed(linkCreationErrorTypes.PLINK_CREATION, e);
                });
            })
            .catch((e) => {
              onCreationFailed(linkCreationErrorTypes.CONDITION_CREATION, e);
            });
        } else {
          updateProductLink({
            accessToken,
            name: selectionName,
            accountId: account.id,
            groupId: groupResponse.id,
            productLinkId: getIdFromHref(selectedProductLink.productLinkHref),
          })
            .then((productLinkResponse) => {
              const formatedProductLink = {
                relatedResourceTypes: [],
                productLinkHref: productLinkHref + productLinkResponse.id,
                productLinkName: productLinkResponse.name,
              };
              setLoader(false);
              onSelectProductLink(formatedProductLink);

              updateSelections();
            })
            .catch((e) => {
              onCreationFailed(linkCreationErrorTypes.PLINK_CREATION, e);
            });
        }
      })
      .catch((e) => {
        onCreationFailed(linkCreationErrorTypes.GROUP_CREATION, e);
      });
  };

  return (
    <Fragment>
      {loader ? (
        <div className={loaderStyle}>
          <Spinner />
          {mode === LINK_MODE.COPY || mode === LINK_MODE.EDIT ? (
            <p>{formatMessage(messages.fetchingProductLink)}</p>
          ) : (
            <p>{formatMessage(messages.savingProductLink)}</p>
          )}
        </div>
      ) : showProductLinkCard ? (
        <Fragment>
          {!isEmpty(creationErrorType) && (
            <Alert
              message={formatMessage(messages.creationFailed, { error: creationErrorType })}
              type="danger"
              dismissable={false}
            />
          )}
          <ProductLinkCard
            originalSelectionName={selectedProductLink.productLinkName}
            mode={mode}
            selectedProducts={selectedProducts}
            searchErrors={searchErrors}
            account={account}
            accessToken={accessToken}
            attributeAggregationStyle={attributeAggregationStyle}
            deleteProductLink={deleteProductLink}
            removeSelectedProducts={removeSelectedProducts}
            updateSelectedAdoptions={updateSelectedAdoptions}
            productTypes={productTypes}
            openDrawerWithSelectedProducts={openDrawerWithSelectedProducts}
            selectedCatalog={selectedCatalog}
            removeSelectedCatalog={removeSelectedCatalog}
            conditionSet={conditionSet}
            setConditionSet={setConditionSet}
            selectionName={selectionName}
            setSelectionName={setSelectionName}
            attributes={attributes}
            setAttributes={setAttributes}
            isNameUnique={isNameUnique}
            searchBarProps={{
              account,
              productTypes,
              standardsHaveAdoptions,
              accessToken,
              searchResults,
              searchErrors,
              selectedProducts,
              searchType,
              updateSearchResults,
              openAdvancedSearchDrawer: openDrawerWithAdvancedSearch,
              openDrawerFromDropdown,
              addSelectedProducts,
              removeSelectedProducts,
              dropdownClosed,
              dropdownOpen,
              closeDropdownAndClearSearch,
              advancedSearchIsOpen: advancedSearchDrawerIsOpen,
            }}
          />
          <Button
            type="primary"
            onClick={mode === LINK_MODE.EDIT ? updateLink : createLink}
            disabled={!canSavePlink}
            className={positionSaveButton}>
            {formatMessage(messages.savePlink)}
          </Button>
        </Fragment>
      ) : (
        <ProductSearchBar
          account={account}
          productTypes={productTypes}
          standardsHaveAdoptions={standardsHaveAdoptions}
          accessToken={accessToken}
          searchResults={searchResults}
          updateSearchResults={updateSearchResults}
          searchType={searchType}
          searchErrors={searchErrors}
          openAdvancedSearchDrawer={openDrawerWithAdvancedSearch}
          openDrawerFromDropdown={openDrawerFromDropdown}
          advancedSearchIsOpen={advancedSearchDrawerIsOpen}
          addSelectedProducts={addSelectedProducts}
          removeSelectedProducts={removeSelectedProducts}
          selectedProducts={selectedProducts}
          dropdownClosed={dropdownClosed}
          dropdownOpen={dropdownOpen}
          closeDropdownAndClearSearch={closeDropdownAndClearSearch}
        />
      )}

      <AdvancedSearchDrawer
        accessToken={accessToken}
        drawerIsOpen={advancedSearchDrawerIsOpen}
        closeDrawer={closeDrawer}
        account={account}
        productTypes={productTypes}
        standardsHaveAdoptions={standardsHaveAdoptions}
        showSelectedProduct={showSelectedProducts}
        searchResults={searchResults}
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
        viewSelectedProducts={viewSelectedProducts}
        viewAdvancedSearch={viewAdvancedSearch}
        updateSearchResults={updateSearchResults}
        keywordPresetSearch={keywordPreset}
        selectedCatalog={selectedCatalog}
        setSelectedCatalog={setSelectedCatalog}
        allowCatalogSelection={allowCatalogSelection}
      />
    </Fragment>
  );
};

export default NewLinkTab;
