import React, { useEffect, useState, useCallback } from 'react';
import differenceBy from 'lodash/differenceBy';
import flatMap from 'lodash/flatMap';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { Button, Snackbar } from '@cimpress/react-components';
import OptionMapper from './OptionMapper';
import DefaultMapper from './DefaultMapper';
import {
  optionHasRange,
  validateRange,
  lookupOption,
  convertToPayload,
  convertToV2Payload,
  isMappingComplete,
  convertMutationToUiData,
  autoMapSameValue,
  getValue,
  autoMapProducts,
} from './helper';
import UnmappedOptionAlert from './UnmappedOptionAlert';
import { useIntl } from 'react-intl';
import msg from './messages';
import { css } from 'emotion';
import { useBesClient } from './besClient';

/**
 * Mapping UI based on provided srcProduct and targetProduct
 * @param srcProduct {Product}
 * @param targetProduct {Product}
 * @param accessToken {string}
 * @param baseBesUrl {string} baseBesUrl for the service https://bes-staging.products.cimpress.io or https://bes.products.cimpress.io
 * @param onSubmit {Function} callback function to get BES payload output. Should be provided if makeRequest is false
 * @param makeRequest {boolean} if true mapping request will be sent to BES, else a validate button payload will appear. Outside of version to version mapping, caller should take the output call BES
 * @param buttonText {string} override default text for "Submit" button
 * @param mutationData {Mutations} optionMutations data to load into the mapper
 * @param fetchExistingData {boolean} fetches existing mapping data from BES
 * @param besPayloadVersion {'v1'|'v2'} BES data model version to output
 * @returns {JSX.Element}
 * @constructor
 */
export const ProductOptionMapper = ({
  srcProduct,
  targetProduct,
  accessToken,
  baseBesUrl = 'https://bes-staging.products.cimpress.io/',
  onSubmit = undefined,
  makeRequest = true,
  buttonText = undefined,
  mutations = undefined,
  fetchExistingData = false,
  besPayloadVersion = 'v1',
}) => {
  const [srcOpts, setSrcOpts] = useState(/** @type Option[] */ []);
  const [targetOpts, setTargetOpts] = useState(/** @type Option[] */ []);
  const [uiMapping, setUiMapping] = useState(/** @type OptionMapping[] */ []);
  const [targetUnmapped, setTargetUnmapped] = useState([]);
  const [srcUnmapped, setSrcUnmapped] = useState([]);
  const [optionDefaults, setOptionDefaults] = useState({});
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [warningMessage, setWarningMessage] = useState(undefined);
  const [successMessage, setSuccessMessage] = useState(undefined);
  const [infoMessage, setInfoMessage] = useState(undefined);
  const [isDoneWithAdditionDefaults, setIsDoneWithAdditionDefaults] = useState(false);
  const [isDoneWithRemovalDefaults, setIsDoneWithRemovalDefaults] = useState(false);
  const { formatMessage } = useIntl();
  const { putMapping, postValidation, getVersionEvolution } = useBesClient(baseBesUrl, accessToken);
  const [existingMutation, setExistingMutation] = useState(mutations || {});

  useEffect(() => {
    setExistingMutation(mutations);
  }, [mutations]);

  useEffect(() => {
    const srcOpts = (srcProduct || {}).options;
    setSrcOpts(srcOpts);
    const targetOpts = (targetProduct || {}).options;
    setTargetOpts(targetOpts);

    // Don't auto-map if optionMutations we have mapping data
    if (!existingMutation && !isEmpty(existingMutation)) {
      return;
    }

    setUiMapping(autoMapProducts(srcOpts, targetOpts));
    setOptionDefaults({});
  }, [srcProduct, targetProduct, existingMutation]);

  useEffect(() => {
    if (existingMutation || srcProduct.productId !== targetProduct.productId || !fetchExistingData) {
      return;
    }

    // Try to get existing mapping
    (async () => {
      let { isSuccess, data } = await getVersionEvolution(
        srcProduct.productId,
        srcProduct.version,
        targetProduct.version
      );
      if (isSuccess && data) {
        setExistingMutation(data);
        setSuccessMessage(<span>{formatMessage(msg.loadExistingDataSuccess)}</span>);
      } else {
        setInfoMessage(<span>{formatMessage(msg.loadExistingDataFail)}</span>);
      }
    })();
  }, [existingMutation, srcProduct, targetProduct, fetchExistingData, formatMessage, getVersionEvolution]);

  useEffect(() => {
    if (!existingMutation || isEmpty(existingMutation)) {
      return;
    }
    const { uiData, warning, defaults } = convertMutationToUiData(
      srcOpts,
      targetOpts,
      existingMutation.optionMutations || [],
      existingMutation.multipleOptionMutations || [],
      msg.loadedInvalidMapping
    );
    if (warning) {
      setWarningMessage(warning);
      setExistingMutation({});
      return;
    }
    setWarningMessage(undefined);
    setUiMapping(uiData);
    setOptionDefaults(defaults);
  }, [srcOpts, targetOpts, existingMutation]);

  /**
   * Update unmapped target option and options that need default
   */
  useEffect(() => {
    const targetMapped = flatMap(uiMapping, (elt) => elt.target.map((val) => ({ name: val })));
    const targetUnmapped = differenceBy(targetOpts, targetMapped, 'name').sort();
    const optWithSingleVal = targetUnmapped.filter(
      (opt) => opt.values && opt.values.length === 1 && opt.values[0].range === undefined
    );
    const newObjectDefaults = { ...optionDefaults };

    optWithSingleVal.forEach((opt) => (newObjectDefaults[opt.name] = getValue(opt.values[0])));

    setOptionDefaults(newObjectDefaults);
    setTargetUnmapped(targetUnmapped);

    // Disabling this until we can track down the folks who wrote this and ensure adding deps won't break anything
  }, [uiMapping]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Update unmapped src option
   */
  useEffect(() => {
    const srcMapped = flatMap(
      uiMapping.filter((elt) => elt.target && elt.target.length > 0),
      (elt) => elt.src.map((val) => ({ name: val }))
    );
    setSrcUnmapped(differenceBy(srcOpts, srcMapped, 'name').sort());

    // Disabling this until we can track down the folks who wrote this and ensure adding deps won't break anything
  }, [uiMapping]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Check if all defaults have been filled in
   */
  useEffect(() => {
    const missingDefaults = targetUnmapped.filter(
      (elt) =>
        optionDefaults[elt.name] === undefined ||
        (optionHasRange(elt.values) && !validateRange(optionDefaults[elt.name], elt.values))
    );
    const missingRemovalDefaults = srcUnmapped.filter(
      (elt) =>
        optionDefaults[elt.name] === undefined ||
        (optionHasRange(elt.values) && !validateRange(optionDefaults[elt.name], elt.values))
    );
    setIsDoneWithAdditionDefaults(missingDefaults.length === 0);
    setIsDoneWithRemovalDefaults(missingRemovalDefaults.length === 0);
  }, [srcUnmapped, targetUnmapped, optionDefaults]);

  const generatePayload = useCallback(() => {
    switch (besPayloadVersion) {
      case 'v1':
        return convertToPayload(srcProduct, targetProduct, uiMapping, optionDefaults);
      case 'v2':
        return convertToV2Payload(srcProduct, targetProduct, uiMapping, optionDefaults);
      default:
        console.error(`Unknown besPayloadVersion specified ${besPayloadVersion} defaulting to v1 payload`);
        return convertToPayload(srcProduct, targetProduct, uiMapping, optionDefaults);
    }
  }, [besPayloadVersion, optionDefaults, targetProduct, srcProduct, uiMapping]);

  const preSubmit = useCallback(() => {
    const isReady =
      uiMapping.filter((i) => !i.isCompleted).length === 0 && isDoneWithAdditionDefaults && isDoneWithRemovalDefaults;
    const payload = generatePayload();

    if (onSubmit !== undefined) {
      isReady ? onSubmit(payload) : onSubmit(false);
    }
    return { payload, isReady };
  }, [uiMapping, isDoneWithAdditionDefaults, isDoneWithRemovalDefaults, generatePayload, onSubmit]);

  async function submitMapping() {
    const { payload, isReady } = preSubmit();

    if (!makeRequest || !isReady) {
      return;
    }

    // TODO validate the code against making request all the time
    const res = await putMapping(srcProduct.productId, targetProduct.productId, payload);
    if (res.isSuccess) {
      setSuccessMessage(<span>{formatMessage(msg.payloadSubmitSuccess)}</span>);
    } else {
      setErrorMessage(<span>{formatMessage(msg.payloadSubmitFailure)}</span>);
    }
  }

  async function onSubmitValidation() {
    const payload = generatePayload();
    const { isSuccess } = postValidation(payload);
    if (isSuccess) {
      setSuccessMessage(<span>{formatMessage(msg.payloadValidationSuccess)}</span>);
    } else {
      setErrorMessage(<span>{formatMessage(msg.payloadValidationFailure)}</span>);
    }
  }

  useEffect(() => {
    preSubmit();
  }, [preSubmit]);

  function onMappingChanged(index, newOptionMappingData) {
    newOptionMappingData.isCompleted = isMappingComplete(newOptionMappingData);
    if (newOptionMappingData.isCompleted) {
      // Remove value mapping with dangling target
      newOptionMappingData.values = newOptionMappingData.values.filter(
        (elt) => elt.src.length > 0 && elt.target.length > 0
      );
    }
    const newMappingData = [...uiMapping];
    newMappingData[index] = newOptionMappingData;
    setUiMapping(newMappingData);
  }

  function onTargetOptionChanged(index, targetOptions) {
    const newMappingData = [...uiMapping];
    // TODO handle multiple options
    const newSrc = lookupOption(srcOpts, newMappingData[index].src);
    const target = lookupOption(targetOpts, targetOptions[0]);

    newMappingData[index] = autoMapSameValue(newSrc, target);
    setUiMapping(newMappingData.filter(isNotEmptyMapping));

    // remove already selected default
    const newDefault = Object.assign({}, optionDefaults);
    delete newDefault[target.name];
    setOptionDefaults(newDefault);
  }

  function onSourceOptionChanged(index, sourceOptions) {
    let newMappingData = [...uiMapping];
    const newSrc = lookupOption(srcOpts, sourceOptions);
    const target = lookupOption(targetOpts, newMappingData[index].target[0]);

    newMappingData[index] = autoMapSameValue(newSrc, target);
    const mappedSrcs = flatMap(
      newMappingData.filter((elt) => elt.target.length > 0 || elt.src.length > 1), // consider multiple options as "mapped"
      'src'
    );

    // TODO: Filtering will cause Accordion to reshuffle if user skip ahead change source options
    newMappingData = newMappingData.filter(
      (elt) =>
        (elt.target.length > 0 || mappedSrcs.includes(...elt.src)) &&
        !(elt.src.length === 1 && mappedSrcs.includes(...elt.src) && elt.target.length === 0)
    );

    let flattenSrcs = flatMap(newMappingData, (md) => md.src.map((elt) => ({ name: elt })));
    let unmappedSrcAttrs = differenceBy(srcOpts, flattenSrcs, 'name');
    for (let i = 0; i < unmappedSrcAttrs.length; i++) {
      const unmappedOption = [lookupOption(srcOpts, unmappedSrcAttrs[i].name)];
      newMappingData.push(autoMapSameValue(unmappedOption, undefined));
    }
    setUiMapping(newMappingData);
  }

  function isNotEmptyMapping(mapping) {
    return mapping.src.length !== 0 || mapping.target.length !== 0;
  }

  function selectComponent(data, index) {
    if (
      data.src.length === 1 &&
      data.target.length &&
      data.src[0].toLowerCase() === 'quantity' &&
      data.target[0].toLowerCase() === 'quantity'
    ) {
      return <></>;
    }
    // index is used as a key to update the data in the callback. Maintain the ordering of the list
    return (
      <OptionMapper
        key={index}
        mappingData={data}
        srcUnmapped={srcUnmapped}
        targetUnmapped={targetUnmapped.map((elt) => elt.name)}
        targetValues={(lookupOption(targetOpts, data.target[0]) || { values: [] }).values
          .filter((elt) => getValue(elt) !== undefined)
          .map((elt) => getValue(elt))}
        onValueMappingChanged={(mappingData) => onMappingChanged(index, mappingData)}
        onSourceOptionChanged={(srcOptions) => onSourceOptionChanged(index, srcOptions)}
        onTargetOptionChanged={(targetOptions) => onTargetOptionChanged(index, targetOptions)}
      />
    );
  }

  if (!srcProduct || !targetProduct) {
    return (
      <div
        className={css`
          text-align: center;
        `}>
        <h2>Pending product selections</h2>
      </div>
    );
  }

  if (targetOpts === undefined || srcOpts === undefined) {
    return (
      <div
        className={css`
          text-align: center;
        `}>
        <h2>Product contains undefined options</h2>
      </div>
    );
  }

  return (
    <React.Fragment>
      {/*display common attributes*/}
      {uiMapping.map(selectComponent)}

      {/*display anything else that exist on target product as needing default*/}
      <DefaultMapper
        opts={targetOpts.filter((opt) => find(targetUnmapped, { name: opt.name }) !== undefined)}
        optionDefaults={optionDefaults}
        onDefaultChanged={setOptionDefaults}
        isDoneWithDefaults={isDoneWithAdditionDefaults}
        product={targetProduct}
        shouldDisplayName={targetProduct.productId !== srcProduct.productId}
        isRemoval={false}
      />
      <DefaultMapper
        opts={srcOpts.filter((opt) => find(srcUnmapped, { name: opt.name }) !== undefined)}
        optionDefaults={optionDefaults}
        onDefaultChanged={setOptionDefaults}
        isDoneWithDefaults={isDoneWithRemovalDefaults}
        product={srcProduct}
        shouldDisplayName={targetProduct.productId !== srcProduct.productId}
        isRemoval={true}
      />
      {(srcUnmapped.length !== 0 ||
        targetUnmapped.filter((elt) => optionDefaults[elt.name] === undefined).length !== 0) && (
        <UnmappedOptionAlert
          srcProduct={srcProduct}
          targetProduct={targetProduct}
          srcUnmapped={srcUnmapped}
          targetUnmapped={targetUnmapped}
          optionDefaults={optionDefaults}
        />
      )}
      {(makeRequest || buttonText !== undefined) && (
        <Button
          disabled={
            uiMapping.filter((i) => !i.isCompleted).length > 0 ||
            !isDoneWithAdditionDefaults ||
            !isDoneWithRemovalDefaults
          }
          onClick={submitMapping}>
          {buttonText || formatMessage(msg.submit)}
        </Button>
      )}
      {accessToken && !makeRequest && !onSubmit && (
        <Button onClick={onSubmitValidation}>{formatMessage(msg.validatePayload)}</Button>
      )}
      {errorMessage && (
        <Snackbar bsStyle="danger" onHideSnackbar={() => setErrorMessage(undefined)} show={true}>
          {errorMessage}
        </Snackbar>
      )}
      {successMessage && (
        <Snackbar bsStyle="success" onHideSnackbar={() => setSuccessMessage(undefined)} show={true}>
          {successMessage}
        </Snackbar>
      )}
      {warningMessage && (
        <Snackbar bsStyle="warning" onHideSnackbar={() => setWarningMessage(undefined)} show={true}>
          {warningMessage}
        </Snackbar>
      )}
      {infoMessage && (
        <Snackbar bsStyle="info" onHideSnackbar={() => setInfoMessage(undefined)} show={true} delay={2000}>
          {infoMessage}
        </Snackbar>
      )}
    </React.Fragment>
  );
};
