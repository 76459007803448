import isEmpty from 'lodash/isEmpty';

import { productTypes } from '../constants/searchConstants';
import { searchSingleProductMerchant } from '../../../services/backboneSearch';

export const checkMerchantProduct = async (accessToken, productId) => {
  const merchantResponse = await searchSingleProductMerchant(accessToken, productId).catch((err) => {
    console.error(err);
    return null;
  });

  if (isEmpty(merchantResponse)) {
    return {};
  }

  return {
    ...merchantResponse,
    productType: productTypes.MERCHANT,
  };
};
