import { IDENTICAL, DIFFERENT } from './constants';

export const standardMetaDataDiffs = (oldStandard, newStandard) => {
  const diffs = {};
  if (oldStandard.name !== newStandard.name) {
    diffs.name = {
      type: DIFFERENT,
      oldValue: oldStandard.name,
      newValue: newStandard.name,
    };
  } else {
    diffs.name = {
      type: IDENTICAL,
      value: oldStandard.name,
    };
  }

  if (oldStandard.description !== newStandard.description) {
    diffs.description = {
      type: DIFFERENT,
      oldValue: oldStandard.description,
      newValue: newStandard.description,
    };
  } else {
    diffs.description = {
      type: IDENTICAL,
      value: oldStandard.description,
    };
  }

  if (oldStandard.relatedResources.productCategory.href !== newStandard.relatedResources.productCategory.href) {
    diffs.category = {
      type: DIFFERENT,
      oldValue: oldStandard.relatedResources.productCategory.href,
      newValue: newStandard.relatedResources.productCategory.href,
    };
  } else {
    diffs.category = {
      type: IDENTICAL,
      value: oldStandard.relatedResources.productCategory.href,
    };
  }

  return diffs;
};
