import find from 'lodash/find';
import get from 'lodash/get';
import pick from 'lodash/pick';
import keyBy from 'lodash/keyBy';
import isEqual from 'lodash/isEqual';
import toPairs from 'lodash/toPairs';
import forOwn from 'lodash/forOwn';
import groupBy from 'lodash/groupBy';
import flow from 'lodash/flow';
import fGroupBy from 'lodash/fp/groupBy';
import fToPairs from 'lodash/fp/toPairs';
import fMap from 'lodash/fp/map';
import map from 'lodash/map';
import fKeyBy from 'lodash/fp/keyBy';
import fMapValues from 'lodash/fp/mapValues';
import fFilter from 'lodash/fp/filter';
import fSortBy from 'lodash/fp/sortBy';
import fFlatMap from 'lodash/fp/flatMap';
import flatMap from 'lodash/flatMap';
import React from 'react';
import filter from 'lodash/filter';
import values from 'lodash/values';
import intersectionBy from 'lodash/intersectionBy';
import differenceBy from 'lodash/differenceBy';
import differenceWith from 'lodash/differenceWith';
import intersectionWith from 'lodash/intersectionWith';
import sortBy from 'lodash/sortBy';
import isString from 'lodash/isString';

export const toOption = (value) => {
  if (value === undefined || value === null) {
    return;
  }
  return { label: value, value: value };
};

export const optionHasRange = (optionValues) => {
  if (!optionValues) {
    return false;
  }
  return optionValues.filter((elt) => elt.range !== undefined).length > 0;
};

export const formatRange = (range) => {
  if (!range) {
    return '';
  }
  const parts = [];
  if (range.minimum !== undefined) {
    parts.push(`min: ${range.minimum}`);
  }
  if (range.maximum !== undefined) {
    parts.push(`max: ${range.maximum}`);
  }
  if (range.increment !== undefined) {
    parts.push(`inc: ${range.increment}`);
  }
  return parts.join(', ');
};

export const getValue = (optionValue) => {
  if (!optionValue) {
    return optionValue;
  }
  if (isString(optionValue)) {
    console.warn(`getValue got passed a string this might be incorrectly used. value: ${optionValue}`);
    return optionValue;
  }
  if (!optionValue) return undefined;
  if (get(optionValue, 'type', '').toLowerCase().includes('literal')) {
    return optionValue[optionValue.type];
  }
  return optionValue.value;
};

export const validateRange = (value, values) => {
  const validInRanges = values.filter((valDef) => {
    if (getValue(valDef) !== undefined) {
      return parseFloat(value) === parseFloat(getValue(valDef));
    }
    const range = valDef.range;
    const min = parseFloat(range.minimum);
    const max = parseFloat(range.maximum);
    value = parseFloat(value);
    if (range.increment) {
      const inc = parseFloat(range.increment);
      return value >= min && value <= max && (value - min) % inc === 0;
    }
    return value >= min && value <= max;
  });
  return validInRanges.length > 0;
};

export const lookupOption = (options, names) => {
  let isArray = Array.isArray(names);
  if (isArray) {
    return names.map((name) => find(options, { name: name })).filter((opt) => opt);
  }
  return find(options, { name: names });
};
/**
 * Compose BES mapping data
 * @param srcProduct {Product}
 * @param targetProduct {Product}
 * @param mappingData {OptionMapping[]}
 * @param optionDefaults {Object.<string, string>} dictionary of new options and their default values
 * @return {(BesVersionMapping|BesIrregularEvolution)}
 */

export const convertToPayload = (srcProduct, targetProduct, mappingData, optionDefaults) => {
  const targetOpts = targetProduct.options;
  const source = pick(srcProduct, ['productId', 'version', 'options']);

  const targetPayload = {
    target: pick(targetProduct, ['productId', 'version', 'options']),
    optionMutations: [],
    multipleOptionMutations: [],
    appliesTo: null,
  };
  const type = Object.freeze({ oneToOne: 0, optionRemoved: 1, multipleOptions: 2, unknown: 3 });

  for (let i = 0; i < mappingData.length; i++) {
    let optMapping = mappingData[i];
    let mutationType =
      optMapping.src.length === 1 && optMapping.target.length === 1
        ? type.oneToOne
        : optMapping.target.length === 0
        ? type.optionRemoved
        : optMapping.src.length >= 1 && optMapping.target.length === 1
        ? type.multipleOptions
        : type.unknown;
    if (mutationType === type.oneToOne) {
      let targetOption = find(targetOpts, { name: optMapping.target[0] });
      if (!targetOption) {
        console.log(`Couldn't find target:${optMapping.src[0]}`);
      }
      let mutation = {
        sourceOption: optMapping.src[0],
        targetOption: optMapping.target[0],
        valueMappings: [
          ...flatMap(optMapping.values, (val) => {
            if (val.src.length > 1) {
              return val.src.map((src) => ({
                source: src,
                target: val.target[0],
              }));
            }
            return {
              source: val.src[0],
              target: val.target[0],
            };
          }),
          ...toPairs(optMapping.removedValues.src)
            .filter(([, value]) => value && optMapping.mappingMode !== 'numeric') // a bit of a hack so acknowledged removal doesn't show up for numeric mapping
            .map(([key]) => ({ source: key, target: null })), // unmapped values
          ...targetOption.values
            .filter(
              (optionValue) =>
                getValue(optionValue) !== undefined &&
                !optMapping.values.map((valueMapping) => valueMapping.target[0]).includes(getValue(optionValue)) &&
                optMapping.mappingMode !== 'numeric'
            )
            .map((i) => ({ source: null, target: getValue(i) })),
          ...optMapping.automap,
        ],
      };
      targetPayload.optionMutations.push(mutation);
    } else if (mutationType === type.multipleOptions) {
      for (let i = 0; i < optMapping.values.length; i++) {
        let valMapping = optMapping.values[i];
        let targetOptionName = optMapping.target[0];
        valMapping.src.forEach((src) =>
          targetPayload.multipleOptionMutations.push({
            sourceOptions: optMapping.valLookup[src].map((opt) => ({ name: opt.attr, value: getValue(opt) })),
            targetOption: {
              name: targetOptionName,
              value: valMapping.target[0],
            },
          })
        );
      }
    } else if (mutationType === type.optionRemoved) {
      let removalSource = optMapping.src[0];
      let removalDefault = optionDefaults[removalSource];
      if (!removalDefault) {
        console.log(`Can't find default for ${removalSource}...`);
      }
      targetPayload.optionMutations.push({
        sourceOption: removalSource,
        targetOption: null,
        defaultValue: removalDefault,
      });
    } else if (mutationType === type.unknown) {
      console.log('unknown mutation type');
      console.log(optMapping);
    }
  }
  // handle the option additions which aren't passed in with mappingData
  forOwn(optionDefaults, (value, key) => {
    let opt = find(targetOpts, { name: key });
    let removedSourceOpt = find(targetPayload.optionMutations, { sourceOption: key, targetOption: null });
    if (!opt & !removedSourceOpt) {
      return console.log(`Can't find ${key} on target...`);
    }
    if (opt) {
      targetPayload.optionMutations.push({
        sourceOption: null,
        targetOption: key,
        defaultValue: value,
        valueMappings: opt.values.map((val) => {
          if (getValue(val) !== undefined) {
            return {
              source: null,
              target: getValue(val),
            };
          }
          return {
            sourceRange: null,
            targetRange: val.range,
          };
        }),
      });
    }
  });

  const mutationsContainQty =
    find(targetPayload.optionMutations, (mutation) => mutation.sourceOption === 'Quantity') !== undefined;
  if (!mutationsContainQty && srcProduct.quantity && targetProduct.quantity) {
    targetPayload.optionMutations.push({
      sourceOption: 'Quantity',
      targetOption: 'Quantity',
      valueMappings: autoMapNumerics(srcProduct.quantity, targetProduct.quantity),
    });
  }

  if (srcProduct.productId === targetProduct.productId) {
    return /** @type {BesVersionMapping} */ {
      source: source,
      ...targetPayload,
    };
  }
  return /** @type {BesIrregularEvolution} */ {
    source: source,
    evolutions: [targetPayload],
  };
};

/**
 * Compose BES v2 mapping data
 * @param srcProduct {Product}
 * @param targetProduct {Product}
 * @param mappingData {OptionMapping[]}
 * @param optionDefaults {Object.<string, string>} dictionary of new options and their default values
 * @return {(BesVersionMapping|BesIrregularEvolution)}
 */

export const convertToV2Payload = (srcProduct, targetProduct, mappingData, optionDefaults) => {
  const targetOptMap = keyBy(targetProduct.options, 'name');
  const sourceOptMap = keyBy(srcProduct.options, 'name');

  const source = { id: srcProduct.productId, version: srcProduct.version };

  const targetPayload = {
    target: {
      id: targetProduct.productId,
      version: targetProduct.version,
    },
    mutations: [],
    multipleMutations: [],
    appliesTo: null,
  };
  const type = Object.freeze({ oneToOne: 0, optionRemoved: 1, multipleOptions: 2, unknown: 3 });

  for (let i = 0; i < mappingData.length; i++) {
    let optMapping = mappingData[i];
    let mutationType =
      optMapping.src.length === 1 && optMapping.target.length === 1
        ? type.oneToOne
        : optMapping.target.length === 0
        ? type.optionRemoved
        : optMapping.src.length >= 1 && optMapping.target.length === 1
        ? type.multipleOptions
        : type.unknown;
    if (mutationType === type.oneToOne) {
      let targetOption = get(targetOptMap, optMapping.target[0]);
      let srcOption = get(sourceOptMap, optMapping.src[0]);
      if (!targetOption) {
        console.log(`Couldn't find target:${optMapping.src[0]}`);
      }
      if (!srcOption) {
        console.log(`Couldn't find source:${optMapping.src[0]}`);
      }
      let mutation = {
        source: { key: optMapping.src[0], type: getV2OptionType(srcOption) },
        target: { key: optMapping.target[0], type: getV2OptionType(targetOption) },
        valueMappings: [
          ...flatMap(optMapping.values, (val) => {
            if (val.src.length > 1) {
              return val.src.map((src) => ({
                source: src,
                target: val.target[0],
              }));
            }
            return {
              source: val.src[0],
              target: val.target[0],
            };
          }),
          ...toPairs(optMapping.removedValues.src)
            .filter(([, value]) => value && optMapping.mappingMode !== 'numeric') // a bit of a hack so acknowledged removal doesn't show up for numeric mapping
            .map(([key]) => ({ source: key, target: null })), // unmapped values
          ...targetOption.values
            .filter(
              (optionValue) =>
                getValue(optionValue) !== undefined &&
                !optMapping.values.map((valMapping) => valMapping.target[0]).includes(getValue(optionValue)) &&
                optMapping.mappingMode !== 'numeric'
            )
            .map((optionValue) => ({ source: null, target: getValue(optionValue) })),
          ...map(
            filter(optMapping.automap, (mapping) => mapping.target || mapping.targetRange),
            v1ValueMappingToV2
          ),
        ],
      };
      targetPayload.mutations.push(mutation);
    } else if (mutationType === type.multipleOptions) {
      for (let i = 0; i < optMapping.values.length; i++) {
        let valMapping = optMapping.values[i];
        let targetOptionName = optMapping.target[0];
        let targetOption = get(targetOptMap, targetOptionName);
        valMapping.src.forEach((src) => {
          targetPayload.multipleMutations.push({
            sources: optMapping.valLookup[src].map((optVal) => {
              let srcOption = get(sourceOptMap, optVal.attr);
              return {
                key: optVal.attr,
                value: getValue(optVal),
                type: getV2OptionType(srcOption),
              };
            }),
            targets: [
              {
                key: targetOptionName,
                value: valMapping.target[0],
                type: getV2OptionType(targetOption),
              },
            ],
          });
        });
      }
    } else if (mutationType === type.optionRemoved) {
      let srcOption = get(sourceOptMap, optMapping.src[0]);
      let removalSource = optMapping.src[0];
      let removalDefault = optionDefaults[removalSource];
      if (!removalDefault) {
        console.log(`Can't find default for ${removalSource}...`);
      }
      targetPayload.mutations.push({
        source: { key: optMapping.src[0], type: getV2OptionType(srcOption) },
        target: null,
        defaultValue: removalDefault,
      });
    } else if (mutationType === type.unknown) {
      console.log('unknown mutation type');
      console.log(optMapping);
    }
  }
  forOwn(optionDefaults, (value, key) => {
    let opt = get(targetOptMap, key);
    if (!opt && !get(sourceOptMap, key)) {
      return console.error(`Can't find ${key} on target...`);
    } else if (!opt) {
      return;
    }
    targetPayload.mutations.push({
      source: null,
      target: { key: key, type: getV2OptionType(opt) },
      defaultValue: value,
      valueMappings: get(opt, 'values', []).map((val) => {
        if (getValue(val) !== undefined) {
          return {
            source: null,
            target: getValue(val),
          };
        }
        return {
          allowedRange: val.range,
        };
      }),
    });
  });

  const mutationsContainQty =
    find(
      targetPayload.mutations,
      (mutation) => mutation.source?.key === 'Quantity' || mutation.target?.key === 'Quantity'
    ) !== undefined;
  if (!mutationsContainQty && srcProduct.quantity && targetProduct.quantity) {
    const qtyOpt = { key: 'Quantity', type: 'numeric' };
    targetPayload.mutations.push({
      source: qtyOpt,
      target: qtyOpt,
      valueMappings: autoMapV2Numerics(srcProduct.quantity, targetProduct.quantity),
    });
  }

  if (srcProduct.productId === targetProduct.productId) {
    return /** @type {BesVersionMapping} */ {
      source: source,
      ...targetPayload,
    };
  }
  return /** @type {BesIrregularEvolution} */ {
    source: source,
    evolutions: [targetPayload],
  };
};
export const ProductNameDisplayMode = {
  NAME_ONLY: 'NAME_ONLY',
  VERSION_ONLY: 'VERSION_ONLY',
  NAME_WITH_ID: 'NAME_WITH_ID',
  ID_AND_VERSION: 'ID_AND_VERSION',
  FULL: 'FULL',
};
export const formatProductName = (product, productNameDisplayMode, noVersionText = undefined) => {
  function versionText() {
    return product.version ? ` v${product.version}` : noVersionText || '';
  }

  switch (productNameDisplayMode) {
    case ProductNameDisplayMode.NAME_ONLY:
      return product.name;
    case ProductNameDisplayMode.NAME_WITH_ID:
      return `${product.name} (${product.productId})`;
    case ProductNameDisplayMode.ID_AND_VERSION:
      return `${product.productId}${versionText()}`;
    case ProductNameDisplayMode.VERSION_ONLY:
      return `${versionText()}`;
    case ProductNameDisplayMode.FULL:
      return `${product.name} (${versionText()})`;
    default:
      throw new Error(`Unknown productNameDisplayMode: ${productNameDisplayMode}`);
  }
};

/**
 * check ranges are equals or not.
 *
 * @param range1 {Range}
 * @param range2 {Range}
 * @return {boolean}
 */
export const areRangesEquals = (range1 = {}, range2 = {}) => {
  const fields = ['minimum', 'maximum', 'increment'];
  return isEqual(pick(range1, fields), pick(range2, fields));
};

/**
 * Given an OptionValue normalize exact range to value
 * @param optionValue {OptionValue}
 * @returns {(OptionValue | NormalizedRange)}
 */
export const normalizeExactValue = (optionValue) =>
  optionValue.range && optionValue.range.minimum === optionValue.range.maximum
    ? { value: optionValue.range.minimum, oldRange: optionValue.range }
    : optionValue;

const cartesian_concat = (a, b) => [].concat(...a.map((a) => b.map((b) => [].concat(a, b))));
const cartesian = (a, b, ...c) => (b ? cartesian(cartesian_concat(a, b), ...c) : a);

export const convertMutationToUiData = (
  srcOpts,
  targetOpts,
  optionMutations,
  multipleOptionMutations,
  warningText = 'Invalid Mapping'
) => {
  // This method is assuming that the definition of source and target product remains the same since version is immutable
  // Handles optionMutations first
  let isInvalidMapping = false;
  let uiData = optionMutations
    .filter((m) => m.sourceOption !== null)
    .map((mutation) => {
      const srcOption = find(srcOpts, { name: mutation.sourceOption });
      let targetOption = { values: [] };
      if (mutation.targetOption !== null) {
        targetOption = find(targetOpts, { name: mutation.targetOption });
      }
      // Mapping is out of sync with product definitions that were passed in
      if (!srcOption || !targetOption) {
        isInvalidMapping = true;
        return undefined;
      }
      const { mapped, removed } = groupBy(mutation.valueMappings, (x) => (x.target !== null ? 'mapped' : 'removed'));
      const optionMapping = {
        src: mutation.sourceOption !== null ? [mutation.sourceOption] : [],
        target: mutation.targetOption !== null ? [mutation.targetOption] : [],
        values: [],
        removedValues: { src: {} },
        automap: [],
      };

      if (srcOption.type === 'number' || srcOption.type === 'numeric') {
        optionMapping.automap = autoMapNumerics(srcOption.values, targetOption.values);
        optionMapping.removedValues.src = unmappedNumeric(srcOption, targetOption);
        optionMapping.mappingMode = 'numeric';
      } else {
        optionMapping.values = flow(
          fFilter((valMutation) => valMutation.source), // filtering out addition
          fGroupBy('target'),
          fToPairs,
          fMap(([targetValue, mapping]) => ({
            src: map(mapping, 'source'),
            target: [targetValue],
          }))
        )(mapped);
        optionMapping.removedValues.src = flow(
          fKeyBy('source'),
          fMapValues(() => true)
        )(removed);
      }
      optionMapping.isCompleted = isMappingComplete(optionMapping);
      return optionMapping;
    });

  const defaults = {};
  optionMutations
    .filter((m) => m.sourceOption === null && m.targetOption)
    .forEach((mutation) => {
      defaults[mutation.targetOption] = mutation.defaultValue;
    });
  const moms = flow(
    fGroupBy((mom) => mom.targetOption.name),
    fToPairs,
    fMap(([targetOpt, mapping]) => {
      let srcOptName = map(mapping[0].sourceOptions, 'name');
      let srcOptions = flow(
        fFilter((opt) => srcOptName.includes(opt.name)),
        fSortBy('name')
      )(srcOpts);
      if (srcOptions.length !== srcOptName.length) {
        isInvalidMapping = true;
        return;
      }
      let valueMappings = flow(
        fGroupBy('targetOption.value'),
        fToPairs,
        fMap(([targetVal, valMappings]) => {
          return {
            src: valMappings.map((x) => flow(fSortBy('name'), fFlatMap(getValue))(x.sourceOptions).join(' + ')),
            target: [targetVal],
          };
        })
      )(mapping);
      let mappedValues = flatMap(valueMappings, 'src');

      const combinedValueLookup = {};
      const srcUnmapped = {};
      cartesian(...srcOptions.map((opt) => opt.values.map((val) => Object.assign(val, { attr: opt.name })))).forEach(
        (valSet) => {
          const key = valSet
            .filter((val) => getValue(val))
            .map((val) => getValue(val))
            .join(' + ');
          combinedValueLookup[key] = valSet;
          if (!mappedValues.includes(key)) {
            srcUnmapped[key] = false;
          }
        }
      );

      let momMapping = /** @type OptionMapping */ {
        src: srcOptName,
        target: [targetOpt],
        values: valueMappings,
        removedValues: { src: srcUnmapped },
        rangesToAdd: [],
        rangesToMap: [],
        rangesToRemove: [],
        valLookup: combinedValueLookup,
      };
      momMapping.isCompleted = isMappingComplete(momMapping);

      return momMapping;
    })
  )(multipleOptionMutations);
  uiData = uiData.concat(moms);

  const srcMapped = flatMap(
    uiData.filter((elt) => elt.src && elt.src.length > 0),
    (elt) => elt.src.map((val) => ({ name: val }))
  );
  // automap missing src options
  let srcUnmapped = differenceBy(srcOpts, srcMapped, 'name');
  if (srcUnmapped.length > 0) {
    let automap = intersectionWith(
      autoMapProducts(srcOpts, targetOpts),
      srcUnmapped,
      (optMapping, unmapped) =>
        optMapping.src.length === 1 && optMapping.src[0].toLowerCase() === unmapped.name?.toLowerCase()
    );
    uiData = [...uiData, ...automap];
  }

  if (isInvalidMapping) {
    return {
      uiData: undefined,
      defaults: undefined,
      warning: <span>There is an invalid mapping, please submit a new one.</span>,
    };
  }
  return {
    uiData: uiData,
    defaults: defaults,
  };
};

export const isMappingComplete = (mappingData) => {
  const isAllSrcValueMapped =
    filter(values(mappingData.removedValues.src), (alreadyMarkedAsRemoved) => !alreadyMarkedAsRemoved).length === 0;
  const isAllSrcValHasTarget =
    filter(mappingData.values, (elt) => elt.target.length === 0 && elt.src.length > 0).length === 0;
  const hasSrcAttr = mappingData.src && mappingData.src.length > 0;
  // number type might not need to be mapped manually at all
  return hasSrcAttr && isAllSrcValueMapped && isAllSrcValHasTarget && mappingData.src.length > 0;
};

const autoMapNumerics = (srcValues, targetValues) => {
  const mappedVals = intersectionBy(srcValues, targetValues, getValue)
    .filter((val) => getValue(val))
    .map((val) => ({ source: getValue(val), target: getValue(val) }));

  const unmappedVals = differenceBy(srcValues, targetValues, getValue)
    .filter((val) => getValue(val))
    .map((val) => ({ source: getValue(val), target: null }));
  const removedVals = [];

  // Normalize then look for mapping
  for (let i = 0; i < unmappedVals.length; i++) {
    let unmappedSrcVal = normalizeNumericString(unmappedVals[i].source);
    let matchedVal = find(targetValues, (tval) => tval && normalizeNumericString(getValue(tval)) === unmappedSrcVal);
    if (matchedVal !== undefined) {
      mappedVals.push({ source: unmappedVals[i].source, target: getValue(matchedVal) });
    } else {
      removedVals.push(unmappedVals[i]);
    }
  }

  // Anything on target that's unmapped is an addition
  let addedVals = flow(
    fFilter((x) => getValue(x)),
    fMap((x) => ({ source: null, target: getValue(x) }))
    // flow differenceBy doesn't work because it expects the given array (targetValues) to be the second parameter
  )(targetValues);
  addedVals = differenceBy(addedVals, mappedVals, 'target');

  // range diff can be done here since it will never change
  const allSrcsRanges = srcValues.filter((i) => i.range).map((i) => i.range);
  const targetRanges = targetValues.filter((elt) => elt.range).map((i) => i.range);
  const rangesToRemove = differenceWith(allSrcsRanges, targetRanges, areRangesEquals).map((i) => ({
    sourceRange: i,
    targetRange: null,
  }));
  const rangesToAdd = differenceWith(targetRanges, allSrcsRanges, areRangesEquals).map((i) => ({
    sourceRange: null,
    targetRange: i,
  }));
  const rangesToMap = intersectionWith(allSrcsRanges, targetRanges, areRangesEquals).map((i) => ({
    sourceRange: i,
    targetRange: i,
  }));
  return [].concat(mappedVals, addedVals, removedVals, rangesToRemove, rangesToAdd, rangesToMap);
};

const autoMapV2Numerics = (srcValues, targetValues) => {
  const mappedVals = intersectionBy(srcValues, targetValues, getValue)
    .filter((val) => getValue(val))
    .map((val) => ({ source: getValue(val), target: getValue(val) }));

  const unmappedVals = differenceBy(srcValues, targetValues, getValue)
    .filter((val) => getValue(val))
    .map((val) => ({ source: getValue(val), target: null }));
  const removedVals = [];

  // Normalize then look for mapping
  for (let i = 0; i < unmappedVals.length; i++) {
    let unmappedSrcVal = normalizeNumericString(unmappedVals[i].source);
    let matchedVal = find(targetValues, (tval) => tval && normalizeNumericString(getValue(tval)) === unmappedSrcVal);
    if (matchedVal !== undefined) {
      mappedVals.push({ source: unmappedVals[i].source, target: getValue(matchedVal) });
    } else {
      removedVals.push(unmappedVals[i]);
    }
  }

  // Anything on target that's unmapped is an addition
  let addedVals = flow(
    fFilter((x) => getValue(x)),
    fMap((x) => ({ source: null, target: getValue(x) }))
    // flow differenceBy doesn't work because it expects the given array (targetValues) to be the second parameter
  )(targetValues);
  addedVals = differenceBy(addedVals, mappedVals, 'target');

  // v2 on only requires allowedRange to be listed
  const targetRanges = targetValues.filter((elt) => elt.range).map((i) => ({ allowedRange: i.range }));

  return [].concat(mappedVals, addedVals, removedVals, targetRanges);
};

/**
 * Given one or more srcOptions and targetOption, maps values that are the same
 * @param srcOptions {Option[]}
 * @param targetOption {Option}
 * @return {{removedValues: {src: {}, target: (*[]|*[])}, src: *, valLookup: {}, values: *[], target: (string[]|*[])}|{removedValues: {src: {}, target: *}, src: *[], values: *, automap: *[], target: (string[]|*[])}}
 */
export const autoMapSameValue = (srcOptions, targetOption) => {
  const srcUnmapped = {};
  if (Array.isArray(srcOptions) && srcOptions.length > 1) {
    const combinedValueLookup = {};
    cartesian(
      ...sortBy(srcOptions, 'name').map((opt) => opt.values.map((val) => Object.assign(val, { attr: opt.name })))
    ).forEach((valSet) => {
      const key = valSet
        .filter((val) => val)
        .map((val) => getValue(val))
        .join(' + ');
      combinedValueLookup[key] = valSet;
      srcUnmapped[key] = false;
    });
    const mappingData = {
      src: srcOptions.map((opt) => opt.name),
      target: targetOption ? [targetOption.name] : [],
      values: [],
      removedValues: {
        src: srcUnmapped,
        target: targetOption ? [...targetOption.values] : [],
      },
      valLookup: combinedValueLookup,
    };
    mappingData.isCompleted = isMappingComplete(mappingData);
    return mappingData;
  }

  const srcOption = Object.assign({ values: [] }, srcOptions[0]);
  targetOption = Object.assign({ values: [] }, targetOption);

  const mappingData = {
    src: srcOption.name ? [srcOption.name] : [],
    target: targetOption.name ? [targetOption.name] : [],
    values: intersectionBy(srcOption.values, targetOption.values, getValue)
      .filter((elt) => getValue(elt))
      .map((elt) => ({
        src: [getValue(elt)],
        target: [getValue(elt)],
      })),
    removedValues: {
      src: srcUnmapped,
      target: differenceBy(targetOption.values, srcOption.values, getValue)
        .filter((elt) => getValue(elt))
        .map((elt) => getValue(elt)),
    },
    automap: [],
  };

  differenceBy(srcOption.values, targetOption.values, getValue).forEach((elt) => {
    if (getValue(elt) === undefined) {
      return;
    }
    srcUnmapped[getValue(elt)] = false;
  });

  // if numeric auto map, and add but acknowledge drop
  if (
    srcOption.type === 'number' ||
    targetOption.type === 'number' ||
    srcOption.type === 'numeric' ||
    targetOption.type === 'numeric'
  ) {
    mappingData.mappingMode = 'numeric';
    mappingData.values = [];
    mappingData.removedValues.src = unmappedNumeric(srcOption, targetOption);
    mappingData.automap = autoMapNumerics(srcOption.values, targetOption.values);
  }

  mappingData.isCompleted = isMappingComplete(mappingData);
  return mappingData;
};

export function autoMapProducts(srcOptions, targetOptions) {
  // Initialize common attributes and values
  let commonAttrs = intersectionBy(srcOptions, targetOptions, 'name');
  let unmappedSrcAttrs = differenceBy(srcOptions, commonAttrs, 'name').sort();
  let targetByName = keyBy(targetOptions, 'name');
  let mapping = [];
  for (let i = 0; i < commonAttrs.length; i++) {
    let srcOption = commonAttrs[i];
    let targetOption = targetByName[get(srcOption, 'name')];
    if (!targetOption) {
      continue;
    }
    mapping.push(autoMapSameValue([srcOption], targetOption));
  }
  for (let i = 0; i < unmappedSrcAttrs.length; i++) {
    let srcOption = unmappedSrcAttrs[i];
    mapping.push(autoMapSameValue([srcOption], undefined));
  }
  return mapping;
}

const unmappedNumeric = (srcOption, targetOption) => {
  const srcVals = srcOption.values.map(normalizeExactValue);
  const targetVals = targetOption.values.map(normalizeExactValue);

  const unmappedValues = {};
  differenceBy(srcVals, targetVals, (x) => normalizeNumericString(getValue(x))).forEach((elt) => {
    if (getValue(elt) === undefined) {
      return;
    }
    unmappedValues[getValue(elt)] = false;
  });
  return unmappedValues;
};

const normalizeNumericString = (x) => parseFloat(x).toString();

const v1ValueMappingToV2 = (valueMapping) => {
  if (valueMapping.target) {
    return { target: valueMapping.target };
  }
  if (valueMapping.targetRange) {
    return { allowedRange: valueMapping.targetRange };
  }
};

const getV2OptionType = (option) => {
  const type = get(option, 'type');
  return type === 'number' ? 'numeric' : type;
};
