import React, { Fragment } from 'react';
import { formatRange, getValue, optionHasRange, toOption, validateRange } from './helper';
import { Accordion, Select, TextField, Tooltip } from '@cimpress/react-components';
import { css } from 'emotion';
import IconCheckCircleAlt from '@cimpress-technology/react-streamline-icons/lib/IconCheckCircleAlt';
import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import { info, success, warning } from '@cimpress/react-components/lib/colors';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import { useIntl } from 'react-intl';
import msg from './messages';
import { formatProductName, ProductNameDisplayMode } from './helper';

const maxMenuHeight = 125;
const DefaultMapper = ({
  opts,
  optionDefaults,
  onDefaultChanged,
  isDoneWithDefaults,
  product,
  shouldDisplayName,
  isRemoval,
}) => {
  const { formatMessage } = useIntl();
  let msgText = isRemoval ? msg.selectRemovalDefaultForVersion : msg.selectDefaultForVersion;

  function onOptionDefaultChanged(value, optionName) {
    const newDefaults = Object.assign({}, optionDefaults);
    if (!value) {
      delete newDefaults[optionName];
    } else {
      newDefaults[optionName] = value;
    }
    onDefaultChanged(newDefaults);
  }

  return (
    <Accordion
      title={
        <Fragment>
          {isDoneWithDefaults ? (
            <IconCheckCircleAlt color={success.base} weight="fill" className={rightMargin} />
          ) : (
            <IconAlertTriangle color={warning.base} weight="fill" className={rightMargin} />
          )}
          {formatMessage(msgText, {
            productName: formatProductName(
              product,
              shouldDisplayName ? ProductNameDisplayMode.NAME_ONLY : ProductNameDisplayMode.VERSION_ONLY,
              formatMessage(msg.noVersion)
            ),
          })}
        </Fragment>
      }>
      {isRemoval && (
        <div
          className={css`
            width: 100%;
          `}>
          <span
            className={css`
              vertical-align: middle;
            `}>
            What's this?
          </span>
          <Tooltip
            direction={'bottom'}
            contents={
              <div>
                {msg.removalDefaultInfoText1.defaultMessage}
                <br />
                <br />
                {msg.removalDefaultInfoText2.defaultMessage}
              </div>
            }>
            <IconInformationCircle
              weight="regular"
              color={info.base}
              className={css`
                padding-left: 5px;
                vertical-align: bottom;
              `}
            />
          </Tooltip>
          <hr />
        </div>
      )}

      {opts.map((opt, index) => {
        let input;
        if (opt.values === undefined) {
          input = <div>Option contains undefined values. Please add values to this option</div>;
        } else if (optionHasRange(opt.values)) {
          input = (
            <Tooltip
              key={`${opt.name}-default`}
              contents={opt.values.map((elt) => {
                const val = getValue(elt) !== undefined ? getValue(elt) : formatRange(elt.range);
                return <div key={`${opt.name}-${val}`}>{val}</div>;
              })}>
              <TextField
                label={formatMessage(msg.inputValue)}
                type="number"
                value={optionDefaults[opt.name]}
                onChange={(e) => onOptionDefaultChanged(e ? e.target.value : '', opt.name)}
                bsStyle={(() => validateRange(optionDefaults[opt.name], opt.values))() ? 'success' : 'error'}
              />
            </Tooltip>
          );
        } else {
          input = (
            <Select
              isMulti={false}
              isClearable={true}
              label={formatMessage(msg.selectValue)}
              value={toOption(optionDefaults[opt.name])}
              options={opt.values.map((elt) => getValue(elt)).map(toOption)}
              onChange={(selected) => onOptionDefaultChanged(selected.value, opt.name)}
              maxMenuHeight={maxMenuHeight}
              menuPortalTarget={index === opts.length - 1 ? document.body : undefined}
            />
          );
        }
        return (
          <div
            key={opt.name}
            className={css`
              width: 100%;
            `}>
            <p>{opt.name}</p>
            {input}
          </div>
        );
      })}
    </Accordion>
  );
};
const rightMargin = css`
  margin-right: 10px;
`;
export default DefaultMapper;
