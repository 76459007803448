import fetchWithAuth from './fetchWithAuth';

const baseUrl = 'https://catalogschema.products.cimpress.io';

export const getCategoryByFamily = async ({ familyId, accessToken }) =>
  await fetchWithAuth({
    baseUrl,
    route: `v2/categories?familyId=${familyId}`,
    accessToken,
  });

export const getCategoryById = async ({ categoryId, accessToken }) => {
  const response = await fetchWithAuth({
    baseUrl,
    route: `v2/categories/${categoryId}`,
    accessToken,
  });
  return response.name;
};
