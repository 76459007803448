import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { css } from 'emotion';

import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import InlineEdit from '@cimpress/react-components/lib/InlineEdit';

import ConditionSetDisplay from './ConditionSetDisplay';
import ConditionSetEditView from './ConditionSetEditView';
import ConstraintCard from '../../../../constraints/constraintCard';

import { optionShape, propertyConditionAttributeShape, conditionSetShape } from '../../../../shared/propTypes';
import { matchesCaseInsensitive } from '../conditionSetHelpers';
import { VIEW } from '../../../../shared/enums/constants';
import messages from '../messages';

const conditionPadding = css`
  padding: 20px;
`;

export const ConditionSetEditor = ({
  nodeId,
  attributes,
  conditionSet,
  conditionSets,
  onChange,
  errorMessage,
  onDelete,
  editing,
  onEditClick,
  mode,
  onOpenConditionSetManager,
  type,
}) => {
  const { formatMessage } = useIntl();

  const [nameError, setNameError] = useState(null);

  if (conditionSet && conditionSet.conditions && conditionSet.conditions.length === 0) {
    setTimeout(
      () =>
        onChange(conditionSet, {
          ...conditionSet,
          conditions: [
            {
              attribute: '',
              operator: undefined,
              id: uuidv4(),
            },
          ],
        }),
      0
    );
  }

  // Need to update this to allow users to delete the last character if they want to start from scratch
  const validateName = (newName) => {
    if (!newName) {
      return formatMessage(messages.mustProvideConditionSetName);
    }

    const existingConditionSet = find(conditionSets, (conditionSet) =>
      matchesCaseInsensitive(conditionSet.name, newName)
    );

    if (existingConditionSet) {
      return formatMessage(messages.existingConditionSet, { name: newName });
    }

    return null;
  };

  const onUpdateName = (e) => {
    const nameInvalid = validateName(e.target.value);

    if (nameInvalid) {
      setNameError(nameInvalid);
      setTimeout(() => setNameError(null), 1500);
      return;
    }

    const newConditionSet = cloneDeep(conditionSet);

    newConditionSet.name = e.target.value;

    setNameError(null);
    onChange(conditionSet, newConditionSet);
  };

  // const onDeleteClick = () => {
  //   onDelete ? onDelete(conditionSet) : onChange(conditionSet, null);
  // };

  return (
    <ConstraintCard
      title={
        <div>
          {nameError ? <div class="text-danger">{nameError}</div> : null}
          {!isEqual(mode, VIEW) ? (
            <InlineEdit
              value={conditionSet.name}
              onChange={onUpdateName}
              placeholder={formatMessage(messages.conditionSetName)}
            />
          ) : (
            <div style={{ margin: '10px 0 10px 0' }}>{conditionSet.name}</div>
          )}
        </div>
      }
      errorMessage={errorMessage}
      editing={editing}
      onEditClick={onEditClick}
      nodeId={nodeId}
      // onDeleteClick={onDeleteClick}
      onOpenConditionSetManager={onOpenConditionSetManager}
      content={
        editing ? (
          <div className={conditionPadding}>
            <ConditionSetEditView
              conditionSet={conditionSet}
              conditionSets={conditionSets}
              attributes={attributes}
              onChange={onChange}
            />
          </div>
        ) : (
          <div className="card-block">
            <ConditionSetDisplay conditions={conditionSet.conditions} />
          </div>
        )
      }
      mode={mode}
      type={type}
    />
  );
};

ConditionSetEditor.propTypes = {
  conditionSet: PropTypes.shape(conditionSetShape),
  conditionSets: PropTypes.arrayOf(PropTypes.shape(conditionSetShape)),
  attributes: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.shape(optionShape), PropTypes.shape(propertyConditionAttributeShape)])
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onEditClick: PropTypes.func,
  errorMessage: PropTypes.string,
  editing: PropTypes.bool.isRequired,
};

ConditionSetEditor.defaultProps = {
  conditionSet: {},
  conditionSets: [],
  errorMessage: '',
};

export default ConditionSetEditor;
