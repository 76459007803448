import React, { Fragment, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { css } from 'emotion';
import isEmpty from 'lodash/isEmpty';

import NavTab from '@cimpress/react-components/lib/NavTab';
import NavTabItem from '@cimpress/react-components/lib/NavTabItem';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import Button from '@cimpress/react-components/lib/Button';
import Drawer from '@cimpress/react-components/lib/Drawer';

import messages from './messages';
import { getProductVersion } from '../../services/productService';
import ProductDataTab from './tabs/ProductDataTab';
import OptionsTab from './tabs/OptionsTab';
import PropertiesTab from './tabs/PropertiesTab';
import ConstraintsTab from './tabs/ConstraintsTab';
import VersionsDiff from './JSONViewer/VersionsDiff';
import { PRODUCT_DATA, OPTIONS, PROPERTIES, CONSTRAINTS } from './constants';

const viewJSONButton = css`
  float: right;
  margin: 20px;
`;

const center = css`
  text-align: center;
`;

const RIGHT = 'right';
const SIZE = 0.8;
const defaultTabs = [PRODUCT_DATA, OPTIONS, PROPERTIES, CONSTRAINTS];

const VersionComparisonComponent = ({
  tabs = defaultTabs,
  contents = {},
  version1,
  version2,
  productId,
  accessToken,
}) => {
  const { formatMessage } = useIntl();
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [productV1, setProductV1] = useState();
  const [productV2, setProductV2] = useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const productV1 = await getProductVersion({ productId, version: version1, accessToken });
      setProductV1(productV1);
    })();
  }, [productId, version1, accessToken]);

  useEffect(() => {
    (async () => {
      const productV2 = await getProductVersion({ productId, version: version2, accessToken });
      setProductV2(productV2);
    })();
  }, [productId, version2, accessToken]);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const tabContent = {
    [PRODUCT_DATA]: <ProductDataTab productV1={productV1} productV2={productV2} accessToken={accessToken} />,
    [OPTIONS]: <OptionsTab productV1={productV1} productV2={productV2} />,
    [PROPERTIES]: <PropertiesTab productV1={productV1} productV2={productV2} />,
    [CONSTRAINTS]: <ConstraintsTab productV1={productV1} productV2={productV2} />,
    ...contents,
  };

  const loading = !isEmpty(productV1) && !isEmpty(productV2);
  return loading ? (
    <Fragment>
      <NavTab>
        {tabs.map((tab) => (
          <NavTabItem key={tab} active={selectedTab === tab}>
            <button onClick={() => setSelectedTab(tab)}>{messages[tab] ? formatMessage(messages[tab]) : tab}</button>
          </NavTabItem>
        ))}
      </NavTab>
      {tabContent[selectedTab]}
      <div className={viewJSONButton}>
        <Button onClick={openDrawer}>{formatMessage(messages.viewJSON)}</Button>
      </div>
      <Drawer position={RIGHT} size={SIZE} show={isDrawerOpen} onRequestHide={closeDrawer} closeOnClickOutside={true}>
        <VersionsDiff productV1={productV1} productV2={productV2} />
      </Drawer>
    </Fragment>
  ) : (
    <Spinner size="large" className={center} />
  );
};

export default VersionComparisonComponent;
