import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidv4 } from 'uuid';

const createRule = (name) => ({
  name,
  description: '',
  conditions: [],
  results: [],
  id: uuidv4(),
});

const createDataSheet = (name) => ({
  name,
  rows: [],
});

const createCondition = (condition, option) => {
  return {
    attribute: '',
    operator: undefined,
    id: uuidv4(),
  };
};

export const addRule = (rules = [], name) => {
  return [createRule(name), ...cloneDeep(rules)];
};

export const addDataSheet = (dataSheets = [], name) => {
  return [createDataSheet(name), ...cloneDeep(dataSheets)];
};

export const deleteDataSheet = (dataSheets, index) => {
  const newDataSheets = cloneDeep(dataSheets);
  newDataSheets.splice(index, 1);

  return newDataSheets;
};

export const updateRule = (rules, id, rule) => {
  const newRules = cloneDeep(rules);
  return (
    rules && newRules.map((ruleValue) => (ruleValue.id === id ? rule : ruleValue)).filter((ruleValue) => ruleValue)
  );
};

export const addCondition = (rule) => {
  return { ...cloneDeep(rule), conditions: rule.conditions.concat([createCondition()]) };
};

export const updateCondition = (rule, originalCondition, updatedCondition) => {
  return {
    ...rule,
    conditions: rule.conditions.map((value) => (value.id === originalCondition.id ? updatedCondition : value)),
  };
};

export const removeCondition = (rule, conditionToRemove) => {
  return { ...rule, conditions: rule.conditions.filter((value) => value.id !== conditionToRemove.id) };
};

export const addResult = (rule) => {
  return { ...cloneDeep(rule), results: rule.results.concat([createCondition()]) };
};

export const updateResult = (rule, originalResult, updatedResult) => {
  return { ...rule, results: rule.results.map((value) => (value.id === originalResult.id ? updatedResult : value)) };
};

export const removeResult = (rule, resultToRemove) => {
  return { ...rule, results: rule.results.filter((value) => value.id !== resultToRemove.id) };
};
