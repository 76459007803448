import { defineMessages } from 'react-intl';

export default defineMessages({
  useExistingProductLinkTab: {
    id: 'productLinker.useExistingProductLinkTab',
    defaultMessage: 'Use Existing Product Link',
  },
  createNewLinkTab: {
    id: 'productLinker.createNewLinkTab',
    defaultMessage: 'Create New Product Link',
  },
  cancel: {
    id: 'productLinker.cancel',
    defaultMessage: 'Cancel',
  },
  done: {
    id: 'productLinker.done',
    defaultMessage: 'Done',
  },
  changeSelection: {
    id: 'productLinker.changeSelection',
    defaultMessage: 'Change Selection',
  },
  failedToFetch: {
    id: 'productLinker.failedToFetch',
    defaultMessage:
      'Failed to fetch Product Link. Please contact CT Product Catalog Support <CTProductCatalogSupport@cimpress.com> ',
  },
});
