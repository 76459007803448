import React from 'react';
import PropTypes from 'prop-types';
import { css, cx } from 'emotion';
import SingleConditionDisplay from '../../../../shared/SingleConditionDisplay';
import { constraintConditionShape } from '../../../../shared/propTypes';

const gridDisplay = css`
  display: grid;
  grid-gap: 5px;
`;

const layouts = {
  conditionSet: css`
    grid-template-columns: min content auto;
  `,
  conditionSetContents: css`
    grid-template-columns: auto;
  `,
};

const ConditionSetDisplay = ({ conditions }) => {
  return (
    <div className={cx(gridDisplay, layouts.conditionSet)}>
      {conditions ? (
        <div className={cx(gridDisplay, layouts.conditionSetContents)}>
          <div>
            {conditions.map((condition) => (
              <div key={JSON.stringify(condition)}>
                <SingleConditionDisplay
                  attribute={condition.attribute}
                  operator={condition.operator}
                  values={condition.values}
                />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

ConditionSetDisplay.propTypes = {
  conditions: PropTypes.arrayOf(PropTypes.shape(constraintConditionShape)),
};

export default ConditionSetDisplay;
