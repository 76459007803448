import React, { useState, useEffect, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { css } from 'emotion';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/fp/isEqual';
import get from 'lodash/get';

import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import { ocean, info, slate } from '@cimpress/react-components/lib/colors';
import Pagination from '@cimpress/react-components/lib/Pagination';

import messages from '../../messages';

const resultsDiv = css`
  vertical-align: middle;
  width: 400px;
  overflow: hidden;
`;

const linkResult = css`
  margin: 7px 2px;
  &:hover {
    cursor: pointer;
    color: ${ocean.base};
    font-weight: 600;
  }
  text-overflow: ellipsis !important;
  white-space: nowrap;
  overflow: hidden;
`;

const selectedLink = css`
  margin: 7px 2px;
  color: ${ocean.base};
  font-weight: 600;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  overflow: hidden;
`;

const infoIcon = css`
  margin-left: 10px;
  cursor: pointer;
`;

const infoText = css`
  border-left: 3px solid ${info.base};
  padding: 2px 10px;
  color: ${slate};
`;

const PAGE_SIZE = 10;

const ResultsList = ({ searchResults, onResultClick, previewProductLink, selectedProductLink }) => {
  const { formatMessage } = useIntl();

  const [productLinks, setProductLinks] = useState([]);
  const [pageOfProductLinks, setPageOfProductLinks] = useState([]);
  const [showResultsInfo, setShowResultsInfo] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    if (!isEmpty(searchResults)) {
      let links = get(searchResults, '_embedded.item');
      if (!isEmpty(links)) {
        setProductLinks(links);
        setPageOfProductLinks(links.slice(0, PAGE_SIZE));
        setPageCount(links.length / PAGE_SIZE);
      }
    } else if (!isEmpty(selectedProductLink)) {
      setProductLinks([selectedProductLink]);
      setPageOfProductLinks([selectedProductLink]);
      setPageCount(1);
    }
  }, [searchResults, selectedProductLink]);

  const onInfoClick = () => setShowResultsInfo(!showResultsInfo);

  const onPageChange = ({ selected }) => {
    //To Do: fetch the next set of results and setSearchResults

    const minIndex = selected * PAGE_SIZE;
    const maxIndex = minIndex + PAGE_SIZE;
    setPageOfProductLinks(productLinks.slice(minIndex, maxIndex));
  };

  const InfoIcon = (
    <IconInformationCircle onClick={onInfoClick} className={infoIcon} weight={showResultsInfo ? 'fill' : 'regular'} />
  );

  const InfoText = (
    <div className={infoText}>
      <i>{formatMessage(messages.resultsHelperInfo)}</i>
    </div>
  );

  return (
    <div className={resultsDiv}>
      {!isEmpty(pageOfProductLinks) ? (
        <Fragment>
          <h4>
            {formatMessage(messages.productLinks)}
            {InfoIcon}
          </h4>
          {showResultsInfo && InfoText}
          {map(pageOfProductLinks, (link) => {
            return (
              <div
                key={`div-${link.productLinkName}`}
                className={
                  isEqual(previewProductLink.productLinkName, link.productLinkName) ? selectedLink : linkResult
                }
                onClick={() => onResultClick(link)}>
                {link.productLinkName}
              </div>
            );
          })}
          {pageCount > 1 && (
            <Pagination
              onPageChange={onPageChange}
              pagesOutsideElipses={1}
              pagesBetweenElipses={1}
              pageCount={pageCount}
            />
          )}
        </Fragment>
      ) : (
        <p>no search results</p>
      )}
    </div>
  );
};

export default ResultsList;
