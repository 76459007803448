import React, { useState, Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { css } from 'emotion';
import isEmpty from 'lodash/isEmpty';

import Drawer from '@cimpress/react-components/lib/Drawer';
import Button from '@cimpress/react-components/lib/Button';
import Table from '@cimpress/react-components/lib/Table';

import { anchorButtonNoMargin } from '../../constants/css';
import messages from './messages';

const RIGHT = 'right';
const SIZE = 0.4;

const positionCatalogInfo = css`
  margin-bottom: 15px;
  tex-align: left;
`;

const ViewAllProductsDrawer = ({
  drawerIsOpen,
  closeDrawer,
  selectedCatalog,
  selectedProducts,
  isCatalogView = false,
}) => {
  const { formatMessage } = useIntl();
  const [products, setProducts] = useState([]);

  const onRequestHide = () => {
    closeDrawer();
  };

  useEffect(() => {
    if (isCatalogView && !isEmpty(selectedCatalog)) {
      setProducts(Object.values(selectedCatalog.products));
    } else if (!isEmpty(selectedProducts)) {
      setProducts(selectedProducts);
    }
  }, [isCatalogView, selectedCatalog, selectedProducts]);

  const drawerContent = (
    <Fragment>
      {!isEmpty(products) && (
        <div>
          <h3>{formatMessage(messages.selectedProductsCount, { count: products.length })}</h3>
          {isCatalogView && (
            <div className={positionCatalogInfo}>
              {formatMessage(messages.catalog)}
              <b>{selectedCatalog.catalog && selectedCatalog.catalog.name}</b>
            </div>
          )}
          <Table
            columns={[
              { Header: formatMessage(messages.productIdHeader), accessor: 'productId' },
              { Header: formatMessage(messages.nameHeader), accessor: 'name' },
              { Header: formatMessage(messages.descriptionHeader), accessor: 'description' },
              { Header: formatMessage(messages.categoryHeader), accessor: 'category' },
            ]}
            data={products.map((x) => {
              return {
                name: <div>{x.name}</div>,
                productId: (
                  <a className={anchorButtonNoMargin} href={x.link} target="_blank" rel="noreferrer">
                    {x.productId}
                  </a>
                ),
                description: <div>{x.description}</div>,
                category: x.category ? x.category.name : '',
              };
            })}
            isCondensed={false}
            isBordered={true}
            hasHover={true}
            paginationPosition="bottomLeft"
            footer={
              <Button type="default" onClick={closeDrawer}>
                {formatMessage(messages.closeDrawer)}
              </Button>
            }
          />
        </div>
      )}
    </Fragment>
  );

  return (
    <Drawer position={RIGHT} size={SIZE} show={drawerIsOpen} onRequestHide={onRequestHide} closeOnClickOutside={true}>
      {drawerContent}
    </Drawer>
  );
};

export default ViewAllProductsDrawer;
