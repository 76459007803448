import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { css, cx } from 'emotion';
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';

import isEmpty from 'lodash/isEmpty';

import IconAdd from '@cimpress-technology/react-streamline-icons/lib/IconAdd';
import { ocean } from '@cimpress/react-components/lib/colors';

import './treeStyles.css';
import messages from './messages';

FileExplorerTheme.scaffoldBlockPxWidth = 20;
FileExplorerTheme.rowHeight = 30;
FileExplorerTheme.rowDirection = 'ltr';

const addIconPosition = css`
   {
    margin-right: 5px !important;
    height: 10px !important;
  }
`;
const addChildFont = css`
   {
    font-size: 12px;
    padding-left: 12px;
  }
`;
const treeContainer = css`
   {
    height: 300px;
    overflow: auto;
  }
`;

const productNodeStyle = css`
  &:hover {
    cursor: default;
  }
`;

const adoptionNodeStyle = css`
  &:hover {
    cursor: pointer;
  }
`;

const selectedNodeSpan = css`
  font-weight: bold;
  color: ${ocean.base};
`;

export const HamSelector = ({ treeData, selectedNode, onSelect, onCreateNewChildCondition }) => {
  const { formatMessage } = useIntl();

  const [tree, setTree] = useState([]);
  const [selectedTreeNode, setSelectedTreeNode] = useState({});

  useEffect(() => {
    setTree(treeData);
  }, [treeData]);

  useEffect(() => {
    setSelectedTreeNode(selectedNode);
  }, [selectedNode]);

  return (
    <Fragment>
      <div style={{ margin: '25px 0 0 25px' }}>
        <SortableTree
          treeData={tree}
          getNodeKey={({ node }) => node.id}
          onChange={(tree) => setTree(tree)}
          theme={FileExplorerTheme}
          isVirtualized={false}
          canDrag={false}
          generateNodeProps={({ node, path }) => ({
            buttons: [
              (node.isProduct || node.isAdoption) && (
                <Fragment>
                  {!node.isStandard && (
                    <span onClick={() => onCreateNewChildCondition(node)} className={addChildFont}>
                      <IconAdd weight="fill" color={ocean.base} size="sm" className={addIconPosition} />
                      {formatMessage(messages.createChild)}
                    </span>
                  )}
                </Fragment>
              ),
            ],
            title: (
              <span
                onClick={!node.isStandard && !node.isProduct ? () => onSelect(node, path) : null}
                className={cx(
                  node.isProduct || node.isStandard ? productNodeStyle : adoptionNodeStyle,
                  !isEmpty(selectedTreeNode) &&
                    (selectedTreeNode.id === node.id ||
                      (selectedTreeNode.isAdoptionHam && selectedTreeNode.productId === node.id))
                    ? selectedNodeSpan
                    : null
                )}>
                {node.title}
              </span>
            ),
          })}
          className={treeContainer}
        />
      </div>
    </Fragment>
  );
};

HamSelector.propTypes = {
  flatData: PropTypes.arrayOf(PropTypes.object),
};

HamSelector.defaultProps = {
  flatData: [],
};

export default HamSelector;
