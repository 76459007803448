import { defineMessages } from 'react-intl';

export default defineMessages({
  version: {
    id: 'productOptionMapper.version',
    defaultMessage: 'version',
  },
  noVersion: {
    id: 'productOptionMapper.noVersion',
    defaultMessage: 'new version',
  },
  payloadValidationSuccess: {
    id: 'productOptionMapper.payloadValidationSuccess',
    defaultMessage: 'Payload validated successfully',
  },
  payloadValidationFailure: {
    id: 'productOptionMapper.payloadValidationFailure',
    defaultMessage: 'BES payload failed validation, please reach out to #mcp-support-pim',
  },
  payloadSubmitSuccess: {
    id: 'productOptionMapper.payloadSubmitSuccess',
    defaultMessage: 'Mapping submitted successfully',
  },
  payloadSubmitFailure: {
    id: 'productOptionMapper.payloadSubmitFailure',
    defaultMessage: 'Failed to submit mapping please reach out to #mcp-support-pim',
  },
  submit: {
    id: 'productOptionMapper.submit',
    defaultMessage: 'Submit',
  },
  unmappedOptions: {
    id: 'productOptionMapper.unmappedOptions',
    defaultMessage: 'Unmapped/Removed Options',
  },
  validatePayload: {
    id: 'productOptionMapper.validatePayload',
    defaultMessage: 'Validate payload',
  },
  selectOptionToMapTo: {
    id: 'productOptionMapper.selectOptionToMap',
    defaultMessage: '<span>Select option to map </span><b>{attributeName}</b><span> to</span>',
  },
  removingOption: {
    id: 'productOptionMapper.removingOption',
    defaultMessage: '<b>{attributeName}</b> is being removed',
  },
  loadedInvalidMapping: {
    id: 'productOptionMapping.loadedInvalidMapping',
    defaultMessage: 'There is an invalid mapping, please submit a new one.',
  },
  mapSourceToTarget: {
    id: 'productOptionMapper.mapSourceToTarget',
    defaultMessage: '<b>{sourceAttribute}</b> mapped to <b>{targetAttribute}</b>',
  },
  values: {
    id: 'productOptionMapper.values',
    defaultMessage: 'Value(s)',
  },
  mappedTo: {
    id: 'productOptionMapper.mappedTo',
    defaultMessage: 'mapped to',
  },
  option: {
    id: 'productOptionMapper.option',
    defaultMessage: 'Option',
  },
  selectOptionToMapFrom: {
    id: 'productOptionMapper.selectOptionToMapFrom',
    defaultMessage: 'Select option to map from',
  },
  selectOptionToMapToPlaceHolder: {
    id: 'productOptionMapper.selectOptionToMapTo',
    defaultMessage: 'Select option to map to',
  },
  selectValuesToMapFrom: {
    id: 'productOptionMapper.selectValuesToMapFrom',
    defaultMessage: 'Select value(s) to map from',
  },
  selectValueToMapTo: {
    id: 'productOptionMapper.selectValueToMapTo',
    defaultMessage: 'Select value to map to',
  },
  unmappedValueTooltip: {
    id: 'productOptionMapper.unmappedValueTooltip',
    defaultMessage:
      'Confirming as unmapped means they cannot be translated to the new product version, so customers will not be able to order this product with that value',
  },
  confirmValuesUnmapped: {
    id: 'productOptionMapper.confirmValuesUnmapped',
    defaultMessage: 'Map below values or confirm to keep unmapped ',
  },
  confirmAll: {
    id: 'productOptionMapper.confirmAll',
    defaultMessage: 'Confirm All',
  },
  undoAll: {
    id: 'productOptionMapper.undoAll',
    defaultMessage: 'Undo All',
  },
  valuesConfirmedUnmapped: {
    id: 'productOptionMapper.valuesConfirmedUnmapped',
    defaultMessage: 'Values confirmed to keep unmapped ',
  },
  selectDefaultForVersion: {
    id: 'productOptionMapper.selectDefaultForVersion',
    defaultMessage: 'Select Defaults for New Options from {productName}',
  },
  selectRemovalDefaultForVersion: {
    id: 'productOptionMapper.selectRemovalDefaultForVersion',
    defaultMessage: 'Select Implicit Defaults for Removed Options from {productName}',
  },
  removalDefaultInfoText1: {
    id: 'productOptionMapper.removalDefaultInfoText1',
    defaultMessage: 'Select an implicit value for each option which is being removed. ',
  },
  removalDefaultInfoText2: {
    id: 'productOptionMapper.removalDefaultInfoText2',
    defaultMessage:
      'If you were to remove Color from a Shirt (with previous values: Blue, Black, Red), the default value would represent the implicit Color of the shirt.',
  },
  newOptionFoundSelectDefault: {
    id: 'productOptionMapper.newOptionFoundSelectDefault',
    defaultMessage: 'New options are found in {productName}, please set default value(s)',
  },
  inputValue: {
    id: 'productOptionMapper.inputValue',
    defaultMessage: 'Input value',
  },
  selectValue: {
    id: 'productOptionMapper.selectValue',
    defaultMessage: 'Select value',
  },
  numericInstruction: {
    id: 'productOptionMapper.numericInstruction',
    defaultMessage:
      'For numeric attribute, please acknowledge removal of exact values. If these values shouldn\'t be "unmapped" please add the value to {optionName}',
  },
  loadExistingDataSuccess: {
    id: 'productOptionMapper.loadExistingDataSuccess',
    defaultMessage: 'Loaded existing mapping data successfully - do not need to click submit unless a change was made',
  },
  loadExistingDataFail: {
    id: 'productOptionMapper.loadExistingDataFail',
    defaultMessage: "There's no mapping for selected version",
  },
});
