import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { css, cx } from 'emotion';
import isEmpty from 'lodash/isEmpty';

import Drawer from '@cimpress/react-components/lib/Drawer';
import Button from '@cimpress/react-components/lib/Button';
import TextField from '@cimpress/react-components/lib/TextField';
import { Checkbox } from '@cimpress/react-components';

import CatalogDropdown from '../../shared/CatalogDropdown';
import RelatedResourcesDropdown from './RelatedResourcesDropdown';
import { anchorButton } from '../../constants/css';
import messages from '../messages';
import sharedMessages from '../../shared/messages';

const sizeOfSearches = css`
  max-width: 700px;
  display: flex;
  flex-direction: column;
`;

const floatLeft = css`
  float: left;
`;

const resetButton = css`
  float: right;
  margin-top: 20px;
  z-index: 9999;
`;

const rightAlign = css`
  float: right;
  margin-top: 10px;
`;

const RIGHT = 'right';
const SIZE = 0.4;
const RETURN = 13;

const AdvancedSearchDrawer = ({
  account,
  drawerIsOpen,
  closeDrawer,
  onAdvancedSearch,
  accessToken,
  authProfile,
  allowCatalogSelection,
}) => {
  const { formatMessage } = useIntl();
  const [plinkNameInput, setPlinkNameInput] = useState('');
  const [catalogForSearch, setCatalogForSearch] = useState(null);
  const [catalogs, setCatalogs] = useState();
  const [productIdsInput, setProductIdsInput] = useState('');
  const [createdByMe, setCreatedByMe] = useState(false);
  const [selectedRelatedResourceType, setSelectedRelatedResourceType] = useState({});

  const onReset = () => {
    setPlinkNameInput('');
    setCatalogForSearch(null);
    setProductIdsInput('');
    setSelectedRelatedResourceType(null);
    setCreatedByMe(false);
  };

  const onRequestHide = () => {
    onReset();
    closeDrawer();
  };

  const onSearch = () => {
    const createdBy = createdByMe ? authProfile.email : null;
    const catalog = catalogForSearch ? catalogForSearch.value : null;
    onAdvancedSearch(plinkNameInput, catalog, productIdsInput, selectedRelatedResourceType.value, createdBy);
    onRequestHide();
  };

  const onKeyDownFilter = (e) => e.keyCode === RETURN && onSearch();
  const updatePlinkName = (e) => setPlinkNameInput(e.target.value);
  const updatePrductIds = (e) => setProductIdsInput(e.target.value);
  const updateCreatedByMe = () => setCreatedByMe(!createdByMe);
  const changeSelectedCatalog = (catalog) => {
    setCatalogForSearch(catalog);
  };

  const searchDisabled =
    isEmpty(plinkNameInput) &&
    isEmpty(catalogForSearch) &&
    isEmpty(productIdsInput) &&
    isEmpty(selectedRelatedResourceType) &&
    !createdByMe;

  const drawerHeader = <h2>{formatMessage(messages.advancedPlinkSearch) + ' '}</h2>;
  const drawerContent = (
    <div className={cx(sizeOfSearches)}>
      <div>
        <h3 className={floatLeft}>{formatMessage(sharedMessages.searchParameters)}</h3>
        <button className={cx(anchorButton, resetButton)} onClick={onReset}>
          {formatMessage(messages.resetSearch)}
        </button>
      </div>
      <div>
        <TextField
          name="plinkName"
          label={formatMessage(messages.plinkName)}
          value={plinkNameInput}
          onChange={updatePlinkName}
          onKeyDown={onKeyDownFilter}
        />
        {allowCatalogSelection && (
          <CatalogDropdown
            accessToken={accessToken}
            account={account}
            catalogForSearch={catalogForSearch}
            setCatalogForSearch={changeSelectedCatalog}
            catalogs={catalogs}
            setCatalogs={setCatalogs}
          />
        )}
        <TextField
          name="productIds"
          label={formatMessage(messages.productIdsOrName)}
          value={productIdsInput}
          onChange={updatePrductIds}
          onKeyDown={onKeyDownFilter}
        />
        <RelatedResourcesDropdown
          selectedRelatedResourceType={selectedRelatedResourceType}
          setSelectedRelatedResourceType={setSelectedRelatedResourceType}
          accessToken={accessToken}
        />
        <Checkbox
          label={formatMessage(messages.createdByMe, { name: authProfile.name })}
          checked={createdByMe}
          onChange={updateCreatedByMe}
        />
        <Button className={rightAlign} type="primary" disabled={searchDisabled} onClick={onSearch}>
          {formatMessage(sharedMessages.search)}
        </Button>
      </div>
    </div>
  );

  return (
    <Drawer
      position={RIGHT}
      size={SIZE}
      show={drawerIsOpen}
      onRequestHide={onRequestHide}
      header={drawerHeader}
      closeOnClickOutside={false}>
      {drawerContent}
    </Drawer>
  );
};

export default AdvancedSearchDrawer;
