import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { cx } from 'emotion';

import Checkbox from '@cimpress/react-components/lib/Checkbox';
import Modal from '@cimpress/react-components/lib/Modal';

import { LeftXTag } from '../../../../shared/LeftXTag';
import { anchorButtonInLineText, negative10TopMargin } from '../../constants/css';
import messages from '../../messages';
import localMessages from '../messages';

const StandardTag = ({
  product,
  selectedAdoptions,
  allAdoptions,
  removable,
  removeSelectedStandard,
  updateSelectedAdoptions,
}) => {
  const { formatMessage } = useIntl();

  const [showModal, setShowModal] = useState(false);
  const [tempSelectedAdoptions, setTempSelectedAdoptions] = useState(
    selectedAdoptions.reduce(function (acc, curr) {
      acc[curr.productId] = curr;
      return acc;
    }, {})
  );

  const doneClicked = () => {
    updateSelectedAdoptions(product.productId, Object.values(tempSelectedAdoptions));
    closeModal();
  };

  const cancelClicked = () => {
    setTempSelectedAdoptions(
      selectedAdoptions.reduce(function (acc, curr) {
        acc[curr.productId] = curr;
        return acc;
      }, {})
    );
    closeModal();
  };

  const closeModal = () => setShowModal(false);
  const openModal = () => setShowModal(true);

  const toggleAdoption = (e, adoption) => {
    if (tempSelectedAdoptions.hasOwnProperty(adoption.productId)) {
      const copyTempSelected = JSON.parse(JSON.stringify(tempSelectedAdoptions));
      delete copyTempSelected[adoption.productId];
      setTempSelectedAdoptions(copyTempSelected);
    } else {
      setTempSelectedAdoptions({ ...tempSelectedAdoptions, [adoption.productId]: adoption });
    }
  };

  const label = (
    <span key={`label-${product.productId}`}>
      {product.productId} -
      <button className={cx(anchorButtonInLineText)} onClick={openModal}>
        {selectedAdoptions.length === 1
          ? formatMessage(localMessages.adoptionTagSingle)
          : formatMessage(localMessages.adoptionTagPlural, { adoptionCount: selectedAdoptions.length })}
      </button>
    </span>
  );

  return (
    <Fragment>
      <Modal
        key={`modal-${product.productId}`}
        show={showModal}
        onRequestHide={cancelClicked}
        title={formatMessage(localMessages.adoptionModalTitle, { productId: product.productId })}
        closeButton={true}
        footer={
          <div className={negative10TopMargin}>
            <button className="btn btn-default" onClick={cancelClicked}>
              {formatMessage(messages.cancel)}
            </button>
            <button className="btn btn-primary" onClick={doneClicked}>
              {formatMessage(messages.done)}
            </button>
          </div>
        }>
        <Fragment>
          <p>{formatMessage(localMessages.adoptionSelectionDetails)}</p>
          {allAdoptions.map((x) => {
            return (
              <Checkbox
                key={`adoption-${x.productId}`}
                label={x.name}
                checked={tempSelectedAdoptions.hasOwnProperty(x.productId)}
                payload={x}
                onChange={toggleAdoption}
                disabled={
                  tempSelectedAdoptions.hasOwnProperty(x.productId) && Object.keys(tempSelectedAdoptions).length === 1
                }
              />
            );
          })}
        </Fragment>
      </Modal>
      <LeftXTag
        data-testid="selectedProductTag"
        label={label}
        onRemove={removeSelectedStandard}
        removable={removable}
        key={`tag-${product.productId}-${product.name}`}
      />
    </Fragment>
  );
};

export default StandardTag;
