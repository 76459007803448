import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import flatMap from 'lodash/flatMap';
import { css } from 'emotion';

import NavTab from '@cimpress/react-components/lib/NavTab';
import NavTabItem from '@cimpress/react-components/lib/NavTabItem';
import Pagination from '@cimpress/react-components/lib/Pagination';
import Select from '@cimpress/react-components/lib/Select';
import TextField from '@cimpress/react-components/lib/TextField';
import Button from '@cimpress/react-components/lib/Button';
import { ocean } from '@cimpress/react-components/lib/colors';
import IconSearch from '@cimpress-technology/react-streamline-icons/lib/IconSearch';

import AddForm from '../shared/addForm';
import DataSheetsContainer from './dataSheetsContainer';
import ConstraintComponent from './constraintComponent';
import RuleOrDataSheetPrompt from './ruleOrDataSheetPrompt';

import { updateRule, addRule, addDataSheet, deleteDataSheet } from './constraintActions';
import { intlShape, constraintRuleShape, optionShape, dataSheetsShape } from '../shared/propTypes';
import { validateRuleName } from '../shared/validators/keyValidators';
import { ruleValidator } from '../validators/rules';
import { filterConstraints } from './util';
import messages from './messages';

const RULES_TAB = 'RULES';
const DATASHEET_TAB = 'DATASHEETS';
const RETURN = 13;

const selectWidth = css`
  max-width: 100px;
  min-width: 100px;
`;

const flex = css`
  display: flex;
  justify-content: space-between;
`;

const searchBar = css`
  width: 40%;
  margin: auto;
  margin-bottom: 20px;
`;

const searchButtonStyle = css`
  height: 48px;
  padding-left: 15px !important;
  padding-right: 15px !important;
`;

class ConstraintsOverviewComponent extends Component {
  static propTypes = {
    constraints: PropTypes.shape({
      dataSheets: PropTypes.arrayOf(PropTypes.shape(dataSheetsShape)),
      rules: PropTypes.arrayOf(PropTypes.shape(constraintRuleShape)),
    }).isRequired,
    options: PropTypes.objectOf(PropTypes.shape(optionShape)).isRequired,
    intl: PropTypes.shape(intlShape).isRequired,
    onChange: PropTypes.func.isRequired,
    allowDataSheets: PropTypes.bool.isRequired,
    shouldPaginate: PropTypes.bool,
    itemsPerPageList: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.number, label: PropTypes.string })),
    getRuleError: PropTypes.func,
  };

  static defaultProps = {
    shouldPaginate: true,
    itemsPerPageList: [
      { value: 5, label: '5' },
      { value: 10, label: '10' },
      { value: 25, label: '25' },
      { value: 50, label: '50' },
    ],
    getRuleError: () => '',
  };

  state = {
    pendingConstraintName: '',
    selectedTab: RULES_TAB,
    page: 1,
    itemsPerPage: this.props.itemsPerPageList[0].value,
    itemsPerPageSelected: this.props.itemsPerPageList[0],
    constraintFilterValue: '',
    searchValue: '',
  };

  onChangeWithValidation = (constraints) => {
    this.props.onChange({
      constraints,
      errors: constraints.rules
        ? flatMap(
            constraints.rules.map((rule) => ruleValidator(rule).map((error) => ({ name: rule.name, message: error })))
          )
        : null,
    });
  };

  onPageChange = ({ selected }) => {
    this.setState({ page: selected + 1 });
  };

  onAddFormClick = (ruleName) => {
    !this.props.allowDataSheets ? this.onAddConstraint(ruleName) : this.setState({ pendingConstraintName: ruleName });
  };

  onAddConstraint = (ruleName) => {
    this.onChangeWithValidation({
      ...this.props.constraints,
      rules: addRule(this.props.constraints.rules, ruleName),
    });
    this.clearPendingConstraintName();
    this.setState({ page: 1, selectedTab: RULES_TAB });
  };

  clearPendingConstraintName = () => this.setState({ pendingConstraintName: '' });

  validateRule = (ruleName) => {
    if (!ruleName) {
      return '';
    }

    const { rules, dataSheets } = this.props.constraints;
    return validateRuleName(ruleName, rules, 'Rule') || validateRuleName(ruleName, dataSheets, 'Data Sheet');
  };

  addCompleteOptionConstraint = (constraint) => {
    const { constraints } = this.props;
    this.onChangeWithValidation({ ...constraints, rules: [constraint].concat(constraints.rules) });
    this.setState({ page: 1 });
  };

  onUpdateRule = (id, rule) => {
    const { constraints } = this.props;
    this.onChangeWithValidation({ ...constraints, rules: updateRule(constraints.rules, id, rule) });
  };

  onAddDataSheet = (name) => {
    const { constraints } = this.props;

    this.onChangeWithValidation({
      ...constraints,
      dataSheets: addDataSheet(constraints.dataSheets, name),
    });
    this.clearPendingConstraintName();
    this.setState({ page: 1, selectedTab: DATASHEET_TAB });
  };

  onUpdateDataSheet = (dataSheets) => {
    const { constraints } = this.props;

    this.onChangeWithValidation({ ...constraints, dataSheets });
  };

  onDeleteDataSheet = (deleteKey) => {
    const { constraints } = this.props;

    this.onChangeWithValidation({
      ...constraints,
      dataSheets: deleteDataSheet(constraints.dataSheets, deleteKey),
    });
  };

  changeItemsPerPage = (itemsPerPageSelected) => {
    this.setState({ itemsPerPageSelected, itemsPerPage: itemsPerPageSelected.value, page: 1 });
  };

  onClickSearch = () => {
    this.setState({ searchValue: this.state.constraintFilterValue });
  };

  onKeyDownFilter = (e) => {
    e.keyCode === RETURN && this.onClickSearch();
  };

  onFilterChange = (e) => {
    this.setState({ searchValue: '', constraintFilterValue: e.target.value });
  };

  render() {
    const {
      shouldPaginate,
      options = [],
      constraints = {},
      allowDataSheets,
      itemsPerPageList,
      getRuleError,
      intl: { formatMessage },
    } = this.props;

    const { rules = [], dataSheets = [] } = constraints;
    const {
      pendingConstraintName,
      selectedTab,
      page,
      itemsPerPage,
      itemsPerPageSelected,
      searchValue,
      constraintFilterValue,
    } = this.state;

    const filteredRules = filterConstraints(rules, searchValue);
    const pageCount = Math.ceil(filteredRules.length / itemsPerPage);
    const minIndex = itemsPerPage * (page - 1);
    const maxIndex = itemsPerPage * page;

    const rulesTabContent = (
      <div>
        {filteredRules.length ? (
          <Fragment>
            {shouldPaginate ? (
              <Fragment>
                {filteredRules.slice(minIndex, maxIndex).map((rule, index) => {
                  if (minIndex > 0) {
                    index += minIndex;
                  }
                  return (
                    <ConstraintComponent
                      key={rule.id}
                      options={options}
                      constraintRules={rules}
                      constraintRule={rule}
                      onChange={this.onUpdateRule}
                      addCompleteOptionConstraint={this.addCompleteOptionConstraint}
                      errorMessage={getRuleError(rule)}
                    />
                  );
                })}
              </Fragment>
            ) : (
              <Fragment>
                {filteredRules.map((rule, i) => {
                  return (
                    <ConstraintComponent
                      key={rule.id}
                      options={options}
                      constraintRules={rules}
                      constraintRule={rule}
                      onChange={this.onUpdateRule}
                      addCompleteOptionConstraint={this.addCompleteOptionConstraint}
                      errorMessage={getRuleError(rule)}
                    />
                  );
                })}
              </Fragment>
            )}

            {shouldPaginate && (
              <Fragment>
                <div className={flex}>
                  <div className={selectWidth}>
                    <Select
                      label="Per Page"
                      options={itemsPerPageList}
                      isSearchable={false}
                      value={itemsPerPageSelected}
                      onChange={this.changeItemsPerPage}
                      isClearable={false}
                    />
                  </div>
                  <div>
                    <Pagination
                      pageCount={pageCount}
                      onPageChange={this.onPageChange}
                      pagesOutsideElipses={1}
                      pagesBetweenElipses={3}
                      forcePage={page - 1}
                    />
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        ) : (
          <div>{formatMessage(messages.noRulesConfigured)}</div>
        )}
      </div>
    );

    const dataSheetsContent = (
      <div>
        {allowDataSheets ? (
          <DataSheetsContainer
            dataSheets={dataSheets}
            options={options}
            updateDataSheets={this.onUpdateDataSheet}
            onDelete={this.onDeleteDataSheet}
          />
        ) : null}
      </div>
    );

    const tabContent = selectedTab === RULES_TAB ? rulesTabContent : dataSheetsContent;

    return (
      <Fragment>
        {selectedTab === RULES_TAB && (
          <TextField
            className={searchBar}
            value={constraintFilterValue}
            onChange={this.onFilterChange}
            onKeyDown={this.onKeyDownFilter}
            placeholder={formatMessage(messages.filterConstraints)}
            rightAddon={
              <Button className={searchButtonStyle} onClick={this.onClickSearch}>
                <IconSearch color={ocean.base} size="lg" />
              </Button>
            }
          />
        )}
        <AddForm onAdd={this.onAddFormClick} validateOption={this.validateRule} />
        {pendingConstraintName ? (
          <RuleOrDataSheetPrompt
            name={pendingConstraintName}
            onDecided={this.clearPendingConstraintName}
            onAddConstraint={this.onAddConstraint}
            onAddDataSheet={this.onAddDataSheet}
          />
        ) : null}
        <div>
          <NavTab>
            <NavTabItem active={selectedTab === RULES_TAB}>
              <button onClick={() => this.setState({ selectedTab: RULES_TAB })}>{formatMessage(messages.rules)}</button>
            </NavTabItem>
            <NavTabItem active={selectedTab === DATASHEET_TAB} disabled={!allowDataSheets}>
              <button disabled={!allowDataSheets} onClick={() => this.setState({ selectedTab: DATASHEET_TAB })}>
                {formatMessage(messages.dataSheets)}
              </button>
            </NavTabItem>
          </NavTab>
          {tabContent}
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(ConstraintsOverviewComponent);
