import React from 'react';
import { css, cx } from 'emotion';
import PropTypes from 'prop-types';
import { alloy, shale } from '@cimpress/react-components/lib/colors';
import { convertDecimalToHex } from './HexConversion/hexConverter';

const tagCss = css`
  display: inline-block;
  border: 1px solid ${alloy};
  background-color: white;
  color: ${shale};
  border-radius: 12px;
  font-size: 10px;
  line-height: 14px;
  padding: 5px 9px;
  max-width: 80px;
  margin: 3px;
`;

const boxCss = css`
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border: 1px solid ${alloy};
  display: inline-block;
  align-items: center;
  line-height: 14px;
  margin-bottom: -2px;
`;

const HexWrapTag = ({ key, decimalValue }) => {
  const convertedValue = convertDecimalToHex(decimalValue);

  if (convertedValue === null) {
    return (
      <span className={tagCss} key={key}>
        {decimalValue}
      </span>
    );
  }

  return (
    <span className={tagCss} key={key}>
      <span
        className={cx(
          boxCss,
          css`
            background-color: ${convertedValue};
          `
        )}></span>
      {convertedValue}
    </span>
  );
};

HexWrapTag.propTypes = {
  style: PropTypes.object,
};

HexWrapTag.defaultProps = {
  style: {},
};

export default HexWrapTag;
